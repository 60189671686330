import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
import echartMixins from "@/utils/resizeMixins";
export default {
  props: {
    activeRegistNum: {
      type: Object,
      required: true
    },
    height: {
      type: [String, Number],
      required: 0
    }
  },
  data: function data() {
    return {
      chart: null,
      activeNumData: {},
      //激活数据
      categoryData: [],
      //横坐标
      actvedev: [],
      //设备激活
      loginAcc: [],
      //账号登录
      registAcc: [],
      //账号注册
      regitstDev: [] //设备注册

    };
  },
  mixins: [echartMixins],
  created: function created() {
    this.activeNumData = this.activeRegistNum;

    for (var k in this.activeNumData) {
      this.categoryData.push(k);
      this.actvedev.push(this.activeNumData[k].active_device);
      this.loginAcc.push(this.activeNumData[k].login_account);
      this.registAcc.push(this.activeNumData[k].register_account);
      this.regitstDev.push(this.activeNumData[k].recharge_account);
    }
  },
  mounted: function mounted() {
    this.draw();
  },
  methods: {
    draw: function draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bottomLeftBar"), this.setEchartWH); //  ----------------------------------------------------------------

      var category = this.categoryData;
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.3)"
        }
      };
      var option = {
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: ["设备激活数", "账号登录数", "账号注册数", "充值账号数"],
          textStyle: {
            color: "#B4B4B4"
          }
        },
        grid: {
          width: 'auto',
          height: 'auto',
          top: "20%",
          left: '0%',
          //默认10%
          right: '3%',
          //默认10%
          bottom: '0',
          //默认60
          containLabel: true
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          }
        },
        series: [{
          name: "设备激活数",
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: this.actvedev
        }, {
          name: "账号登录数",
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: this.loginAcc
        }, {
          name: "账号注册数",
          type: "bar",
          stack: "two",
          emphasis: emphasisStyle,
          data: this.registAcc
        }, {
          name: "充值账号数",
          type: "bar",
          stack: "two",
          emphasis: emphasisStyle,
          data: this.regitstDev
        }]
      };
      this.chart.setOption(option);
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  }
};