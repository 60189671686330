export default {
  props: {
    propsData: Object
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  activated: function activated() {},
  computed: {},
  methods: {}
};