var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page dataaLtv dataAll-content" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCountry: false } },
            on: { searchChange: _vm.searchChange }
          }),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择环境"
                      },
                      on: { change: _vm.severlistChange },
                      model: {
                        value: _vm.searchData.is_prod,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "is_prod", $$v)
                        },
                        expression: "searchData.is_prod"
                      }
                    },
                    _vm._l(_vm.severlist, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择服务器"
                      },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.name || item.serverName,
                          value: item.id || item.serverID
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "服务器渠道" },
                      on: { change: _vm.serverChannelChange },
                      model: {
                        value: _vm.server_channel,
                        callback: function($$v) {
                          _vm.server_channel = $$v
                        },
                        expression: "server_channel"
                      }
                    },
                    _vm._l(_vm.serverChannel, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "服务器"
                      },
                      on: { change: _vm.severChange },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverListYJ, function(item) {
                      return _c("el-option", {
                        key: item.serverID,
                        attrs: { label: item.serverName, value: item.serverID }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("royalpirates") == -1 &&
          _vm.rawData.game_mark.indexOf("yjfm") == -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "服务器ID"
                    },
                    model: {
                      value: _vm.searchData.server_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "server_id", $$v)
                      },
                      expression: "searchData.server_id"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex", justify: "end" } },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "sticky-table-header",
              rawName: "v-sticky-table-header",
              value: 0,
              expression: "0"
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          ref: "tableRef",
          attrs: { id: "tableRef", data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "date", label: "日期", "min-width": "105" }
          }),
          _vm.isShow
            ? _c("el-table-column", {
                attrs: {
                  prop: "server_id",
                  label: "服务器ID",
                  "min-width": "105"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(" " + _vm._s(row.server_id || "/") + " ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3434105287
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "login_pnum", label: "登录账号" }
          }),
          !_vm.isShow
            ? _c("el-table-column", {
                attrs: { prop: "login_times", label: "登录次数" }
              })
            : _vm._e(),
          !_vm.isShow
            ? _c("el-table-column", {
                attrs: {
                  prop: "duration_avg_per_login",
                  label: "平均每次登录时长"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " + _vm._s(row.duration_avg_per_login) + "(m) "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3790069734
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "duration_avg_per_account",
              label: "平均每账号在线时长"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.duration_avg_per_account) + "(m) ")
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }