//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogShow: false,
      list: []
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      var date = JSON.parse(data);
      this.list = [{
        reg_begintime: date.extra_param.reg_begintime,
        reg_endtime: date.extra_param.reg_endtime,
        type: date.extra_param.type,
        reward_num: date.reward_num
      }];
      this.dialogShow = true;
    },
    hiad: function hiad() {
      this.dialogShow = false;
    }
  }
};