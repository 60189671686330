import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      article: '',
      fullscreenLoading: true
    };
  },
  mounted: function mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      import('./content.js').then(function (data) {
        var title = data.default.title;
        document.title = title;
        _this.article = data.default;
        setTimeout(function () {
          _this.fullscreenLoading = false;

          _this.$nextTick(function () {
            window.print();
          });
        }, 3000);
      });
    }
  }
};