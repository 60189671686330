var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "产品权限管理", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.dataReset()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popover" },
            [
              _vm._l(_vm.productList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "gameItem" },
                  [
                    _c("img", {
                      staticClass: "gameItem-icon",
                      attrs: { src: _vm.$utils.imgTrow(item.icon), alt: "" }
                    }),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: item.name,
                          placement: "top"
                        }
                      },
                      [
                        _c("div", { staticClass: "gameItem-text" }, [
                          _vm._v(_vm._s(item.name))
                        ])
                      ]
                    ),
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { "margin-top": "16px" },
                        attrs: { size: "small" },
                        model: {
                          value: item.Selection,
                          callback: function($$v) {
                            _vm.$set(item, "Selection", $$v)
                          },
                          expression: "item.Selection"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: true, border: "" } }, [
                          _vm._v("开启")
                        ]),
                        _c(
                          "el-radio",
                          { attrs: { label: false, border: "" } },
                          [_vm._v("关闭")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              !_vm.productList.length
                ? _c(
                    "div",
                    {
                      staticClass: "x-c",
                      staticStyle: {
                        "font-size": "18px",
                        "font-weight": "500",
                        width: "100%"
                      }
                    },
                    [_vm._v(" 没有产品！ ")]
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { round: "" }, on: { click: _vm.dataReset } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }