//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addChannel, updataChannel } from "@/api/aymentsdk/getchancllist";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    num: {
      type: String,
      required: true
    },
    bindObj: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      id: "",
      searchData: {
        login_name: "",
        login_mark: ""
      }
    };
  },
  created: function created() {},
  watch: {
    bindObj: function bindObj(val) {
      if (this.show && this.num == 2) {
        this.searchData = val;
      }
    }
  },
  computed: {
    Myheader: function Myheader() {
      return {
        token: getToken()
      };
    },
    isShow: {
      get: function get() {
        if (this.show) {
          return this.show;
        }
      },
      set: function set(val) {
        this.$emit("changeShow", false);
      }
    },
    isnum: {
      get: function get() {
        if (this.num) {
          return this.num;
        }
      },
      set: function set() {}
    }
  },
  methods: {
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      if (this.isnum == "1") {
        addChannel(this.searchData).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              type: "success",
              message: "新增成功!"
            });

            _this.$emit("changeShow", false);

            _this.searchDat = {};
          } else {
            _this.$message({
              type: "error",
              message: "新增失败!"
            });

            _this.searchData = {};
          }
        });
      }

      if (this.isnum == "2") {
        updataChannel(this.searchData).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              type: "success",
              message: "修改成功!"
            });

            _this.$emit("changeShow", false);

            _this.$emit("changeData", _this.bindObj);

            _this.searchDat = {};
          } else {
            _this.$message({
              type: "error",
              message: "修改失败!"
            });

            _this.searchData = {};
          }
        });
      }
    },
    clearData: function clearData() {
      this.searchData = {};
    }
  }
};