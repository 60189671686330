//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { getPanglinData, exportPanglinData } from "@/api/dataAll/pangolin";
import clipboard from "@/utils/clipboard";
export default {
  name: "pangolinData",
  mixins: [serchSelect],
  data: function data() {
    return {
      total: 0,
      pagrSize: 50,
      addWayConfig: false,
      tableData: [],
      sumData: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      getPanglinData(this.searchData).then(function (res) {
        if (res.status == 200) {
          var list = ['game', 'platform', 'channel', 'pkg'];
          _this.tableData = res.data.data.data.length ? _this.$utils.dataConversion(res.data.data.data, list) : [];
          _this.sumData = res.data.sum;
          _this.total = res.data.data.total - 0 || 0;
        }
      });
    },
    exportdata: function exportdata() {
      if (this.searchData.game != "" && this.searchData.platform != "") {
        if (process.env.NODE_ENV) {
          window.location.href = process.env.VUE_APP_API + "/datum/export_pangolin" + "?game=" + (this.searchData.game == undefined ? "" : this.searchData.game) + "&" + "platform=" + (this.searchData.platform = undefined ? "" : this.searchData.platform) + "&" + "channel=" + (this.searchData.channel == undefined ? "" : this.searchData.channel) + "&" + "pkg=" + (this.searchData.pkg == undefined ? "" : this.searchData.pkg) + "&pag" + this.searchData.pag;
          "&kind=" + "1";
        }
      } else {
        this.$message({
          message: "请填完必选项哦"
        });
      }
    },
    handleSource: function handleSource(id, num) {
      var _this2 = this;

      this.addWayConfig = true;
      this.$nextTick(function () {
        _this2.$refs.addWayConfig.init(id, num);
      });
    },
    handleClipboard: function handleClipboard(text, event) {
      clipboard(text, event);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.searchClick();
    }
  }
};