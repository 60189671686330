import request from "@/utils/request";
export function rawDataSummary(data) {
  return request({
    url: "/data/rawDataSummary",
    method: "get",
    params: data
  });
}
export function reportDataList(data) {
  return request({
    url: "/data/reportDataList",
    method: "get",
    params: data
  });
}