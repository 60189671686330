var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogShow,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                model: _vm.paramsData,
                "label-width": "100px"
              }
            },
            [
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    { staticClass: "lodingItem", attrs: { label: "等级" } },
                    [
                      _c(
                        "el-form-item",
                        { key: "maxlevel", attrs: { prop: "level.maxlevel" } },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number leftItem",
                            attrs: {
                              disabled: "",
                              type: "number",
                              placeholder: "等级上限"
                            },
                            model: {
                              value: _vm.paramsData.level.maxlevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.level,
                                  "maxlevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.level.maxlevel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { key: "minlevel", attrs: { prop: "level.minlevel" } },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number ",
                            attrs: {
                              disabled: "",
                              type: "number",
                              placeholder: "等级下限"
                            },
                            model: {
                              value: _vm.paramsData.level.minlevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.level,
                                  "minlevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.level.minlevel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    {
                      key: "sdk_type",
                      staticClass: "lodingItem",
                      attrs: { prop: "sdk_type", label: "sdk_type" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "flex-1 no-number",
                        attrs: {
                          disabled: "",
                          type: "number",
                          placeholder: "0 - 65535"
                        },
                        model: {
                          value: _vm.paramsData.sdk_type,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "sdk_type", _vm._n($$v))
                          },
                          expression: "paramsData.sdk_type"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    { staticClass: "lodingItem", attrs: { label: "VIP等级" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "maxviplevel",
                          attrs: { prop: "vip_level.maxviplevel" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number leftItem",
                            attrs: {
                              disabled: "",
                              type: "number",
                              placeholder: "等级上限"
                            },
                            model: {
                              value: _vm.paramsData.vip_level.maxviplevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.vip_level,
                                  "maxviplevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.vip_level.maxviplevel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          key: "minviplevel",
                          attrs: { prop: "vip_level.minviplevel" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number",
                            attrs: {
                              disabled: "",
                              type: "number",
                              placeholder: "等级下限"
                            },
                            model: {
                              value: _vm.paramsData.vip_level.minviplevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.vip_level,
                                  "minviplevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.vip_level.minviplevel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    {
                      key: "maxlogouttime",
                      staticClass: "lodingItem",
                      attrs: {
                        prop: "logout_time.maxlogouttime",
                        label: "时间上限"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          disabled: "",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "时间上限",
                          align: "right"
                        },
                        model: {
                          value: _vm.paramsData.logout_time.maxlogouttime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.paramsData.logout_time,
                              "maxlogouttime",
                              _vm._n($$v)
                            )
                          },
                          expression: "paramsData.logout_time.maxlogouttime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    {
                      key: "minlogouttime",
                      staticClass: "lodingItem",
                      attrs: {
                        prop: "logout_time.minlogouttime",
                        label: "时间下限"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          disabled: "",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "时间下限",
                          align: "right"
                        },
                        model: {
                          value: _vm.paramsData.logout_time.minlogouttime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.paramsData.logout_time,
                              "minlogouttime",
                              _vm._n($$v)
                            )
                          },
                          expression: "paramsData.logout_time.minlogouttime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }