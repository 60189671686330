var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCountry: false } },
            on: { searchChange: _vm.searchChange }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "type-padds",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择类型"
                  },
                  model: {
                    value: _vm.searchData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "type", $$v)
                    },
                    expression: "searchData.type"
                  }
                },
                _vm._l(_vm.typeArr, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.type_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "game",
                  label: "游戏",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform",
                  label: "平台",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channel",
                  label: "渠道",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_channel",
                  label: "广告渠道",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_placement",
                  label: "广告位置",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pkg",
                  label: "包名",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "timestamp", label: "日期", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$times.timestampToTime(
                                  row.timestamp,
                                  "YY-MM-DD H"
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2828590360
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "aid_name",
                  label: "广告计划名称",
                  "show-overflow-tooltip": "",
                  width: "200"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "campaign_name",
                  label: "广告创意名称",
                  "show-overflow-tooltip": "",
                  width: "200"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ctype",
                  label: "广告样式",
                  "show-overflow-tooltip": "",
                  width: "200"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "os",
                  label: "系统",
                  "show-overflow-tooltip": "",
                  width: "120"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c("el-pagination", {
            attrs: {
              layout: "total, prev, pager, next",
              "current-page": _vm.searchData.page,
              total: _vm.total,
              "page-size": _vm.pagrSize
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        : _vm._e(),
      _vm.addWayConfig
        ? _c("addLinks", {
            ref: "addWayConfig",
            on: { refreshDataList: _vm.searchClick }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }