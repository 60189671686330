import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogShow: false,
      data: '',
      type: ''
    };
  },
  created: function created() {},
  methods: {
    init: function init(data, type) {
      this.type = type;
      this.data = data.map(function (i) {
        i.type_id = i.type_id ? i.type_id : i.item_id;
        return i;
      });
      this.dialogShow = true;
    }
  }
};