import { clickOperationLog } from "@/api/gmtool/packageCode";
export default {
  data: function data() {
    return {};
  },
  created: function created() {},
  computed: {},
  methods: {
    lodclickOperationLog: function lodclickOperationLog(type, name) {
      clickOperationLog({
        click: type,
        name: name
      });
    }
  }
};