import "core-js/modules/es.array.find";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { serchSelect, tabulation, gmToolNewPage } from "@/mixins/index";
import { getServerDataList, getServerDataListCurrency } from "@/api/dataAll/dataAll";
import { serverList as hdServerList } from "@/api/gmtool/packageCode";
import { serverList as yjServerList } from "@/api/gmToolNew/gmToolNew";
export default {
  name: "serverDimensionData",
  mixins: [serchSelect, tabulation, gmToolNewPage],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        server_id: [],
        scope: ''
      },
      PrimitiveserverLists: [],
      serverLists: [],
      tableData: [],
      Dynamic: [],
      isServer: false
    };
  },
  created: function created() {
    this.getServerLists();
    this.isServer = this.rawData.game_mark.indexOf('royalpirates') > -1 || this.rawData.game_mark.indexOf('yjfm') > -1 ? true : false;
  },
  computed: _objectSpread({}, mapState("d2admin", {
    severlist: function severlist(state) {
      return state.user.GMauthority;
    }
  })),
  methods: {
    //请求服务器列表  用于指定海盗和御剑请求
    getServerLists: function getServerLists() {
      this.RollSuit = false;
      this.serverLists = [];
      this.searchData.server_id = [];
      this.searchData.is_prod = '';
      var game = this.rawData.game_mark;

      if (game.indexOf(this.royalpirates) > -1) {
        this.RollSuit = this.severlist.length ? true : false;
      } else if (game.indexOf('yjfm') > -1) {
        this.RollSuit = true;
      }
    },
    searchChange: function searchChange(e) {
      this[e.type] = e.data instanceof Array ? e.data : _objectSpread(_objectSpread({}, this.searchData), e.data);
      this.screensever();
    },
    DataChange: function DataChange() {
      this.screensever();
    },
    severlistChange: function severlistChange(e) {
      var _this = this;

      this.PrimitiveserverLists = [];
      this.serverLists = [];
      this.searchData.server_id = [];

      if (e) {
        hdServerList({
          is_prod: this.searchData.is_prod
        }).then(function (res) {
          if (res.status == 200) {
            res.data.map(function (i) {
              i.openTime = _this.$times.dateTimeToTimeStamp(_this.$times.formDate(i.openTime));
            });
            _this.PrimitiveserverLists = res.data;

            _this.screensever();
          }
        });
      }
    },
    screensever: function screensever() {
      if (!this.value2[0]) {
        return;
      }

      var list = [];
      var timeData = {
        startTime: this.$times.dateTimeToTimeStamp(this.value2[0] + " 00:00:00"),
        endTime: this.$times.dateTimeToTimeStamp(this.value2[1] + " 23:59:59")
      };
      this.PrimitiveserverLists.forEach(function (i) {
        if (timeData.startTime <= i.openTime && timeData.endTime >= i.openTime) {
          list.push(i);
        }
      });
      this.serverLists = list;
    },
    searchClick: function searchClick() {
      var _this2 = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      var data = JSON.parse(JSON.stringify(this.searchData));

      if (this.value2.length == 0) {
        this.$message.error('请选择时间');
        return;
      }

      if (this.rawData.game_mark.indexOf('royalpirates') > -1 && !data.is_prod) {
        this.$message.error('请选择环境');
        return;
      }

      if (data.server_id.length == 0) {
        this.$message.error('请选择服务器');
        return;
      }

      if (data.scope.indexOf(',') > -1) {
        var str = data.scope.split(',');
        var strOne = '';
        str.map(function (i) {
          if (i) {
            strOne += i + ',';
          }
        });
        data.scope = strOne.substring(0, strOne.length - 1);
      }

      this.reqLoading = true;
      data.starttime = this.value2[0];
      data.endtime = this.value2[1]; // if(data.server_id.length == 0 ){
      //   data.server_id = this.serverLists.map(i=>{return i.serverID})
      // }

      var reqFn = this.currencyClose ? getServerDataListCurrency : getServerDataList;
      reqFn(_objectSpread(_objectSpread({}, data), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          res.data.map(function (i) {
            i.serverName = _this2.serverLists.find(function (j) {
              return j.serverID == i.server_id;
            }).serverName;
            i.openTime = _this2.$times.timestampToTime(_this2.serverLists.find(function (j) {
              return j.serverID == i.server_id;
            }).openTime / 1000);
            i.amount = _this2.$utils.conversion(i.amount);
          });
          _this2.tableData = res.data;

          _this2.$nextTick(function () {
            _this2.getTableRight();
          });
        }
      }).finally(function () {
        _this2.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '服务器名称',
        prop: 'serverName'
      }, {
        label: '开服时间',
        prop: 'openTime'
      }, {
        label: '天数',
        prop: 'day'
      }, {
        label: '新增账号',
        prop: 'new'
      }, {
        label: '充值人数',
        prop: 'recharge'
      }, {
        label: '付费金额',
        prop: 'amount'
      }];
      this.$export.excel({
        columns: columns,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};