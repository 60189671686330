import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accrechargeGear, accrechargeGear_currency } from "@/api/dataAll/recharge";
export default {
  name: "accrechargeGear",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: [],
      intReq: ['getCountrys']
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      var reqFn = this.currencyClose ? accrechargeGear_currency : accrechargeGear;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this.tableData = res.data;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }, {
        label: '充值人数',
        prop: 'recharge_num'
      }, {
        label: '首充人数',
        prop: 'first_num'
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};