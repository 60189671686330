import request from '@/utils/request'; // 主播数据

export function hostdata(data) {
  return request({
    url: 'hostdata',
    method: 'GET',
    params: data
  });
} // 主播列表

export function hostList(data) {
  return request({
    url: 'hostdata/host-list',
    method: 'GET',
    params: data
  });
} // 添加主播

export function addhost(data) {
  return request({
    url: 'hostdata/add-host',
    method: 'POST',
    params: data
  });
} // 删除主播

export function delhost(data) {
  return request({
    url: 'hostdata/del-host',
    method: 'POST',
    params: data
  });
}