import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { dataAll } from "@/api/dataAll/new";
export default {
  name: "channelSummary",
  mixins: [serchSelect],
  data: function data() {
    return {
      per_page: 10,
      total: 0,
      page: 1,
      value2: [],
      searchData: {
        page: 1
      },
      tableData: [],
      show: false,
      more: true
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.searchClick();
    },
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      dataAll(this.searchData).then(function (res) {
        _this.tableData = res.data.data || [];
        _this.total = res.data.total || 0;
        _this.page = res.data.current_page;
        _this.per_page = res.data.per_page;
        _this.show = true;
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "日期",
        prop: "date"
      }, {
        label: "ID",
        prop: "id"
      }, {
        label: "游戏",
        prop: "game"
      }, {
        label: "平台",
        prop: "platform"
      }, {
        label: "渠道",
        prop: "channel"
      }, {
        label: "包名",
        prop: "pkg"
      }, {
        label: "广告渠道",
        prop: "platform"
      }, {
        label: "收入",
        prop: "revenue"
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};