// 信息共享分类清单
import request from '@/utils/request';
export function getmsgSharList(data) {
  return request({
    url: '/datum/information_sharing_classify',
    method: 'get',
    params: data
  });
} //新增信息分类

export function addmsgSharing(data) {
  return request({
    url: '/datum/add_classify',
    method: 'post',
    params: data
  });
} //获取信息反显

export function returnmsgSharing(data) {
  return request({
    url: '/datum/up_classify',
    method: 'get',
    params: data
  });
} //修改分类

export function updatenmsgSharing(data) {
  return request({
    url: '/datum/up_classify',
    method: 'post',
    params: data
  });
} //删除分类

export function delemsgSharing(data) {
  return request({
    url: '/datum/del_classify',
    method: 'get',
    params: data
  });
}