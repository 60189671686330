var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id !== "" ? "修改" : "新增",
            visible: _vm.dialogShow
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData(true)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "version", label: "版本号" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      placeholder: "请输入版本号",
                      disabled: _vm.paramsData.id ? true : false
                    },
                    model: {
                      value: _vm.paramsData.version,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "version", $$v)
                      },
                      expression: "paramsData.version"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "pkgId", label: "渠道" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        disabled: _vm.paramsData.id ? true : false,
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.pkgId,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "pkgId", $$v)
                        },
                        expression: "paramsData.pkgId"
                      }
                    },
                    _vm._l(_vm.examinePkgLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.gm_channel_name || item.pkgData,
                          value: item.id
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "onoff", label: "状态" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.paramsData.onoff,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "onoff", $$v)
                        },
                        expression: "paramsData.onoff"
                      }
                    },
                    _vm._l(_vm.onoffs, function(item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.value,
                          attrs: { label: item.value, border: "" }
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }