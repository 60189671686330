import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accActivity } from "@/api/dataAll/new";
import * as echarts from "echarts";
export default {
  name: "accActivity",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: [],
      echarts: false,
      dates: [],
      series: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.kind = 1;
      this.reqLoading = true;
      accActivity(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this.tableData = [];
          _this.series = [];
          _this.dates = [];
          var obj = {
            name: '活跃度',
            type: "line",
            data: [],
            smooth: true
          };

          for (var k in res.data.other.login_account_num) {
            _this.tableData.push({
              date: k,
              num: res.data.other.login_account_num[k]
            });

            _this.dates.push(k);

            obj.data.push(res.data.other.login_account_num[k]);
          }

          _this.series.push(obj);
        }

        _this.getechart();

        _this.$nextTick(function () {
          _this.getTableRight();
        });
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("accActivityEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: '账号活跃度'
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          // 这里显示点击搜索的日期--图表的最上面
          // selected: this.selectedArr,
          width: "80%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.dates
        },
        yAxis: {
          type: "value"
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }, {
        label: '登录人数',
        prop: 'num'
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};