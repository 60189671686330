import request from '@/utils/request'; // facebook 元数据
// 列表

export function facebookMetaList(data) {
  return request({
    url: '/facebook/meta-data',
    method: 'get',
    params: data
  });
} //增

export function addFacebookMeta(data) {
  return request({
    url: '/facebook/add/meta-data',
    method: 'post',
    params: data
  });
} //改

export function updFacebookMeta(data) {
  return request({
    url: '/facebook/update/meta-data',
    method: 'post',
    params: data
  });
} // 删

export function delFacebookMeta(data) {
  return request({
    url: '/facebook/delete/meta-data',
    method: 'post',
    params: data
  });
}