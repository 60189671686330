import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { generateCode } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var _this = this;

    var validateCode = function validateCode(rule, value, callback) {
      if (value === '' || _this.paramsData.is_special == 0 && value.length !== 3 || _this.paramsData.is_special == 1 && (value.length < 4 || value.length > 8)) {
        callback(new Error('code不正确'));
      } else {
        callback();
      }
    };

    var validateBodyLength = function validateBodyLength(rule, value, callback) {
      if (value === '' || _this.paramsData.is_special == 0 && (value < 6 || value > 8) || _this.paramsData.is_special == 1 && (value < 4 || value > 8)) {
        callback(new Error('长度不正确'));
      } else {
        callback();
      }
    };

    return {
      titleName: "",
      paramsData: {
        code: '',
        expire_time: null,
        item_list: [{
          num: '',
          expire: '',
          item_id: '',
          ispermanent: false
        }],
        is_special: 0,
        num: null,
        reward_num: null,
        body_length: '',
        register_start_time: 0,
        register_end_time: 0
      },
      value1: '',
      ex_Val: [],
      date: [],
      expire_time: '',
      dialogShow: false,
      whetherNot: [{
        label: '是',
        value: 1
      }, {
        label: '否',
        value: 0
      }],
      type: 'add',
      start: '',
      rules: {
        code: [{
          validator: validateCode,
          required: true,
          trigger: "blur"
        }],
        num: [{
          required: true,
          message: "请输入礼包码数量",
          trigger: "blur"
        }],
        reward_num: [{
          required: true,
          message: "请输入礼包码可领取数量",
          trigger: "blur"
        }],
        body_length: [{
          validator: validateBodyLength,
          required: true,
          trigger: "blur"
        }],
        item_id: [{
          required: true,
          message: "请选择物品",
          trigger: "blur"
        }],
        expire_time: [{
          required: true,
          message: "请选择过期时间",
          trigger: "blur"
        }],
        item_num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.start = this.$times.timestampToTime(Date.parse(new Date(this.$times.zeroTimeZone())) / 1000, 'YY-MM-DD');
  },
  methods: {
    open: function open(type, data) {
      this.type = type;

      if (type == 'add') {} else {
        this.paramsData = _objectSpread({}, data);
        this.date = [data.start_time * 1000, data.end_time * 1000];
      }

      this.dialogShow = true;
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    addDomain: function addDomain() {
      this.paramsData.item_list.push({
        num: '',
        expire: '',
        item_id: '',
        ispermanent: false
      });
    },
    removeDomain: function removeDomain(item) {
      var index = this.paramsData.item_list.indexOf(item);

      if (index !== -1) {
        this.paramsData.item_list.splice(index, 1);
      }
    },
    clearData: function clearData() {
      this.paramsData = {
        code: '',
        expire_time: null,
        item_list: [{
          num: '',
          expire: '',
          item_id: '',
          ispermanent: false
        }],
        is_special: 0,
        num: null,
        reward_num: null,
        body_length: '',
        register_start_time: 0,
        register_end_time: 0
      };
      this.date = [];
      this.expire_time = '';
      this.ex_Val = [];
      this.$refs.ruleForm.clearValidate();
    },
    whetherNotChange: function whetherNotChange(e) {
      this.paramsData.code = '';
      this.paramsData.body_length = '';
    },
    dataChange: function dataChange(e) {
      if (e) {
        if (e[0] == e[1]) {
          this.$message({
            message: '开始时间不能等于结束时间',
            type: 'warning'
          });
          this.date = [];
          return;
        }
      }

      this.paramsData.register_start_time = e ? e[0] / 1000 : 0;
      this.paramsData.register_end_time = e ? e[1] / 1000 : 0;
    },
    expireChange: function expireChange(e) {
      var timestamp = Date.parse(new Date(this.$times.zeroTimeZone()));

      if (e <= timestamp) {
        this.$message({
          message: '过期时间必须大于今天',
          type: 'warning'
        });
        this.expire_time = '';
        return;
      }

      this.paramsData.expire_time = e / 1000;
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;

      this.$refs.ruleForm.clearValidate();
      var paramsData = JSON.parse(JSON.stringify(this.paramsData));

      if (!paramsData.item_list.length) {
        this.$message({
          message: '请选择物品',
          type: 'warning'
        });
        return;
      }

      paramsData.item_list.forEach(function (item) {
        item.item_id = parseInt(item.item_id), item.expire = item.ispermanent ? 0 : item.expire, item.num = parseInt(item.num) || null;
      });
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          paramsData.item_list = JSON.stringify(paramsData.item_list);
          paramsData.body_length = parseInt(paramsData.body_length) || '';
          paramsData.num = parseInt(paramsData.num) || null;
          paramsData.reward_num = parseInt(paramsData.reward_num) || null;

          if (_this2.type == 'add') {
            generateCode(_objectSpread(_objectSpread({}, paramsData), _this2.prod)).then(function (res) {
              if (res.status == 200) {
                _this2.$message({
                  message: '添加成功',
                  type: 'success'
                });

                _this2.dialogShow = false;

                _this2.$emit('update');
              }
            });
          } else {
            upAnnounce({
              data: data
            }).then(function (res) {
              if (res.status == 200) {
                _this2.$message({
                  message: '修改成功',
                  type: 'success'
                });

                _this2.dialogShow = false;

                _this2.$emit('upd');
              }
            });
          }
        }
      });
    }
  }
};