import request from '@/utils/request'; // 广告数据展示--账号

export function accadvShowData(data) {
  return request({
    url: '/datum/ad_account',
    method: 'get',
    params: data
  });
} //   广告数据展示--设备

export function advShowData(data) {
  return request({
    url: '/datum/ad_device',
    method: 'get',
    params: data
  });
} // 广告原始数据

export function getAdvistOrigin(data) {
  return request({
    url: '/datum/ad_original_data',
    method: 'get',
    params: data
  });
}