import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { activityTypeAdd, activityTypeUpd, getActivityConfigNameList } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  data: function data() {
    return {
      paramsData: {
        id: '',
        name: '',
        file: [],
        development_config_id: ''
      },
      ConfigNameList: [],
      dialogShow: false,
      rules: {
        name: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        file: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        development_config_id: [{
          required: true,
          message: "请输入配置ID",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    // 获取配置列表
    getgetActivityConfigNameList: function getgetActivityConfigNameList() {
      var _this = this;

      getActivityConfigNameList().then(function (res) {
        if (res.status == 200) {
          _this.ConfigNameList = res.data;
        }
      });
    },
    open: function open(row) {
      this.getgetActivityConfigNameList();

      if (row) {
        var data = JSON.parse(JSON.stringify(row));
        data.file = data.file ? data.file.split(',') : [];
        this.paramsData = _objectSpread(_objectSpread({}, this.paramsData), data);
      }

      this.dialogShow = true;
    },
    clearData: function clearData(isClearId) {
      this.paramsData = {
        id: isClearId ? '' : this.paramsData.id,
        name: '',
        file: []
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.paramsData));
          data.file = data.file.toString();
          var reqFn = data.id !== '' ? activityTypeUpd : activityTypeAdd;
          reqFn(data).then(function (res) {
            if (res.status == 200) {
              _this2.$bus.$emit('nameUpd', data.id);

              _this2.$message({
                message: '操作成功',
                type: 'success'
              });

              _this2.dialogShow = false;

              _this2.$emit('update');
            }
          });
        }
      });
    }
  }
};