import "core-js/modules/es.object.to-string";
import { serverList } from "@/api/gmToolNew/gmToolNew";
import store from '@/store/index';
export default {
  namespaced: true,
  state: {
    serverListYJ: []
  },
  actions: {
    getserverListYJ: function getserverListYJ(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        serverList().then(function (res) {
          if (res.code == 200) {
            commit('set_serverListYJ', res.data);
            resolve(res.data);
          }
        });
      });
    }
  },
  mutations: {
    set_serverListYJ: function set_serverListYJ(state, data) {
      state.serverListYJ = data;
    }
  }
};