import { render, staticRenderFns } from "./unifiedUser.vue?vue&type=template&id=0f12faaf&"
import script from "./unifiedUser.vue?vue&type=script&lang=js&"
export * from "./unifiedUser.vue?vue&type=script&lang=js&"
import style0 from "./unifiedUser.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\admin\\datacenter_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f12faaf')) {
      api.createRecord('0f12faaf', component.options)
    } else {
      api.reload('0f12faaf', component.options)
    }
    module.hot.accept("./unifiedUser.vue?vue&type=template&id=0f12faaf&", function () {
      api.rerender('0f12faaf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/loginconfig/unifiedUser.vue"
export default component.exports