import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accLtv, accLtv_currency } from "@/api/dataAll/accountLtv";
import * as echarts from "echarts";
export default {
  name: "dataLtvPirate",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: [],
      dates: [],
      DynamicLTV: [],
      seriesLTV: [],
      screenLTV: [],
      PrimitiveDyLTV: [],
      Dynamicamount: [],
      seriesamount: [],
      screenamount: [],
      PrimitiveDyamount: [],
      radio4: 'LTV',
      cellStylekeys: ["LTV", "AMT"]
    };
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      _this.searchClick();
    });
  },
  methods: {
    toggle: function toggle() {
      this.getechart();
    },
    searchClick: function searchClick() {
      var _this2 = this;

      var isverification = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   if(isverification){
      //     this.$message.error('请选择货币类型')
      //   }
      //   return
      // }
      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.kind = 1;
      this.reqLoading = true;
      var reqFn = this.currencyClose ? accLtv_currency : accLtv;
      this.timeInterval();
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this2.tableData = [];
          _this2.dates = [];
          _this2.seriesLTV = [];
          _this2.DynamicLTV = [];
          _this2.seriesamount = [];
          _this2.Dynamicamount = [];

          for (var k in res.data.ltv_data) {
            var data = {
              date: k,
              new_account_num: res.data.ltv_data[k].other.new_account_num
            };

            _this2.dates.push(k);

            var objLTV = {
              name: k,
              type: "line",
              data: [],
              smooth: true
            };
            var objamount = {
              name: k,
              type: "line",
              data: [],
              smooth: true
            };
            var length = Object.keys(res.data.ltv_data[k].ltv).length;

            for (var j in res.data.ltv_data[k].ltv) {
              data['LTV' + j] = res.data.ltv_data[k].ltv[j] == '/' ? '--' : _this2.$utils.conversion(res.data.ltv_data[k].ltv[j]).toFixed(3);
              data['AMT' + j] = res.data.ltv_data[k].amount[j] == '/' ? '--' : _this2.$utils.conversion(res.data.ltv_data[k].amount[j]).toFixed(3);
              objLTV.data.push(data['LTV' + j]);
              objamount.data.push(data['AMT' + j]);

              if (_this2.DynamicLTV.length < length) {
                _this2.DynamicLTV.push({
                  label: "L_".concat(j),
                  prop: 'LTV' + j
                });

                _this2.Dynamicamount.push({
                  label: "L_".concat(j),
                  prop: 'AMT' + j
                });
              }
            }

            if (!_this2.screenLTV.length) {
              _this2.screenLTV = _this2.DynamicLTV.map(function (item) {
                return item.prop;
              });
              _this2.screenamount = _this2.Dynamicamount.map(function (item) {
                return item.prop;
              });
              _this2.PrimitiveDyLTV = _this2.DynamicLTV;
              _this2.PrimitiveDyamount = _this2.Dynamicamount;
            }

            _this2.seriesLTV.push(objLTV);

            _this2.seriesamount.push(objamount);

            _this2.screenControls();

            _this2.tableData.push(data);
          }

          _this2.$nextTick(function () {
            _this2.getTableRight();
          });
        }

        _this2.getechart();
      }).finally(function () {
        _this2.reqLoading = false;
      });
    },
    screenControls: function screenControls() {
      var _this3 = this;

      var lista = [];
      this.PrimitiveDyLTV.forEach(function (item) {
        _this3.screenLTV.forEach(function (citem) {
          if (item.prop == citem) {
            lista.push(item);
          }
        });
      });
      this.DynamicLTV = lista;
      var listb = [];
      this.PrimitiveDyamount.forEach(function (item) {
        _this3.screenamount.forEach(function (citem) {
          if (item.prop == citem) {
            listb.push(item);
          }
        });
      });
      this.Dynamicamount = listb;
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("dataLtvPirateEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: this.radio4 == 'LTV' ? '汇总LTV' : '汇总金额'
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          width: '80%'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.radio4 == 'LTV' ? this.PrimitiveDyLTV.map(function (item) {
            return item.prop;
          }) : this.PrimitiveDyamount.map(function (item) {
            return item.prop;
          })
        },
        yAxis: {
          type: "value"
        },
        series: this.radio4 == 'LTV' ? this.seriesLTV : this.seriesamount
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }, {
        label: '注册账号',
        prop: 'new_account_num'
      }];
      columns = this.radio4 == 'LTV' ? [].concat(_toConsumableArray(columns), _toConsumableArray(this.DynamicLTV)) : [].concat(_toConsumableArray(columns), _toConsumableArray(this.Dynamicamount));
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.radio4 == 'LTV' ? '汇总LTV' : '汇总金额'
      });
    }
  }
};