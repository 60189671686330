var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "montorStatic section dataAll-content" },
    [
      _c("div", { staticClass: "flex-cont dataSummary-header" }, [
        _c(
          "div",
          { staticClass: "flex-item" },
          [
            _c(
              "el-select",
              {
                ref: "",
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择游戏"
                },
                on: { change: _vm.gamechange },
                model: {
                  value: _vm.searchData.game,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "game", $$v)
                  },
                  expression: "searchData.game"
                }
              },
              _vm._l(_vm.searchgames, function(item) {
                return _c(
                  "el-option",
                  {
                    key: item.id,
                    attrs: {
                      "value-key": item.id,
                      label: item.game_name,
                      value: item.id
                    }
                  },
                  [_vm._v(_vm._s(item.game_name) + " ")]
                )
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择平台"
                },
                on: { change: _vm.platformchange },
                model: {
                  value: _vm.searchData.platform,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "platform", $$v)
                  },
                  expression: "searchData.platform"
                }
              },
              _vm._l(_vm.searchplatforms, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.platform_name, value: item.id }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择渠道"
                },
                on: { change: _vm.channelchange },
                model: {
                  value: _vm.searchData.channel,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "channel", $$v)
                  },
                  expression: "searchData.channel"
                }
              },
              _vm._l(_vm.searchchannels, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.channel_name, value: item.id }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择广告渠道"
                },
                on: { change: _vm.advChannelchange },
                model: {
                  value: _vm.searchData.advChannel,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "advChannel", $$v)
                  },
                  expression: "searchData.advChannel"
                }
              },
              _vm._l(_vm.advchannels, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.adv_platform_name, value: item.id }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择广告位置"
                },
                on: { change: _vm.advLocationchange },
                model: {
                  value: _vm.searchData.advLocation,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "advLocation", $$v)
                  },
                  expression: "searchData.advLocation"
                }
              },
              _vm._l(_vm.advLocation, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.adv_place_name, value: item.id }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择投放包"
                },
                on: { change: _vm.pkgchange },
                model: {
                  value: _vm.searchData.pkg,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "pkg", $$v)
                  },
                  expression: "searchData.pkg"
                }
              },
              _vm._l(_vm.searchpkgs, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.pkg_name, value: item.id }
                })
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-star" },
          [
            _c("el-date-picker", {
              staticStyle: { "margin-left": "-16px" },
              attrs: {
                "value-format": "yyyy-MM-dd",
                type: "daterange",
                align: "right",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期"
              },
              model: {
                value: _vm.value2,
                callback: function($$v) {
                  _vm.value2 = $$v
                },
                expression: "value2"
              }
            }),
            _c(
              "el-select",
              {
                staticClass: "type-padds",
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择类型"
                },
                on: { change: _vm.typechange },
                model: {
                  value: _vm.searchData.type,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "type", $$v)
                  },
                  expression: "searchData.type"
                }
              },
              _vm._l(_vm.typeArr, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.type_name, value: item.type_name }
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "13px" },
                attrs: { type: "success", loading: _vm.reqLoading },
                on: { click: _vm.searchClick }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "levelDetails-body" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "ID",
                  width: "80",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  label: "日期",
                  align: "center",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "game", label: "游戏", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "platform", label: "平台", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "channel", label: "渠道", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_channel",
                  label: "广告渠道",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_placement",
                  label: "广告位置",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pkg",
                  label: "包名",
                  align: "center",
                  "min-width": "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "num",
                  label: "人数",
                  align: "center",
                  width: "100"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.addWayConfig
        ? _c("addLinks", {
            ref: "addWayConfig",
            on: { refreshDataList: _vm.searchClick }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }