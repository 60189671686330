var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCountry: false, isCurrency: true } },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptionsDay,
                  type: "date",
                  placeholder: "选择激活日期",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.searchData.activatetime,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "activatetime", $$v)
                  },
                  expression: "searchData.activatetime"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  fixed: "",
                  prop: "date",
                  label: "日期",
                  "class-name": "font-bold",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("2020-01-01", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "activity_device",
                  label: "激活设备",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("激活设备", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "register",
                  label: "注册",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("注册人", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "create_role",
                  label: "创角数",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("创角数", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "login_time",
                  label: "登录设备数",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("登录设备数", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "sum_login_time",
                  label: "累计登录设备数",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth(
                    "累计登录设备数",
                    true
                  )
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "recharge_time",
                  label: "付费设备数",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("付费设备数", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "sum_recharge_time",
                  label: "累计付费设备数",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth(
                    "累计付费设备数",
                    true
                  )
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "pay_amount",
                  label: "当日付费金额",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("当日付费金额", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "sum_pay_amount",
                  label: "累计付费金额",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("累计付费金额", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "arpu",
                  label: "当日arpu",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("当日arpu", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "sum_arpu",
                  label: "累计arpu",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("累计arpu", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "arppu",
                  label: "当日arppu",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("当日arppu", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "sum_arppu",
                  label: "累计arppu",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("累计arppu", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "ltv",
                  label: "ltv",
                  "show-overflow-tooltip": "",
                  "min-width": _vm.$utils.flexColumnWidth("ltvss", true)
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        staticClass: "margin-top",
        staticStyle: { height: "500px" },
        attrs: { id: "congroupAnalEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }