var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogShow,
            "close-on-click-modal": false,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-title" }, [
                _vm._v("发送者")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.sender,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "sender", $$v)
                      },
                      expression: "data.sender"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-title" }, [_vm._v("标题")]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.title,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "title", $$v)
                      },
                      expression: "data.title"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex lodingItem-title first_box lodingItem-title",
                    staticStyle: {
                      "margin-bottom": "20px",
                      width: "100%",
                      "align-items": "center"
                    }
                  },
                  [_vm._v("邮件内容: ")]
                ),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: { minRows: 2, maxRows: 12 },
                    disabled: ""
                  },
                  model: {
                    value: _vm.data.content,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "content", $$v)
                    },
                    expression: "data.content"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }