var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.titleName,
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "addappmmmmm add-header" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.form, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "游戏",
                        prop: "game",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择游戏"
                          },
                          on: { change: _vm.gamechange },
                          model: {
                            value: _vm.form.game,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "game", $$v)
                            },
                            expression: "form.game"
                          }
                        },
                        _vm._l(_vm.searchgames, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                "value-key": item.id,
                                label: item.game_name,
                                value: item.id
                              }
                            },
                            [_vm._v(_vm._s(item.game_name) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "平台",
                        prop: "selects",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择平台"
                          },
                          on: { change: _vm.platformchange },
                          model: {
                            value: _vm.form.platform,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "platform", $$v)
                            },
                            expression: "form.platform"
                          }
                        },
                        _vm._l(_vm.searchplatforms, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.platform_name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "渠道",
                        prop: "selects",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择渠道"
                          },
                          on: { change: _vm.channelchange },
                          model: {
                            value: _vm.form.channel,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "channel", $$v)
                            },
                            expression: "form.channel"
                          }
                        },
                        _vm._l(_vm.searchchannels, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.channel_name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告渠道",
                        prop: "selects",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择广告渠道"
                          },
                          on: { change: _vm.advChannelchange },
                          model: {
                            value: _vm.form.advChannel,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "advChannel", $$v)
                            },
                            expression: "form.advChannel"
                          }
                        },
                        _vm._l(_vm.advchannels, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.adv_platform_name,
                              value: item.id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告位置",
                        prop: "selects",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择广告位置"
                          },
                          on: { change: _vm.advLocationchange },
                          model: {
                            value: _vm.form.advLocation,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "advLocation", $$v)
                            },
                            expression: "form.advLocation"
                          }
                        },
                        _vm._l(_vm.advLocation, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.adv_place_name,
                              value: item.id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "包名",
                        prop: "pkg",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择包名"
                          },
                          on: { change: _vm.pkgchange },
                          model: {
                            value: _vm.form.pkg,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "pkg", $$v)
                            },
                            expression: "form.pkg"
                          }
                        },
                        _vm._l(_vm.searchpkgs, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.pkg_name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告主ID",
                        prop: "advertiser_id  ",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "256px" },
                        attrs: { placeholder: "请输入广告主ID" },
                        on: { change: _vm.textChange },
                        model: {
                          value: _vm.form.advertiser_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "advertiser_id", $$v)
                          },
                          expression: "form.advertiser_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }