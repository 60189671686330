// 汇总数据
import request from '@/utils/request';
export function dataSummary(data) {
  return request({
    url: '/data/summary',
    method: 'get',
    params: data
  });
}
export function dataSummary_currency(data) {
  return request({
    url: '/data/summary_currency',
    method: 'get',
    params: data
  });
}
export function summary_currency_other(data) {
  return request({
    url: '/data/summary_currency_other',
    method: 'get',
    params: data
  });
}