import request from '@/utils/request'; //消耗详情列表

export function getAll(data) {
  return request({
    url: '/financial/consume_list',
    method: 'get',
    params: data
  });
} //获取所有下拉框资源

export function getsearch(data) {
  return request({
    url: '/data/searchData',
    method: 'get',
    params: data
  });
} //获取广告渠道与位置下拉框资源

export function getadv(data) {
  return request({
    url: '/adv_relevance/lists',
    method: 'get',
    params: data
  });
} //新增消耗

export function addConsume(data) {
  return request({
    url: '/financial/consume_add',
    method: 'post',
    params: data
  });
} //   资源产销删除 

export function delConsume(data) {
  return request({
    url: 'financial/consume_del',
    method: 'get',
    params: data
  });
} //获取修改数据

export function upConsume(data) {
  return request({
    url: '/financial/consume_update',
    method: 'get',
    params: data
  });
} //修改数据

export function trueUpConsume(data) {
  return request({
    url: '/financial/consume_update',
    method: 'post',
    params: data
  });
}
/**
 * 管理分成比例
 */
// 列表

export function sharingList(data) {
  return request({
    url: '/financial/sharing-percent-list',
    method: 'get',
    params: data
  });
} // 新增

export function addSharing(data) {
  return request({
    url: '/financial/add-sharing-percent',
    method: 'post',
    params: data
  });
} // 修改

export function updSharing(data) {
  return request({
    url: '/financial/edit-sharing-percent',
    method: 'post',
    params: data
  });
} // 删除

export function delSharing(data) {
  return request({
    url: '/financial/delete-sharing-percent',
    method: 'post',
    params: data
  });
}
/**
 * 广点通参数管理
 */
// 获取参数

export function gdtParams(data) {
  return request({
    url: '/financial/gdt-params',
    method: 'get',
    params: data
  });
} // 新增

export function addGdtParams(data) {
  return request({
    url: '/financial/add-gdt-params',
    method: 'post',
    params: data
  });
} // 修改

export function editGdtParams(data) {
  return request({
    url: '/financial/edit-gdt-params',
    method: 'post',
    params: data
  });
} // 删除

export function deleteGdtParams(data) {
  return request({
    url: '/financial/delete-gdt-params',
    method: 'post',
    params: data
  });
}
/**
 * 巨量参数管理
 */
// 获取参数

export function jlParams(data) {
  return request({
    url: '/financial/jl-params',
    method: 'get',
    params: data
  });
} // 新增

export function addJlParams(data) {
  return request({
    url: '/financial/add-jl-params',
    method: 'post',
    params: data
  });
} // 修改

export function editJlParams(data) {
  return request({
    url: '/financial/edit-jl-params',
    method: 'post',
    params: data
  });
} // 删除

export function deleteJlParams(data) {
  return request({
    url: '/financial/delete-jl-params',
    method: 'post',
    params: data
  });
}
/**
 * 投放分析 安装量
 */
// 获取参数

export function analysisInstallList(data) {
  return request({
    url: '/financial/analysisInstallList',
    method: 'get',
    params: data
  });
} // 新增

export function addAnalysisInstall(data) {
  return request({
    url: '/financial/analysisInstallAdd',
    method: 'post',
    params: data
  });
} // 修改

export function updAnalysisInstall(data) {
  return request({
    url: '/financial/analysisInstallUpdate',
    method: 'post',
    params: data
  });
} // 删除

export function delAnalysisInstall(data) {
  return request({
    url: '/financial/analysisInstallDel',
    method: 'get',
    params: data
  });
}
/**
 * 投放分析 广告收益
 */
// 获取参数

export function adIncomeList(data) {
  return request({
    url: '/financial/adIncomeList',
    method: 'get',
    params: data
  });
} // 新增

export function addAdIncome(data) {
  return request({
    url: '/financial/adIncomeAdd',
    method: 'post',
    params: data
  });
} // 修改

export function updAdIncome(data) {
  return request({
    url: '/financial/adIncomeUpdate',
    method: 'post',
    params: data
  });
} // 删除

export function delAdIncome(data) {
  return request({
    url: '/financial/adIncomeDel',
    method: 'get',
    params: data
  });
}
/**
 * 财务中心 亿帆参数管理
 */
// 获取参数

export function yfList(data) {
  return request({
    url: '/financial/yf-params',
    method: 'get',
    params: data
  });
} // 新增

export function addyf(data) {
  return request({
    url: '/financial/add-yf-params',
    method: 'post',
    params: data
  });
} // 修改

export function updyf(data) {
  return request({
    url: '/financial/edit-yf-params',
    method: 'post',
    params: data
  });
} // 删除

export function delyf(data) {
  return request({
    url: '/financial/delete-yf-params',
    method: 'post',
    params: data
  });
} // 获取巨量回传参数

export function jlPostBackData(data) {
  return request({
    url: '/financial/jlPostBackData',
    method: 'get',
    params: data
  });
} // 修改巨量回传参数

export function jlPostBackUpdate(data) {
  return request({
    url: '/financial/jlPostBackUpdate',
    method: 'post',
    params: data
  });
}
/**
 * faceBook 账号
 */
// 列表

export function fbProductAccData(data) {
  return request({
    url: '/financial/fbProductAccData',
    method: 'get',
    params: data
  });
} // 新增

export function fbProductAccAdd(data) {
  return request({
    url: '/financial/fbProductAccAdd',
    method: 'post',
    params: data
  });
} // 修改

export function fbProductAccUpdate(data) {
  return request({
    url: '/financial/fbProductAccUpdate',
    method: 'post',
    params: data
  });
} // 删除

export function fbProductAccDel(data) {
  return request({
    url: '/financial/fbProductAccDel',
    method: 'post',
    params: data
  });
}
/**
 * faceBook  token
 */
// 列表

export function fbTokenData(data) {
  return request({
    url: '/financial/fbTokenData',
    method: 'get',
    params: data
  });
} // 新增

export function fbTokenAdd(data) {
  return request({
    url: '/financial/fbTokenAdd',
    method: 'post',
    params: data
  });
} // 修改

export function fbTokenUpdate(data) {
  return request({
    url: '/financial/fbTokenUpdate',
    method: 'post',
    params: data
  });
} // 删除

export function fbTokenDel(data) {
  return request({
    url: '/financial/fbTokenDel',
    method: 'post',
    params: data
  });
} // admon app
// 列表

export function admobAppParamsData(data) {
  return request({
    url: '/financial/admobAppParamsData',
    method: 'get',
    params: data
  });
} // 新增

export function admobAppParamsAdd(data) {
  return request({
    url: '/financial/admobAppParamsAdd',
    method: 'post',
    params: data
  });
} // 修改

export function admobAppParamsUpdate(data) {
  return request({
    url: '/financial/admobAppParamsUpdate',
    method: 'post',
    params: data
  });
} // 删除

export function admobAppParamsDel(data) {
  return request({
    url: '/financial/admobAppParamsDel',
    method: 'post',
    params: data
  });
} // admon game
// 列表

export function admobGameParamsData(data) {
  return request({
    url: '/financial/admobGameParamsData',
    method: 'get',
    params: data
  });
} // 新增

export function admobGameParamsAdd(data) {
  return request({
    url: '/financial/admobGameParamsAdd',
    method: 'post',
    params: data
  });
} // 修改

export function admobGameParamsUpdate(data) {
  return request({
    url: '/financial/admobGameParamsUpdate',
    method: 'post',
    params: data
  });
} // 删除

export function admobGameParamsDel(data) {
  return request({
    url: '/financial/admobGameParamsDel',
    method: 'post',
    params: data
  });
}
/**
 * 谷歌广告参数
 */
// 列表

export function googleAdvManageData(data) {
  return request({
    url: '/financial/googleAdvManageData',
    method: 'get',
    params: data
  });
} // 新增

export function googleAdvManageAdd(data) {
  return request({
    url: '/financial/googleAdvManageAdd',
    method: 'post',
    params: data
  });
} // 修改

export function googleAdvManageUpd(data) {
  return request({
    url: '/financial/googleAdvManageUpd',
    method: 'post',
    params: data
  });
} // 删除

export function googleAdvManageDel(data) {
  return request({
    url: '/financial/googleAdvManageDel',
    method: 'post',
    params: data
  });
}
/**
 * 谷歌token
 */
// 列表

export function googleTokenManageData(data) {
  return request({
    url: '/financial/googleTokenManageData',
    method: 'get',
    params: data
  });
} // 新增

export function googleTokenManageAdd(data) {
  return request({
    url: '/financial/googleTokenManageAdd',
    method: 'post',
    params: data
  });
} // 修改

export function googleTokenManageUpd(data) {
  return request({
    url: '/financial/googleTokenManageUpd',
    method: 'post',
    params: data
  });
} // 删除

export function googleTokenManageDel(data) {
  return request({
    url: '/financial/googleTokenManageDel',
    method: 'post',
    params: data
  });
}