import "core-js/modules/es.object.keys";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { taptapData } from "@/api/dataAll/developAdvertisingData";
export default {
  name: "developAdvertisingData",
  data: function data() {
    return {
      tableData: [],
      labWidth: "160",
      formData: {
        start_date: '',
        end_date: ''
      },
      defaultDate: [],
      booking_detail: [],
      // 预约
      booking_detailNum: [],
      booking_detailTol: 0,
      download_times: [],
      // 下载
      download_timesNum: [],
      download_timesTol: 0,
      trans_percent: [],
      // 转换率
      trans_percentNum: [],
      watch_times: [],
      // 浏览
      watch_timesNum: [],
      watch_timesTol: 0,
      ispush: false,
      rankingData: [],
      rankingNum: [{
        name: '时间',
        key: 'date'
      }, {
        name: 'Andriod榜单日最高排名',
        key: 'and'
      }, {
        name: 'ios榜单日最高排名',
        key: 'ios'
      }],
      releaseData: [],
      releaseNum: [{
        name: '时间',
        key: 'date'
      }, {
        name: '广告展现量',
        key: 'key1'
      }, {
        name: '广告点击量',
        key: 'key2'
      }, {
        name: '广告下载/预约量',
        key: 'key3'
      }],
      datas: []
    };
  },
  created: function created() {
    var data = this.getStartEndTime();
    this.defaultDate = [data[0] * 1000, (data[1] + 1) * 1000];
    this.formData.start_date = data[0];
    this.formData.end_date = data[1] + 1;
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;

      var booking_detail = [];
      var download_times = [];
      var trans_percent = [];
      var watch_times = [];
      var ranking_data = [];
      var release_data = [];
      this.booking_detailNum = [];
      this.download_timesNum = [];
      this.trans_percentNum = [];
      this.watch_timesNum = [];
      this.watch_timesTol = 0;
      this.download_timesTol = 0;
      this.booking_detailTol = 0;
      this.datas = [this.formatDate(this.defaultDate[0]), this.formatDate(this.defaultDate[1])];
      taptapData(this.formData).then(function (res) {
        if (res.status == 200) {
          var oneNum = 0;

          for (var k in res.data) {
            oneNum++; // 预约

            var bookingData = {
              date: k,
              key1: 0,
              key2: 0,
              key3: 0,
              key4: 0,
              key5: 0,
              key6: 0,
              key7: 0,
              key8: 0,
              key9: 0
            };
            var bookingnewData = res.data[k].booking_detail.length ? JSON.parse(res.data[k].booking_detail[0].data)[0] : {};
            var bookingNum = 0;

            for (var i in bookingnewData) {
              bookingNum++;
              bookingData['key' + bookingNum] = bookingnewData[i];

              if (Object.keys(bookingnewData).length != _this.booking_detailNum.length + 1) {
                _this.booking_detailNum.push({
                  name: i,
                  key: 'key' + bookingNum
                });
              }

              if (i == '新增预约总量') {
                _this.booking_detailTol = _this.booking_detailTol + parseInt(bookingnewData[i]);
              }
            } // 下载


            var downloadData = {
              date: k,
              key1: 0,
              key2: 0,
              key3: 0,
              key4: 0,
              key5: 0,
              key6: 0,
              key7: 0,
              key8: 0,
              key9: 0,
              key10: 0,
              key11: 0
            };
            var downloadnewData = res.data[k].download_times.length ? JSON.parse(res.data[k].download_times[0].data)[0] : {};
            var downloadnum = 0;

            for (var _i in downloadnewData) {
              downloadnum++;
              downloadData['key' + downloadnum] = downloadnewData[_i];

              if (Object.keys(downloadnewData).length != _this.download_timesNum.length + 1) {
                _this.download_timesNum.push({
                  name: _i,
                  key: 'key' + downloadnum
                });
              }

              if (_i == '新增下载') {
                _this.download_timesTol = _this.download_timesTol + parseInt(downloadnewData[_i]);
              }
            } // 转换率


            var transData = {
              date: k,
              key1: 0,
              key2: 0,
              key3: '0%',
              key4: 0,
              key5: '0%'
            };
            var transnewData = res.data[k].trans_percent.length ? JSON.parse(res.data[k].trans_percent[0].data)[0] : {};
            var transnum = 0;

            for (var _i2 in transnewData) {
              transnum++;
              transData['key' + transnum] = transnewData[_i2] || '/';

              if (Object.keys(transnewData).length != _this.trans_percentNum.length + 1) {
                _this.trans_percentNum.push({
                  name: _i2,
                  key: 'key' + transnum
                });
              }
            } // 浏览


            var watchData = {
              date: k,
              key1: 0,
              key2: 0,
              key3: 0,
              key4: 0,
              key5: 0,
              key6: 0,
              key7: 0,
              key8: 0,
              key9: 0,
              key10: 0
            };
            var watchnewData = res.data[k].watch_times.length ? JSON.parse(res.data[k].watch_times[0].data)[0] : {};
            var watchnum = 0;

            for (var _i3 in watchnewData) {
              watchnum++;
              watchData['key' + watchnum] = watchnewData[_i3] || '/';

              if (Object.keys(watchnewData).length != _this.watch_timesNum.length + 1) {
                _this.watch_timesNum.push({
                  name: _i3,
                  key: 'key' + watchnum
                });
              }

              if (_i3 == '总浏览量') {
                _this.watch_timesTol = _this.watch_timesTol + parseInt(watchnewData[_i3]);
              }
            } // 排行


            var rankingData = {
              date: k,
              and: 0,
              ios: 0
            }; // 排行

            var releaseData = {
              date: k,
              key1: 0,
              key2: 0,
              key3: 0
            };
            booking_detail.push(bookingData);
            download_times.push(downloadData);
            trans_percent.push(transData);
            watch_times.push(watchData);
            ranking_data.push(rankingData);
            release_data.push(releaseData);
          }

          _this.booking_detailNum.unshift({
            name: '时间',
            key: 'date'
          });

          _this.download_timesNum.unshift({
            name: '时间',
            key: 'date'
          });

          _this.trans_percentNum.unshift({
            name: '时间',
            key: 'date'
          });

          _this.watch_timesNum.unshift({
            name: '时间',
            key: 'date'
          });

          _this.booking_detail = booking_detail;
          _this.download_times = download_times;
          _this.trans_percent = trans_percent;
          _this.watch_times = watch_times;
          _this.rankingData = ranking_data;
          _this.releaseData = release_data; // console.log(this.booking_detail, 'bookingData')
          // console.log(this.download_times, 'downloadData')
          // console.log(this.trans_percent, 'trans_percent')
          // console.log(this.watch_times, 'watch_times')
          // console.log(this.rankingData, 'rankingData')
          // console.log(this.releaseData, 'releaseData')
          // console.log(this.trans_percentNum, 'trans_percentNum')
          // console.log(this.rankingNum, 'rankingNum')
        }
      });
    },
    datachange: function datachange() {
      this.formData.start_date = this.defaultDate[0] / 1000;
      this.formData.end_date = this.defaultDate[1] / 1000;
      this.getData();
    },
    getStartEndTime: function getStartEndTime() {
      var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var todayStartTime = new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000;
      var todayEndTime = parseInt(new Date(new Date().setHours(23, 59, 59, 999)).getTime() / 1000);
      return [todayStartTime, todayEndTime];
    },
    formatDate: function formatDate(value) {
      var date = new Date(value);
      var y = date.getFullYear(),
          m = date.getMonth() + 1,
          d = date.getDate(),
          h = date.getHours(),
          i = date.getMinutes(),
          s = date.getSeconds();

      if (m < 10) {
        m = '0' + m;
      }

      if (d < 10) {
        d = '0' + d;
      }

      if (h < 10) {
        h = '0' + h;
      }

      if (i < 10) {
        i = '0' + i;
      }

      if (s < 10) {
        s = '0' + s;
      } // var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;


      var t = y + '-' + m + '-' + d;
      return t;
    }
  }
};