import request from "@/utils/request"; //

export function hourlySummary(data) {
  return request({
    url: "/data/hourly-summary",
    method: "get",
    params: data
  });
}
export function hourlySummary_currency(data) {
  return request({
    url: "/data/hourly-summary-currency",
    method: "get",
    params: data
  });
}
export function totalSummary(data) {
  return request({
    url: "/data/total-summary",
    method: "get",
    params: data
  });
}
export function totalSummary_currency(data) {
  return request({
    url: "/data/total-summary-currency",
    method: "get",
    params: data
  });
}
export function onlineSummary(data) {
  return request({
    url: "/data/online-summary",
    method: "get",
    params: data
  });
}