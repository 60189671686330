var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "服务器渠道" },
                  on: { change: _vm.serverChannelChange },
                  model: {
                    value: _vm.server_channel,
                    callback: function($$v) {
                      _vm.server_channel = $$v
                    },
                    expression: "server_channel"
                  }
                },
                _vm._l(_vm.serverChannel, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function() {
                      _vm.list = []
                      _vm.serverChannelChange()
                    }
                  }
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addserver.open(null, false)
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.serverListYJ, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "serverName",
              label: "服务器名称",
              "min-width": "160"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "serverID", label: "服务器ID", "min-width": "100" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "areaID",
              label: "服务器所属大区",
              "min-width": "160"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serverIP",
              label: "服务器IP地址",
              "min-width": "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serverPort",
              label: "服务器端口号",
              "min-width": "130"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "new_tag", label: "是否是新服", "min-width": "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.new_tag ? "是" : "否") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_test",
              label: "是否是测试服",
              "min-width": "130"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.is_test ? "是" : "否") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_recomm",
              label: "推荐服务器标记",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.recommList.find(function(i) {
                            return i.value == row.is_recomm
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "config_state",
              label: "配置服务器状态",
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.configState.find(function(i) {
                            return i.value == row.config_state
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "role_count",
              label: "当前服务器注册玩家数",
              "min-width": "190"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "push_msg",
              label: "服务器推送信息",
              "min-width": "190",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(" " + _vm._s(row.push_msg || "/") + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "ver_max", label: "最高版本号", "min-width": "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "ver_min", label: "最低版本号", "min-width": "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "openTime", label: "开服时间", "min-width": "260" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.openTime
                            ? _vm.$times.formDate(row.openTime * 1000)
                            : "/"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "70", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.$refs.addserver.open(row, true)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("addserver", {
        ref: "addserver",
        attrs: { prod: { is_prod: _vm.paramsData.is_prod } },
        on: { update: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }