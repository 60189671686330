// 广告主ID对应应用列表
import request from '@/utils/request';
export function getappMappList(data) {
  return request({
    url: '/advertising/app-mapping-list',
    method: 'get',
    params: data
  });
} // 新增广告对用

export function addappMapp(data) {
  return request({
    url: '/advertising/add-app-mapping',
    method: 'post',
    params: data
  });
} // 修改广告对用

export function updateappMapp(data) {
  return request({
    url: '/advertising/update-app-mapping',
    method: 'post',
    params: data
  });
} // 反显广告对用

export function returnteappMapp(data) {
  return request({
    url: '/advertising/update-app-mapping',
    method: 'get',
    params: data
  });
} //删除广告对用

export function deleappMapp(data) {
  return request({
    url: '/advertising/delete-app-mapping',
    method: 'post',
    params: data
  });
}