var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sheet" },
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "预览",
            visible: _vm.dialogShow,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "table",
            _vm._l(_vm.fileArreys, function(item, index) {
              return _c(
                "tr",
                { key: index },
                _vm._l(item, function(itemth, indexth) {
                  return _c("th", { key: indexth }, [_vm._v(_vm._s(itemth))])
                }),
                0
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }