import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import layoutHeaderAside from '@/layout/header-aside';
export function generaMenu(data, routes) {
  var res = [];
  data.forEach(function (item, index) {
    var menu = {
      path: item.url == '' ? item.id + '_key' : item.url,
      title: item.title,
      icon: item.icon || ''
    };

    if (item.son_menu.length) {
      menu.children = generaMenu(item.son_menu);
    }

    res.push(menu);
  });
  return res;
}
export function generaRouter(data, routes) {
  var res = [];
  data.forEach(function (item) {
    var menu = {
      path: item.path,
      name: item.title,
      component: item.path == '' ? layoutHeaderAside : function (resolve) {
        return require(["@/views".concat(item.path)], resolve);
      },
      // component: item.url == "" ? Layout : () => import(`@/views/game/list`),
      // component: item.url == null ? Layout : () => import(`@/views${item.url}/index`),
      // hidden: true,
      children: [],
      meta: {
        title: item.title,
        id: item.id
      }
    };

    if (item.son_menu) {
      menu.son_menu = generaRouter(item.son_menu);
    }

    res.push(menu);
  });
  return res;
}
var state = {
  isFetchPermissionInfo: false
};
var mutations = {
  setFet: function setFet(state, data) {
    state.isFetchPermissionInfo = data;
  }
};
var actions = {
  generateAccessMenus: function generateAccessMenus(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      // 先查询后台并返回左侧菜单数据并把数据添加到路由
      var accessedMenus = generaMenu(data);
      resolve(accessedMenus);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};