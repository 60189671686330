var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "giftPagesss section dataAll-content" }, [
    _c("div", { staticClass: "flex-cont dataSummary-header" }, [
      _c(
        "div",
        { staticClass: "flex-item" },
        [
          _c(
            "el-select",
            {
              ref: "",
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择游戏"
              },
              on: { change: _vm.gamechange },
              model: {
                value: _vm.searchData.game,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "game", $$v)
                },
                expression: "searchData.game"
              }
            },
            _vm._l(_vm.searchgames, function(item) {
              return _c(
                "el-option",
                {
                  key: item.id,
                  attrs: {
                    "value-key": item.id,
                    label: item.game_name,
                    value: item.id
                  }
                },
                [_vm._v(_vm._s(item.game_name) + " ")]
              )
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择平台"
              },
              on: { change: _vm.platformchange },
              model: {
                value: _vm.searchData.platform,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "platform", $$v)
                },
                expression: "searchData.platform"
              }
            },
            _vm._l(_vm.searchplatforms, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.platform_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择渠道"
              },
              on: { change: _vm.channelchange },
              model: {
                value: _vm.searchData.channel,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "channel", $$v)
                },
                expression: "searchData.channel"
              }
            },
            _vm._l(_vm.searchchannels, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.channel_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择广告渠道"
              },
              on: { change: _vm.advChannelchange },
              model: {
                value: _vm.searchData.advChannel,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "advChannel", $$v)
                },
                expression: "searchData.advChannel"
              }
            },
            _vm._l(_vm.advchannels, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.adv_platform_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择广告位置"
              },
              on: { change: _vm.advLocationchange },
              model: {
                value: _vm.searchData.advLocation,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "advLocation", $$v)
                },
                expression: "searchData.advLocation"
              }
            },
            _vm._l(_vm.advLocation, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.adv_place_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择投放包"
              },
              on: { change: _vm.pkgchange },
              model: {
                value: _vm.searchData.pkg,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "pkg", $$v)
                },
                expression: "searchData.pkg"
              }
            },
            _vm._l(_vm.searchpkgs, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.pkg_name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-star" },
        [
          _c("el-date-picker", {
            staticStyle: { "margin-left": "-16px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "daterange",
              align: "right",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            },
            model: {
              value: _vm.value2,
              callback: function($$v) {
                _vm.value2 = $$v
              },
              expression: "value2"
            }
          }),
          _c("el-date-picker", {
            staticStyle: { "margin-left": "20px" },
            attrs: {
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "激活开始日期",
              "end-placeholder": "激活结束日期",
              "value-format": "yyyy-MM-dd",
              align: "right"
            },
            model: {
              value: _vm.value1,
              callback: function($$v) {
                _vm.value1 = $$v
              },
              expression: "value1"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "type-padds",
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择状态"
              },
              on: { change: _vm.stausChang },
              model: {
                value: _vm.searchData.state,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "state", $$v)
                },
                expression: "searchData.state"
              }
            },
            _vm._l(_vm.searchcountry, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.lable, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "13px" },
              attrs: { type: "success" },
              on: { click: _vm.searchClick }
            },
            [_vm._v("搜索")]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "button_box" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
          [_vm._v("导出")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "levelDetails-body" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "98%", "margin-left": "30px" },
            attrs: { data: _vm.tableData, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "id", label: "ID", width: "80", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "game", label: "游戏", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "platform", label: "平台", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "channel", label: "渠道", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "ad_channel", label: "广告渠道", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "ad_placement",
                label: "广告位置",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "pkg",
                label: "包名",
                align: "center",
                "min-width": "120"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "ip",
                label: "IP",
                align: "center",
                "min-width": "120"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "timestamp",
                label: "时间",
                align: "center",
                "min-width": "120"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "bag_name", label: "礼包名", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "amount",
                label: "礼包金额",
                align: "center",
                "min-width": "120"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "state",
                label: "状态",
                align: "center",
                "min-width": "120"
              }
            })
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            layout: "total, prev, pager, next",
            "current-page": _vm.searchData.page,
            total: _vm.total,
            "page-size": _vm.pagrSize
          },
          on: { "current-change": _vm.handleCurrentChange }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }