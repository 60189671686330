var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.searchData.id ? "修改" : "新增",
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                model: _vm.searchData,
                rules: _vm.rules,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "类型标名", prop: "pay_name" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入类型标名" },
                    model: {
                      value: _vm.searchData.pay_name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "pay_name", $$v)
                      },
                      expression: "searchData.pay_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "类型标识", prop: "pay_mark" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入类型标识" },
                    model: {
                      value: _vm.searchData.pay_mark,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "pay_mark", $$v)
                      },
                      expression: "searchData.pay_mark"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "类型ICON", prop: "icon" }
                },
                [
                  _c("uploadImage", {
                    ref: "bgtiveUp",
                    attrs: { limit: 1, fileList: _vm.fileList },
                    on: {
                      change: function($event) {
                        return _vm.BgUrl($event, "自己需要携带的参数")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }