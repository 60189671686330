//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import scroll from './mixins/normal';
export default {
  name: 'd2-container-full',
  mixins: [scroll],
  data: function data() {
    return {
      isSlide: false
    };
  },
  mounted: function mounted() {
    var _this = this;

    // 增加滚动事件监听
    this.addScrollListener();
    this.$bus.$on('isSlide', function (data) {
      _this.isSlide = data;
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 移除滚动事件监听
    this.removeScrollListener();
  }
};