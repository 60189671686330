//数据中心充值数部分
import request from "@/utils/request"; // 充值档位分布--账号

export function accrechargeGear(data) {
  return request({
    url: "/data/account_recharge_gear",
    method: "get",
    params: data
  });
}
export function accrechargeGear_currency(data) {
  return request({
    url: "/data/account_recharge_gear_currency",
    method: "get",
    params: data
  });
} // 充值档位分布--设备

export function advrechargeGear(data) {
  return request({
    url: "/data/device_recharge_gear",
    method: "get",
    params: data
  });
}
export function advrechargeGear_currency(data) {
  return request({
    url: "/data/device_recharge_gear_currency",
    method: "get",
    params: data
  });
} // 充值VIP分布--账号

export function accrechargeVip(data) {
  return request({
    url: "/data/account_recharge_vip",
    method: "get",
    params: data
  });
} // 充值VIP分布--设备

export function advrechargeVip(data) {
  return request({
    url: "data/device_recharge_vip",
    method: "get",
    params: data
  });
} // 徽章购买--账号

export function accrechargeBadge(data) {
  return request({
    url: "data/badge_account",
    method: "get",
    params: data
  });
} // 徽章购买--设备

export function advrechargeBadge(data) {
  return request({
    url: "data/badge_device",
    method: "get",
    params: data
  });
}