//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect, Kamitaku } from "@/mixins/index";
import { flowPercent } from "@/api/kamitaku/kamitaku";
export default {
  name: "kamitakuAd",
  mixins: [serchSelect, Kamitaku],
  data: function data() {
    return {
      reqFn: flowPercent,
      searchData: {},
      Calculation: [{
        label: '百分比',
        value: 'task_percent'
      }, {
        label: '人数',
        value: 'task_pnum'
      }]
    };
  },
  created: function created() {},
  methods: {
    getListnew: function getListnew() {// this.getList((res) => {
      //   this.tabList = []
      //   for (let key in res) {
      //     this.tabList.push({ ...res[key], date: key })
      //   }
      // })
    },
    exportTab: function exportTab() {}
  }
};