import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { swallowOperation } from "@/api/dataAll/new";
export default {
  name: "swallowOperation",
  mixins: [serchSelect],
  data: function data() {
    var _this = this;

    return {
      more: true,
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          if (_this.searchData.activation != "") {
            return time.getTime() > Date.now() || time.getTime() > _this.searchData.activation;
          } else {
            return time.getTime() > Date.now();
          }
        }
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < _this.searchData.operation || time.getTime() > Date.now();
        }
      },
      statusArr: [{
        value: "1",
        label: "失败"
      }, {
        value: "2",
        label: "成功"
      }],
      tableTitle: [],
      gameId: "",
      // value1: "",
      value1: [],
      value2: [],
      searchData: {
        activation: [],
        operation: [],
        operation_keyword: "",
        expandKey: "",
        state: ""
      },
      num: "",
      times: "",
      tableData: [],
      show: false
    };
  },
  created: function created() {
    this.getsearchDate();
    this.searchData.activation_time = "";
    this.searchData.activation_endtime = "";
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    beginDate: function beginDate() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.searchData.operation) {
            return new Date(self.searchData.operation).getTime() < time.getTime();
          } else {}
        }
      };
    },
    processDate: function processDate() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.searchData.activation) {
            return new Date(self.searchData.activation).getTime() > time.getTime();
          } else {}
        }
      };
    },
    searchClick: function searchClick() {
      var _this2 = this;

      // this.searchData.activation_time = this.searchData.activation;
      // this.searchData.operation_time = this.searchData.operation;
      this.searchData.activation_time = "";
      this.searchData.activation_endtime = "";
      this.searchData.expand_keyword = this.searchData.expandKey;
      this.guankaArr = [];

      if (this.value2 && Array.isArray(this.value2) && this.value2.length > 0) {
        this.searchData.operation_time = this.value2[0];
        this.searchData.operation_endtime = this.value2[1];
      }

      if (this.value1 && Array.isArray(this.value1) && this.value1.length > 0) {
        this.searchData.activation_time = this.value1[0];
        this.searchData.activation_endtime = this.value1[1];
      } else {
        this.searchData.activation_time = "";
        this.searchData.activation_endtime = "";
      }

      swallowOperation(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this2.tableData = [];

          _this2.tableData.push({
            peopleNum: res.data.num,
            frequency: res.data.times
          });
        }
      });
    }
  }
};