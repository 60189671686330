var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statiStical" }, [
    _c(
      "div",
      { staticClass: "statiSticalChaet", attrs: { id: "statiStical" } },
      [
        _vm.height
          ? _c(
              "div",
              [
                _c("bottomLeftBar", {
                  attrs: {
                    activeRegistNum: _vm.activeRegistNum,
                    height: _vm.height
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }