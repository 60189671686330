var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dataAll-content" }, [
    _c(
      "div",
      { staticClass: "table_box" },
      [
        _c(
          "div",
          { staticClass: "top_btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "success", round: "" },
                on: { click: _vm.addType }
              },
              [_vm._v("新增")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%", "margin-left": "30px" },
            attrs: { data: _vm.tableData, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "id", label: "ID", width: "200", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "login_name",
                label: "登录渠道名称",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "login_mark",
                label: "登录渠道标志",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                width: "160",
                align: "center",
                fixed: "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "x-c" }, [
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.editType(scope.row)
                              }
                            }
                          },
                          [_vm._v("修改")]
                        ),
                        _c("div", { staticClass: "controlsBtn-vertical" }),
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.deleType(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            layout: "total, prev, pager, next",
            "current-page": _vm.page,
            total: _vm.total,
            "page-size": _vm.pagSize
          },
          on: { "current-change": _vm.handleCurrentChange }
        })
      ],
      1
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialog,
          expression: "dialog"
        }
      ],
      staticClass: "mask"
    }),
    _c(
      "div",
      [
        _c("channle-Dialog", {
          ref: "addThree",
          attrs: { show: _vm.dialog, num: _vm.num, bindObj: _vm.bindObj },
          on: { changeShow: _vm.changeShow, changeData: _vm.changeData }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }