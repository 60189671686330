import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addAnnounce, upAnnounce, upAnnounceNew } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    serverList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      paramsData: {
        id: '',
        label: 0,
        server_rangeStart: '',
        server_rangeEnd: '',
        channelStart: '',
        channelEnd: '',
        pause_start_time: '',
        pause_duration: '',
        priority: '',
        force_push: '',
        start_time: '',
        main_content: [],
        end_time: '',
        pause: 0,
        show_switch: '',
        type: ''
      },
      isStart: false,
      isEnd: false,
      dialogShow: false,
      whetherNot: [{
        label: '是',
        value: 1
      }, {
        label: '否',
        value: 2
      }],
      rules: {
        title: [{
          required: true,
          message: "请输入标题",
          trigger: "blur"
        }],
        label: [{
          required: true,
          message: "请输入标识",
          trigger: "blur"
        }],
        priority: [{
          required: true,
          message: "请输入优先值",
          trigger: "blur"
        }],
        force_push: [{
          required: true,
          message: "请选择是否强提醒",
          trigger: "blur"
        }],
        start_time: [{
          required: true,
          message: "请选择开始时间",
          trigger: "blur"
        }],
        end_time: [{
          required: true,
          message: "请选择结束时间",
          trigger: "blur"
        }],
        main_content: [{
          required: true,
          message: "请添加公告内容",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入内容",
          trigger: "blur"
        }],
        server_ids: [{
          required: true,
          message: "请选择服务器",
          trigger: "blur"
        }],
        channelStart: [{
          required: true,
          message: "请输入开始渠道",
          trigger: "blur"
        }],
        channelEnd: [{
          required: true,
          message: "请输入结束渠道",
          trigger: "blur"
        }],
        pause_start_time: [{
          required: true,
          message: "请输入停服开始时间",
          trigger: "blur"
        }],
        pause_duration: [{
          required: true,
          message: "请输入持续时间",
          trigger: "blur"
        }],
        language: [{
          required: true,
          message: "请选择语言",
          trigger: "blur"
        }],
        pause: [{
          required: true,
          message: "请选择类型",
          trigger: "blur"
        }],
        server_rangeStart: [{
          required: true,
          message: "请选择开始服务器",
          trigger: "blur"
        }],
        server_rangeEnd: [{
          required: true,
          message: "请选择结束服务器",
          trigger: "blur"
        }],
        show_switch: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    labelChange: function labelChange(e) {
      var _this = this;

      if (e == 4 && this.paramsData.pause == 1) {
        this.paramsData.main_content.map(function (item) {
          if (item.language == 'cn') {
            item.content = _this.FillIn['cnT'];
          } else if (item.language == 'en') {
            item.content = _this.FillIn['enT'];
          } else if (item.language == 'tc') {
            item.content = _this.FillIn['tcT'];
          } else if (item.language == 'vn') {
            item.content = _this.FillIn['vnT'];
          }
        });
      }
    },
    addtag: function addtag(data, index) {
      this.paramsData.main_content[index].content = "".concat(this.paramsData.main_content[index].content).concat(data);
    },
    addContent: function addContent() {
      if (this.paramsData.main_content.length < this.langList.length) {
        this.paramsData.main_content.push({
          content: '',
          language: '',
          title: ''
        });
      }
    },
    removeContent: function removeContent(item) {
      var index = this.paramsData.main_content.indexOf(item);

      if (index !== -1) {
        this.paramsData.main_content.splice(index, 1);
      }
    },
    pauseChange: function pauseChange(e) {
      var _this2 = this;

      this.paramsData.main_content = [];
      this.$refs.ruleForm.clearValidate();
      this.paramsData.label = 0;

      if (e !== 0) {
        this.langList.forEach(function (i) {
          _this2.paramsData.main_content.push({
            content: (e == 1 ? _this2.FillIn : _this2.NewSuitlIn)[i.value],
            language: i.value,
            title: e == 1 ? _this2.FillInTitle[i.value] : _this2.NewSuitlInTitle[i.value]
          });
        });
      } else {
        this.paramsData.main_content = [{
          content: '',
          language: '',
          title: ''
        }];
      }
    },
    open: function open(data) {
      if (data) {
        data = JSON.parse(JSON.stringify(data));

        if (data.start_time == -1) {
          data.start_time = '';
          this.isStart = true;
        } else {
          data.start_time = data.start_time * 1000;
          this.isStart = false;
        }

        if (data.end_time == -1) {
          data.end_time = '';
          this.isEnd = true;
        } else {
          data.end_time = data.end_time * 1000;
          this.isEnd = false;
        }

        data.server_rangeStart = data.server_rangeList[0];
        data.server_rangeEnd = data.server_rangeList[1];
        data.channelStart = data.channelList[0];
        data.channelEnd = data.channelList[1];
        data.show_switch = data.show_switch ? 1 : 2;
        data.pause_start_time = data.pause_start_time ? data.pause_start_time * 1000 : ''; // 兼容已发公告

        if (!data.main_content) {
          data.main_content = [{
            content: data.content,
            language: data.language,
            title: data.title
          }];
        }

        this.paramsData = _objectSpread(_objectSpread({}, this.paramsData), data); // force_push === 0 时转为否  

        this.paramsData.force_push = this.paramsData.force_push === 0 ? 2 : this.paramsData.force_push;
      } else {
        this.paramsData.main_content = [{
          content: '',
          language: '',
          title: ''
        }];
      }

      this.dialogShow = true;
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    clearData: function clearData(isClearId) {
      this.paramsData = {
        id: isClearId ? '' : this.paramsData.id,
        label: 0,
        server_rangeStart: '',
        server_rangeEnd: '',
        channelStart: '',
        channelEnd: '',
        pause_start_time: '',
        pause_duration: '',
        priority: '',
        force_push: '',
        start_time: '',
        end_time: '',
        show_switch: '',
        pause: 0,
        type: '',
        main_content: [{
          content: '',
          language: '',
          title: ''
        }]
      };
      this.isStart = false;
      this.isEnd = false;
      this.$refs.ruleForm.clearValidate();
    },
    isS: function isS(e, type) {
      this.paramsData[type] = '';
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this3 = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var params = JSON.parse(JSON.stringify(_this3.paramsData));
          var data = {
            // title: params.title,
            // content: params.content,
            // language: params.language,
            pause: params.pause,
            start_time: _this3.isStart ? -1 : params.start_time / 1000,
            end_time: _this3.isEnd ? -1 : params.end_time / 1000,
            label: parseInt(params.label),
            priority: parseInt(params.priority),
            force_push: parseInt(params.force_push),
            pause_start_time: params.pause_start_time / 1000,
            pause_duration: params.pause_duration,
            // pause_start_time: params.is_pause ? (params.pauseTime[0] / 1000) : '',
            // pause_end_time: params.is_pause ? (params.pauseTime[1] / 1000) : '',
            server_range: params.server_rangeStart ? params.server_rangeEnd ? [params.server_rangeStart, params.server_rangeEnd].toString() : [params.server_rangeStart, params.server_rangeStart].toString() : '0',
            show_switch: params.show_switch == 1 ? true : false,
            channel: params.channelStart ? params.channelEnd ? [params.channelStart, params.channelEnd].toString() : [params.channelStart, params.channelStart].toString() : '-1',
            id: params.id,
            main_content: params.main_content
          };
          data = JSON.stringify([data]);
          var reqFn = _this3.paramsData.id !== '' ? _this3.paramsData.type == 'old' ? upAnnounce : upAnnounceNew : addAnnounce;
          var date = _this3.paramsData.type == 'new' ? _this3.paramsData.id ? _objectSpread(_objectSpread({
            data: data
          }, _this3.prod), {}, {
            id: params.id
          }) : _objectSpread({
            data: data
          }, _this3.prod) : _objectSpread({
            data: data
          }, _this3.prod);
          reqFn(date).then(function (res) {
            if (res.status == 200) {
              _this3.$message({
                message: '操作成功',
                type: 'success'
              });

              _this3.dialogShow = false;

              _this3.$emit('update');
            }
          });
        }
      });
    }
  }
};