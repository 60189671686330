var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          staticStyle: { width: "60%" },
          attrs: {
            "label-position": "left",
            rules: _vm.rules,
            model: _vm.paramsData,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "is_prod", label: "环境" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.getserverList },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "config_type_id", label: "配置类型" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.paramsData.config_type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "config_type_id", $$v)
                    },
                    expression: "paramsData.config_type_id"
                  }
                },
                _vm._l(_vm.TypeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              staticStyle: { width: "100%" },
              attrs: { prop: "", label: "开服天数要求" }
            },
            [
              _c("el-input", {
                staticClass: "flex-1",
                attrs: { type: "number", placeholder: "请输入" },
                on: { input: _vm.dayInput },
                model: {
                  value: _vm.paramsData.days,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "days", $$v)
                  },
                  expression: "paramsData.days"
                }
              }),
              _c("div", { staticStyle: { "margin-left": "40px" } }, [
                _vm._v("(满足开服X天的服务器开活动)")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              staticStyle: { width: "100%" },
              attrs: { prop: "server_list", label: "已选服务器" }
            },
            [
              _vm.serverLists.length
                ? _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.paramsData.server_list,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "server_list", $$v)
                        },
                        expression: "paramsData.server_list"
                      }
                    },
                    _vm._l(_vm.serverLists, function(item) {
                      return _c(
                        "el-checkbox",
                        { key: item.serverID, attrs: { label: item.serverID } },
                        [_vm._v(_vm._s(item.serverName))]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.claerData } },
                [_vm._v("重置表单")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v(_vm._s(_vm.paramsData.id ? "修改配置" : "提交配置"))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }