var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "operationFrame-entity",
                  attrs: {
                    clearable: "",
                    "collapse-tags": "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.paramsData.file,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "file", $$v)
                    },
                    expression: "paramsData.file"
                  }
                },
                _vm._l(_vm.ConfigNameList, function(item, index) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.clickup } },
                [_vm._v("上传配置文件")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "file", label: "配置表名" } }),
          _c("el-table-column", {
            attrs: { prop: "config_id", label: "活动配置ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "上传时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.timestampToTime(
                            row.created_at,
                            "YY-MM-DD HH"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "updated_at", label: "修改时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.timestampToTime(
                            row.updated_at,
                            "YY-MM-DD HH"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "controlsBtn",
                        on: {
                          click: function($event) {
                            return _vm.openView(row)
                          }
                        }
                      },
                      [_vm._v("查看配置")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total,
          "page-size": _vm.paramsData.pageSize
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.getList }
      }),
      _c("addfileActivities", {
        ref: "addfileActivities",
        on: { updFile: _vm.updFile }
      }),
      _c("viewfileActivities", { ref: "viewfileActivities" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }