import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { onlineLevel } from "@/api/dataAll/onlineLevel";
export default {
  name: "onlineLevel",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      show: false,
      searchData: {
        starttime: ''
      },
      device_data: [],
      account_data: [],
      device_level: "",
      account_level: ""
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.searchData.starttime = date;
    },
    getSummaries: function getSummaries(param) {
      var _this = this;

      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = "截止目前最高等级";
          return;
        }

        sums[1] = _this.account_level + "级";
      });
      return sums;
    },
    getSdeivce: function getSdeivce(param) {
      var _this2 = this;

      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = "截止目前最高设备等级";
          return;
        }

        sums[1] = _this2.device_level + "级";
      });
      return sums;
    },
    searchClick: function searchClick() {
      var _this3 = this;

      this.searchData.starttime = this.searchData.starttime;
      this.reqLoading = true;
      onlineLevel(this.searchData).then(function (res) {
        var acount = res.data.account_data;
        var device = res.data.device_data;
        _this3.account_level = res.data.account_level;
        _this3.device_level = res.data.device_level;

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var a = [];

        for (var key in acount) {
          a.push(key); // 级数放进了一个数组里
        }

        var acountdata = objToTitle(acount);
        acountdata.forEach(function (item, index) {
          item.level = a[index] + "级";
        });
        _this3.account_data = acountdata;
        var b = [];

        for (var _key in device) {
          b.push(_key); // 级数放进了一个数组里
        }

        var devicedata = objToTitle(device);
        devicedata.forEach(function (item, index) {
          item.level = b[index] + "级";
        });
        _this3.device_data = devicedata;
        _this3.show = true;
      }).finally(function () {
        _this3.reqLoading = false;
      });
    }
  }
};