var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.game_name, value: item.game_mark }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.platform_name,
                      value: item.platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择渠道"
                  },
                  on: { change: _vm.channelchange },
                  model: {
                    value: _vm.searchData.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "channel", $$v)
                    },
                    expression: "searchData.channel"
                  }
                },
                _vm._l(_vm.searchchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告渠道"
                  },
                  on: { change: _vm.advChannelchange },
                  model: {
                    value: _vm.searchData.advChannel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "advChannel", $$v)
                    },
                    expression: "searchData.advChannel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.adv_platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告位置"
                  },
                  on: { change: _vm.advLocationchange },
                  model: {
                    value: _vm.searchData.advLocation,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "advLocation", $$v)
                    },
                    expression: "searchData.advLocation"
                  }
                },
                _vm._l(_vm.advLocation, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.adv_place_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  on: { change: _vm.pkgchange },
                  model: {
                    value: _vm.searchData.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pkg", $$v)
                    },
                    expression: "searchData.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          !_vm.advanced
            ? _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.searchClick }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "moreOp",
                      on: {
                        click: function($event) {
                          _vm.advanced = !_vm.advanced
                        }
                      }
                    },
                    [
                      _c("div", {}, [
                        _vm._v(_vm._s(_vm.advanced ? "收起" : "展开"))
                      ]),
                      _c("i", {
                        class: _vm.advanced
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down"
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.advanced,
                expression: "advanced"
              }
            ]
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { display: "flex" }, attrs: { span: 4 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchClick }
                      },
                      [_vm._v("搜索")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "moreOp",
                        on: {
                          click: function($event) {
                            _vm.advanced = !_vm.advanced
                          }
                        }
                      },
                      [
                        _c("div", {}, [
                          _vm._v(_vm._s(_vm.advanced ? "收起" : "展开"))
                        ]),
                        _c("i", {
                          class: _vm.advanced
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-table",
        {
          staticStyle: {
            width: "100%",
            "margin-top": "70px",
            "margin-left": "30px"
          },
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "ID", width: "200" }
          }),
          _c("el-table-column", { attrs: { prop: "vip", label: "VIP等级" } }),
          _c("el-table-column", { attrs: { prop: "count", label: "数量" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }