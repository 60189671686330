import request from '@/utils/request'; //映射相关
// 操作映射列表

export function actionList(data) {
  return request({
    url: 'data/action-list',
    method: 'get',
    params: data
  });
} // 商城商品映射列表

export function marketList(data) {
  return request({
    url: 'data/market-list',
    method: 'get',
    params: data
  });
}