var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCountry: false } },
            on: { searchChange: _vm.searchChange }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _vm.radio4 == "RR"
                ? _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "collapse-tags": ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.screenControls()
                        }
                      },
                      model: {
                        value: _vm.screenRR,
                        callback: function($$v) {
                          _vm.screenRR = $$v
                        },
                        expression: "screenRR"
                      }
                    },
                    _vm._l(_vm.PrimitiveDyRR, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.prop }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.radio4 == "RRn"
                ? _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "collapse-tags": ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.screenControls()
                        }
                      },
                      model: {
                        value: _vm.screenRRn,
                        callback: function($$v) {
                          _vm.screenRRn = $$v
                        },
                        expression: "screenRRn"
                      }
                    },
                    _vm._l(_vm.PrimitiveDyRRn, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.prop }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { fill: "#68B837", size: "small" },
                  on: { input: _vm.toggle },
                  model: {
                    value: _vm.radio4,
                    callback: function($$v) {
                      _vm.radio4 = $$v
                    },
                    expression: "radio4"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "RR" } }, [
                    _vm._v("汇总留存率")
                  ]),
                  _c("el-radio-button", { attrs: { label: "RRn" } }, [
                    _vm._v("汇总留存数")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.radio4 == "RR"
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "tableRef",
                "cell-class-name": _vm.cellStyle,
                data: _vm.tableData,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  fixed: "",
                  label: "日期",
                  "min-width": "105"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "dateClass" }, [
                            _vm._v(_vm._s(row.date))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1227652827
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "register_num",
                  label: "注册账号",
                  "min-width": "70"
                }
              }),
              _vm._l(_vm.DynamicRR, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    "min-width": "70"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row[item.prop] !== "--"
                                    ? row[item.prop] + "%"
                                    : row[item.prop]
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            ],
            2
          )
        : _vm._e(),
      _vm.radio4 == "RRn"
        ? _c(
            "el-table",
            {
              ref: "tableRef",
              attrs: {
                id: "tableRef",
                "cell-class-name": _vm.cellStyle,
                data: _vm.tableData,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "日期", "min-width": "105" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "dateClass" }, [
                            _vm._v(_vm._s(row.date))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1227652827
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "register_num",
                  label: "注册账号",
                  "min-width": "70"
                }
              }),
              _vm._l(_vm.DynamicRRn, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    "min-width": "70"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", {
        staticClass: "margin-top",
        staticStyle: { height: "500px" },
        attrs: { id: "accountRetainPirateEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }