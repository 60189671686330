var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCode" },
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "生成礼包码",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "is_special", label: "是否特殊礼包" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.whetherNotChange },
                      model: {
                        value: _vm.paramsData.is_special,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "is_special", $$v)
                        },
                        expression: "paramsData.is_special"
                      }
                    },
                    _vm._l(_vm.whetherNot, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "code", label: "code" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity ",
                    attrs: { placeholder: "特殊码:4~8个，普通码:3个" },
                    model: {
                      value: _vm.paramsData.code,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "code", $$v)
                      },
                      expression: "paramsData.code"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "num", label: "生成数量" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity no-number",
                    attrs: { type: "number", placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.num,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "num", _vm._n($$v))
                      },
                      expression: "paramsData.num"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "reward_num", label: "可领取次数" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity no-number",
                    attrs: { type: "number", placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.reward_num,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "reward_num", _vm._n($$v))
                      },
                      expression: "paramsData.reward_num"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "body_length", label: "礼包码长度" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity no-number",
                    attrs: {
                      type: "number",
                      placeholder: "特殊码:4-8，普通码:6-8"
                    },
                    model: {
                      value: _vm.paramsData.body_length,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "body_length", _vm._n($$v))
                      },
                      expression: "paramsData.body_length"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "date", label: "可领取时间" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      "value-format": "timestamp",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    on: { change: _vm.dataChange },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "expire_time", label: "过期时间" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "日期"
                    },
                    on: { change: _vm.expireChange },
                    model: {
                      value: _vm.expire_time,
                      callback: function($$v) {
                        _vm.expire_time = $$v
                      },
                      expression: "expire_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addDomain }
                    },
                    [_vm._v("添加物品 ")]
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.item_list, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "Dynamic",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-f " },
                      [
                        _vm.paramsData.item_list.length > 1
                          ? _c("el-button", {
                              staticStyle: { "margin-right": "20px" },
                              attrs: {
                                size: "mini",
                                type: "danger",
                                circle: "",
                                icon: "el-icon-delete"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeDomain(domain)
                                }
                              }
                            })
                          : _vm._e(),
                        _c("el-switch", {
                          staticStyle: { "margin-right": "20px" },
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                            "active-text": "永久",
                            "inactive-text": "有效"
                          },
                          model: {
                            value: domain.ispermanent,
                            callback: function($$v) {
                              _vm.$set(domain, "ispermanent", $$v)
                            },
                            expression: "domain.ispermanent"
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem",
                            staticStyle: {
                              width: "150px",
                              "margin-right": "20px"
                            },
                            attrs: {
                              prop: "item_list." + index + ".item_id",
                              rules: _vm.rules.item_id
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "物品"
                                },
                                model: {
                                  value: domain.item_id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "item_id", $$v)
                                  },
                                  expression: "domain.item_id"
                                }
                              },
                              _vm._l(_vm.articleList, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: { label: items.kind, value: items.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem",
                            staticStyle: {
                              width: "200px !important",
                              "margin-right": "20px"
                            },
                            attrs: {
                              prop: "item_list." + index + ".expire",
                              rules: domain.ispermanent
                                ? null
                                : _vm.rules.expire_time
                            }
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "popper-class": "no-atTheMoment",
                                disabled: domain.ispermanent,
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "日期"
                              },
                              model: {
                                value: domain.expire,
                                callback: function($$v) {
                                  _vm.$set(domain, "expire", $$v)
                                },
                                expression: "domain.expire"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem",
                            staticStyle: { width: "100px" },
                            attrs: {
                              prop: "item_list." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", $$v)
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }