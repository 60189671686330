//监测数据汇总
import request from '@/utils/request';
export function getSummerData(data) {
  return request({
    url: '/datum/monitoring_collect_data',
    method: 'get',
    params: data
  });
}
export function getSummerDatajl(data) {
  return request({
    url: '/datum/monitoring_jl_collect_data',
    method: 'get',
    params: data
  });
}
export function getSummerDatagdt(data) {
  return request({
    url: '/datum/monitoring_gdt_collect_data',
    method: 'get',
    params: data
  });
}