import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { accRechByWeek, accRechByWeek_currency } from "@/api/dataAll/accRechgeRink";
import { tabulation } from "@/mixins/index";
import { mapState } from "vuex";
export default {
  name: "accRechRankByWeek",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      searchData: {
        starttime: "",
        endtime: "",
        start_week: "",
        end_week: ""
      },
      startWeek: [{
        id: 1,
        value: 1
      }, {
        id: 2,
        value: 2
      }, {
        id: 3,
        value: 3
      }, {
        id: 4,
        value: 4
      }, {
        id: 5,
        value: 5
      }],
      endWeek: [{
        id: 1,
        value: 1
      }, {
        id: 2,
        value: 2
      }, {
        id: 3,
        value: 3
      }, {
        id: 4,
        value: 4
      }, {
        id: 5,
        value: 5
      }],
      tableHead: [],
      tableData: [],
      weekData: [],
      Header: [{
        label: "名称",
        prop: "role_name"
      }, {
        label: "服务器ID",
        prop: "server_id"
      }, {
        label: "当前等级",
        prop: "level"
      }, {
        label: "战斗力",
        prop: "fighting"
      }, {
        label: "充值金额",
        prop: "upMoney"
      }, {
        label: "用户ID",
        prop: "user_id"
      }, {
        label: "角色ID",
        prop: "role_id"
      }, {
        label: "VIP等级",
        prop: "vip"
      }, {
        label: "最后登录时间",
        prop: "last_login_time"
      }]
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  activated: function activated() {// if (this.searchData.game.indexOf(this.royalpirates) > -1) {
    //   this.Header = [
    //     {
    //       label: '名称',
    //       prop: 'role_name'
    //     }, {
    //       label: '服务器ID',
    //       prop: 'server_id'
    //     }, {
    //       label: '当前等级',
    //       prop: 'level'
    //     }, {
    //       label: '战斗力',
    //       prop: 'fighting'
    //     }, {
    //       label: '用户ID',
    //       prop: 'user_id'
    //     }, {
    //       label: '角色ID',
    //       prop: 'role_id'
    //     }, {
    //       label: '充值金额',
    //       prop: 'upMoney'
    //     }, {
    //       label: 'VIP等级',
    //       prop: 'vip'
    //     }, {
    //       label: '最后登录时间',
    //       prop: 'last_login_time'
    //     }
    //   ]
    // } else {
    //   this.Header = [
    //     {
    //       label: '用户ID',
    //       prop: 'user_id'
    //     }, {
    //       label: '充值金额',
    //       prop: 'upMoney'
    //     }
    //   ]
    // }
  },
  computed: _objectSpread({}, mapState("d2admin/serchSelect", ["royalpirates"])),
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date1 = year + "-" + month;
      var date2 = date;
      this.weekData = date1;
      this.weeks(date1, date2);
    },
    weeks: function weeks(now_month, date2) {
      var _this = this;

      var week_array = [];
      var today = new Date(Date.parse(now_month));
      var year = today.getFullYear();
      var month = today.getMonth();
      var i = 0;
      var start = new Date(year, month, 1); // 得到当月第一天

      var end = new Date(year, month + 1, 0); // 得到当月最后一天
      // 循环每周最后天叠设置为第一天，直到最后一天小于当月的最后一天

      while (start <= end) {
        var monday = new Date(start.getTime());
        var sunday = new Date(start.getTime());
        monday.setDate(monday.getDate() + 1 - monday.getDay());
        sunday.setDate(sunday.getDate() + 7 - sunday.getDay());
        week_array.push([this.$times.timestampToTime(monday / 1000, "YY-MM-DD"), this.$times.timestampToTime(sunday / 1000, "YY-MM-DD")]);
        start = sunday;
      }

      week_array[0][0] = this.$times.timestampToTime(new Date(year, month, 1) / 1000, "YY-MM-DD");
      week_array[week_array.length - 1][1] = this.$times.timestampToTime(new Date(year, month + 1, 0) / 1000, "YY-MM-DD");
      week_array.forEach(function (item, index) {
        if (Number(item[0].substring(item[0].length, item[0].length - 2)) >= date2 || Number(item[1].substring(item[1].length, item[1].length - 2)) <= date2) {
          _this.searchData.start_week = index;
          _this.searchData.end_week = index;
        }
      });
    },
    rowClick: function rowClick(row, item, prop) {
      if (prop.indexOf("user_id") > -1 && row[prop]) {
        this.$router.replace({
          name: "orderDisplay",
          params: {
            user_id: row[prop],
            date: item.label + "/" + this.searchData.month,
            type: "W"
          }
        });
      }
    },
    exportdata: function exportdata() {
      var _this2 = this;

      var pageData = this.resData;
      pageData = Object.keys(pageData).map(function (item) {
        return _objectSpread({
          week: item
        }, pageData[item]);
      });
      var columns = [];
      var data = [];
      var merges = [];
      var complete = {};
      pageData.map(function (item, index) {
        columns.push.apply(columns, _toConsumableArray(_this2.Header.map(function (i, ins) {
          return {
            label: ins == 0 ? "第" + item.week + "周" : "",
            prop: "".concat(index + i.prop)
          };
        })));

        _this2.Header.forEach(function (i) {
          complete[index + i.prop] = i.label;
        });

        var IN = -1;

        var _loop = function _loop(i) {
          if (i != "week") {
            item[i].forEach(function (k, ki) {
              IN += 1;
              var taskData = {};

              _this2.Header.forEach(function (j, ji) {
                taskData[index + j.prop] = j.prop.indexOf("upMoney") > -1 ? _this2.$utils.conversion(i.slice(2)) : k[j.prop] instanceof Array ? k[j.prop].toString() : k[j.prop];

                if (k.recharge_amount && k.recharge_amount instanceof Array) {
                  if (j.prop.indexOf("upMoney") > -1) {
                    var list = [];
                    k.recharge_amount.map(function (s) {
                      list.push(_this2.$utils.conversion(s));
                    });
                    taskData[index + j.prop] = list.toString();
                  }
                }
              });

              data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
            });
          }
        };

        for (var i in item) {
          _loop(i);
        }
      }); // for (let n = 0; n < parseInt(columns.length / 2); n++) {
      //   merges.push([this.headerZh(n, 0), this.headerZh(n, 1)])
      // }

      data.unshift(complete);
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title,
        merges: merges
      });
    },
    headerZh: function headerZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      var num = k > 0 ? letterTable[k - 1] : "";

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[k <= 1 ? index * 2 : index];
        } else {
          num = num + letterTable[k <= 1 ? index * 2 + 1 : index + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2];
        } else {
          num = num + letterTable[index % 13 * 2 + 1];
        }
      }

      return num + "1";
    },
    searchClick: function searchClick(nums) {
      var _this3 = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.numing = nums;
      this.guankaArr = [];

      if (this.weekData && this.weekData.length > 0) {
        this.searchData.month = this.weekData;
      }

      this.reqLoading = true;
      var reqFn = this.currencyClose ? accRechByWeek_currency : accRechByWeek;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this3.resData = res.data;
          _this3.tableHead = [];
          var data = [];
          var pageData = Object.keys(res.data).map(function (item) {
            return _objectSpread({
              week: item
            }, res.data[item]);
          });
          pageData.forEach(function (item, index) {
            _this3.tableHead.push({
              label: "第" + item.week + "周",
              list: _this3.Header.map(function (i) {
                return {
                  label: i.label,
                  prop: "".concat(index + i.prop)
                };
              })
            });

            var IN = -1;

            var _loop2 = function _loop2(i) {
              if (i != "week") {
                item[i].forEach(function (k, ki) {
                  IN += 1;
                  var taskData = {};

                  _this3.Header.forEach(function (j, ji) {
                    taskData[index + j.prop] = j.prop.indexOf("upMoney") > -1 ? _this3.$utils.conversion(i.slice(2)) : k[j.prop] instanceof Array ? k[j.prop].toString() : k[j.prop];

                    if (k.recharge_amount && k.recharge_amount instanceof Array) {
                      if (j.prop.indexOf("upMoney") > -1) {
                        var list = [];
                        k.recharge_amount.map(function (s) {
                          list.push(_this3.$utils.conversion(s));
                        });
                        taskData[index + j.prop] = list.toString();
                      }
                    }
                  });

                  data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
                });
              }
            };

            for (var i in item) {
              _loop2(i);
            }
          });
          _this3.tableData = data;

          _this3.$nextTick(function () {
            _this3.getTableRight();
          });
        }
      }).finally(function () {
        _this3.reqLoading = false;
      });
    }
  }
};