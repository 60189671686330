import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { accDailyTask } from "@/api/dataAll/new";
import { getadvLocation } from "@/api/dataAll/advLocation";
export default {
  name: "accTaskStatistical",
  data: function data() {
    return {
      gameId: "",
      value1: "",
      value2: [],
      searchData: {
        starttime: "",
        endtime: "",
        advChannel: "",
        advLocation: ""
      },
      tableData: [],
      resource: [],
      guankaArr: [],
      gkTd: "",
      tableTime: [],
      show: false,
      task: ""
    };
  },
  created: function created() {
    var _this = this;

    this.getsearchDate();
    searchgame().then(function (res) {
      _this.searchgames = res.data;
      _this.gameId = res.data.id;
    });
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    datechange: function datechange() {
      if (this.value2.length > 0) {
        this.searchMark.starttime = this.value2[0];
        this.searchMark.endtime = this.value2[1];
      }
    },
    searchClick: function searchClick() {
      var _this2 = this;

      this.guankaArr = [];

      if (this.value2.length > 0) {
        this.searchMark.starttime = this.value2[0];
        this.searchMark.endtime = this.value2[1];
      }

      accDailyTask(this.searchMark).then(function (res) {
        _this2.tableTime = [];

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objToKey = function objToKey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        _this2.tableData = objToTitle(res.data);

        _this2.tableData.forEach(function (item) {
          var k = objToKey(item);

          _this2.tableTime.push(k[0]);
        });

        var newNum = [];
        var sum_num = _this2.value2;
        var table_bodoy = "<tr><td></td><td></td>";

        _this2.tableData.forEach(function (val, index) {
          table_bodoy += "<td>已完成</td><td>已完成未领取</td>";
        });

        table_bodoy += "</tr>";
        var table_top = "<th style='width:200px;'>任务ID</th><th style='width:200px;'></th>";
        var table_newNum = "<th style='width:100px;'></th><th style='width:100px;'>参与任务人数</th>";

        var _iterator = _createForOfIteratorHelper(_this2.tableTime),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var date = _step.value;
            table_top += "<th colspan='2' style='width:250px;line-height:40px;'>" + date + "</th>";
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        _this2.tableData.forEach(function (item, i) {
          var key = _this2.tableTime[i];
          newNum.push(item[key].sum_num);
          var task = objToKey(item[key].task);
          _this2.task = [];
          task.forEach(function (val, index) {
            if (_this2.task.indexOf(val) > -1) {} else {
              _this2.task.push(val);
            }
          });
        });

        newNum.forEach(function (item) {
          table_newNum += "<th colspan='2' style='width:250px;line-height:40px;'>" + item + "</th>";
        });

        if (_this2.task.length > 0) {
          _this2.task.forEach(function (item, i) {
            table_bodoy += "<tr>";
            table_bodoy += "<td style='width:100px;WORD-WRAP:break-word'>" + item + "</td>";
            table_bodoy += "<td></td>";

            _this2.tableData.forEach(function (val, index) {
              var a = _this2.tableTime[index];
              var b = val[a];
              var c = objToTitle(b.task);

              if (c[i] != undefined) {
                table_bodoy += "<td>" + c[i].done + "</td>";
                table_bodoy += "<td>" + c[i].unclaimed + "</td>";
              } else {
                table_bodoy += "<td>/</td>";
                table_bodoy += "<td>/</td>";
                table_bodoy += "<td>/</td>";
              }
            });

            table_bodoy += "</tr>";
          });
        }

        var header = document.getElementById("header");
        var bodoy = document.getElementById("bodoy");
        var num = document.getElementById("newNum");
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        num.innerHTML = table_newNum;
        _this2.show = true;
      });
    }
  }
};