import request from '@/utils/request'; //获取下拉款全部渠道

export function getUniUserList(data) {
  return request({
    url: '/qudao/qudao_getall',
    method: 'get',
    params: data
  });
} //获取统一用户渠道列表

export function getChnnelList(data) {
  return request({
    url: '/qudao/qudao_list',
    method: 'get',
    params: data
  });
} //新增页面获取统一渠道

export function getAddChannel(data) {
  return request({
    url: '/qudao/qudao_add',
    method: 'get',
    params: data
  });
} //新增统一渠道

export function addChannel(data) {
  return request({
    url: '/qudao/qudao_add',
    method: 'post',
    params: data
  });
} // 返显数据

export function returnData(data) {
  return request({
    url: '/qudao/qudao_update',
    method: 'get',
    params: data
  });
} // 修改渠道数据

export function updataChannel(data) {
  return request({
    url: '/qudao/qudao_update',
    method: 'post',
    params: data
  });
} // 删除渠道数据

export function deleChannel(data) {
  return request({
    url: '/qudao/qudao_del',
    method: 'get',
    params: data
  });
}