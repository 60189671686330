import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { dataShowAll } from "@/api/largeScreenData/largeScreenData";
import { mapState } from "vuex";
import vueSeamless from 'vue-seamless-scroll';
import { serchSelect } from "@/mixins/index";
import * as echarts from "echarts";
export default {
  name: 'largeScreenData',
  mixins: [serchSelect],
  components: {
    vueSeamless: vueSeamless
  },
  data: function data() {
    return {
      searchData: {},
      tooltip: {
        confine: true,
        backgroundColor: 'rgba(0,0,0,.5)',
        borderWidth: '0',
        textStyle: {
          color: '#FFFFFF'
        }
      },
      textStyle: {
        color: '#FFFFFF',
        fontFamily: "Heavy"
      },
      defaultOption: {
        step: 0.6
      },
      // 折线图ID
      eChartList: {
        Insregrate: null,
        IncrementalFeeRate: null,
        activePayRate: null,
        timeremaining: null,
        ltvOne: null,
        installationCost: null,
        onlineEchart: null,
        PayersRechargeIncome: null,
        ItemRatio: null,
        RealTimeaccountNumLogin: null,
        newProportion: null
      },
      orderList: [],
      // 订单列表
      rechargeRankList: [],
      // 充值人员排行榜
      onlineList: [],
      //在线时长排行
      xAxisList: []
    };
  },
  created: function created() {},
  activated: function activated() {
    this.getData();
  },
  mounted: function mounted() {
    for (var i = 0; i < 24; i++) {
      var str = i < 10 ? "0".concat(i) : "".concat(i);
      this.xAxisList.push(str);
    }
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['asideCollapse'])),
  watch: {
    asideCollapse: function asideCollapse(val) {
      var _this = this;

      setTimeout(function () {
        for (var i in _this.eChartList) {
          _this.eChartList[i] && _this.eChartList[i].resize();
        }
      }, 500);
    }
  },
  methods: {
    handleOptionClick: function handleOptionClick(e) {
      this.getData();
    },
    // 
    getData: function getData() {
      var _this2 = this;

      this.orderList = [];
      this.rechargeRankList = [];
      this.onlineList = [];
      delete this.searchData.ad_channel;
      delete this.searchData.ad_placement;
      dataShowAll(this.searchData).then(function (res) {
        if (res.status == 200) {
          var datas = {
            other: {
              "NPR": {
                'r0': 10,
                //注册人数
                'p0': 6 //付费人数

              },
              // 新增付费率
              "PR": {
                'a0': 10,
                //活跃人数
                'r0': 4 // 付费人数

              },
              //  活跃付费率
              "eCPR_count": 17,
              //次留用户数
              "eCPI": {
                "t0": 0,
                // 成本
                "i0": 0 // 安装量

              },
              // 新增安装成本
              "ltv1": "381.818",
              // ltv1
              "install": 100,
              // 安装量
              "free_install": 100,
              // 自然量
              "buy_install": 0,
              // 投放量
              "register": 10
            },
            // 在线时长排行榜
            onlineData: [{
              "hour": "5",
              //
              "user_id": 12344 //用户id

            }, {
              "hour": "2",
              //在线时长
              "user_id": 1234 //用户id

            }, {
              "hour": "1",
              //在线时长
              "user_id": 123 //用户id

            }],
            // 充值人员排行榜
            rechargeRank: {
              "123": 80,
              //充值金额
              "101024": 120,
              //充值人员
              "202401013": 101,
              "10000": 50
            },
            more: {
              // 激活注册占比
              ar_percent: {
                'a0': 121,
                // 激活数量
                'r0': 11 // 注册数量

              },
              // 每小时在线人数
              hour_online_list: {
                "2023-12-31": {
                  "01": 100,
                  "02": 110
                },
                "2024-01-01": {
                  "01": 10,
                  "02": 30
                }
              },
              // 每小时付费金额
              hour_recharge_list: {
                "2024-01-16": {
                  "1": 100
                },
                "2024-01-17": {
                  "2": 100
                }
              },
              // 
              recharge_hour_account_list: {
                "2024-01-16": {
                  "1": 100
                },
                "2024-01-17": {
                  "2": 100
                }
              },
              // 每小时登录人数
              login_hour_list: {
                "2024-01-16": {
                  "1": 10,
                  "2": 21,
                  "3": 321,
                  "4": 63
                },
                "2024-01-17": {
                  "1": 10,
                  "2": 1,
                  "3": 54,
                  "4": 64
                }
              },
              // 活跃付费比例
              lr_percent: {
                "l0": 100,
                // 活跃用户数
                "r0": 80 // 付费用户数

              },
              // LTV1
              ltv1: {
                "r0": 100,
                // 昨日新增用户数
                "l1": 1000 // 昨日和今日新增用户付费数

              },
              // 订单列表
              order_list: [{
                "role_name": "角色名称",
                "product_name": "商品名称",
                "amount": 100,
                "time": 1705631002
              }],
              // 商品购买占比
              product_proportion: {
                product_1: "100",
                product_2: "200",
                product_3: "300",
                product_4: "400"
              },
              // 每小时注册人数
              register_hour_list: {
                "2024-01-16": {
                  "1": 10,
                  "2": 21,
                  "3": 321,
                  "4": 63
                },
                "2024-01-17": {
                  "1": 10,
                  "2": 1,
                  "3": 54,
                  "4": 64
                }
              },
              // 次留
              retain1: {
                "r0": 0,
                // 前一日注册用户数
                "l0": 0 // 今天留存用户数量

              },
              // 新增付费比例
              rr_percent: {
                "a0": 100,
                // 注册用户数
                "r0": 80 // 付费用户数

              }
            }
          };
          var data = res.data; // let data = datas
          // 订单列表数据

          _this2.orderList = data.more.order_list; // 充值人员排行榜

          var rechargeRankList = [];

          for (var i in data.rechargeRank) {
            rechargeRankList.push({
              'user_id': i,
              'amount': _this2.$utils.conversion(data.rechargeRank[i]).toFixed(2)
            });
          }

          ;
          rechargeRankList.sort(function (a, b) {
            return b.amount - a.amount; //降序排列，return a-b; —>升序排列
          });
          _this2.rechargeRankList = rechargeRankList; // 在线时长排行榜

          var onlineList = [];
          data.onlineData.forEach(function (i, index) {
            onlineList.push(i);
          });
          _this2.onlineList = onlineList; // 实时在线人数

          _this2.getonlineEchart(data.more.hour_online_list); // 购买物品占比  


          _this2.getItemRatio(data.more.product_proportion); // 百分比列表


          var percentList = [{
            title: '转化率',
            graphic: '',
            id: 'Insregrate',
            color: '#37A2DA',
            data: []
          }, {
            title: '新增付费率',
            graphic: '',
            id: 'IncrementalFeeRate',
            color: '#32C5E9',
            data: []
          }, {
            title: '活跃付费率',
            graphic: '',
            id: 'activePayRate',
            color: '#67E0E3',
            data: []
          }, {
            title: '留存率',
            graphic: '',
            id: 'timeremaining',
            color: '#FB7293',
            data: []
          }, {
            title: '自然率',
            graphic: '',
            id: 'ltvOne',
            color: '#FFDB5C',
            data: []
          }, {
            title: '买量率',
            graphic: '',
            id: 'installationCost',
            color: '#9FE6B8',
            data: []
          }]; // 安装注册转化率

          var graphic0 = !data.other.register || !data.other.install ? 0 : (data.other.register / data.other.install * 100).toFixed(2);
          percentList[0].graphic = graphic0 + '%';
          percentList[0].data = [{
            value: graphic0,
            name: '注册率'
          }, {
            value: (100 - graphic0).toFixed(2),
            name: '未注册率'
          }]; // 新增付费率

          var graphic1 = !data.other.NPR.p0 || !data.other.NPR.r0 ? 0 : (data.other.NPR.p0 / data.other.NPR.r0 * 100).toFixed(2);
          percentList[1].graphic = graphic1 + '%';
          percentList[1].data = [{
            value: graphic1,
            name: '付费率'
          }, {
            value: (100 - graphic1).toFixed(2),
            name: '未付费率'
          }]; // 活跃付费率

          var graphic2 = !data.other.PR.r0 || !data.other.PR.a0 ? 0 : (data.other.PR.r0 / data.other.PR.a0 * 100).toFixed(2);
          percentList[2].graphic = graphic2 + '%';
          percentList[2].data = [{
            value: graphic2,
            name: '付费率'
          }, {
            value: (100 - graphic2).toFixed(2),
            name: '未付费率'
          }]; // 次留
          // l0 今日留存数量  ro昨日注册数量

          var graphic3 = !data.more.retain1.l0 || !data.more.retain1.r0 ? 0 : (data.more.retain1.l0 / data.more.retain1.r0 * 100).toFixed(2);
          percentList[3].graphic = graphic3 + '%';
          percentList[3].data = [{
            value: graphic3,
            name: '留存率'
          }, {
            value: (100 - graphic3).toFixed(2),
            name: '流失率'
          }]; // 自然量K因子

          var graphic4 = !data.other.free_install || !data.other.install ? 0 : (data.other.free_install / data.other.buy_install * 100).toFixed(2);
          percentList[4].graphic = graphic4 + '%';
          percentList[4].data = [{
            value: graphic4,
            name: '新增自然率'
          }, {
            value: (100 - graphic4).toFixed(2),
            name: '新增投放率'
          }]; // 买量安装

          var graphic5 = !data.other.buy_install || !data.other.install ? 0 : (data.other.buy_install / data.other.install * 100).toFixed(2);
          percentList[5].graphic = graphic5 + '%';
          percentList[5].data = [{
            value: graphic5,
            name: '新增投放率'
          }, {
            value: (100 - graphic5).toFixed(2),
            name: '新增自然率'
          }];
          percentList.forEach(function (item) {
            _this2.getInsregrate(item);
          }); // 实时新增账号数、实时登录数据

          _this2.getRealTimeaccountNumLogin(data.more.register_hour_list, data.more.login_hour_list); // 新增占比


          _this2.getnewProportion(data.other); // 实时付费人数 充值收入


          _this2.getPayersRechargeIncome(data.more.recharge_hour_account_list, data.more.hour_recharge_list);
        }
      });
    },
    // 实时在线人数
    getonlineEchart: function getonlineEchart(data) {
      var _this3 = this;

      var series = [];

      var _loop = function _loop(i) {
        var d = {
          name: i,
          type: 'line',
          smooth: true,
          data: []
        };

        _this3.xAxisList.forEach(function (k) {
          d.data.push(data[i][k] ? data[i][k] : 0);
        });

        series.push(d);
      };

      for (var i in data) {
        _loop(i);
      }

      var chartDom = document.getElementById("onlineEchart");
      this.eChartList.onlineEchart = echarts.init(chartDom);
      var option = {
        tooltip: _objectSpread({
          trigger: "axis"
        }, this.tooltip),
        textStyle: this.textStyle,
        grid: {
          top: '8%',
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // data: series[0].data.map((item,index)=>{ return index + 1}),
          data: this.xAxisList,
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,.1)",
              width: 1,
              type: "solid"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              fontFamily: "微软雅黑",
              color: "rgba(255,255,255,.6)"
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,1)'
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,.1)"
            }
          }
        },
        series: series
      };
      option && this.eChartList.onlineEchart.setOption(option, true);
    },

    /**
     * 安装注册转化率 Insregrate
     * 新增付费率 IncrementalFeeRate
     * 活跃付费率 activePayRate
     * 次留  timeremaining
     * ltv1  ltvOne
     * 新增安装成本  installationCost 
     * @param {*} data 
     *  title  graphic name data
     */
    getInsregrate: function getInsregrate(data) {
      var chartDom = document.getElementById(data.id);
      this.eChartList[data.id] = echarts.init(chartDom);
      var option = {
        tooltip: _objectSpread({
          trigger: 'item',
          formatter: function formatter(param) {
            return param.data.name + ': ' + param.data.value + '%';
          }
        }, this.tooltip),
        textStyle: this.textStyle,
        color: [data.color, "#777570"],
        title: {
          text: data.title,
          left: "center",
          top: "50%",
          textStyle: {
            color: "#FFFFFF",
            fontSize: 14,
            align: "center"
          }
        },
        graphic: {
          type: "text",
          left: "center",
          top: "40%",
          style: {
            text: data.graphic,
            textAlign: "center",
            fill: "#FFFFFF",
            fontSize: 14,
            fontWeight: 700
          }
        },
        series: [{
          name: data.title,
          type: 'pie',
          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: 'center'
            }
          },
          data: data.data
        }]
      };
      option && this.eChartList[data.id].setOption(option, true);
    },
    // 付费人数充值收入
    getPayersRechargeIncome: function getPayersRechargeIncome(PayBill, topUp) {
      var _this4 = this;

      var series = [];
      var num = 0;
      var dataTimeArr = [];

      var _loop2 = function _loop2(i) {
        dataTimeArr.push(i);
        num++;
        var registerData = {
          name: '付费人数',
          "yAxisIndex": 0,
          date: i,
          type: 'bar',
          data: [],
          barWidth: '25%',
          //柱子宽度
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: num == 1 ? '#3ca1ff' : '#f8b135'
              }, {
                offset: 1,
                color: num == 1 ? '#0a73d5' : '#e59100'
              }]),
              opacity: 1,
              barBorderRadius: 50
            }
          }
        };
        var loginData = {
          name: '充值收入(元)',
          type: 'line',
          date: i,
          "yAxisIndex": 1,
          smooth: true,
          data: [],
          itemStyle: {
            color: num == 1 ? '#e59100' : '#0a73d5'
          }
        };

        _this4.xAxisList.forEach(function (k) {
          registerData.data.push(PayBill[i][k] ? PayBill[i][k] : 0);
          loginData.data.push(topUp[i][k] ? _this4.$utils.conversion(topUp[i][k]).toFixed(2) : 0);
        });

        series.push(registerData);
        series.push(loginData);
      };

      for (var i in PayBill) {
        _loop2(i);
      }

      var chartDom = document.getElementById('PayersRechargeIncome');
      this.eChartList.PayersRechargeIncome = echarts.init(chartDom);
      var option = {
        tooltip: _objectSpread(_objectSpread({
          trigger: 'axis'
        }, this.tooltip), {}, {
          formatter: function formatter(params) {
            var n = params[0].seriesName;
            var i = -1;
            var val = params[0].name + "<br/>";
            params.forEach(function (items, index) {
              if (n == items.seriesName) {
                i += 1;
                val += dataTimeArr[i] + "<br/>";
              }

              var color = items.seriesType == 'bar' ? items.color.colorStops[0].color : items.color;
              var dotHtml = "<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:" + color + "\"></span>";
              val += dotHtml + items.seriesName + ':' + items.value + "<br/>";
            });
            return val;
          }
        }),
        textStyle: this.textStyle,
        legend: {
          data: series.map(function (i) {
            return i.name;
          }),
          align: 'right',
          right: '40%',
          bottom: '0%',
          textStyle: {
            color: "#fff"
          },
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 35
        },
        grid: {
          left: '3%',
          top: '2%',
          right: '3%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxisList,
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,.1)",
              width: 1,
              type: "solid"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            // rotate:50,
            show: true,
            splitNumber: 15,
            textStyle: {
              fontFamily: "微软雅黑",
              color: "rgba(255,255,255,.6)",
              fontSize: '120%'
            }
          }
        },
        yAxis: [{
          type: 'value',
          name: '付费人数',
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,1)'
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,.1)"
            }
          }
        }, {
          type: 'value',
          name: '充值收入',
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,1)'
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,.1)"
            }
          }
        }],
        series: series
      };
      option && this.eChartList.PayersRechargeIncome.setOption(option, true);
    },
    // 购买物品占比
    getItemRatio: function getItemRatio(data) {
      var legendData = [];
      var seriesData = [];
      var num = 0;

      for (var i in data) {
        num++;

        if (num < 8) {
          legendData.push(i);
          seriesData.push({
            name: i,
            value: data[i]
          });
        }
      }

      if (!seriesData.length) {
        return;
      }

      var chartDom = document.getElementById('ItemRatio');
      this.eChartList.ItemRatio = echarts.init(chartDom);
      var option = {
        tooltip: _objectSpread({
          trigger: 'item',
          formatter: "{b} : {c} ({d}%)"
        }, this.tooltip),
        textStyle: this.textStyle,
        legend: {
          right: 0,
          top: 30,
          height: 160,
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 10,
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontSize: 12
          },
          orient: 'vertical',
          formatter: function formatter(name) {
            if (name.length > 8) {
              name = name.slice(0, 8) + '...';
            }

            return name;
          },
          tooltip: {
            show: true
          },
          data: legendData
        },
        calculable: true,
        series: [{
          name: ' ',
          color: ['#62c98d', '#2f89cf', '#4cb9cf', '#53b666', '#62c98d', '#205acf', '#c9c862', '#c98b62', '#c962b9', '#7562c9', '#c96262', '#c25775', '#00b7be'],
          type: 'pie',
          radius: [30, 70],
          center: ['35%', '50%'],
          roseType: 'radius',
          label: {
            normal: {
              show: true
            },
            emphasis: {
              show: true
            }
          },
          lableLine: {
            normal: {
              show: true
            },
            emphasis: {
              show: true
            }
          },
          data: seriesData
        }]
      };
      option && this.eChartList.ItemRatio.setOption(option, true);
    },
    // 实时新增账号数 实时登录数据
    getRealTimeaccountNumLogin: function getRealTimeaccountNumLogin(register_hour_list, login_hour_list) {
      var _this5 = this;

      var series = [];
      var num = 0;
      var dataTimeArr = [];

      var _loop3 = function _loop3(i) {
        dataTimeArr.push(i);
        num++;
        var registerData = {
          name: '新增账号数',
          "yAxisIndex": 0,
          date: i,
          type: 'bar',
          data: [],
          barWidth: '25%',
          //柱子宽度
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: num == 1 ? '#7CFC00' : '#48D1CC'
              }, {
                offset: 1,
                color: num == 1 ? '#ADFF2F' : '#87CEFA'
              }]),
              opacity: 1,
              barBorderRadius: 50
            }
          }
        };
        var loginData = {
          name: '登录数',
          type: 'line',
          date: i,
          "yAxisIndex": 1,
          smooth: true,
          data: [],
          itemStyle: {
            color: num == 1 ? '#7DD2F3' : '#FC8452'
          }
        };

        _this5.xAxisList.forEach(function (k) {
          registerData.data.push(register_hour_list[i][k] ? register_hour_list[i][k] : 0);
          loginData.data.push(login_hour_list[i] && login_hour_list[i][k] ? login_hour_list[i][k] : 0);
        });

        series.push(registerData);
        series.push(loginData);
      };

      for (var i in register_hour_list) {
        _loop3(i);
      }

      var chartDom = document.getElementById('RealTimeaccountNumLogin');
      this.eChartList.RealTimeaccountNumLogin = echarts.init(chartDom);
      var option = {
        tooltip: _objectSpread(_objectSpread({
          trigger: 'axis'
        }, this.tooltip), {}, {
          formatter: function formatter(params) {
            var n = params[0].seriesName;
            var i = -1;
            var val = params[0].name + "<br/>";
            params.forEach(function (items, index) {
              if (n == items.seriesName) {
                i += 1;
                val += dataTimeArr[i] + "<br/>";
              }

              var color = items.seriesType == 'bar' ? items.color.colorStops[0].color : items.color;
              var dotHtml = "<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:" + color + "\"></span>";
              val += dotHtml + items.seriesName + ':' + items.value + "<br/>";
            });
            return val;
          }
        }),
        textStyle: this.textStyle,
        legend: {
          data: series.map(function (i) {
            return i.name;
          }),
          align: 'right',
          right: '40%',
          bottom: '0%',
          textStyle: {
            color: "#fff"
          },
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 35
        },
        grid: {
          left: '3%',
          top: '2%',
          right: '3%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxisList,
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,.1)",
              width: 1,
              type: "solid"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            // rotate:50,
            show: true,
            splitNumber: 15,
            textStyle: {
              fontFamily: "微软雅黑",
              color: "rgba(255,255,255,.6)",
              fontSize: '120%'
            }
          }
        },
        yAxis: [{
          type: 'value',
          name: '付费人数',
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,1)'
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,.1)"
            }
          }
        }, {
          type: 'value',
          name: '充值收入',
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,1)'
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,.1)"
            }
          }
        }],
        series: series
      }; // let option = {
      //   tooltip: {
      //     trigger: 'axis',
      //     axisPointer: {
      //       lineStyle: {
      //         color: '#57617B'
      //       }
      //     },
      //     ...this.tooltip
      //   },
      //   legend: {
      //     data: ['新增账号', '登录数'],
      //     top: '0',
      //     textStyle: {
      //       color: "#fff"
      //     },
      //     itemGap: 20,
      //   },
      //   grid: {
      //     left: '0',
      //     right: '20',
      //     top: '10',
      //     bottom: '20',
      //     containLabel: true
      //   },
      //   xAxis: {
      //     type: 'category',
      //     boundaryGap: false,
      //     axisLabel: {
      //       show: true,
      //       textStyle: {
      //         color: 'rgba(255,255,255,1)',
      //         fontSize: 11
      //       }
      //     },
      //     axisLine: {
      //       lineStyle: {
      //         color: 'rgba(255,255,255,.1)'
      //       }
      //     },
      //     data: ['WE', 'WE', 'WE', 'WE', 'WE', 'WE', 'WE', 'WE', 'WE', 'WE', 'WE', 'WE',]
      //   },
      //   yAxis: {
      //     axisLabel: {
      //       show: true,
      //       textStyle: {
      //         color: 'rgba(255,255,255,.6)'
      //       }
      //     },
      //     axisLine: {
      //       lineStyle: {
      //         color: 'rgba(255,255,255,.1)'
      //       }
      //     },
      //     splitLine: {
      //       lineStyle: {
      //         color: 'rgba(255,255,255,.1)'
      //       }
      //     }
      //   },
      //   series: [{
      //     name: '新增账号',
      //     type: 'line',
      //     smooth: true,
      //     symbol: 'circle',
      //     symbolSize: 5,
      //     showSymbol: false,
      //     lineStyle: {
      //       normal: {
      //         width: 2
      //       }
      //     },
      //     areaStyle: {
      //       normal: {
      //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      //           offset: 0,
      //           color: 'rgba(24, 163, 64, 0.3)'
      //         }, {
      //           offset: 0.8,
      //           color: 'rgba(24, 163, 64, 0)'
      //         }], false),
      //         shadowColor: 'rgba(0, 0, 0, 0.1)',
      //         shadowBlur: 10
      //       }
      //     },
      //     itemStyle: {
      //       normal: {
      //         color: '#cdba00',
      //         borderColor: 'rgba(137,189,2,0.27)',
      //         borderWidth: 12
      //       }
      //     },
      //     data: [205, 191, 239, 169, 205, 125, 162, 136, 189, 157, 121, 158]
      //   }, {
      //     name: '登录数',
      //     type: 'line',
      //     smooth: true,
      //     symbol: 'circle',
      //     symbolSize: 5,
      //     showSymbol: false,
      //     lineStyle: {
      //       normal: {
      //         width: 2
      //       }
      //     },
      //     areaStyle: {
      //       normal: {
      //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      //           offset: 0,
      //           color: 'rgba(39, 122,206, 0.3)'
      //         }, {
      //           offset: 0.8,
      //           color: 'rgba(39, 122,206, 0)'
      //         }], false),
      //         shadowColor: 'rgba(0, 0, 0, 0.1)',
      //         shadowBlur: 10
      //       }
      //     },
      //     itemStyle: {
      //       normal: {
      //         color: '#277ace',
      //         borderColor: 'rgba(0,136,212,0.2)',
      //         borderWidth: 12
      //       }
      //     },
      //     data: [266, 289, 299, 345, 278, 375, 270, 270, 286, 315, 304, 220]
      //   }]
      // }

      option && this.eChartList.RealTimeaccountNumLogin.setOption(option, true);
    },
    // 新增占比
    getnewProportion: function getnewProportion(other) {
      var data = [// {
      //   name: '新增安装量',
      //   value: other.install 
      // },
      {
        name: '新增投放量',
        value: other.buy_install
      }, {
        name: '新增自然量',
        value: other.free_install
      }];
      var datas = !other.buy_install && !other.free_install ? [] : data;
      var chartDom = document.getElementById('newProportion');
      this.eChartList.newProportion = echarts.init(chartDom);
      var option = {
        tooltip: _objectSpread({
          trigger: 'item',
          formatter: function formatter(param) {
            return param.data.name + ': ' + param.percent * 2 + '%';
          }
        }, this.tooltip),
        textStyle: this.textStyle,
        legend: {
          top: '4%',
          data: data.map(function (i) {
            return i.name;
          }),
          icon: 'circle',
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontFamily: 'serif'
          }
        },
        calculable: true,
        series: [{
          name: '占比',
          color: ['#62c98d', '#2f89cf', '#4cb9cf', '#53b666', '#62c98d', '#205acf', '#c9c862', '#c98b62', '#c962b9', '#c96262'],
          type: 'pie',
          //起始角度，支持范围[0, 360]
          startAngle: 0,
          //饼图的半径，数组的第一项是内半径，第二项是外半径
          radius: ["40%", "70%"],
          //支持设置成百分比，设置成百分比时第一项是相对于容器宽度，第二项是相对于容器高度
          center: ['50%', '45%'],
          //是否展示成南丁格尔图，通过半径区分数据大小。可选择两种模式：
          // 'radius' 面积展现数据的百分比，半径展现数据的大小。
          //  'area' 所有扇区面积相同，仅通过半径展现数据大小
          //是否启用防止标签重叠策略，默认开启，圆环图这个例子中需要强制所有标签放在中心位置，可以将该值设为 false。
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: true
            },
            emphasis: {
              show: true
            }
          },
          labelLine: {
            normal: {
              show: true,
              length2: 1
            },
            emphasis: {
              show: true
            }
          },
          data: [].concat(_toConsumableArray(datas), [{
            // make an record to fill the bottom 50%
            value: Number(other.buy_install) + Number(other.free_install),
            itemStyle: {
              // stop the chart from rendering this piece
              color: 'none',
              decal: {
                symbol: 'none'
              }
            },
            label: {
              show: false
            }
          }])
        }]
      };
      option && this.eChartList.newProportion.setOption(option, true);
    }
  }
};