var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.pramsData.id ? "修改" : "新增",
            visible: _vm.dialog,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialog = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.pramsData,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "role_id", label: "玩家ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.pramsData.role_id,
                      callback: function($$v) {
                        _vm.$set(_vm.pramsData, "role_id", _vm._n($$v))
                      },
                      expression: "pramsData.role_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "pay_channel", label: "渠道ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.pramsData.pay_channel,
                      callback: function($$v) {
                        _vm.$set(_vm.pramsData, "pay_channel", _vm._n($$v))
                      },
                      expression: "pramsData.pay_channel"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "pay_id", label: "充值ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.pramsData.pay_id,
                      callback: function($$v) {
                        _vm.$set(_vm.pramsData, "pay_id", _vm._n($$v))
                      },
                      expression: "pramsData.pay_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "orderId", label: "订单号" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.pramsData.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.pramsData, "orderId", $$v)
                      },
                      expression: "pramsData.orderId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "op_type", label: "补单操作" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择支付类型"
                      },
                      model: {
                        value: _vm.pramsData.op_type,
                        callback: function($$v) {
                          _vm.$set(_vm.pramsData, "op_type", $$v)
                        },
                        expression: "pramsData.op_type"
                      }
                    },
                    _vm._l(_vm.supplement, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.pramsData.op_type == "supplement_reward"
                ? _c(
                    "el-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addDomain }
                        },
                        [_vm._v("添加物品 ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.pramsData.op_type == "supplement_reward"
                ? _c(
                    "div",
                    { staticClass: "popover" },
                    _vm._l(_vm.pramsData.reward, function(domain, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "lodingItem",
                          staticStyle: { "margin-bottom": "0" }
                        },
                        [
                          _c("el-button", {
                            staticStyle: { margin: "0 10px 18px 0" },
                            attrs: {
                              size: "mini",
                              type: "danger",
                              circle: "",
                              icon: "el-icon-delete"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removeDomain(domain)
                              }
                            }
                          }),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                prop: "reward." + index + ".item_id",
                                rules: _vm.rules.item_id
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "物品"
                                  },
                                  model: {
                                    value: domain.item_id,
                                    callback: function($$v) {
                                      _vm.$set(domain, "item_id", $$v)
                                    },
                                    expression: "domain.item_id"
                                  }
                                },
                                _vm._l(_vm.articleList, function(
                                  items,
                                  indexs
                                ) {
                                  return _c("el-option", {
                                    key: indexs,
                                    attrs: {
                                      label: items.kind,
                                      value: items.id
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                prop: "reward." + index + ".expire",
                                rules: _vm.rules.expire
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "popper-class": "no-atTheMoment",
                                  "value-format": "timestamp",
                                  type: "datetime",
                                  placeholder: "日期"
                                },
                                model: {
                                  value: domain.expire,
                                  callback: function($$v) {
                                    _vm.$set(domain, "expire", $$v)
                                  },
                                  expression: "domain.expire"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "reward." + index + ".num",
                                rules: _vm.rules.item_num
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number", placeholder: "数量" },
                                model: {
                                  value: domain.num,
                                  callback: function($$v) {
                                    _vm.$set(domain, "num", _vm._n($$v))
                                  },
                                  expression: "domain.num"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }