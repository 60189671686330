import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { activation } from "@/api/dataAll/activation";
import * as echarts from "echarts";
export default {
  name: "activation",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: [],
      dates: [],
      datas: [],
      series: [],
      Dynamic: [],
      selectedArr: {}
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.searchData.starttime = this.value2 ? this.value2[0] : '';
      this.searchData.endtime = this.value2 ? this.value2[1] : '';
      this.reqLoading = true;
      activation(this.searchData).then(function (res) {
        if (res.status == 200) {
          var columns = [{
            label: '时间段(点)',
            prop: 'date'
          }];
          var data = [];
          var num = 0;
          var knum = 0;

          for (var i in res.data.data) {
            num += 1;
            columns.push({
              label: i,
              prop: num + 'Number'
            });

            for (var k in res.data.data[i]) {
              knum += 1;
              data.push({
                date: Number(knum) - 1 + ' ~ ' + knum
              });
              data[knum - 1][num + 'Number'] = res.data.data[i][k];

              if (knum == 24) {
                knum = 0;
              }
            }
          }

          data.splice(24, data.length - 24);
          _this.tableData = data;
          _this.Dynamic = columns; // 老版本图表数据 未改动

          var data_array = res.data.data;
          var date_array = res.data.date_array;
          _this.dates = []; // 格式化图表时间数组

          var _iterator = _createForOfIteratorHelper(date_array),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var date = _step.value;

              _this.dates.push(date);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          _this.datas = [];
          _this.series = [];

          for (var h = 1; h <= 24; h++) {
            var ds = Number(h) - 1 + ' ~ ' + h;

            _this.datas.push(ds);
          }

          var objToTitle = function objToTitle(obj) {
            return Object.keys(obj).map(function (k) {
              return obj[k];
            });
          };

          var objTokey = function objTokey(obj) {
            return Object.keys(obj).map(function (k) {
              return k;
            });
          };

          var datat = objToTitle(data_array);
          datat.forEach(function (item, i) {
            var ds = objToTitle(item);
            var obj = {
              name: _this.dates[i],
              type: "line",
              data: ds,
              smooth: true
            };

            _this.series.push(obj);
          });
          var a = null;

          _this.dates.forEach(function (val, i) {
            if (a != val) {
              a = val;

              if (i == 0) {
                _this.selectedArr[a] = true;
              } else {
                _this.selectedArr[a] = false;
              }
            }
          });

          _this.getechart();

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }

        return;
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("activationEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "激活汇总(人)"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          // 这里显示点击搜索的日期--图表的最上面
          width: '80%'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.datas
        },
        yAxis: {
          type: "value"
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      this.$export.excel({
        columns: this.Dynamic,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};