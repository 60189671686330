var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id !== "" ? "修改公告" : "新增公告",
            visible: _vm.dialogShow
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData(true)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "is_pause", label: "公告类型" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.paramsData.type == "old" ? true : false
                      },
                      on: { change: _vm.pauseChange },
                      model: {
                        value: _vm.paramsData.pause,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "pause", $$v)
                        },
                        expression: "paramsData.pause"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("普通公告")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("停服公告")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("新服开服")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "label", label: "标识" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: { filterable: "", placeholder: "请选择" },
                      on: { change: _vm.labelChange },
                      model: {
                        value: _vm.paramsData.label,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "label", $$v)
                        },
                        expression: "paramsData.label"
                      }
                    },
                    _vm._l(_vm.tagggList, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem lodingItems",
                  attrs: { prop: "priority", label: "优先值" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1 no-number",
                    attrs: {
                      type: "number",
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入优先值"
                    },
                    model: {
                      value: _vm.paramsData.priority,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "priority", _vm._n($$v))
                      },
                      expression: "paramsData.priority"
                    }
                  }),
                  _c("div", { staticClass: "lodingItemTips" }, [
                    _vm._v(" (数值越大越靠上) ")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem lodingItems",
                  attrs: { prop: "force_push", label: "是否强提醒" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.force_push,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "force_push", $$v)
                        },
                        expression: "paramsData.force_push"
                      }
                    },
                    _vm._l(_vm.whetherNot, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("div", { staticClass: "lodingItemTips" }, [
                    _vm._v(" (强提醒：公告发布后无需重登刷新可见) ")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "开始无限制" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "开启",
                      "inactive-text": "关闭",
                      "active-color": "#13ce66"
                    },
                    on: {
                      change: function($event) {
                        return _vm.isS($event, "start_time")
                      }
                    },
                    model: {
                      value: _vm.isStart,
                      callback: function($$v) {
                        _vm.isStart = $$v
                      },
                      expression: "isStart"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: {
                    prop: "start_time",
                    rules: !_vm.isStart
                      ? _vm.rules.start_time
                      : [{ require: false }],
                    label: "开始时间"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "flex-1 ",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      disabled: _vm.isStart,
                      type: "datetime",
                      "value-format": "timestamp",
                      placeholder: "请选择",
                      align: "right"
                    },
                    model: {
                      value: _vm.paramsData.start_time,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "start_time", $$v)
                      },
                      expression: "paramsData.start_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "结束无限制" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "开启",
                      "inactive-text": "关闭",
                      "active-color": "#13ce66"
                    },
                    on: {
                      change: function($event) {
                        return _vm.isS($event, "end_time")
                      }
                    },
                    model: {
                      value: _vm.isEnd,
                      callback: function($$v) {
                        _vm.isEnd = $$v
                      },
                      expression: "isEnd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: {
                    prop: "end_time",
                    rules: !_vm.isEnd
                      ? _vm.rules.end_time
                      : [{ require: false }],
                    label: "结束时间"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "flex-1 ",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      disabled: _vm.isEnd,
                      type: "datetime",
                      "value-format": "timestamp",
                      placeholder: "请选择",
                      align: "right"
                    },
                    model: {
                      value: _vm.paramsData.end_time,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "end_time", $$v)
                      },
                      expression: "paramsData.end_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: {
                    prop: "server_rangeStart",
                    rules: _vm.paramsData.server_rangeEnd
                      ? _vm.rules.server_rangeStart
                      : [],
                    label: "开始服务器"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        disabled: _vm.paramsData.type == "old" ? true : false,
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.server_rangeStart,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "server_rangeStart", $$v)
                        },
                        expression: "paramsData.server_rangeStart"
                      }
                    },
                    _vm._l(_vm.serverList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.serverID,
                          attrs: {
                            label: item.serverName,
                            value: item.serverID
                          }
                        },
                        [
                          _c("div", { staticClass: "serverOption" }, [
                            _c("div", [_vm._v(_vm._s(item.serverName))]),
                            _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.is_recomm == 1 ? "推荐" : ""))
                            ])
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "结束服务器" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        disabled: _vm.paramsData.type == "old" ? true : false,
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.server_rangeEnd,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "server_rangeEnd", $$v)
                        },
                        expression: "paramsData.server_rangeEnd"
                      }
                    },
                    _vm._l(_vm.serverList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.serverID,
                          attrs: {
                            label: item.serverName,
                            value: item.serverID
                          }
                        },
                        [
                          _c("div", { staticClass: "serverOption" }, [
                            _c("div", [_vm._v(_vm._s(item.serverName))]),
                            _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.is_recomm == 1 ? "推荐" : ""))
                            ])
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: {
                    prop: "channelStart",
                    rules: _vm.paramsData.channelEnd
                      ? _vm.rules.channelStart
                      : [],
                    label: "开始渠道"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      disabled: _vm.paramsData.type == "old" ? true : false,
                      placeholder: "开始渠道"
                    },
                    model: {
                      value: _vm.paramsData.channelStart,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "channelStart", $$v)
                      },
                      expression: "paramsData.channelStart"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "结束渠道" } },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      disabled: _vm.paramsData.type == "old" ? true : false,
                      placeholder: "结束渠道"
                    },
                    model: {
                      value: _vm.paramsData.channelEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "channelEnd", $$v)
                      },
                      expression: "paramsData.channelEnd"
                    }
                  })
                ],
                1
              ),
              _vm.paramsData.pause !== 0
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      attrs: {
                        prop: "pause_start_time",
                        label:
                          _vm.paramsData.pause == 1
                            ? "停服开始时间"
                            : "服务器开启时间"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          "value-format": "timestamp",
                          type: "datetime",
                          placeholder: "请选择"
                        },
                        model: {
                          value: _vm.paramsData.pause_start_time,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "pause_start_time", $$v)
                          },
                          expression: "paramsData.pause_start_time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.pause === 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      attrs: { prop: "show_switch", label: "切换按钮显示" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-1",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.paramsData.show_switch,
                            callback: function($$v) {
                              _vm.$set(_vm.paramsData, "show_switch", $$v)
                            },
                            expression: "paramsData.show_switch"
                          }
                        },
                        _vm._l(_vm.whetherNot, function(item) {
                          return _c("el-option", {
                            key: item.label,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.pause === 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      attrs: { prop: "pause_duration", label: "持续时间(分钟)" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "flex-1",
                        attrs: { type: "number", placeholder: "请输入" },
                        model: {
                          value: _vm.paramsData.pause_duration,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.paramsData,
                              "pause_duration",
                              _vm._n($$v)
                            )
                          },
                          expression: "paramsData.pause_duration"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.pause === 0
                ? _c(
                    "el-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "lodingItem",
                          attrs: { prop: "main_content" }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled:
                                  _vm.paramsData.type == "old" ? true : false
                              },
                              on: { click: _vm.addContent }
                            },
                            [_vm._v("添加内容")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.paramsData.main_content, function(domain, index) {
                return _c(
                  "div",
                  { key: index + "k", staticClass: "main_contentClass" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "lodingItem-operationFrame flex",
                        staticStyle: { "margin-bottom": "20px" }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass:
                              "lodingItem contentValitem lodingItemt",
                            attrs: {
                              prop: "main_content." + index + ".title",
                              rules: _vm.rules.title,
                              label: "标题",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "operationFrame-entity",
                              attrs: {
                                disabled: _vm.paramsData.pause !== 0,
                                clearable: "",
                                placeholder: "请输入标题"
                              },
                              model: {
                                value: domain.title,
                                callback: function($$v) {
                                  _vm.$set(domain, "title", $$v)
                                },
                                expression: "domain.title"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass:
                              "lodingItem contentValitem lodingItemt",
                            attrs: {
                              prop: "main_content." + index + ".language",
                              rules: _vm.rules.language,
                              label: "语言",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "flex-1",
                                attrs: {
                                  disabled: _vm.paramsData.pause !== 0,
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: domain.language,
                                  callback: function($$v) {
                                    _vm.$set(domain, "language", $$v)
                                  },
                                  expression: "domain.language "
                                }
                              },
                              _vm._l(_vm.langList, function(item) {
                                return _c("el-option", {
                                  key: item.label,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.paramsData.main_content.length > 1 &&
                        !_vm.paramsData.pause !== 0
                          ? _c("el-button", {
                              staticStyle: { margin: "0 0 10px 10px" },
                              attrs: {
                                size: "mini",
                                type: "danger",
                                circle: "",
                                icon: "el-icon-delete"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeContent(domain)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex lodingItem-title first_box",
                        staticStyle: {
                          "margin-bottom": "20px",
                          width: "100%",
                          "align-items": "center"
                        }
                      },
                      [
                        _vm._v("内容: "),
                        _vm.paramsData.pause === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { "padding-left": "20px" }
                              },
                              _vm._l(_vm.tagList, function(items, indexs) {
                                return _c(
                                  "div",
                                  {
                                    key: indexs,
                                    staticClass: "addtag",
                                    on: {
                                      click: function($event) {
                                        return _vm.addtag(items.content, index)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          prop: "main_content." + index + ".content",
                          rules: _vm.rules.content
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            disabled: _vm.paramsData.pause !== 0,
                            autosize: { minRows: 2, maxRows: 12 },
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: domain.content,
                            callback: function($$v) {
                              _vm.$set(domain, "content", $$v)
                            },
                            expression: "domain.content "
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }