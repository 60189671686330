//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getActivityLogList } from "@/api/gmtool/packageCode";
export default {
  name: "activityLog",
  data: function data() {
    return {
      tableData: [],
      userList: [],
      total: 0,
      searchData: {
        page_size: 10,
        page: 1,
        user_id: ''
      },
      drawer: false,
      content: ''
    };
  },
  created: function created() {
    this.searchClick(1);
  },
  methods: {
    sizeChange: function sizeChange(e) {
      this.searchData.page_size = e;
      this.searchClick(1);
    },
    openParms: function openParms(data) {
      if (!data) {
        return;
      }

      this.content = JSON.stringify(JSON.parse(data), null, 2);
      this.drawer = true;
    },
    searchClick: function searchClick(e) {
      var _this = this;

      this.searchData.page = e;
      getActivityLogList(this.searchData).then(function (res) {
        _this.tableData = res.data.data || [];
        _this.total = res.data.total - 0 || 0;
      });
    }
  }
};