var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        "value-key": item.id,
                        label: item.game_name,
                        value: item.id
                      }
                    },
                    [_vm._v(_vm._s(item.game_name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择渠道"
                  },
                  on: { change: _vm.channelchange },
                  model: {
                    value: _vm.searchData.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "channel", $$v)
                    },
                    expression: "searchData.channel"
                  }
                },
                _vm._l(_vm.searchchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  on: { change: _vm.pkgchange },
                  model: {
                    value: _vm.searchData.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pkg", $$v)
                    },
                    expression: "searchData.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchClick } },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSource()
                    }
                  }
                },
                [_vm._v("新增网关配置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "margin-top",
          attrs: { "max-height": "500", data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "game_reveal", label: "游戏", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "platform_reveal", label: "平台", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "channel_reveal", label: "渠道", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "pkg_reveal", label: "包名", "min-width": "150" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "secret_key_id",
              label: "密钥ID",
              align: "center",
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#606266", "font-size": "14px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleJoinPeople(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.secret_key_id))]
                    ),
                    _c("el-button", {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.handleJoinPeople(scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "login_url", label: "登录验证地址", width: "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "login_id",
              label: "登录密钥ID",
              align: "center",
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#606266", "font-size": "14px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.loginid(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.login_id))]
                    ),
                    _c("el-button", {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.loginid(scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "pay_url", label: "支付地址", width: "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pay_id",
              label: "支付密钥ID",
              align: "center",
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#606266", "font-size": "14px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.callback(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.pay_id))]
                    ),
                    _c("el-button", {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.callback(scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "callback_url", label: "回调地址", width: "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "expand_parameter",
              label: "拓展参数",
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: row.expand_parameter,
                          placement: "top-start"
                        }
                      },
                      [
                        _c("div", { staticClass: "text-ellipsis-3" }, [
                          _vm._v(_vm._s(row.expand_parameter))
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row.id, 2)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": 1,
          total: _vm.total,
          "page-size": 10
        },
        on: { "current-change": _vm.handleCurrentChange }
      }),
      _c("addWayConfig", {
        ref: "addWayConfig",
        attrs: { showing: _vm.showing },
        on: { changeShow: _vm.changeShow, refreshDataList: _vm.searchClick }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }