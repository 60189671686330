// 账号充值按年排行
import request from "@/utils/request";
export function accRechByYear(data) {
  return request({
    url: "/account_recharge_year_rank",
    method: "get",
    params: data
  });
}
export function accRechByYear_currency(data) {
  return request({
    url: "/account_recharge_year_rank_currency",
    method: "get",
    params: data
  });
} // 账号充值按月排行

export function accRechByMonth(data) {
  return request({
    url: "/account_recharge_month_rank",
    method: "get",
    params: data
  });
}
export function accRechByMonth_currency(data) {
  return request({
    url: "/account_recharge_month_rank_currency",
    method: "get",
    params: data
  });
} // 账号充值按周排行

export function accRechByWeek(data) {
  return request({
    url: "/account_recharge_week_rank",
    method: "get",
    params: data
  });
}
export function accRechByWeek_currency(data) {
  return request({
    url: "/account_recharge_week_rank_currency",
    method: "get",
    params: data
  });
} // 账号充值按天排行

export function accRechByDay(data) {
  return request({
    url: "/account_recharge_day_rank",
    method: "get",
    params: data
  });
} // 账号充值按天排行 货币类型

export function accRechByDay_currency(data) {
  return request({
    url: "/account_recharge_day_rank_currency",
    method: "get",
    params: data
  });
}