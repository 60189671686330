// 账号LTV数据
import request from '@/utils/request';
export function accLtv(data) {
  return request({
    url: '/data/accountltv',
    method: 'get',
    params: data
  });
}
export function accLtv_currency(data) {
  return request({
    url: '/data/accountltvCurrency',
    method: 'get',
    params: data
  });
}
export function accountltvCurrencyOther(data) {
  return request({
    url: '/data/accountltvCurrencyOther',
    method: 'get',
    params: data
  });
} // 设备LTV数据

export function advLtv(data) {
  return request({
    url: '/data/deviceltv',
    method: 'get',
    params: data
  });
} // 激活设备LTV数据

export function activityDevireLtvCurrencyOther(data) {
  return request({
    url: '/data/activityDevireLtvCurrencyOther',
    method: 'get',
    params: data
  });
}