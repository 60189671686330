import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";

var files = require.context('./', true, /\.js$/);

var autoGenerateMenus = [];
var autoGenerateRouters = [];
files.keys().forEach(function (key) {
  if (key === './index.js') return;

  if (key.indexOf('menu') > -1) {
    autoGenerateMenus.push(files(key).default);
  } else if (key.indexOf('router') > -1) {
    autoGenerateRouters.push(files(key).default);
  }
});
export default {
  menus: autoGenerateMenus,
  routers: autoGenerateRouters
};