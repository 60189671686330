var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleSource()
                }
              }
            },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "game_reveal", label: "游戏" }
          }),
          _c("el-table-column", {
            attrs: { prop: "platform_reveal", label: "平台" }
          }),
          _c("el-table-column", {
            attrs: { prop: "channel_reveal", label: "渠道" }
          }),
          _c("el-table-column", {
            attrs: { prop: "pkg_reveal", label: "包名" }
          }),
          _c("el-table-column", {
            attrs: { prop: "login_type_mark", label: "第三方登录" }
          }),
          _c("el-table-column", {
            attrs: { prop: "expand_parameter", label: "参数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: row.expand_parameter,
                          placement: "top-start"
                        }
                      },
                      [
                        _c("div", { staticClass: "text-ellipsis-3" }, [
                          _vm._v(_vm._s(row.expand_parameter))
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row.id, 2)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.deleType(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.page,
          total: _vm.total,
          "page-size": _vm.pagSize
        },
        on: { "current-change": _vm.handleCurrentChange }
      }),
      _c("bind-Three", {
        ref: "addTypeConfig",
        on: { refreshDataList: _vm.getType }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }