import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { onlineDevices } from "@/api/dataAll/onlineDevice";
import * as echarts from "echarts";
export default {
  name: "onlineDevice",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      tableTitle: [],
      value2: [],
      tableData: [],
      show: false,
      dates: [],
      datas: [],
      series: [],
      selectedArr: {},
      Dynamic: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      onlineDevices(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this.dates = [];
          _this.datas = [];
          _this.series = [];
          var columns = [{
            label: '同时在线',
            prop: 'length'
          }];
          var data = [];
          var num = 0;
          var knum = 0;

          for (var i in res.data.data) {
            num += 1;
            columns.push({
              label: i,
              prop: num + 'Number'
            });

            _this.dates.push(i);

            var obj = {
              name: i,
              type: "line",
              data: [],
              smooth: true
            };

            for (var k in res.data.data[i]) {
              knum += 1;
              var a = knum - 1 < 10 ? "0" + (knum - 1) : knum - 1;
              var b = knum - 1 + 1 < 10 ? "0" + knum : knum;
              data.push({
                length: a + '点' + '~' + b + '点'
              });
              data[knum - 1][num + 'Number'] = res.data.data[i][k];
              obj.data.push(res.data.data[i][k]);

              if (knum == 24) {
                knum = 0;
              }
            }

            _this.series.push(obj);
          }

          data.splice(24, data.length - 24);
          data.forEach(function (i) {
            _this.datas.push(i['length']);
          });
          _this.tableData = data;
          _this.Dynamic = columns;

          _this.getechart();

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("onlineDeviceEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "在线设备统计(个)"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          // 这里显示点击搜索的日期--图表的最上面
          width: '80%' // selected: this.selectedArr,

        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.datas
        },
        yAxis: {
          type: "value"
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      this.$export.excel({
        columns: this.Dynamic,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};