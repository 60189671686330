var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popover" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.list, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "accid", label: "角色ID", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "expire", label: "封禁时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.type == 1
                                    ? row.expire == 0
                                      ? "永封"
                                      : _vm.$times.timestampToTime(
                                          row.expire,
                                          "YY-MM-DD HH"
                                        )
                                    : "/"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }