var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechangeManage },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.game_name, value: item.game_mark }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.platform_name,
                      value: item.platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "监控类型"
                  },
                  model: {
                    value: _vm.searchData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "type", $$v)
                    },
                    expression: "searchData.type"
                  }
                },
                _vm._l(_vm.typeForms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.type_name, value: item.type_name }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "投放渠道" },
                  model: {
                    value: _vm.searchData.op_ad_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "op_ad_channel", $$v)
                    },
                    expression: "searchData.op_ad_channel"
                  }
                },
                _vm._l(_vm.opAdChannel, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSource()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", disabled: _vm.sels.length == 0 },
                  on: {
                    click: function($event) {
                      return _vm.deleAll(_vm.selsString)
                    }
                  }
                },
                [_vm._v("批量删除")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "game_reveal", label: "游戏" }
          }),
          _c("el-table-column", {
            attrs: { prop: "platform_reveal", label: "平台" }
          }),
          _c(
            "el-table-column",
            { attrs: { label: "投放渠道" } },
            [
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.opAdChannel.find(function(i) {
                        return i.value == _vm.searchData.op_ad_channel
                      }).label
                    ) +
                    " "
                )
              ]
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              prop: "parameter",
              label: "参数(点击复制)",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                          cursor: "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleClipboard(scope.row.parameter)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.parameter))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "type", label: "监控类型" } }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "110",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.deleAll(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.searchClick }
      }),
      _c("addPamse", {
        ref: "addWayConfig",
        on: {
          refreshDataList: function($event) {
            return _vm.searchClick(_vm.searchData.page)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }