var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          staticStyle: { "pointer-events": "auto" },
          attrs: {
            title: _vm.titleName,
            visible: _vm.dialogFormVisible,
            width: "30%",
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false,
            top: "13vh"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "add-headerdddd" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.is_visitor == 2,
                      expression: "is_visitor == 2"
                    }
                  ],
                  ref: "ruleForm",
                  attrs: { model: _vm.form, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账号",
                        prop: "accass",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "账号...",
                          disabled: ""
                        },
                        on: { change: _vm.valChange },
                        model: {
                          value: _vm.form.user_name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "user_name", $$v)
                          },
                          expression: "form.user_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "密码",
                        prop: "value",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请输入密码...",
                          disabled: _vm.user_name.length == 0
                        },
                        on: { change: _vm.passChange },
                        model: {
                          value: _vm.form.passwd,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "passwd", $$v)
                          },
                          expression: "form.passwd"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "手机",
                        prop: "phoneNum",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请输入手机号...",
                          disabled: _vm.phone.length == 0
                        },
                        on: { change: _vm.phoneChange },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "邮箱",
                        prop: "email",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请输入邮箱...",
                          disabled: _vm.email.length == 0
                        },
                        on: { change: _vm.emailChange },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "第三方登录渠道",
                        prop: "threeChannl",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "三方登录渠道...",
                          disabled: ""
                        },
                        model: {
                          value: _vm.form.oauth,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "oauth", $$v)
                          },
                          expression: "form.oauth"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.is_visitor == 1,
                      expression: "is_visitor == 1"
                    }
                  ],
                  ref: "ruleForm",
                  attrs: { model: _vm.form, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备ID",
                        prop: "threeChannl",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "设备ID...",
                          disabled: ""
                        },
                        model: {
                          value: _vm.form.device_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "device_id", $$v)
                          },
                          expression: "form.device_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("修改")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }