//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
export default {
  mixins: [serchSelect],
  data: function data() {
    return {
      intReq: []
    };
  },
  watch: {
    searchData: {
      handler: function handler(newVal) {
        this.$emit('searchChange', {
          type: 'searchData',
          data: newVal
        });
      },
      immediate: true,
      deep: true
    },
    value2: {
      handler: function handler(newVal) {
        this.$emit('searchChange', {
          type: 'value2',
          data: newVal
        });
      },
      immediate: true,
      deep: true
    }
  },
  mounted: function mounted() {},
  methods: {}
};