import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { addAdvRevenHome } from "@/api/dataAll/advrisiReven";
import { getadvLocation } from "@/api/dataAll/advLocation";
export default {
  name: "dailyRevenNewUserProdout",
  mixins: [serchSelect],
  data: function data() {
    return {
      tableTitle: [],
      gameId: "",
      value1: "",
      value2: [],
      searchData: {
        starttime: "",
        endtime: "",
        advChannel: "",
        advLocation: "",
        day: ''
      },
      searchMark: {
        game: "",
        platform: "",
        channel: "",
        pkg: "",
        starttime: "",
        endtime: "",
        ad_channel: "",
        ad_placement: "",
        day: ''
      },
      tableData: [],
      tableLtv: [],
      tableDate: "",
      show: false,
      dates: [],
      new_device_num: [],
      new_account_num: [],
      active_account_num: [],
      day_account: [],
      recharge_account_num: [],
      recharge_account_amount: [],
      pay_account_percent: [],
      pay_account_arpu: [],
      pay_account_arppu: [],
      login_account_num: [],
      _this: this
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    dayChange: function dayChange(val) {
      this.searchMark.day = val;
    },
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchMark.starttime = this.value2[0];
        this.searchMark.endtime = this.value2[1];
      }

      this.searchMark.kind = 1;
      addAdvRevenHome(this.searchMark).then(function (res) {
        var data_array = res.data; // 数据数组

        var table_top = "<th style='width:80px;'>ID</th><th style='width:100px;'>日期</th><th style='width:100px;'>新增设备</th>"; // 定义表头

        var table_bodoy = ""; // 定义表格

        var tableLtv = [];

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objTokey = function objTokey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        var date = [];

        for (var _a in data_array) {
          data_array[_a].other.date = _a;
          var ltv = data_array[_a].data;

          for (var i in ltv) {
            if (tableLtv.indexOf(i) > -1) {} else {
              tableLtv.push(i);
            }
          }
        }

        _this.datas = [];
        var objDate = {}; // 拼接表头

        for (var _i = 0, _tableLtv = tableLtv; _i < _tableLtv.length; _i++) {
          var _date = _tableLtv[_i];

          _this.datas.push(_date + "金额");

          _this.datas.push(_date + "平均次数");

          _this.datas.push(_date + "ROI");

          table_top += '<th style="width:100px;line-height:40px;">' + _date + "\u65E5\u91D1\u989D".concat(_this.MonetaryUnit ? "(".concat(_this.MonetaryUnit, ")") : '');
          "</th>";
          table_top += '<th style="width:100px;line-height:40px;">' + _date + "日平均次数";
          "</th>";
          table_top += '<th style="width:100px;line-height:40px;">' + _date + "日ROI";
          "</th>";
        }

        _this.dates = [];
        _this.selectedArr = {};
        var data = objToTitle(data_array);
        data.forEach(function (item, i) {
          _this.dates.push(item.other.date);

          var c = i + 1;
          table_bodoy += "<tr>";
          table_bodoy += "<td style='width:100px;'>" + c + "</td>";
          table_bodoy += "<td style='width:100px;'>" + item.other.date + "</td>";
          table_bodoy += "<td style='width:100px;'>" + item.other.activity + "</td>";
          tableLtv.forEach(function (val, a) {
            table_bodoy += "<td>" + item.data[val].money + "</td>";
            table_bodoy += "<td>" + item.data[val].average_times + "</td>";
            table_bodoy += "<td>" + item.data[val].roi + "</td>";
          });
          table_bodoy += "</tr>";
        });
        var ds = null;
        var obj = {};
        _this.series = [];
        data.forEach(function (item, i) {
          ds = objToTitle(item);
          obj = {
            name: _this.dates[i],
            type: "line",
            data: objToTitle(ds[0])
          };

          _this.series.push(obj);
        });
        var a = null;
        _this.selectedArr = {};

        _this.dates.forEach(function (val, i) {
          if (a != val) {
            a = val;

            if (i == 0) {
              _this.selectedArr[a] = true;
            } else {
              _this.selectedArr[a] = false;
            }
          }
        });

        var header = document.getElementById("header");
        var bodoy = document.getElementById("bodoy");
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        _this.show = true;

        _this.setScroll();

        _this.setLftScroll();
      });
    },
    setScroll: function setScroll() {
      var tableCont = document.querySelector('#table-boxs');

      function scrollHandle(e) {
        var scrollTop = this.scrollTop;
        this.querySelector('thead').setAttribute("style", " position:relative;z-index:15;");
        this.querySelector('thead').style.transform = 'translateY(' + scrollTop + 'px)';
      }

      tableCont.addEventListener('scroll', scrollHandle);
    },
    setLftScroll: function setLftScroll() {
      var tableCont = document.querySelector('#table-boxs');

      function scrollHandle(e) {
        var scrollTop = this.scrollLeft;
        this.querySelectorAll('.left-title').forEach(function (element) {
          element.setAttribute("style", " position:relative;z-index:10;");
          element.style.transform = 'translateX(' + scrollTop + 'px)';
        });
      }

      tableCont.addEventListener('scroll', scrollHandle);
    },
    setLftdataScroll: function setLftdataScroll() {
      var tableCont = document.querySelector('#table-boxs');

      function scrollHandle(e) {
        var scrollTop = this.scrollLeft;
        this.querySelectorAll('.left-title-data').forEach(function (element) {
          element.setAttribute("style", " position:relative;z-index:10;");
          element.style.transform = 'translateX(' + scrollTop + 'px)';
        });
      }

      tableCont.addEventListener('scroll', scrollHandle);
    }
  }
};