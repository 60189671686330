import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { advLtv } from "@/api/dataAll/accountLtv";
import { getadvLocation } from "@/api/dataAll/advLocation";
import * as echarts from "echarts";
export default {
  name: "advLtv",
  data: function data() {
    return {
      tableTitle: [],
      gameId: "",
      value1: "",
      value2: [],
      searchData: {
        game: "",
        platform: "",
        channel: "",
        starttime: "",
        endtime: "",
        advChannel: "",
        advLocation: "",
        country: ""
      },
      tableData: [],
      tableLtv: [],
      tableDate: "",
      show: false,
      echarts: false,
      dates: [],
      datas: [],
      series: [],
      selectedArr: {},
      isgetdataCurrency: true
    };
  },
  created: function created() {
    var _this = this;

    this.getsearchDate();
    searchgame().then(function (res) {
      _this.searchgames = res.data;
      _this.gameId = res.data.id;
    });
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this2 = this;

      if (this.value2.length > 0) {
        this.searchMark.starttime = this.value2[0];
        this.searchMark.endtime = this.value2[1];
      }

      this.searchMark.kind = 1;
      advLtv(this.searchMark).then(function (res) {
        var data_array = res.data; // 数据数组

        var table_top = "<th style='width:100px;'>ID</th><th style='width:100px;'>日期</th><th style='width:100px;'>新增设备</th>"; // 定义表头

        var table_bodoy = ""; // 定义表格

        var tableLtv = [];

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objTokey = function objTokey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        var date = [];

        for (var _a in data_array) {
          data_array[_a].other.date = _a;
          var ltv = data_array[_a].ltv;

          for (var i in ltv) {
            if (tableLtv.indexOf(i) > -1) {} else {
              tableLtv.push(i);
            }
          }
        }

        _this2.datas = []; // 拼接表头

        for (var _i = 0, _tableLtv = tableLtv; _i < _tableLtv.length; _i++) {
          var _date = _tableLtv[_i];

          _this2.datas.push("ltv" + _date);

          table_top += '<th style="width:100px;line-height:40px;">' + "ltv" + _date + "".concat(_this2.MonetaryUnit ? "(".concat(_this2.MonetaryUnit, ")") : "") + "</th>";
        }

        _this2.dates = [];
        _this2.selectedArr = {};
        var data = objToTitle(data_array);
        data.forEach(function (item, i) {
          _this2.dates.push(item.other.date);

          var c = i + 1;
          table_bodoy += "<tr>";
          table_bodoy += "<td style='width:100px;'>" + c + "</td>";
          table_bodoy += "<td style='width:100px;'>" + item.other.date + "</td>";
          table_bodoy += "<td style='width:100px;'>" + item.other.new_device_num + "</td>";
          tableLtv.forEach(function (val, a) {
            if (item.ltv[val] == "/") {
              table_bodoy += "<td>" + item.ltv[val] + "</td>";
            } else {
              table_bodoy += "<td>" + "".concat(((item.ltv[val] - 0) / 100).toFixed(2)) + "</td>";
            }
          });
          table_bodoy += "</tr>";
        });
        var ds = null;
        var obj = {};
        _this2.series = [];
        data.forEach(function (item, i) {
          ds = objToTitle(item);
          obj = {
            name: _this2.dates[i],
            type: "line",
            data: objToTitle(ds[0])
          };

          _this2.series.push(obj);
        });
        var a = null;
        _this2.selectedArr = {};

        _this2.dates.forEach(function (val, i) {
          if (a != val) {
            a = val;

            if (i == 0) {
              _this2.selectedArr[a] = true;
            } else {
              _this2.selectedArr[a] = false;
            }
          }
        });

        var header = document.getElementById("header");
        var bodoy = document.getElementById("bodoy");
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        _this2.show = true;
        _this2.echarts = true;

        _this2.getechart();

        _this2.setScroll();
      });
    },
    setScroll: function setScroll() {
      var tableCont = document.querySelector("#table-box");

      function scrollHandle(e) {
        var scrollTop = this.scrollTop;
        this.querySelector("thead").setAttribute("style", " position:relative;z-index:15;");
        this.querySelector("thead").style.transform = "translateY(" + scrollTop + "px)";
      }

      tableCont.addEventListener("scroll", scrollHandle);
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("advLtvEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          width: "80%",
          selected: this.selectedArr
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.datas
        },
        yAxis: {
          type: "value"
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    }
  }
};