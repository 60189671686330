var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogShow,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popover" },
            [
              _vm.type == "Articles" && _vm.data.length
                ? _c(
                    "el-table",
                    { attrs: { data: _vm.data, border: "" } },
                    [
                      _vm.data[0].role_id
                        ? _c("el-table-column", {
                            attrs: { prop: "role_id", label: "玩家ID" }
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          prop: "type_id",
                          label: "物品",
                          align: "center",
                          width: "220"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.articleList.findIndex(function(
                                            item
                                          ) {
                                            return item.id == row.type_id
                                          }) > -1
                                            ? _vm.articleList[
                                                _vm.articleList.findIndex(
                                                  function(item) {
                                                    return (
                                                      item.id == row.type_id
                                                    )
                                                  }
                                                )
                                              ].kind
                                            : row.type_id
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3778900696
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "num", label: "数量", align: "center" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "expire",
                          label: "过期时间",
                          align: "center",
                          width: "180"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.expire == 0 ? "永久" : row.expire
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2552291027
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type != "Articles"
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.data) } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }