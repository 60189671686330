var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.Renewal },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.sendEmail.open()
                    }
                  }
                },
                [_vm._v("添加草稿")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.batchEmail.open()
                    }
                  }
                },
                [_vm._v("批量上传")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", disabled: !_vm.selsString },
                  on: {
                    click: function($event) {
                      return _vm.handleDelete(_vm.selsString)
                    }
                  }
                },
                [_vm._v("批量删除")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function() {
                      _vm.tableData = []
                      _vm.getList(_vm.paramsData.page)
                    }
                  }
                },
                [_vm._v("刷新")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "id",
              type: "selection",
              selectable: function(row) {
                return row.status == 2 && row.is_item_recycle == 2
              },
              label: "ID",
              width: "50"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "admin_name", label: "操作员" }
          }),
          _c("el-table-column", {
            attrs: { prop: "target", label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.player_type == "all_role"
                      ? _c("div", [_vm._v("所有玩家")])
                      : _vm._e(),
                    row.player_type == "online_role"
                      ? _c("div", [_vm._v("在线玩家")])
                      : _vm._e(),
                    row.player_type == "list_role"
                      ? _c("div", [_vm._v("选中玩家")])
                      : _vm._e(),
                    row.player_type == "limit"
                      ? _c("div", [_vm._v("条件列表")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "server_name",
              label: "服务器名称",
              "show-overflow-tooltip": "",
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.server_name
                              ? row.server_name.substring(
                                  0,
                                  row.server_name.length - 1
                                )
                              : "/"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "args", "show-overflow-tooltip": "", label: "条件" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.player_type !== "limit"
                      ? _c("div", [_vm._v("/")])
                      : _c(
                          "div",
                          {
                            staticClass: "text-cursor",
                            staticStyle: { color: "#409EFF" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.limitDetails.init(row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "args",
              "show-overflow-tooltip": "",
              label: "玩家ID"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.args.toString() == "0" || !row.args.length
                      ? _c("div", [_vm._v("/")])
                      : _c(
                          "div",
                          {
                            staticClass: "text-cursor",
                            staticStyle: { color: "#409EFF" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.idDetails.init(row.args)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "reward", label: "物品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.reward.length
                      ? _c(
                          "div",
                          {
                            staticClass: "text-cursor",
                            staticStyle: { color: "#409EFF" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.viewArticles.init(
                                  row.reward,
                                  "Articles"
                                )
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : _c("div", [_vm._v("/")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "request_data", label: "内容详情" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-cursor",
                        staticStyle: { color: "#409EFF" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.contentView.open(
                              row.content_Val,
                              "Articles"
                            )
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "is_delay", label: "定时发送", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.is_delay == 1
                            ? _vm.$times.timestampToTime(
                                row.delay_time,
                                "YY-MM-DD H"
                              )
                            : "/"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { style: { color: row.status == 1 ? "#409EFF" : "" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.status == 1 ? "已发送" : "未发送") +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "添加时间", "min-width": "180" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "160", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      row.status == 2
                        ? _c("div", { staticClass: "x-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.sendEmail.open(row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            row.is_delay == 2
                              ? _c("div", {
                                  staticClass: "controlsBtn-vertical"
                                })
                              : _vm._e(),
                            row.is_delay == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.sendEmails(row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("发送")]
                                )
                              : _vm._e(),
                            row.is_item_recycle == 2
                              ? _c("div", {
                                  staticClass: "controlsBtn-vertical"
                                })
                              : _vm._e(),
                            row.is_item_recycle == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        _vm.handleDelete(JSON.stringify(row.id))
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total,
          page_size: _vm.paramsData.page_size
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      }),
      _c("addEmail", {
        ref: "sendEmail",
        attrs: {
          serverList: _vm.serverLists,
          prod: { is_prod: _vm.paramsData.is_prod }
        },
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      }),
      _c("viewArticles", {
        ref: "viewArticles",
        attrs: { prod: { is_prod: _vm.paramsData.is_prod } }
      }),
      _c("contentView", { ref: "contentView" }),
      _c("idDetails", { ref: "idDetails" }),
      _c("limitDetails", { ref: "limitDetails" }),
      _c("batchEmail", {
        ref: "batchEmail",
        attrs: { paramsData: { is_prod: _vm.paramsData.is_prod } },
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }