var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "上传配置",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex " },
            [
              _c("uploadImagetow", {
                ref: "uploadImagetow",
                staticStyle: { "margin-right": "20px" },
                attrs: {
                  Url: "/hjhd-gm/uploadAcvitityConfig",
                  filesname: "file",
                  paramsData: { type: "1" },
                  title: "替换文件",
                  fileType: ""
                },
                on: { change: _vm.upList }
              }),
              _c("uploadImagetow", {
                ref: "uploadImagetow",
                attrs: {
                  Url: "/hjhd-gm/uploadAcvitityConfig",
                  filesname: "file",
                  paramsData: { type: "2" },
                  title: "覆盖文件",
                  fileType: ""
                },
                on: { change: _vm.upList }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }