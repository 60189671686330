import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import { tabulation, gmToolNewPage } from "@/mixins/index";
import { onlineSummary } from "@/api/dataPirate/channelData";
import { serverList as hdServerList } from "@/api/gmtool/packageCode";
import { serverList as yjServerList } from "@/api/gmToolNew/gmToolNew";
import * as echarts from "echarts";
export default {
  name: "onlineDetails",
  mixins: [tabulation, gmToolNewPage],
  data: function data() {
    return {
      searchData: {
        server_id: ''
      },
      value2: [],
      tableData: [],
      Dynamic: [],
      series: [],
      screen: [],
      serverLists: [],
      isShow: false
    };
  },
  created: function created() {
    this.getServerLists();
    this.isServer = this.rawData.game_mark.indexOf('royalpirates') > -1 || this.rawData.game_mark.indexOf('yjfm') > -1 ? true : false;
  },
  computed: _objectSpread({}, mapState("d2admin", {
    severlist: function severlist(state) {
      return state.user.GMauthority;
    }
  })),
  methods: {
    getsearchDate: function getsearchDate() {
      var _this = this;

      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
      this.$nextTick(function () {
        _this.searchClick();
      });
    },
    //请求服务器列表  用于指定海盗和御剑请求
    getServerLists: function getServerLists() {
      this.RollSuit = false;
      this.serverLists = [];
      this.searchData.server_id = [];
      this.searchData.is_prod = '';
      var game = this.rawData.game_mark;

      if (game.indexOf(this.royalpirates) > -1) {
        this.RollSuit = this.severlist.length ? true : false;
      } else if (game.indexOf('yjfm') > -1) {
        this.RollSuit = true;
      }
    },
    DataChange: function DataChange() {
      this.screensever();
    },
    severlistChange: function severlistChange(e) {
      var _this2 = this;

      this.PrimitiveserverLists = [];
      this.serverLists = [];
      this.searchData.server_id = [];

      if (e) {
        hdServerList({
          is_prod: this.searchData.is_prod
        }).then(function (res) {
          if (res.status == 200) {
            res.data.map(function (i) {
              i.openTime = _this2.$times.dateTimeToTimeStamp(_this2.$times.formDate(i.openTime));
            });
            _this2.PrimitiveserverLists = res.data;

            _this2.screensever();
          }
        });
      }
    },
    screensever: function screensever(severlist) {
      if (!this.value2[0]) {
        return;
      }

      var list = [];
      var timeData = {
        startTime: this.$times.dateTimeToTimeStamp(this.value2[0] + " 00:00:00"),
        endTime: this.$times.dateTimeToTimeStamp(this.value2[1] + " 23:59:59")
      }; // this.PrimitiveserverLists.forEach(i => {
      //   if (timeData.startTime <= i.openTime && timeData.endTime >= i.openTime) {
      //     list.push(i)
      //   }
      // })

      this.serverLists = this.PrimitiveserverLists;
    },
    searchClick: function searchClick() {
      var _this3 = this;

      this.searchData.start_date = this.value2[0];
      this.searchData.end_date = this.value2[1];
      this.reqLoading = true; // this.searchData.kind = 1;

      onlineSummary(this.searchData).then(function (res) {
        if (res.code == 200) {
          _this3.tableData = [];
          _this3.dates = [];
          _this3.series = [];
          _this3.Dynamic = [];
          var list = [];
          _this3.isShow = _this3.searchData.server_id.length ? true : false;

          if (_this3.searchData.server_id.length) {
            for (var k in res.data) {
              list = [].concat(_toConsumableArray(list), _toConsumableArray(res.data[k]));
            }
          } else {
            for (var _k in res.data) {
              list = [].concat(_toConsumableArray(list), [_objectSpread({}, res.data[_k])]);
            }
          }

          for (var _k2 in list) {
            list[_k2].duration_avg_per_login = list[_k2].duration_avg_per_login ? (list[_k2].duration_avg_per_login / 60).toFixed(3) : '0.000';
            list[_k2].duration_avg_per_account = list[_k2].duration_avg_per_account ? (list[_k2].duration_avg_per_account / 60).toFixed(3) : '0.000';

            _this3.tableData.push(list[_k2]);
          }

          _this3.$nextTick(function () {
            _this3.getTableRight();
          });
        } // this.getechart();

      }).finally(function () {
        _this3.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("onlineDetailsEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: this.radio4 == '' ? '汇总' : '汇总金额'
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          width: '80%'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.radio4 == '' ? this.PrimitiveDy.map(function (item) {
            return item.prop;
          }) : this.PrimitiveDyamount.map(function (item) {
            return item.prop;
          })
        },
        yAxis: {
          type: "value"
        },
        series: this.radio4 == '' ? this.series : this.seriesamount
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }, {
        label: '注册账号',
        prop: 'new_account_num'
      }];
      columns = this.radio4 == '' ? [].concat(_toConsumableArray(columns), _toConsumableArray(this.Dynamic)) : [].concat(_toConsumableArray(columns), _toConsumableArray(this.Dynamicamount));
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.radio4 == '' ? '汇总' : '汇总金额'
      });
    }
  }
};