import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { searchgame, searchplatform, searchchannel, searchpkg } from '@/api/dataAll/dataAll';
import { onlineDevices } from '@/api/dataAll/onlineDevice';
import * as echarts from 'echarts';
export default {
  name: "test",
  data: function data() {
    return {
      tableTitle: [],
      // 表头
      gameId: '',
      // 时间选择数据
      value1: '',
      value2: [],
      searchData: {
        game: '',
        platform: '',
        channel: '',
        pkg: '',
        starttime: '',
        endtime: ''
      },
      tableData: [],
      // 表格数据
      tableDate: [],
      // 表格时间
      show: false,
      echarts: false,
      // 图标显示
      dates: [],
      // 报表日期数组
      datas: [],
      // 报表时间数据
      series: [],
      selectedArr: {}
    };
  },
  mounted: function mounted() {
    var hDiv = document.getElementById('hDiv');
    var dDiv = document.getElementById('dDiv');
    var tb0 = document.getElementById('tb0');
    window.addEventListener('scroll', this.handleScroll, true);
    dDiv.addEventListener('scroll', function () {
      tb0.style.left = dDiv.scrollLeft * -1 + 'px';
    });
  },
  created: function created() {
    var _this = this;

    this.getsearchDate(); // 获取游戏搜索

    searchgame().then(function (res) {
      _this.searchgames = res.data;
      _this.gameId = res.data.id;
    });
  },
  methods: {
    // 默认选中时间
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? '0' + now.getDate() : now.getDate();
      var date = year + '-' + month + '-' + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    gamechange: function gamechange(id) {
      var _this2 = this;

      searchplatform({
        game_id: id
      }).then(function (res) {
        _this2.searchplatforms = res.data;
        _this2.searchData.platform = '';
        _this2.searchData.channel = '';
        _this2.searchData.pkg = '';
        _this2.searchMark.platform = '';
        _this2.searchMark.channel = '';
        _this2.searchMark.pkg = '';
        _this2.searchchannels = [], _this2.searchpkgs = [];
      });
      this.searchgames.forEach(function (item) {
        if (item.id == id) {
          _this2.searchMark.game = item.game_mark;
        }
      });
    },
    platformchange: function platformchange(id) {
      var _this3 = this;

      searchchannel({
        platform_id: id
      }).then(function (res) {
        _this3.searchchannels = res.data;
        _this3.searchData.pkg = '';
        _this3.searchMark.pkg = '';
        _this3.searchpkgs = [];
      });
      this.searchplatforms.forEach(function (item) {
        if (item.id == id) {
          _this3.searchMark.platform = item.platform_mark;
          _this3.searchData.channel = '';
          _this3.searchMark.channel = '';
          _this3.searchpkgs = [];
        }
      });
    },
    channelchange: function channelchange(id) {
      var _this4 = this;

      searchpkg({
        channel_id: id
      }).then(function (res) {
        _this4.searchpkgs = res.data;
      });
      this.searchchannels.forEach(function (item) {
        if (item.id == id) {
          _this4.searchMark.channel = item.channel_mark;
          _this4.searchData.pkg = '';
          _this4.searchMark.pkg = '';
        }
      });
    },
    pkgchange: function pkgchange(id) {
      var _this5 = this;

      this.searchpkgs.forEach(function (item) {
        if (item.id == id) {
          _this5.searchMark.pkg = item.pkg_mark;
        }
      });
    },
    searchClick: function searchClick() {
      var _this6 = this;

      if (this.value2.length > 0) {
        this.searchMark.starttime = this.value2[0];
        this.searchMark.endtime = this.value2[1];
      }

      onlineDevices(this.searchMark).then(function (res) {
        var data_array = res.data.data;
        var date_array = res.data.date_array;
        var table_top = "<th style='width:100px;'>同时在线</th>";
        var table_bodoy = '';
        _this6.dates = []; // 格式化图表时间数组

        var _iterator = _createForOfIteratorHelper(date_array),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var date = _step.value;

            _this6.dates.push(date);

            table_top += '<th style="width:100px;line-height:40px;">' + date + '</th>';
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        _this6.datas = [];

        for (var h = 1; h <= 24; h++) {
          _this6.datas.push(h + '点');

          table_bodoy += '<tr>';
          table_bodoy += "<td style='width:100px;'>" + h + '点</td>';

          var _iterator2 = _createForOfIteratorHelper(date_array),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var day = _step2.value;
              table_bodoy += '<td>' + data_array[day][h] + '</td>';
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }

          table_bodoy += '</tr>';
        }

        _this6.series = [];

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objTokey = function objTokey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        var data = objToTitle(data_array);
        data.forEach(function (item, i) {
          var ds = objToTitle(item);
          var obj = {
            name: _this6.dates[i],
            type: 'line',
            data: ds
          };

          _this6.series.push(obj);
        });
        var a = null;

        _this6.dates.forEach(function (val, i) {
          if (a != val) {
            a = val;

            if (i == 0) {
              _this6.selectedArr[a] = true;
            } else {
              _this6.selectedArr[a] = false;
            }
          }
        });

        var header = document.getElementById('header');
        var bodoy = document.getElementById('bodoy');
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        _this6.show = true;
        _this6.echarts = true;

        _this6.getechart();
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById('echart');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        legend: {
          data: this.dates,
          // 这里显示点击搜索的日期--图表的最上面
          selected: this.selectedArr,
          width: '80%'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          nameLocation: 'middle',
          data: this.datas
        },
        yAxis: {
          type: 'value'
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    }
  }
};