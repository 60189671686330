//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    advanced: Boolean
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    searchChange: function searchChange() {
      this.$emit('searchChange');
    },
    Toggle: function Toggle() {
      this.$emit('advancedChange', !this.advanced);
    }
  }
};