import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import sheet from "@/components/sheet/sheet.vue";
import util from '@/libs/util';
import axios from 'axios';
export default {
  components: {
    sheet: sheet
  },
  props: {
    filesname: {
      type: String,
      default: function _default() {
        return 'items';
      }
    },
    Url: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    paramsData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      action: process.env.VUE_APP_API + '/hjhd-gm/upload-file-email',
      headers: {
        'Authorization': '',
        'Request-Url': ''
      },
      fileList: [{}, {}, {}, {}],
      fileLoading: false,
      dialogShow: false,
      uploadData: {
        mail_type: 'all_role'
      },
      whetherNot: [{
        label: '所有玩家',
        value: 'all_role'
      }, {
        label: '在线玩家',
        value: 'online_role'
      }, {
        label: '选中玩家',
        value: 'list_role'
      }, {
        label: '条件列表',
        value: 'limit'
      }]
    };
  },
  created: function created() {
    this.headers['Authorization'] = 'Bearer ' + (util.cookies.get('token') || '');
  },
  methods: {
    open: function open() {
      this.dialogShow = true;
      this.uploadData = _objectSpread(_objectSpread({}, this.uploadData), this.paramsData);
    },
    fileChang: function fileChang(type) {
      var flieData = document.getElementById("file" + type).files;

      if (flieData[0].type !== 'text/csv') {
        this.$message.error('文件类型错误');
        document.getElementById("file" + type).value = '';
        return;
      }

      this.$set(this.fileList, type, flieData[0]);
      document.getElementById("file" + type).value = '';
    },
    removeFile: function removeFile(index) {
      this.$set(this.fileList, index, {});
    },
    clearData: function clearData() {
      this.fileList = [{}, {}, {}, {}];
      this.paramsData.mail_type = 'all_role';
    },
    Toggle: function Toggle() {
      this.fileList[2] = {};
      this.fileList[3] = {};
    },
    uploadFile: function uploadFile() {
      var _this = this;

      var fileList = this.fileList;

      if (!fileList[0].name) {
        this.$message.warning('请选择物品文件');
        return;
      }

      if (!fileList[1].name) {
        this.$message.warning('请选择邮件文件');
        return;
      } // if (!fileList[2].name && this.uploadData.mail_type == 'list_role') {
      //   this.$message.warning('请角色文件')
      //   return
      // }


      if (!fileList[3].name && this.uploadData.mail_type == 'limit') {
        this.$message.warning('请条件文件');
        return;
      } // this.fileLoading = true


      var formDate = new FormData();
      fileList[0].name && formDate.append("item_file", fileList[0]);
      fileList[1].name && formDate.append("mail_file", fileList[1]); // formDate.append("role_file", fileList[2])

      fileList[3].name && formDate.append("range_file", fileList[3]);

      for (var key in this.uploadData) {
        formDate.append(key, this.uploadData[key]);
      }

      this.headers['Request-Url'] = this.$route.path;
      axios.post(this.action, formDate, {
        headers: this.headers
      }).then(function (res) {
        if (res.data.code == 200) {
          _this.$message.success('上传成功');

          _this.$emit('change');

          _this.dialogShow = false;
        } else {
          _this.$message.error(res.data.message || res.data.msg);
        }
      }).finally(function () {
        _this.fileLoading = false;
      });
    },
    //文件超出个数限制时的函数
    handleExceed: function handleExceed(files, fileList) {
      this.$message.info("\u6700\u591A\u53EA\u5141\u8BB8\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247"));
    }
  }
};