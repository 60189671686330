var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id ? "修改" : "提交",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem ",
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { prop: "content_Val" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addContent }
                        },
                        [_vm._v("添加内容 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.content_Val, function(domain, index) {
                return _c(
                  "div",
                  { key: index + "k", staticClass: "content_ValClass" },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem contentValitem",
                            attrs: {
                              prop: "content_Val." + index + ".title",
                              rules: _vm.rules.title,
                              label: "标题",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "operationFrame-entity",
                              attrs: {
                                clearable: "",
                                placeholder: "请输入标题"
                              },
                              model: {
                                value: domain.title,
                                callback: function($$v) {
                                  _vm.$set(domain, "title", $$v)
                                },
                                expression: "domain.title "
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem contentValitem",
                            attrs: {
                              prop: "content_Val." + index + ".language",
                              rules: _vm.rules.language,
                              label: "语言",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "flex-1",
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: domain.language,
                                  callback: function($$v) {
                                    _vm.$set(domain, "language", $$v)
                                  },
                                  expression: "domain.language "
                                }
                              },
                              _vm._l(_vm.langList, function(item) {
                                return _c("el-option", {
                                  key: item.label,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.paramsData.content_Val.length > 1
                          ? _c("el-button", {
                              staticStyle: { margin: "0 0 20px 10px" },
                              attrs: {
                                size: "mini",
                                type: "danger",
                                circle: "",
                                icon: "el-icon-delete"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeContent(domain)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex lodingItem-title first_box",
                        staticStyle: {
                          "margin-bottom": "20px",
                          width: "100%",
                          "align-items": "center"
                        }
                      },
                      [
                        _vm._v("邮件内容: "),
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: { "padding-left": "20px" }
                          },
                          _vm._l(_vm.tagList, function(items, indexs) {
                            return _c(
                              "div",
                              {
                                key: indexs,
                                staticClass: "addtag",
                                on: {
                                  click: function($event) {
                                    return _vm.addtag(items.content, index)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(items.name))]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          prop: "content_Val." + index + ".content",
                          rules: _vm.rules.content
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 2, maxRows: 12 },
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: domain.content,
                            callback: function($$v) {
                              _vm.$set(domain, "content", $$v)
                            },
                            expression: "domain.content "
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addDomain }
                    },
                    [_vm._v("添加角色")]
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.desc, function(domain, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { width: "100%" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "server_id",
                            staticClass: "lodingItem",
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              prop: "desc." + index + ".server_id",
                              rules: _vm.rules.server_ids,
                              label: "服务器(可多选)",
                              "label-width": "120px"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "operationFrame-entity",
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: domain.server_id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "server_id", $$v)
                                  },
                                  expression: "domain.server_id"
                                }
                              },
                              _vm._l(_vm.serverList, function(item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.serverID,
                                    attrs: {
                                      label: item.serverName,
                                      value: item.serverID
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "serverOption" }, [
                                      _c("div", [
                                        _vm._v(_vm._s(item.serverName))
                                      ]),
                                      _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.is_recomm == 1 ? "推荐" : ""
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: "role_id",
                            staticClass: "lodingItem",
                            attrs: {
                              prop: "desc." + index + ".role_id",
                              rules: _vm.rules.role_id,
                              label: "角色ID"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "operationFrame-entity",
                              attrs: { placeholder: "角色ID" },
                              model: {
                                value: domain.role_id,
                                callback: function($$v) {
                                  _vm.$set(domain, "role_id", _vm._n($$v))
                                },
                                expression: "domain.role_id"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem marginL",
                            staticStyle: { width: "200px" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "controlsItem" },
                              [
                                _vm.paramsData.desc.length > 1
                                  ? _c("el-button", {
                                      staticStyle: { margin: "6px 0 6px 20px" },
                                      attrs: {
                                        size: "mini",
                                        type: "danger",
                                        circle: "",
                                        icon: "el-icon-delete"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removeDomain(domain)
                                        }
                                      }
                                    })
                                  : _c("div"),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addItem(index)
                                      }
                                    }
                                  },
                                  [_vm._v("添加物品")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "itemBox" },
                      _vm._l(domain.item_list, function(domains, indexs) {
                        return _c(
                          "div",
                          { key: indexs, staticClass: "lodingItem" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "0px" },
                                attrs: {
                                  prop:
                                    "desc." +
                                    index +
                                    ".item_list." +
                                    indexs +
                                    ".item_id",
                                  rules: _vm.rules.item_id
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "物品"
                                    },
                                    model: {
                                      value: domains.item_id,
                                      callback: function($$v) {
                                        _vm.$set(domains, "item_id", $$v)
                                      },
                                      expression: "domains.item_id"
                                    }
                                  },
                                  _vm._l(_vm.articleList, function(
                                    haidaoitem,
                                    haidaoindex
                                  ) {
                                    return _c("el-option", {
                                      key: haidaoindex,
                                      attrs: {
                                        label: haidaoitem.kind,
                                        value: haidaoitem.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "0px" },
                                attrs: {
                                  prop:
                                    "desc." +
                                    index +
                                    ".item_list." +
                                    indexs +
                                    ".num",
                                  rules: _vm.rules.num
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "数量"
                                  },
                                  model: {
                                    value: domains.num,
                                    callback: function($$v) {
                                      _vm.$set(domains, "num", _vm._n($$v))
                                    },
                                    expression: "domains.num"
                                  }
                                })
                              ],
                              1
                            ),
                            domain.item_list.length > 1
                              ? _c("el-button", {
                                  staticStyle: { margin: "10px 0 10px 20px" },
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    circle: "",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeItem(index, indexs)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "", loading: _vm.issubmit },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }