// 付费账号留存
import request from '@/utils/request';
export function payAccountRetain(data) {
  return request({
    url: '/data/payaccountretain',
    method: 'get',
    params: data
  });
}
export function payAccountRetain_currency(data) {
  return request({
    url: '/data/payaccountretainCurrency',
    method: 'get',
    params: data
  });
} // 付费设备留存

export function payDeviceRetain(data) {
  return request({
    url: '/data/paydeviceretain',
    method: 'get',
    params: data
  });
}
export function payDeviceRetain_currency(data) {
  return request({
    url: '/data/paydeviceretainCurrency',
    method: 'get',
    params: data
  });
}