import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { advertisingData, exportadvertising } from "@/api/dataAll/new";
export default {
  name: "advertisingData",
  mixins: [serchSelect],
  data: function data() {
    return {
      per_page: 5,
      total: 0,
      page: 1,
      searchData: {
        page: 1
      },
      searchpadv: [],
      value2: [],
      tableData: [],
      show: false,
      totalshow: false,
      more: true
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      advertisingData(this.searchData).then(function (res) {
        _this.tableData = res.data.data || [];
        _this.total = res.data.total || 0;
        _this.page = res.data.current_page;
        _this.per_page = res.data.per_page;
        _this.show = true;
        _this.totalshow = true;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.searchClick();
    },
    exportdata: function exportdata() {
      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      } else {
        this.searchData.starttime = "";
        this.searchData.endtime = "";
      }

      if (this.searchData.game != "" && this.searchData.game != undefined && this.searchData.platform != "" && this.searchData.platform != undefined && this.searchData.starttime != "" && this.searchData.starttime != undefined && this.searchData.endtime != "" && this.searchData.endtime != undefined) {
        if (window.location.hostname == "localhost" || window.location.host == "192.168.100.192:9527") {
          window.location.href = "http://192.168.100.58/index.php/api/data/export_advertising_max_list?game=" + (this.searchData.game = "" ? "" : this.searchData.game = undefined ? "" : this.searchData.game) + "&" + "platform=" + (this.searchData.platform = "" ? "" : this.searchData.platform = undefined ? "" : this.searchData.platform) + "&" + "starttime=" + this.searchData.starttime + "&" + "endtime=" + this.searchData.endtime + "&" + "page=" + this.searchData.page + "&" + "channel=" + (this.searchData.channel = "" ? "" : this.searchData.channel) + "&" + "pkg=" + (this.searchData.pkg = "" ? "" : this.searchData.pkg) + "&ad_channel=" + this.searchData.ad_channel + "&ad_placement=" + this.searchData.ad_placement;
        } else if (window.location.host == "datacenter-server.onemgames.com") {
          window.location.href = "https://datacenter-server.onemgames.com/index.php/api/data/export_advertising_max_list?game=" + (this.searchData.game = "" ? "" : this.searchData.game = undefined ? "" : this.searchData.game) + "&" + "platform="(this.searchData.platform = "" ? "" : this.searchData.platform = undefined ? "" : this.searchData.platform) + "&" + "starttime=" + this.searchData.starttime + "&" + "endtime=" + this.searchData.endtime + "&" + "page=" + this.searchData.page + "&" + "channel=" + (this.searchData.channel = "" ? "" : this.searchData.channel) + "&" + "pkg=" + (this.searchData.pkg = "" ? "" : this.searchData.pkg) + "&ad_channel=" + this.searchData.ad_channel + "&ad_placement=" + this.searchData.ad_placement;
        } else if (window.location.host == "datacenter-server-test.onemgames.com") {
          window.location.href = "http://datacenter-server-test.onemgames.com:81/api/data/export_advertising_max_list?game=" + (this.searchData.game = "" ? "" : this.searchData.game = undefined ? "" : this.searchData.game) + "&" + "platform=" + (this.searchData.platform = "" ? "" : this.searchData.platform = undefined ? "" : this.searchData.platform) + "&" + "starttime=" + this.searchData.starttime + "&" + "endtime=" + this.searchData.endtime + "&" + "page=" + this.searchData.page + "&" + "channel=" + (this.searchData.channel = "" ? "" : this.searchData.channel = undefined ? "" : this.searchData.channel) + "&" + "pkg=" + (this.searchData.pkg = "" ? "" : this.searchData.pkg = undefined ? "" : this.searchData.pkg) + "&ad_channel=" + this.searchData.ad_channel + "&ad_placement=" + this.searchData.ad_placement;
        } else {
          window.location.href = "http://datacenter-server-f.onemgames.com/index.php/api/data/export_advertising_max_list?game=" + (this.searchData.game = "" ? "" : this.searchData.game = undefined ? "" : this.searchData.game) + "&" + "platform=" + (this.searchData.platform = "" ? "" : this.searchData.platform = undefined ? "" : this.searchData.platform) + "&" + "starttime=" + this.searchData.starttime + "&" + "endtime=" + this.searchData.endtime + "&" + "page=" + this.searchData.page + "&" + "channel=" + (this.searchData.channel = "" ? "" : this.searchData.channel = undefined ? "" : this.searchData.channel) + "&" + "pkg=" + (this.searchData.pkg = "" ? "" : this.searchData.pkg = undefined ? "" : this.searchData.pkg) + "&ad_channel=" + this.searchData.ad_channel + "&ad_placement=" + this.searchData.ad_placement;
        }
      } else {
        this.$message({
          message: "请填完必选项哦"
        });
      }
    }
  }
};