// 获取敏感词列表
import request from '@/utils/request';
export function getSenstityList(data) {
  return request({
    url: '/datum/sensitive_word',
    method: 'get',
    params: data
  });
} // 新增

export function addSenstityList(data) {
  return request({
    url: '/datum/add_sensitive_word',
    method: 'post',
    params: data
  });
} // 反显数据

export function returnData(data) {
  return request({
    url: '/datum/up_sensitive_word',
    method: 'get',
    params: data
  });
} // 编辑敏感词列表

export function updataSentityList(data) {
  return request({
    url: '/datum/up_sensitive_word',
    method: 'post',
    params: data
  });
} // 删除敏感词列表

export function deleSentityList(data) {
  return request({
    url: '/datum/del_sensitive_word',
    method: 'get',
    params: data
  });
}