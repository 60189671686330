var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "flex", attrs: { span: 4 } },
            [
              _c("div", { staticClass: "vsTitle" }, [_vm._v(" 当前版本: ")]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.version,
                  callback: function($$v) {
                    _vm.version = $$v
                  },
                  expression: "version"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.upVs } },
                [_vm._v("更新版本号")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function() {
                      _vm.tableData = []
                      _vm.getList(_vm.paramsData.page)
                    }
                  }
                },
                [_vm._v("刷新")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", { attrs: { prop: "nickname", label: "昵称" } }),
          _c("el-table-column", { attrs: { prop: "email", label: "账号" } }),
          _c("el-table-column", {
            attrs: { prop: "auth_group_name", label: "角色名称" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }