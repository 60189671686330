import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { banUser } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      paramsData: {
        op_kind: 1,
        op_type: '',
        uids: [{
          uids: null,
          expire_time: '',
          isForever: false
        }],
        reason: ''
      },
      dialogShow: false,
      whetherNot: [{
        label: '封禁',
        value: 1
      }, {
        label: '解封',
        value: 0
      }],
      rules: {
        op_type: [{
          required: true,
          message: "请选择状态",
          trigger: "blur"
        }],
        uids: [{
          required: true,
          message: "请输入角色ID",
          trigger: "blur"
        }],
        expire_time: [{
          required: true,
          message: "请选择封禁时间",
          trigger: "blur"
        }],
        reason: [{
          required: true,
          message: "请输入原因",
          trigger: "blur"
        }]
      },
      issubmit: false,
      isdisabled: false,
      type: 0
    };
  },
  created: function created() {},
  methods: {
    open: function open(data, type) {
      this.paramsData.op_kind = type;
      this.dialogShow = true;

      if (JSON.stringify(data) !== "{}") {
        this.isdisabled = true;
        this.paramsData.op_type = 1;
        this.paramsData.uids[0].uids = Number(data.role_id);
      }
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    addDomain: function addDomain() {
      this.paramsData.uids.push({
        uids: null,
        expire_time: '',
        isForever: false
      });
    },
    removeDomain: function removeDomain(item) {
      var index = this.paramsData.uids.indexOf(item);

      if (index !== -1) {
        this.paramsData.uids.splice(index, 1);
      }
    },
    clearData: function clearData() {
      this.paramsData = {
        op_kind: this.paramsData.op_kind,
        op_type: '',
        uids: [{
          uids: null,
          expire_time: '',
          isForever: false
        }],
        reason: ''
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.issubmit = true;
          var data = JSON.parse(JSON.stringify(_this.paramsData));
          data.reason = data.reason == '' ? '1' : data.reason;
          data.uids.map(function (item) {
            item.expire_time = !item.isForever ? item.expire_time / 1000 : 0;
            delete item.isForever;
          });
          banUser(_objectSpread(_objectSpread({}, data), _this.prod)).then(function (res) {
            if (res.code == 200 || res.status == 200) {
              _this.$message({
                message: '操作成功',
                type: 'success'
              });

              _this.dialogShow = false;

              _this.$emit('change');
            }
          }).finally(function () {
            _this.issubmit = false;
          });
        }
      });
    }
  }
};