var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page advssdata section dataAll-content" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _vm.game_show_type == 1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择投放包"
                      },
                      on: { change: _vm.pkgChange },
                      model: {
                        value: _vm.searchData.pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "pkg", $$v)
                        },
                        expression: "searchData.pkg"
                      }
                    },
                    _vm._l(_vm.searchpkgs, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg_name, value: item.pkg_mark }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告渠道"
                  },
                  on: { change: _vm.advChannelchange },
                  model: {
                    value: _vm.searchData.ad_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_channel", $$v)
                    },
                    expression: "searchData.ad_channel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_platform_name,
                      value: item.adv_platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告位置"
                  },
                  model: {
                    value: _vm.searchData.ad_placement,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_placement", $$v)
                    },
                    expression: "searchData.ad_placement"
                  }
                },
                _vm._l(_vm.advLocation, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_place_name,
                      value: item.adv_place_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: false,
                  "picker-options": _vm.pickerOptionsRadius,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.value2,
                  callback: function($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchClick } },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-row",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, "max-height": "400", border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "timestamp", label: "时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "user_id", label: "用户ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "event_id", label: "事件ID" }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "page-size": _vm.per_page,
          layout: "total, prev, pager, next",
          total: _vm.total
        },
        on: { "current-change": _vm.handleCurrentChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }