import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accrechargeBadge } from "@/api/dataAll/recharge";
export default {
  name: "accPurchBadge",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: [],
      show: false
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      accrechargeBadge(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this.tableData = [];

          for (var k in res.data.other.recharge_num) {
            _this.tableData.push({
              date: k,
              recharge_num: res.data.other.recharge_num[k]
            });
          }

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }, {
        label: '充值人数',
        prop: 'recharge_num'
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};