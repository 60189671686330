import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { getConAanData, getConAanData_currency } from "@/api/dataAll/congroupAnal";
import * as echarts from "echarts";
export default {
  name: "congroupAnal",
  mixins: [tabulation],
  data: function data() {
    return {
      searchData: {
        activatetime: ""
      },
      tableData: [],
      tableLtv: [],
      dates: [],
      show: false,
      activity_device_arr: [],
      register_arr: [],
      create_role_arr: [],
      login_time_arr: [],
      sum_login_time_arr: [],
      recharge_time_arr: [],
      sum_recharge_time_arr: [],
      pay_amount_arr: [],
      sum_pay_amount_arr: [],
      arpu_arr: [],
      sum_arpu_arr: [],
      arppu_arr: [],
      sum_arppu_arr: [],
      ltv_arr: [],
      isgetdataCurrency: true
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.dates = [];
      this.activity_device_arr = [];
      this.register_arr = [];
      this.create_role_arr = [];
      this.login_time_arr = [];
      this.sum_login_time_arr = [];
      this.recharge_time_arr = [];
      this.sum_recharge_time_arr = [];
      this.pay_amount_arr = [];
      this.sum_pay_amount_arr = [];
      this.arpu_arr = [];
      this.sum_arpu_arr = [];
      this.arppu_arr = [];
      this.sum_arppu_arr = [];
      this.ltv_arr = [];

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      var reqFn = this.currencyClose ? getConAanData_currency : getConAanData;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        var dataArr = [];
        res.data.forEach(function (item, index) {
          item.id = index + 1;
          item.pay_amount = _this.$utils.conversion(item.pay_amount);
          item.sum_pay_amount = _this.$utils.conversion(item.sum_pay_amount);
          item.arpu = _this.$utils.conversion(item.arpu).toFixed(2);
          item.sum_arpu = _this.$utils.conversion(item.sum_arpu).toFixed(2);
          item.ltv = _this.$utils.conversion(item.ltv).toFixed(2);
          item.arppu = _this.$utils.conversion(item.arppu).toFixed(2);
          item.sum_arppu = _this.$utils.conversion(item.sum_arppu).toFixed(2);
          item.activity_device = Number(item.activity_device);
          item.create_role = Number(item.create_role);

          _this.activity_device_arr.push(item.activity_device);

          _this.register_arr.push(item.register);

          _this.create_role_arr.push(item.create_role);

          _this.login_time_arr.push(item.login_time);

          _this.sum_login_time_arr.push(item.sum_login_time);

          _this.recharge_time_arr.push(item.recharge_time);

          _this.sum_recharge_time_arr.push(item.sum_recharge_time);

          _this.pay_amount_arr.push(item.pay_amount);

          _this.sum_pay_amount_arr.push(item.sum_pay_amount);

          _this.arpu_arr.push(item.arpu);

          _this.sum_arpu_arr.push(item.sum_arpu);

          _this.arppu_arr.push(item.arppu);

          _this.sum_arppu_arr.push(item.sum_arppu);

          _this.ltv_arr.push(item.ltv);
        });
        _this.tableData = res.data || []; // this.tableData = dataArr

        _this.dates = _this.tableData.map(function (v) {
          return v.date;
        });
        _this.echarts = true;

        _this.getechart();

        _this.$nextTick(function () {
          _this.getTableRight();
        });
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("congroupAnalEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "同群分析"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        toolbox: {
          feature: {
            // magicType: { show: true, type: ['line', 'bar'] },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        legend: {
          data: ["激活设备", "注册", "创角数", "登录设备数", "累计登录设备数", "付费设备数", "累计付费设备数", "当日付费金额", "累计付费金额", "当日arpu", "累计arpu", "当日arppu", "累计arppu", "ltv"],
          width: '80%'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.dates
        },
        yAxis: {
          type: "value"
        },
        series: [{
          name: "激活设备",
          type: "line",
          data: this.activity_device_arr,
          smooth: true
        }, {
          name: "注册",
          type: "line",
          data: this.register_arr,
          smooth: true
        }, {
          name: "创角数",
          type: "line",
          data: this.create_role_arr,
          smooth: true
        }, {
          name: "登录设备数",
          type: "line",
          data: this.login_time_arr,
          smooth: true
        }, {
          name: "累计登录设备数",
          type: "line",
          data: this.sum_login_time_arr,
          smooth: true
        }, {
          name: "付费设备数",
          type: "line",
          data: this.recharge_time_arr,
          smooth: true
        }, {
          name: "累计付费设备数",
          type: "line",
          data: this.sum_recharge_time_arr,
          smooth: true
        }, {
          name: "当日付费金额",
          type: "line",
          data: this.pay_amount_arr,
          smooth: true
        }, {
          name: "累计付费金额",
          type: "line",
          data: this.sum_pay_amount_arr,
          smooth: true
        }, {
          name: "当日arpu",
          type: "line",
          data: this.arpu_arr,
          smooth: true
        }, {
          name: "累计arpu",
          type: "line",
          data: this.sum_arpu_arr,
          smooth: true
        }, {
          name: "当日arppu",
          type: "line",
          data: this.arppu_arr,
          smooth: true
        }, {
          name: "累计arppu",
          type: "line",
          data: this.sum_arppu_arr,
          smooth: true
        }, {
          name: "ltv",
          type: "line",
          data: this.ltv_arr,
          smooth: true
        }]
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [// {
      //   label: 'ID',
      //   prop: 'id'
      // }, 
      {
        label: '日期',
        prop: 'date'
      }, {
        label: '激活设备',
        prop: 'activity_device'
      }, {
        label: '注册',
        prop: 'register'
      }, {
        label: '创角数',
        prop: 'create_role'
      }, {
        label: '登录设备数',
        prop: 'login_time'
      }, {
        label: '累计登录设备数',
        prop: 'sum_login_time'
      }, {
        label: '付费设备数',
        prop: 'recharge_time'
      }, {
        label: '累计付费设备数',
        prop: 'sum_recharge_time'
      }, {
        label: "\u5F53\u65E5\u4ED8\u8D39\u91D1\u989D",
        prop: 'pay_amount'
      }, {
        label: "\u7D2F\u8BA1\u4ED8\u8D39\u91D1\u989D",
        prop: 'sum_pay_amount'
      }, {
        label: "\u5F53\u65E5arpu",
        prop: 'arpu'
      }, {
        label: "\u7D2F\u8BA1arpu",
        prop: 'sum_arpu'
      }, {
        label: "\u5F53\u65E5arppu",
        prop: 'arppu'
      }, {
        label: "\u7D2F\u8BA1arppu",
        prop: 'sum_arppu'
      }, {
        label: "ltv",
        prop: 'ltv'
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    } // handleCurrentChange(val) {},

  }
};