var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id ? "修改" : "新增",
            visible: _vm.dialogShow,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "uid", label: "游戏UID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入游戏UID" },
                    model: {
                      value: _vm.paramsData.uid,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "uid", $$v)
                      },
                      expression: "paramsData.uid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "order_id", label: "订单ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入订单ID" },
                    model: {
                      value: _vm.paramsData.order_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "order_id", $$v)
                      },
                      expression: "paramsData.order_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "pay_channel_order_id", label: "支付渠道订单" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入支付渠道订单" },
                    model: {
                      value: _vm.paramsData.pay_channel_order_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "pay_channel_order_id", $$v)
                      },
                      expression: "paramsData.pay_channel_order_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "product_id", label: "商品ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { type: "number", placeholder: "请输入商品ID" },
                    model: {
                      value: _vm.paramsData.product_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "product_id", _vm._n($$v))
                      },
                      expression: "paramsData.product_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "pay_type", label: "支付方式" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { placeholder: "请选择支付方式" },
                      model: {
                        value: _vm.paramsData.pay_type,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "pay_type", $$v)
                        },
                        expression: "paramsData.pay_type"
                      }
                    },
                    _vm._l(_vm.payType, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "lodingItem", attrs: { prop: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addDomain }
                        },
                        [_vm._v("添加物品")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.reward, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "lodingItem",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-button", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain(domain)
                            }
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "reward." + index + ".type",
                              rules: _vm.rules.item_type
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { placeholder: "类型" },
                                on: {
                                  change: function($event) {
                                    return _vm.domainType(index)
                                  }
                                },
                                model: {
                                  value: domain.type,
                                  callback: function($$v) {
                                    _vm.$set(domain, "type", $$v)
                                  },
                                  expression: "domain.type"
                                }
                              },
                              _vm._l(_vm.itemType, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: {
                                    label: items.label,
                                    value: items.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "reward." + index + ".id",
                              rules: _vm.rules.id
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "150px"
                                },
                                attrs: {
                                  filterable: "",
                                  disabled: !domain.type,
                                  placeholder: "物品"
                                },
                                model: {
                                  value: domain.id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "id", $$v)
                                  },
                                  expression: "domain.id"
                                }
                              },
                              [
                                _vm._l(_vm.itemType1, function(items, indexs) {
                                  return domain.type == 1
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType2, function(items, indexs) {
                                  return domain.type == 2
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType3, function(items, indexs) {
                                  return domain.type == 3
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType4, function(items, indexs) {
                                  return domain.type == 4
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType5, function(items, indexs) {
                                  return domain.type == 5
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType6, function(items, indexs) {
                                  return domain.type == 6
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType7, function(items, indexs) {
                                  return domain.type == 7
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType8, function(items, indexs) {
                                  return domain.type == 8
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "reward." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", _vm._n($$v))
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        ),
                        domain.type == 2
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { margin: "0px" },
                                attrs: {
                                  prop: "reward." + index + ".quality",
                                  rules: _vm.rules.item_quality
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { placeholder: "品质" },
                                    model: {
                                      value: domain.quality,
                                      callback: function($$v) {
                                        _vm.$set(domain, "quality", $$v)
                                      },
                                      expression: "domain.quality"
                                    }
                                  },
                                  _vm._l(_vm.quality, function(items, indexs) {
                                    return _c("el-option", {
                                      key: indexs,
                                      attrs: { label: items, value: items }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }