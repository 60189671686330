var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: {
                    change: function($event) {
                      return _vm.getList(1)
                    }
                  },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "玩家ID" },
                model: {
                  value: _vm.paramsData.role_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "role_id", $$v)
                  },
                  expression: "paramsData.role_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "渠道ID" },
                model: {
                  value: _vm.paramsData.pay_channel,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "pay_channel", $$v)
                  },
                  expression: "paramsData.pay_channel"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "充值ID" },
                model: {
                  value: _vm.paramsData.pay_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "pay_id", $$v)
                  },
                  expression: "paramsData.pay_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "订单号" },
                model: {
                  value: _vm.paramsData.orderId,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "orderId", $$v)
                  },
                  expression: "paramsData.orderId"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "补单类型" },
                  model: {
                    value: _vm.paramsData.op_type,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "op_type", $$v)
                    },
                    expression: "paramsData.op_type"
                  }
                },
                _vm._l(_vm.supplement, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addreplenishment.init()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "admin_name", label: "操作员", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.admin_name || "/") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "role_id", label: "玩家ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "pay_channel", label: "渠道ID" }
          }),
          _c("el-table-column", { attrs: { prop: "pay_id", label: "充值ID" } }),
          _c("el-table-column", {
            attrs: {
              prop: "orderId",
              label: "订单号",
              "min-width": "200",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "补单时间", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.timestampToTime(
                            row.created_at,
                            "YY-MM-DD HH"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "passwd_secret", label: "补单类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.supplement.filter(function(item) {
                            return item.value == row.op_type
                          })[0].label
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "reward", label: "物品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.op_type != "supplement_product"
                      ? _c(
                          "div",
                          {
                            staticClass: "text-cursor",
                            staticStyle: { color: "#409EFF" },
                            on: {
                              click: function($event) {
                                return _vm.viewCode(row.reward)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : _c("div", [_vm._v("/")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "补单状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(row.status == 1 ? "成功" : "失败"))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      }),
      _c("addreplenishment", {
        ref: "addreplenishment",
        attrs: { prod: { is_prod: _vm.paramsData.is_prod } },
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      }),
      _c(
        "el-drawer",
        {
          attrs: { title: "详情", visible: _vm.isCode },
          on: {
            "update:visible": function($event) {
              _vm.isCode = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "codeListsTop" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "40px 20px 0", position: "relative" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.codeLists, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "expire", label: "时间", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$times.timestampToTime(row.expire)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "item_id", label: "物品" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.articleList.findIndex(function(item) {
                                      return item.id == row.item_id
                                    }) > -1
                                      ? _vm.articleList[
                                          _vm.articleList.findIndex(function(
                                            item
                                          ) {
                                            return item.id == row.item_id
                                          })
                                        ].kind
                                      : row.item_id
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "num", label: "数量" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }