import _defineProperty from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
// 混入代码 resize-mixins.js
import { debounce } from '@/utils/index';
var resizeChartMethod = '$__resizeChartMethod';
export default {
  data: function data() {
    // 在组件内部将图表init的引用映射到chart属性上
    return {
      chart: null
    };
  },
  created: function created() {
    window.addEventListener('resize', this[resizeChartMethod], false);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('reisze', this[resizeChartMethod]);
  },
  methods: _defineProperty({}, resizeChartMethod, debounce(function () {
    if (this.chart) {
      this.chart.resize();
    }
  }, 100))
};