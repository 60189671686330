var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "newUuersRever section dataAll-content" }, [
    _c("div", { staticClass: "flex-cont dataSummary-header" }, [
      _c(
        "div",
        { staticClass: "flex-item" },
        [
          _c(
            "el-select",
            {
              ref: "",
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择游戏"
              },
              on: { change: _vm.gamechange },
              model: {
                value: _vm.searchData.game,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "game", $$v)
                },
                expression: "searchData.game"
              }
            },
            _vm._l(_vm.searchgames, function(item) {
              return _c(
                "el-option",
                {
                  key: item.id,
                  attrs: {
                    "value-key": item.id,
                    label: item.game_name,
                    value: item.id
                  }
                },
                [_vm._v(_vm._s(item.game_name) + " ")]
              )
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择平台"
              },
              on: { change: _vm.platformchange },
              model: {
                value: _vm.searchData.platform,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "platform", $$v)
                },
                expression: "searchData.platform"
              }
            },
            _vm._l(_vm.searchplatforms, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.platform_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择渠道"
              },
              on: { change: _vm.channelchange },
              model: {
                value: _vm.searchData.channel,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "channel", $$v)
                },
                expression: "searchData.channel"
              }
            },
            _vm._l(_vm.searchchannels, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.channel_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择广告渠道"
              },
              on: { change: _vm.advChannelchange },
              model: {
                value: _vm.searchData.advChannel,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "advChannel", $$v)
                },
                expression: "searchData.advChannel"
              }
            },
            _vm._l(_vm.advchannels, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.adv_platform_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择广告位置"
              },
              on: { change: _vm.advLocationchange },
              model: {
                value: _vm.searchData.advLocation,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "advLocation", $$v)
                },
                expression: "searchData.advLocation"
              }
            },
            _vm._l(_vm.advLocation, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.adv_place_name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择投放包"
              },
              on: { change: _vm.pkgchange },
              model: {
                value: _vm.searchData.pkg,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "pkg", $$v)
                },
                expression: "searchData.pkg"
              }
            },
            _vm._l(_vm.searchpkgs, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.pkg_name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-star" },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入天数" },
            on: { change: _vm.dayChange },
            model: {
              value: _vm.searchData.day,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "day", $$v)
              },
              expression: "searchData.day"
            }
          }),
          _c(
            "el-select",
            {
              staticStyle: { "margin-left": "109px" },
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "请选择国家"
              },
              on: { change: _vm.countrychange },
              model: {
                value: _vm.searchData.country,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "country", $$v)
                },
                expression: "searchData.country"
              }
            },
            _vm._l(_vm.searchcountry, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.country, value: item.country }
              })
            }),
            1
          ),
          _c("el-date-picker", {
            staticStyle: { "margin-left": "109px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "daterange",
              align: "right",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            },
            model: {
              value: _vm.value2,
              callback: function($$v) {
                _vm.value2 = $$v
              },
              expression: "value2"
            }
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "13px" },
              attrs: { type: "success" },
              on: { click: _vm.searchClick }
            },
            [_vm._v("搜索")]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show"
          }
        ],
        attrs: { id: "box-posie" }
      },
      [_vm._m(0)]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "levelDetails-body", attrs: { id: "table-boxs" } },
      [
        _c("table", { attrs: { id: "table" } }, [
          _c("thead", { staticClass: "table-headers" }, [
            _c("tr", { attrs: { id: "header" } })
          ]),
          _c("tbody", { attrs: { id: "bodoy" } })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }