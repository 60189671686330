import request from '@/utils/request'; //新增三方绑定配置

export function addBindConfig(data) {
  return request({
    url: '/pay/bind_login_type',
    method: 'post',
    params: data
  });
} //增加支付类型

export function addPayType(data) {
  return request({
    url: '/pay/add_pay_type',
    method: 'post',
    params: data
  });
} // 支付类型详情

export function updategetpayType(data) {
  return request({
    url: '/pay/update_pay_type',
    method: 'get',
    params: data
  });
} // 修改支付类型

export function updatepayType(data) {
  return request({
    url: '/pay/update_pay_type',
    method: 'post',
    params: data
  });
} //初始化获取支付类型列表

export function typeList(data) {
  return request({
    url: '/pay/pay_type_lists',
    method: 'get',
    params: data
  });
} //获取所有支付类型

export function Type(data) {
  return request({
    url: '/pay/pay_type',
    method: 'post',
    params: data
  });
} //支付类型关联列表/pay/relevance

export function relationType(data) {
  return request({
    url: 'pay/relevance_list',
    method: 'get',
    params: data
  });
} // 支付关联类型下拉框

export function SelectType(data) {
  return request({
    url: '/pay/pay_type',
    method: 'get',
    params: data
  });
} // 新增关联支付类型

export function adarelationPayType(data) {
  return request({
    url: '/pay/relevance',
    method: 'post',
    params: data
  });
} // 修改关联支付类型

export function updaterelationPayType(data) {
  return request({
    url: '/pay/update_relevance',
    method: 'post',
    params: data
  });
} // 删除关联支付类型

export function delerelationPayType(data) {
  return request({
    url: '/pay/del_relevance',
    method: 'get',
    params: data
  });
} //删除支付类型

export function delPayType(data) {
  return request({
    url: '/pay/del_pay_type',
    method: 'get',
    params: data
  });
} //支付类型配置列表

export function payTypeList(data) {
  return request({
    url: '/pay/pay_type_config',
    method: 'get',
    params: data
  });
} // 支付类型配置删除

export function delconfig(data) {
  return request({
    url: '/pay/del_pay_type_config',
    method: 'get',
    params: data
  });
} // 支付类型配置新增

export function adddelconfig(data) {
  return request({
    url: 'pay/add_pay_type_config',
    method: 'post',
    params: data
  });
} // 支付类型配置修改

export function updelconfig(data) {
  return request({
    url: '/pay/update_pay_type_config',
    method: 'get',
    params: data
  });
} // 支付类型配置修改

export function payconfig(data) {
  return request({
    url: 'pay/config_pay_type',
    method: 'get',
    params: data
  });
} // 登录类型配置确认修改

export function uptruedelconfig(data) {
  return request({
    url: '/pay/update_pay_type_config',
    method: 'post',
    params: data
  });
} // 登录类型配置确认修改返显

export function getThreeReuturn(data) {
  return request({
    url: '/pay/update_pay_type_config',
    method: 'get',
    params: data
  });
} // 商品转换列表

export function getconversion(data) {
  return request({
    url: '/pay/goods_conversion',
    method: 'get',
    params: data
  });
} // 新增商品转换

export function addconversion(data) {
  return request({
    url: 'pay/add_goods_conversion',
    method: 'post',
    params: data
  });
} // 打开修改商品转换

export function getupconversion(data) {
  return request({
    url: 'pay/update_goods_conversion',
    method: 'get',
    params: data
  });
} // 确认修改商品转换

export function postupconversion(data) {
  return request({
    url: 'pay/update_goods_conversion',
    method: 'post',
    params: data
  });
} // 删除商品转换

export function delconversion(data) {
  return request({
    url: '/pay/del_goods_conversion',
    method: 'get',
    params: data
  });
} // 支付sdk配置列表

export function paysdkconfig(data) {
  return request({
    url: '/pay/pay_sdk_config',
    method: 'get',
    params: data
  });
} // 增加支付sdk配置列表

export function adpaysdkconfig(data) {
  return request({
    url: 'pay/add_pay_sdk_config',
    method: 'post',
    params: data
  });
} // 获取修改支付sdk配置列表

export function getupsdkconfig(data) {
  return request({
    url: 'pay/update_pay_sdk_config',
    method: 'get',
    params: data
  });
} //确认修改支付sdk配置列表

export function uppostsdkconfig(data) {
  return request({
    url: 'pay/update_pay_sdk_config',
    method: 'post',
    params: data
  });
} //重置sdk配置列表

export function resetsdkconfig(data) {
  return request({
    url: '/pay/reload_pay_sdk_config',
    method: 'get',
    params: data
  });
} //删除sdk配置列表

export function delsdkconfig(data) {
  return request({
    url: '/pay/del_pay_sdk_config',
    method: 'get',
    params: data
  });
} //网关列表获取

export function gatewayList(data) {
  return request({
    url: '/pay/gateway_sdk',
    method: 'get',
    params: data
  });
} //新增网关列表

export function addgateway(data) {
  return request({
    url: 'pay/add_gateway_sdk',
    method: 'post',
    params: data
  });
} //修改网关列表获取

export function getupgateway(data) {
  return request({
    url: 'pay/update_gateway_sdk',
    method: 'get',
    params: data
  });
} //确认修改网关列表

export function postupgateway(data) {
  return request({
    url: 'pay/update_gateway_sdk',
    method: 'post',
    params: data
  });
} //重置网关 

export function resetgateway(data) {
  return request({
    url: '/pay/reload_gateway_sdk',
    method: 'get',
    params: data
  });
} //删除网关列表

export function delgateway(data) {
  return request({
    url: '/pay/del_gateway_sdk',
    method: 'get',
    params: data
  });
} // 重置密钥

export function resetsecret(data) {
  return request({
    url: '/pay/reload_gateway_sdk',
    method: 'get',
    params: data
  });
} // 批量上传文件

export function batchImporatFile(data) {
  return request({
    url: '/pay/import_goods_conversion',
    method: 'post',
    params: data
  });
}