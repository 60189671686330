import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { recycleItem, sendMail } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    serverList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      paramsData: {
        desc: [{
          role_id: '',
          server_id: '',
          item_list: [{
            item_id: '',
            num: ''
          }]
        }],
        title: [],
        content: [],
        language: [],
        server_ids: [],
        content_Val: [{
          content: '',
          language: '',
          title: ''
        }],
        type: 'all_role',
        role_ids: '',
        is_recycle_mail: 1
      },
      dialogShow: false,
      whetherNot: [{
        label: '禁言',
        value: 1
      }, {
        label: '解封',
        value: 0
      }],
      rules: {
        role_id: [{
          required: true,
          message: "请输入角色ID",
          trigger: "blur"
        }],
        item_id: [{
          required: true,
          message: "请选择物品",
          trigger: "blur"
        }],
        num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }],
        title: [{
          required: true,
          message: "请输入标题",
          trigger: "blur"
        }],
        server_ids: [{
          required: true,
          message: "请选择服务器",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入邮件内容",
          trigger: "blur"
        }],
        language: [{
          required: true,
          message: "请选择语言",
          trigger: "blur"
        }]
      },
      issubmit: false
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      this.dialogShow = true;

      if (data) {// this.paramsData = d
      }
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    addtag: function addtag(data, index) {
      this.paramsData.content_Val[index].content = "".concat(this.paramsData.content_Val[index].content).concat(data);
    },
    addDomain: function addDomain() {
      this.paramsData.desc.push({
        role_id: '',
        item_list: [{
          item_id: '',
          num: ''
        }]
      });
    },
    removeDomain: function removeDomain(item) {
      var index = this.paramsData.desc.indexOf(item);

      if (index !== -1) {
        this.paramsData.desc.splice(index, 1);
      }
    },
    addContent: function addContent() {
      this.paramsData.content_Val.push({
        content: '',
        language: '',
        title: ''
      });
    },
    removeContent: function removeContent(item) {
      var index = this.paramsData.content_Val.indexOf(item);

      if (index !== -1) {
        this.paramsData.content_Val.splice(index, 1);
      }
    },
    addItem: function addItem(index) {
      this.paramsData.desc[index].item_list.push({
        item_id: '',
        num: ''
      });
    },
    removeItem: function removeItem(index, indexs) {
      this.paramsData.desc[index].item_list.splice(indexs, 1);
    },
    clearData: function clearData() {
      this.paramsData = {
        desc: [{
          role_id: '',
          item_list: [{
            item_id: '',
            num: ''
          }]
        }],
        title: [],
        content: [],
        language: [],
        server_ids: [],
        content_Val: [{
          content: '',
          language: '',
          title: ''
        }],
        type: 'all_role',
        role_ids: '',
        is_recycle_mail: 1
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.issubmit = true;
          var data = JSON.parse(JSON.stringify(_this.paramsData));
          var emailPramsData = {
            title: [],
            language: [],
            is_default: [],
            content: [],
            role_ids: '',
            server_ids: [],
            type: data.type,
            is_recycle_mail: data.is_recycle_mail
          };
          var recoveryPramsData = {
            desc: []
          };
          data.content_Val.forEach(function (item) {
            emailPramsData.title.push(item.title);
            emailPramsData.language.push(item.language);
            emailPramsData.content.push(item.content);
            emailPramsData.is_default.push(0);
          });
          data.desc.forEach(function (item) {
            emailPramsData.server_ids.push(item.server_id);
            delete item.server_id;
            item.item_list.map(function (items) {
              items.item_id = JSON.parse(items.item_id);
            });
          });
          emailPramsData.role_ids = data.desc.map(function (i) {
            return i.role_id;
          }).toString();
          recoveryPramsData.desc = data.desc;
          sendMail(_objectSpread(_objectSpread({}, emailPramsData), _this.prod)).then(function (res) {
            if (res.status == 200) {
              recycleItem(_objectSpread(_objectSpread(_objectSpread({}, recoveryPramsData), _this.prod), {}, {
                email_id: res.data.email_id
              })).then(function (res) {
                if (res.code == 200 || res.status == 200) {
                  _this.$message({
                    message: '操作成功',
                    type: 'success'
                  });

                  _this.dialogShow = false;

                  _this.$emit('change');
                }
              }).finally(function () {
                _this.issubmit = false;
              });
            }
          }).finally(function () {
            _this.issubmit = false;
          });
        }
      });
    }
  }
};