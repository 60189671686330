var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.Renewal },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function() {
                      _vm.list = []
                      _vm.getList(1)
                    }
                  }
                },
                [_vm._v("刷新")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addCode.open("add")
                    }
                  }
                },
                [_vm._v("生成礼包码")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "gen_id", label: "ID", "min-width": "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "code_type", label: "类型", "min-width": "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "gen_num", label: "数量", "min-width": "120" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "reward_num",
              label: "可领取次数",
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "奖品详情", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "viewText",
                        on: {
                          click: function($event) {
                            return _vm.viewCloose(scope.row.reward_str)
                          }
                        }
                      },
                      [_vm._v(" 查看 ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "", label: "是否特殊礼包", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.is_special == 0 ? "否" : "是") +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "", label: "生成时间", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.gen_time) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "end_time", label: "开始时间", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.extra_param !== "{}"
                            ? _vm.$times.timestampToTime(
                                JSON.parse(scope.row.extra_param).reg_begintime
                              )
                            : "/"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "end_time", label: "结束时间", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.extra_param !== "{}"
                            ? _vm.$times.timestampToTime(
                                JSON.parse(scope.row.extra_param).reg_endtime
                              )
                            : "/"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "dead_times", label: "过期时间", "min-width": "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "passwd_secret",
              label: "是否删除",
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(row.is_del == 1 ? "已删除" : "正常") + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.viewCode(scope.row)
                            }
                          }
                        },
                        [_vm._v("查看礼包码")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("addCode", {
        ref: "addCode",
        attrs: { prod: { is_prod: _vm.paramsData.is_prod } },
        on: { update: _vm.getList }
      }),
      _c("articles", { ref: "articles" }),
      _c("other", { ref: "other" }),
      _c(
        "el-drawer",
        {
          attrs: { title: "详情", visible: _vm.isPrize },
          on: {
            "update:visible": function($event) {
              _vm.isPrize = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.prizeList, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "item_id", label: "名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.articleList.findIndex(function(item) {
                                      return item.id == row.item_id
                                    }) > -1
                                      ? _vm.articleList[
                                          _vm.articleList.findIndex(function(
                                            item
                                          ) {
                                            return item.id == row.item_id
                                          })
                                        ].kind
                                      : row.item_id
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "num", label: "数量" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "expire", label: "时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(row.expire || "不限制") + " ")
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: { title: "详情", visible: _vm.isCode },
          on: {
            "update:visible": function($event) {
              _vm.isCode = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "codeListsTop" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "40px 20px 0", position: "relative" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.codeLists, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "code_body", label: "礼品码体" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "gen_id", label: "操作ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "draw_nums", label: "已领取次数" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total,
          "page-size": 10
        },
        on: { "current-change": _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }