import request from '@/utils/request'; // 获取当前版本号

export function getVersionManagement(data) {
  return request({
    url: '/user/getVersionManagement',
    method: 'get',
    params: data
  });
} // 修改当前版本号

export function updVersionManagement(data) {
  return request({
    url: '/user/updVersionManagement',
    method: 'post',
    params: data
  });
}