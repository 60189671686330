var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id == 1 ? "修改" : "封禁",
            visible: _vm.dialogShow,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "uids", label: "玩家ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      disabled: _vm.paramsData.is_abnormal_op,
                      placeholder: "1,2,3"
                    },
                    model: {
                      value: _vm.paramsData.uids,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "uids", $$v)
                      },
                      expression: "paramsData.uids"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: {
                    prop: "expire_time",
                    rules: !_vm.paramsData.isForever
                      ? _vm.rules.expire_time
                      : [],
                    label: "封号时间"
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      disabled: _vm.paramsData.isForever,
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "日期"
                    },
                    model: {
                      value: _vm.paramsData.expire_time,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "expire_time", $$v)
                      },
                      expression: "paramsData.expire_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "flex lodingItem-title first_box",
                  staticStyle: {
                    "margin-bottom": "20px",
                    width: "100%",
                    "align-items": "center"
                  }
                },
                [_vm._v(" 原因: ")]
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "100%" }, attrs: { prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 12 },
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.paramsData.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "reason", $$v)
                      },
                      expression: "paramsData.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }