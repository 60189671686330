var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSource()
                    }
                  }
                },
                [_vm._v("新增")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(1)
                    }
                  }
                },
                [_vm._v("刷新")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "60" }
              }),
              _c("el-table-column", {
                attrs: { prop: "game_reveal", label: "游戏" }
              }),
              _c("el-table-column", {
                attrs: { prop: "sensitive_word", label: "敏感词列表" }
              }),
              _c("el-table-column", {
                attrs: { prop: "timestamp", label: "日期" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "160",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "x-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleSource(scope.row)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _c("div", { staticClass: "controlsBtn-vertical" }),
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1331420832
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c("el-pagination", {
            attrs: {
              layout: "total,sizes, prev, pager, next",
              "current-page": _vm.searchData.page,
              total: _vm.total
            },
            on: {
              "current-change": _vm.searchClick,
              "size-change": _vm.sizeChange
            }
          })
        : _vm._e(),
      _c("addSentityword", {
        ref: "addWayConfig",
        attrs: { showing: _vm.showing },
        on: {
          changeShow: _vm.changeShow,
          refreshDataList: function($event) {
            return _vm.searchClick(_vm.searchData.page)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }