var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSource("t", 1)
                    }
                  }
                },
                [_vm._v("添加广告平台")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", disabled: _vm.sels.length == 0 },
                  on: {
                    click: function($event) {
                      return _vm.handleDelete(_vm.selsString)
                    }
                  }
                },
                [_vm._v("批量删除")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "adv_platform_name", label: "广告平台名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "adv_platform_mark", label: "广告平台标识" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0
                      ? _c("span", { staticClass: "statusTag1" }, [
                          _vm._v("禁用")
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("span", { staticClass: "statusTag2" }, [
                          _vm._v("正常")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDisable(scope.row.id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.status == 0 ? "启用" : "禁用")
                          )
                        ]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row.id, 2)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.getData }
      }),
      _vm.addvertisingPlatform
        ? _c("addvertisingPlatform", {
            ref: "addvertisingPlatform",
            attrs: { showing: _vm.showing },
            on: {
              refreshDataList: function($event) {
                return _vm.getData(_vm.searchData.page)
              },
              changeShow: _vm.changeShow
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }