var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "span",
      {
        directives: [{ name: "popover", rawName: "v-popover:pop", arg: "pop" }]
      },
      [
        _c(
          "el-tag",
          {
            staticClass: "icon-tag",
            attrs: { type: "info" },
            on: {
              click: function($event) {
                return _vm.copy(_vm.icon)
              }
            }
          },
          [_c("i", { staticClass: "iconStyle", class: "fa fa-" + _vm.icon })]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }