import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _defineProperty from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accRechByDay, accRechByDay_currency } from "@/api/dataAll/accRechgeRink";
import { mapState } from 'vuex';
export default {
  name: "accRechRankByDay",
  mixins: [tabulation],
  data: function data() {
    var _ref;

    return _ref = {
      more: true,
      tableHead: [],
      value2: [],
      searchData: {
        starttime: "",
        endtime: ""
      },
      tableData: []
    }, _defineProperty(_ref, "more", true), _defineProperty(_ref, "Header", [{
      label: '名称',
      prop: 'role_name'
    }, {
      label: '服务器ID',
      prop: 'server_id'
    }, {
      label: '当前等级',
      prop: 'level'
    }, {
      label: '战斗力',
      prop: 'fighting'
    }, {
      label: '充值金额',
      prop: 'upMoney'
    }, {
      label: '用户ID',
      prop: 'user_id'
    }, {
      label: '角色ID',
      prop: 'role_id'
    }, {
      label: 'VIP等级',
      prop: 'vip'
    }, {
      label: '最后登录时间',
      prop: 'last_login_time'
    }]), _ref;
  },
  created: function created() {},
  activated: function activated() {// if (this.searchData.game.indexOf(this.royalpirates) > -1) {
    //   this.Header = [
    //     {
    //       label: '名称',
    //       prop: 'role_name'
    //     }, {
    //       label: '服务器ID',
    //       prop: 'server_id'
    //     }, {
    //       label: '当前等级',
    //       prop: 'level'
    //     }, {
    //       label: '战斗力',
    //       prop: 'fighting'
    //     }, {
    //       label: '充值金额',
    //       prop: 'upMoney'
    //     }, {
    //       label: '用户ID',
    //       prop: 'user_id'
    //     }, {
    //       label: '角色ID',
    //       prop: 'role_id'
    //     }, {
    //       label: 'VIP等级',
    //       prop: 'vip'
    //     }, {
    //       label: '最后登录时间',
    //       prop: 'last_login_time'
    //     }
    //   ]
    // } else {
    //   this.Header = [
    //     {
    //       label: '用户ID',
    //       prop: 'user_id'
    //     }, {
    //       label: '充值金额',
    //       prop: 'upMoney'
    //     }
    //   ]
    // }
  },
  computed: _objectSpread({}, mapState('d2admin/serchSelect', ['royalpirates'])),
  methods: {
    rowClick: function rowClick(row, item, prop) {
      if (prop.indexOf('user_id') > -1 && row[prop]) {
        this.$router.replace({
          name: 'orderDisplay',
          params: {
            user_id: row[prop],
            date: item.label,
            type: 'D'
          }
        });
      }
    },
    searchClick: function searchClick(nums) {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.searchData.starttime = this.value2[0] || '';
      this.searchData.endtime = this.value2[1] || '';
      this.reqLoading = true;
      var reqFn = this.currencyClose ? accRechByDay_currency : accRechByDay;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this.resData = res.data;
          _this.tableHead = [];
          var data = [];
          var pageData = Object.keys(res.data).map(function (item) {
            return _objectSpread({
              week: item
            }, res.data[item]);
          });
          pageData.forEach(function (item, index) {
            _this.tableHead.push({
              label: item.week,
              list: _this.Header.map(function (i) {
                return {
                  label: i.label,
                  prop: "".concat(index + i.prop)
                };
              })
            });

            var IN = -1;

            var _loop = function _loop(i) {
              if (i != 'week') {
                item[i].forEach(function (k, ki) {
                  IN += 1;
                  var taskData = {};

                  _this.Header.forEach(function (j, ji) {
                    taskData[index + j.prop] = j.prop.indexOf('upMoney') > -1 ? _this.$utils.conversion(i.slice(2)) : k[j.prop] instanceof Array ? k[j.prop].toString() : k[j.prop];

                    if (k.recharge_amount && k.recharge_amount instanceof Array) {
                      if (j.prop.indexOf('upMoney') > -1) {
                        var list = [];
                        k.recharge_amount.map(function (s) {
                          list.push(_this.$utils.conversion(s));
                        });
                        taskData[index + j.prop] = list.toString();
                      }
                    }
                  });

                  data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
                });
              }
            };

            for (var i in item) {
              _loop(i);
            }
          });
          _this.tableData = data;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var _this2 = this;

      var pageData = this.resData;
      pageData = Object.keys(pageData).map(function (item) {
        return _objectSpread({
          week: item
        }, pageData[item]);
      });
      var columns = [];
      var data = [];
      var merges = [];
      var complete = {};
      pageData.map(function (item, index) {
        columns.push.apply(columns, _toConsumableArray(_this2.Header.map(function (i, ins) {
          return {
            label: ins == 0 ? item.week : '',
            prop: "".concat(index + i.prop)
          };
        })));

        _this2.Header.forEach(function (i) {
          complete[index + i.prop] = i.label;
        });

        var IN = -1;

        var _loop2 = function _loop2(i) {
          if (i != 'week') {
            item[i].forEach(function (k, ki) {
              IN += 1;
              var taskData = {};

              _this2.Header.forEach(function (j, ji) {
                taskData[index + j.prop] = j.prop.indexOf('upMoney') > -1 ? _this2.$utils.conversion(i.slice(2)) : k[j.prop] instanceof Array ? k[j.prop].toString() : k[j.prop];

                if (k.recharge_amount && k.recharge_amount instanceof Array) {
                  if (j.prop.indexOf('upMoney') > -1) {
                    var list = [];
                    k.recharge_amount.map(function (s) {
                      list.push(_this2.$utils.conversion(s));
                    });
                    taskData[index + j.prop] = list.toString();
                  }
                }
              });

              data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
            });
          }
        };

        for (var i in item) {
          _loop2(i);
        }
      }); // for (let n = 0; n < parseInt(columns.length / 2); n++) {
      //   merges.push([this.headerZh(n, 0), this.headerZh(n, 1)])
      // }

      data.unshift(complete);
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title,
        merges: merges
      });
    },
    headerZh: function headerZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      var num = k > 0 ? letterTable[k - 1] : '';

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[k <= 1 ? index * 2 : index];
        } else {
          num = num + letterTable[k <= 1 ? index * 2 + 1 : index + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2];
        } else {
          num = num + letterTable[index % 13 * 2 + 1];
        }
      }

      return num + '1';
    }
  }
};