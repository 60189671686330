//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import { gmToolNew } from "@/mixins/index";
export default {
  mixins: [gmToolNew],
  data: function data() {
    return {
      dialogShow: false,
      data: [],
      type: ''
    };
  },
  created: function created() {},
  methods: {
    init: function init(data) {
      this.data = JSON.parse(data);
      this.dialogShow = true;
    }
  }
};