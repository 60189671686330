var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.list, border: "" } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "expire",
              label: "操作类型",
              align: "center",
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(row.type == "1" ? "新增" : "修改") + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "admin_id",
              label: "操作员",
              align: "center",
              "min-width": "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "expire",
              label: "服务器名称",
              align: "center",
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.params.request.serverName) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serverID",
              label: "服务器ID",
              align: "center",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.params.request.serverID) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "areaID",
              label: "服务器所属大区",
              align: "center",
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.params.request.areaID) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serverIP",
              label: "服务器IP地址",
              align: "center",
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.params.request.serverIP) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serverPort",
              label: "服务器端口号",
              align: "center",
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.params.request.serverPort) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "config_state",
              label: "配置服务器状态",
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.configState.find(function(i) {
                            return (
                              i.value ==
                              (row.params.request.state ||
                                row.params.request.config_state)
                            )
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "new_tag", label: "是否是新服", "min-width": "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.params.request.new_tag == "true" ? "是" : "否"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_recomm",
              label: "推荐服务器标记",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.recommList.find(function(i) {
                            return i.value == row.params.request.is_recomm
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "push_msg",
              label: "服务器推送信息",
              "min-width": "190",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(
                        " " + _vm._s(row.params.request.push_msg || "/") + " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ver_max",
              label: "最高版本号",
              "min-width": "120",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(" " + _vm._s(row.params.request.ver_max) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ver_min",
              label: "最低版本号",
              "min-width": "120",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(" " + _vm._s(row.params.request.ver_min) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "OpenTime", label: "开服时间", "min-width": "260" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.params.request.OpenTime
                            ? _vm.$times.formDate(
                                row.params.request.OpenTime * 1000
                              )
                            : "/"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "timestamp",
              label: "提交时间",
              align: "center",
              "min-width": "180"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_timing",
              label: "是否定时",
              align: "center",
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.params.request.is_timing == 1
                              ? "定时任务"
                              : "普通任务"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "timestamp",
              label: "定时时间",
              align: "center",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.params.request.showTime
                              ? _vm.$times.timestampToTime(
                                  row.params.request.showTime / 1000
                                )
                              : "/"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "done_time",
              label: "定时执行时间",
              align: "center",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      _vm._v(" " + _vm._s(row.params.done_time || "/") + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "result",
              label: "状态",
              align: "center",
              fixed: "right",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1 " }, [
                      row.update_state == 0
                        ? _c("p", [_vm._v("未执行")])
                        : _vm._e(),
                      row.update_state == 1
                        ? _c("p", [_vm._v("已执行成功")])
                        : _vm._e(),
                      row.update_state == 2
                        ? _c("p", [_vm._v("已执行失败")])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total,
          page_size: _vm.paramsData.page_size
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }