var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticClass: "x-f" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.gameshow,
              expression: "gameshow"
            }
          ],
          staticClass: "item_box"
        },
        [
          _c("div", { staticClass: "box_header" }, [
            _c("div", { staticClass: "titles" }, [_vm._v("游戏")])
          ]),
          _c(
            "el-table",
            {
              staticClass: "table_box",
              attrs: {
                "highlight-current-row": "",
                data: _vm.gameList,
                height: "100"
              },
              on: {
                "row-click": function($event) {
                  return _vm.gameClick($event, false)
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "game_name", label: "游戏名", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "game_mark", label: "游戏标识", align: "center" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.plshow,
              expression: "plshow"
            }
          ],
          staticClass: "item_box"
        },
        [
          _c("div", { staticClass: "box_header" }, [
            _c("div", { staticClass: "titles" }, [_vm._v("平台")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canshow == false && _vm.pkgshow == false,
                    expression: "canshow == false && pkgshow == false"
                  }
                ]
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini", round: "" },
                    on: { click: _vm.addplatId }
                  },
                  [_vm._v("设置关联")]
                )
              ],
              1
            )
          ]),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table_box",
              attrs: {
                "highlight-current-row": "",
                height: "100",
                data: _vm.platformList
              },
              on: {
                "row-click": function($event) {
                  return _vm.platformClick($event, false)
                },
                "selection-change": _vm.selectionChange,
                toggleRowSelection: _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", label: "关联", width: "55" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform_name",
                  label: "平台名称",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform_mark",
                  label: "平台标识",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canshow,
              expression: "canshow"
            }
          ],
          staticClass: "item_box"
        },
        [
          _c("div", { staticClass: "box_header" }, [
            _c("div", { staticClass: "titles" }, [_vm._v("渠道")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pkgshow == false,
                    expression: "pkgshow == false"
                  }
                ]
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini", round: "" },
                    on: { click: _vm.addplatId }
                  },
                  [_vm._v("设置关联")]
                )
              ],
              1
            )
          ]),
          _c(
            "el-table",
            {
              ref: "multipleTable2",
              staticClass: "table_box",
              attrs: {
                "highlight-current-row": "",
                height: "100",
                data: _vm.channelList
              },
              on: {
                "row-click": function($event) {
                  return _vm.channelClick($event, false)
                },
                "selection-change": _vm.selectionChange1,
                toggleRowSelection: _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", label: "关联", width: "55" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channel_name",
                  label: "渠道名称",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channel_mark",
                  label: "渠道标识",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pkgshow,
              expression: "pkgshow"
            }
          ],
          staticClass: "item_box"
        },
        [
          _c("div", { staticClass: "box_header" }, [
            _c("div", { staticClass: "titles" }, [_vm._v("包名")]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "", size: "mini" },
                    on: { click: _vm.addplatId }
                  },
                  [_vm._v("设置关联")]
                )
              ],
              1
            )
          ]),
          _c(
            "el-table",
            {
              ref: "multipleTable3",
              staticClass: "table_box",
              attrs: {
                "highlight-current-row": "",
                data: _vm.pkgList,
                height: "100"
              },
              on: { "selection-change": _vm.selectionChange2 }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", label: "关联", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { prop: "pkg_name", label: "包名名称", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "pkg_mark", label: "包名标识", align: "center" }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }