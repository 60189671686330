import request from '@/utils/request'; // 获取可查询数据表

export function getTableList(data) {
  return request({
    url: '/data/getTableList',
    method: 'get',
    params: data
  });
} // 获取表字段

export function getTableConlumns(data) {
  return request({
    url: '/data/getTableConlumns',
    method: 'get',
    params: data
  });
} // 执行sql语句 

export function sqlExecutor(data) {
  return request({
    url: '/data/sqlExecutor',
    method: 'post',
    params: data
  });
} // 获取IP 

export function getIpData(data) {
  return request({
    url: '/data/getIpData',
    method: 'get',
    params: data
  });
} // 保存IP 

export function addIpWhite(data) {
  return request({
    url: '/data/addIpWhite',
    method: 'post',
    params: data
  });
}