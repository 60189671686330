import request from '@/utils/request'; // 获取货币列表

export function getCurrencyManagement(data) {
  return request({
    url: '/data/getCurrencyManagement',
    method: 'get',
    params: data
  });
}
export function addCurrencyManagement(data) {
  return request({
    url: '/data/addCurrencyManagement',
    method: 'post',
    params: data
  });
}
export function updCurrencyManagement(data) {
  return request({
    url: '/data/updCurrencyManagement',
    method: 'post',
    params: data
  });
}
export function delCurrencyManagement(data) {
  return request({
    url: '/data/delCurrencyManagement',
    method: 'post',
    params: data
  });
}