import request from '@/utils/request'; // 账号活跃度

export function accActivity(data) {
  return request({
    url: '/record/active_account',
    method: 'get',
    params: data
  });
} // 设备活跃度

export function advActivity(data) {
  return request({
    url: '/record/active_device',
    method: 'get',
    params: data
  });
} // 主线任务--账号

export function accMainlineTask(data) {
  return request({
    url: '/record/task_account',
    method: 'get',
    params: data
  });
} // 主线任务--设备

export function advMainlineTask(data) {
  return request({
    url: 'record/task_device',
    method: 'get',
    params: data
  });
} // 成就任务--账号

export function accachievementTask(data) {
  return request({
    url: '/record/achievement_account',
    method: 'get',
    params: data
  });
} // 成就任务--设备

export function advachievementTask(data) {
  return request({
    url: '/record/achievement_device',
    method: 'get',
    params: data
  });
} // 资源产销

export function resourcesOutput(data) {
  return request({
    url: '/record/resources_prosumer',
    method: 'get',
    params: data
  });
} // 产出资源列表/record/cost_resources

export function resources(data) {
  return request({
    url: '/record/resources',
    method: 'get',
    params: data
  });
} // 资源产出分析

export function outputAnalysis(data) {
  return request({
    url: '/record/resources_output',
    method: 'get',
    params: data
  });
} // 消耗资源列表

export function costresources(data) {
  return request({
    url: '/record/cost_resources',
    method: 'get',
    params: data
  });
} // 资源消耗分析

export function consumptionAnalysis(data) {
  return request({
    url: '/record/cost_resources_list',
    method: 'get',
    params: data
  });
} // 商店操作统计--账号

export function accStoreOperation(data) {
  return request({
    url: 'data/shop_account_operate',
    method: 'get',
    params: data
  });
} // 商店操作统计--设备

export function advStoreOperation(data) {
  return request({
    url: 'data/shop_device_operate',
    method: 'get',
    params: data
  });
} // 玩法--账号accplay

export function accplay(data) {
  return request({
    url: '/data/play_account',
    method: 'get',
    params: data
  });
} // 玩法--设备

export function deviceplay(data) {
  return request({
    url: '/data/play_device',
    method: 'get',
    params: data
  });
} // 日常任务分析--账号

export function accDailyTask(data) {
  return request({
    url: '/data/account_daily_task',
    method: 'get',
    params: data
  });
} // 日常任务分析--设备

export function advDailyTask(data) {
  return request({
    url: '/data/device_daily_task',
    method: 'get',
    params: data
  });
} // 吞广告点击次数

export function swallowClick(data) {
  return request({
    url: 'data/swallow_advertising',
    method: 'get',
    params: data
  });
} // 吞广告点击次数

export function swallowClickNumber(data) {
  return request({
    url: 'data/tun_click_pnum',
    method: 'get',
    params: data
  });
} // 吞玩家操作

export function swallowOperation(data) {
  return request({
    url: '/data/swallow_operate',
    method: 'get',
    params: data
  });
} // 广告渠道列表搜索

export function posterlist_all(data) {
  return request({
    url: 'poster/list_all',
    method: 'get',
    params: data
  });
} // 广告渠道数据展示/data/advert_channel

export function dataAll(data) {
  return request({
    url: '/data/advert_channel',
    method: 'get',
    params: data
  });
} // 获取国家列表

export function getCountry(data) {
  return request({
    url: '/data/country',
    method: 'get',
    params: data
  });
} // 广告原始数据列表

export function advertisingData(data) {
  return request({
    url: '/data/advertising_max_list',
    method: 'get',
    params: data
  });
} // 导出功能-广告原始数据列表

export function exportadvertising(data) {
  return request({
    url: '/data/export_advertising_max_list',
    method: 'get',
    params: data
  });
} // 吞玩家操作原始数据

export function swallowPrimitive(data) {
  return request({
    url: 'data/swallow_operate_list',
    method: 'get',
    params: data
  });
} // 吞玩家操作原始数据导出

export function exportswallow(data) {
  return request({
    url: '/data/export_swallow_operate_list',
    method: 'get',
    params: data
  });
}