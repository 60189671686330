import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { advachievementTask } from "@/api/dataAll/new";
export default {
  name: "advachievementTask",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: [],
      Dynamic: [],
      merges: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.guankaArr = [];

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      advachievementTask(this.searchData).then(function (res) {
        if (res.status == 200) {
          var objToTitle = function objToTitle(obj) {
            return Object.keys(obj).map(function (k) {
              return obj[k];
            });
          };

          var objToKey = function objToKey(obj) {
            return Object.keys(obj).map(function (k) {
              return k;
            });
          };

          var pageData = res.data;
          var columns = [{
            label: '任务ID',
            prop: 'taskId'
          }];
          var data = [];
          var complete = {
            taskId: ''
          };
          var merges = [];
          var listNum = 0;
          pageData.map(function (item, index) {
            var k = objToKey(item)[0];
            columns.push.apply(columns, [{
              label: k,
              prop: index + 'userid'
            }, {
              label: '',
              prop: index + 'upMoney'
            }]);
            complete[index + 'userid'] = '开始';
            complete[index + 'upMoney'] = '完成';
            var IN = -1;

            for (var i in item[k].task) {
              data.push({});
              IN += 1;
              listNum = IN;
              var taskData = {
                taskId: i
              };
              taskData[index + 'userid'] = item[k].task[i].begin;
              taskData[index + 'upMoney'] = item[k].task[i].end;
              data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
            }
          });

          for (var n = 0; n < parseInt((columns.length - 1) / 2); n++) {
            merges.push([_this.headerZh(n, 0), _this.headerZh(n, 1)]);
          }

          data.unshift(complete);
          data.splice(listNum + 2, data.length - listNum + 2);
          _this.tableData = data;
          _this.Dynamic = columns;
          _this.merges = merges;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      this.$export.excel({
        columns: this.Dynamic,
        data: this.tableData,
        title: this.$route.meta.title,
        merges: this.merges
      });
    },
    headerZh: function headerZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      var num = k > 0 ? letterTable[k - 1] : '';

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[(k <= 1 ? index * 2 : index) + 1];
        } else {
          num = num + letterTable[(k <= 1 ? index * 2 + 1 : index + 1) + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2 + 1];
        } else {
          num = num + letterTable[index % 13 * 2 + 1 + 1];
        }
      }

      return num + '1';
    }
  }
};