import request from '@/utils/request'; //   收入明细

export function income(data) {
  return request({
    url: '/data/income',
    method: 'get',
    params: data
  });
}
export function income_currency(data) {
  return request({
    url: '/data/income_currency',
    method: 'get',
    params: data
  });
}