import request from "@/utils/request"; // 趋势

export function dataOverView(data) {
  return request({
    url: "/data/dataOverView",
    method: "get",
    params: data
  });
} // 趋势图表

export function dataOverViewChat(data) {
  return request({
    url: "/data/dataOverViewChat",
    method: "get",
    params: data
  });
} // 昨日分时数据对比

export function data_compare(data) {
  return request({
    url: "/data/data_compare",
    method: "get",
    params: data
  });
} // 核心渠道效果

export function dataOverView_channelChat(data) {
  return request({
    url: "/data/dataOverView_channelChat",
    method: "get",
    params: data
  });
}