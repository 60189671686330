//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  data: function data() {
    return {
      date: [],
      dialogShow: false,
      main_content: [],
      type: ''
    };
  },
  created: function created() {},
  methods: {
    open: function open(data, type) {
      this.main_content = data.main_content ? data.main_content : [{
        content: data.content,
        language: data.language,
        title: data.title
      }];
      this.dialogShow = true;
    }
  }
};