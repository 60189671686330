import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { recharge_total_rank_currency } from "@/api/dataAll/dataAll";
export default {
  name: "topupLeaderboard",
  mixins: [tabulation],
  data: function data() {
    return {
      tableHead: [],
      value2: [],
      tableData: [],
      reqLoading: false,
      Header: [{
        label: '名称',
        prop: 'role_name'
      }, {
        label: '服务器ID',
        prop: 'server_id'
      }, {
        label: '当前等级',
        prop: 'level'
      }, {
        label: '战斗力',
        prop: 'fighting'
      }, {
        label: '充值金额',
        prop: 'upMoney'
      }, {
        label: '用户ID',
        prop: 'user_id'
      }, {
        label: '角色ID',
        prop: 'role_id'
      }, {
        label: 'VIP等级',
        prop: 'vip'
      }, {
        label: '最后登录时间',
        prop: 'last_login_time'
      }]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.reqLoading = true;
      recharge_total_rank_currency(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          (function () {
            _this.resData = res.data;
            _this.tableHead = [];
            var data = [];

            var pageData = _objectSpread({}, res.data);

            var _loop = function _loop(k) {
              pageData[k].forEach(function (i) {
                var taskData = {};

                _this.Header.forEach(function (j, ji) {
                  taskData[j.prop] = j.prop.indexOf('upMoney') > -1 ? _this.$utils.conversion(k.slice(2)) : i[j.prop] instanceof Array ? i[j.prop].toString() : i[j.prop];

                  if (i.recharge_amount && i.recharge_amount instanceof Array) {
                    if (j.prop.indexOf('upMoney') > -1) {
                      var list = [];
                      i.recharge_amount.map(function (s) {
                        list.push(_this.$utils.conversion(s));
                      });
                      taskData[j.prop] = list.toString();
                    }
                  }
                });

                data.push(taskData);
              });
            };

            for (var k in pageData) {
              _loop(k);
            }

            _this.tableData = data;
          })();
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      this.$export.excel({
        columns: this.Header,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};