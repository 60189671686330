import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
export default {
  // 用于列表显示转换
  dataConversion: function dataConversion(list, conversion) {
    list.map(function (item) {
      conversion.forEach(function (items) {
        if (items !== "ad_channel" && items !== "ad_placement") {
          item[items + "_reveal"] = item[items + "_name"] ? item[items + "_name"] : item[items];
        } else {
          if (items == "ad_channel") {
            item[items + "_reveal"] = item[items + "_name"] ? item[items + "_name"] : item["adv_platform_name"] || item[items];
          } else if (items == "ad_placement") {
            item[items + "_reveal"] = item[items + "_name"] ? item[items + "_name"] : item["adv_place_name"] || item[items];
          }
        }
      });
    });
    return list;
  },

  /**
   * 用于列表动态宽度
   * @param {*} strthe  需要转换的数据
   * @param {*} type   是否有排序
   * @returns
   */
  flexColumnWidth: function flexColumnWidth(strthe) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var num = 0; //数字   分配

    var cnnum = 0; //中文字符

    var space = 0; //空格

    var caps = 0; //大写字符个数

    var little = 0; //小写字符个数

    var other = 0; //其他字符个数
    // let s = disassemble ? strthe : strthe.split(".")
    // let sList = [0,0]
    // s[0] = s[0] == '日期' ? '2020-01-01' : s[0]

    var _iterator = _createForOfIteratorHelper(strthe),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var char1 = _step.value;

        if (char1 >= "A" && char1 <= "Z") {
          // 大写
          caps++;
        } else if (char1 >= "a" && char1 <= "z") {
          // 小写字母
          little++;
        } else if (char1 >= "\u4E00" && char1 <= "\u9FA5") {
          // 中文
          cnnum++;
        } else if (!isNaN(Number(char1))) {
          // 数字
          num++;
        } else {
          // 其他种类字符，为字符分配8个单位宽度
          other++;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    var sListw = 0;
    sListw = num * 10 + cnnum * 16 + space * 3 + caps * 12 + little * 9 + other * 4;

    if (type) {
      sListw += 18;
    }

    sListw += 36;
    return sListw;
  },
  // 统一单位转换
  conversion: function conversion(num) {
    return Number(num) / 100;
  },
  utilTofixd: function utilTofixd(n) {
    if (n > 20 || n < 0) {
      throw new RangeError("toFixed() digits argument must be between 0 and 20");
    }

    var number = this;

    if (isNaN(number) || number >= Math.pow(10, 21)) {
      return number.toString();
    }

    if (typeof n == "undefined" || n == 0) {
      return Math.round(number).toString();
    }

    var result = number.toString();
    var arr = result.split("."); // 整数的情况

    if (arr.length < 2) {
      result += ".";

      for (var i = 0; i < n; i += 1) {
        result += "0";
      }

      return result;
    }

    var integer = arr[0];
    var decimal = arr[1];

    if (decimal.length == n) {
      return result;
    }

    if (decimal.length < n) {
      for (var _i = 0; _i < n - decimal.length; _i += 1) {
        result += "0";
      }

      return result;
    }

    result = integer + "." + decimal.substr(0, n);
    var last = decimal.substr(n, 1); // 四舍五入，转换为整数再处理，避免浮点数精度的损失

    if (parseInt(last, 10) >= 5) {
      var x = Math.pow(10, n);
      result = (Math.round(parseFloat(result) * x) + 1) / x;
      result = result.toFixed(n);
    }

    return result;
  },
  textRepl: function textRepl(str) {
    var reple = process.env.VUE_APP_IMAGEURLRPLE;
    var url = process.env.VUE_APP_IMAGEURL;
    var newstr = str.replaceAll(reple, url + reple);
    return newstr;
  },
  imgTrow: function imgTrow(str) {
    var url = process.env.VUE_APP_IMAGEURL;
    return url + str;
  },
  debounce: function debounce(func, wait, immediate) {
    var timer, result;

    var later = function later() {
      clearTimeout(timer);
      timer = null;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      result = func.apply(null, args);
    };

    return function () {
      // 因为防抖是响应最新一次操作，所以清空之前的定时器
      if (timer) clearTimeout(timer); // 如果配置了 immediate = true

      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      if (immediate) {
        // 没有定时函数等待执行才可以立即执行
        var callNow = !timer; // 是否立即执行，并不影响设定时器的延迟执行

        timer = setTimeout.apply(void 0, [later, wait].concat(args));

        if (callNow) {
          result = func.apply(null, args);
        }
      } else {
        timer = setTimeout.apply(void 0, [later, wait].concat(args));
      }

      return result;
    };
  },
  randomString: function randomString(e) {
    e = e || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    var a = t.length;
    var n = "";

    for (var i = 0; i < e; i++) {
      n += t.charAt(Math.floor(Math.random() * a));
    }

    return n;
  }
};