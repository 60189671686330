//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "threeLoginConfig",
  data: function data() {
    return {
      value: '',
      searchgames: [],
      tableData: []
    };
  },
  created: function created() {},
  methods: {}
};