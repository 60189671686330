var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
          on: { select: _vm.modelChange }
        },
        [
          _c("el-menu-item", { attrs: { index: "appMg" } }, [
            _vm._v("广告参数")
          ]),
          _c("el-menu-item", { attrs: { index: "gameMg" } }, [
            _vm._v("游戏参数")
          ])
        ],
        1
      ),
      _c("gameMg", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "gameMg",
            expression: "activeIndex == 'gameMg'"
          }
        ],
        ref: "gameMg"
      }),
      _c("appMg", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "appMg",
            expression: "activeIndex == 'appMg'"
          }
        ],
        ref: "appMg"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }