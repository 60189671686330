var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id !== "" ? "修改" : "新增",
            visible: _vm.dialogShow
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData(true)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "event_name", label: "事件名称" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: { placeholder: "请输入货币名称" },
                    model: {
                      value: _vm.paramsData.event_name,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "event_name", $$v)
                      },
                      expression: "paramsData.event_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "event", label: "事件标识" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: { placeholder: "请输入事件标识" },
                    model: {
                      value: _vm.paramsData.event,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "event", $$v)
                      },
                      expression: "paramsData.event"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }