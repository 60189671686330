var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleSource("t")
                }
              }
            },
            [_vm._v("添加广告渠道")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "margin-top",
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "广告渠道名称" }
          }),
          _c("el-table-column", { attrs: { prop: "mark", label: "广告标识" } }),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0
                      ? _c("span", { staticClass: "statusTag1" }, [
                          _vm._v("禁用")
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("span", { staticClass: "statusTag2" }, [
                          _vm._v("正常")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      scope.row.status == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.handleDisable(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("启用")]
                          )
                        : _vm._e(),
                      scope.row.status == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.handleDisable(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("禁用")]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.getData }
      }),
      _vm.adChannels
        ? _c("adChannels", {
            ref: "adChannels",
            attrs: { showing: _vm.showing },
            on: {
              changeShow: _vm.changeShow,
              refreshDataList: function($event) {
                return _vm.getData(_vm.searchData.page)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }