import "core-js/modules/es.number.constructor";
import '@/styles/sticky-table-header.scss';
export default {
  bind: function bind(el, binding) {
    el.setAttribute('is-sticky', true);
    updateStickyTop(el, binding);
  },
  update: function update(el, binding) {
    updateStickyTop(el, binding);
  }
};

var updateStickyTop = function updateStickyTop(el, binding) {
  var value = binding.value,
      oldValue = binding.oldValue;
  if (value === oldValue) return;
  var top = Number(value);

  if (!isNaN(top)) {
    el.style.setProperty('--sticky-top', "".concat(top, "px"));
  }
};