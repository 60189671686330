import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addVersion, updVersion } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
import { facebookMetaList } from '@/api/management/facebookData';
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var version = function version(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入'));
      } else if (/^\d+\.\d+\.\d+$/.test(value)) {
        callback();
      } else {
        callback(new Error('版本号不正确'));
      }
    };

    return {
      paramsData: {
        id: '',
        version: '',
        channel_id: '',
        introduction: []
      },
      dialogShow: false,
      rules: {
        version: [{
          required: true,
          validator: version,
          trigger: "blur"
        }],
        // version: [{ required: true, message: "请输入", trigger: "blur" }],
        language: [{
          required: true,
          message: "请选择语言",
          trigger: "blur"
        }],
        introduction: [{
          required: true,
          message: "请添加语言内容",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      this.dialogShow = true;

      if (data) {
        var d = JSON.parse(JSON.stringify(data));
        d.channel_id = d.channel_id == 0 ? '' : d.channel_id;
        this.paramsData = _objectSpread({}, d);
      }
    },
    addtag: function addtag(data, index) {
      this.paramsData.introduction[index].content = "".concat(this.paramsData.introduction[index].content).concat(data);
    },
    addContent: function addContent() {
      this.paramsData.introduction.push({
        content: '',
        lang: ''
      });
    },
    removeContent: function removeContent(item) {
      var index = this.paramsData.introduction.indexOf(item);

      if (index !== -1) {
        this.paramsData.introduction.splice(index, 1);
      }
    },
    clearData: function clearData() {
      var isClearId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.paramsData = {
        id: isClearId ? '' : this.paramsData.id,
        version: '',
        channel_id: '',
        introduction: []
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this.paramsData));
          data.introduction = JSON.stringify(data.introduction);
          data.channel_id = data.channel_id == '' ? 0 : data.channel_id;
          var reqFn = _this.paramsData.id ? updVersion : addVersion;
          reqFn(_objectSpread(_objectSpread({}, data), _this.prod)).then(function (res) {
            if (res.code == 200) {
              _this.$message({
                message: '操作成功',
                type: 'success'
              });

              _this.dialogShow = false;

              _this.$emit('change');
            }
          });
        }
      });
    }
  }
};