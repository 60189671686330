import "core-js/modules/es.array.map";
import "core-js/modules/es.number.to-fixed";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addReplenishment from "./components/addReplenishment.vue";
import { tabulation, Kamitaku } from "@/mixins/index";
import { loginRechargeData, loginRechargeData_currency, replenishmentSelect } from "@/api/kamitaku/kamitaku";
export default {
  name: "kamitakuTopup",
  mixins: [tabulation, Kamitaku],
  components: {
    addReplenishment: addReplenishment
  },
  data: function data() {
    return {
      more: true,
      reqFn: loginRechargeData,
      searchData: {},
      Replenishment: [],
      drawer: false,
      ReplenishmentColumn: [{
        label: '游戏',
        prop: 'game',
        width: 160
      }, {
        label: '平台',
        prop: 'platform',
        width: 120
      }, {
        label: '渠道',
        prop: 'channel',
        width: 120
      }, {
        label: '包名',
        prop: 'pkg',
        width: 200
      }, {
        label: '服务器ID',
        prop: 'server_id',
        width: 120
      }, {
        label: '设备ID',
        prop: 'device_id',
        width: 160
      }, {
        label: '账号ID',
        prop: 'user_id',
        width: 100
      }, {
        label: '角色ID',
        prop: 'role_id',
        width: 100
      }, {
        label: '订单号',
        prop: 'order_id',
        width: 160
      }, {
        label: '等级',
        prop: 'level',
        width: 80
      }, {
        label: '金额(分)',
        prop: 'amount',
        width: 100
      }, {
        label: '货币类型',
        prop: 'currency',
        width: 100
      }, {
        label: '商品ID',
        prop: 'product_id',
        width: 160
      }, {
        label: '商品名称',
        prop: 'product_name',
        width: 160
      }, {
        label: '充值用户IP',
        prop: 'ip',
        width: 160
      }, {
        label: '充值时间',
        prop: 'timestamp',
        width: 180
      }]
    };
  },
  created: function created() {},
  methods: {
    getListnew: function getListnew() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.reqFn = this.currencyClose ? loginRechargeData_currency : loginRechargeData;
      this.searchData = _objectSpread(_objectSpread({}, this.searchData), this.defaultData());
      this.getList(function (res) {
        var totalNum = 0;
        var totalMoney = 0;

        for (var key in _this.tabData) {
          _this.tabData[key].recharge_num = _this.$utils.conversion(_this.tabData[key].recharge_num);
          totalNum += _this.tabData[key].login_num;
          totalMoney += _this.tabData[key].recharge_num;

          _this.tableData.push(_objectSpread({
            date: key
          }, _this.tabData[key]));
        }

        _this.tableData.push({
          date: '总计',
          login_num: totalNum,
          recharge_num: totalMoney.toFixed(2)
        });
      });
    },
    viewRecord: function viewRecord(scope) {
      var _this2 = this;

      var data = {
        game: this.searchData.game,
        platform: this.searchData.platform,
        channel: this.searchData.channel,
        game_id: this.searchData.game_id,
        pkg: this.searchData.pkg,
        country: this.searchData.country
      };
      replenishmentSelect(_objectSpread({
        date: scope.row.date
      }, data)).then(function (res) {
        if (res.status == 200) {
          if (res.data.length) {
            res.data.map(function (i) {
              i.timestamp = _this2.$times.timestampToTime(i.timestamp, 'YY-MM-DD H');
            });
            _this2.Replenishment = res.data;
            _this2.drawer = true;
          } else {
            _this2.$message({
              message: '无数据'
            });
          }
        }
      });
    },
    exportTab: function exportTab() {
      var columns = [{
        label: '时间',
        prop: 'date'
      }, {
        label: '活跃用户',
        prop: 'login_num'
      }, {
        label: '总付费金额',
        prop: 'recharge_num'
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    },
    exportReplenishment: function exportReplenishment() {
      var columns = this.ReplenishmentColumn;
      var data = this.Replenishment;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};