import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import util from '@/libs/util';
export default {
  props: {
    limit: Number,
    disabled: Boolean,
    filesname: {
      type: String,
      default: function _default() {
        return 'pic';
      }
    },
    fileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      imageUrl: '',
      headers: {
        token: '',
        'Request-Url': ''
      },
      imgUrl: [],
      action: process.env.VUE_APP_API + "/pay/upload_img",
      // 上传的视频服务器地址
      showUpload: false,
      //控制limit最大值之后 关闭上传按钮
      dialogVisible: false //查看图片弹出框

    };
  },
  watch: {
    fileList: function fileList(value) {
      if (value.length) {
        this.showUpload = true;
      } else {
        this.showUpload = false;
      }
    }
  },
  created: function created() {
    this.headers.Authorization = 'Bearer ' + util.cookies.get('token');
    this.headers['Request-Url'] = this.$route.path;
  },
  methods: {
    remove: function remove(file, fileList) {
      this.$emit('change', fileList);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.imageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload: function beforeUpload(file) {
      var isUp = true;
      var size = file.size / 1024 / 1024 / 2;

      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        isUp = false;
        this.$notify.warning({
          title: "警告",
          message: "请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片"
        });
      } else if (size > 100) {
        isUp = false;
        this.$notify.warning({
          title: "警告",
          message: "图片大小必须小于100M"
        });
      }

      return isUp;
    },
    uploacd: function uploacd() {},
    upSuccess: function upSuccess(response, file, fileList) {
      var _this = this;

      var list = [];

      if (response.status == 200) {
        if (fileList.length) {
          fileList.map(function (item) {
            list.push({
              name: item.name,
              url: _this.$utils.imgTrow(item.response.data.image)
            });
          });
        }
      } else {
        this.$message.error('上传失败,请重试');
      }

      this.$emit('change', list);
    },
    //文件超出个数限制时的函数
    handleExceed: function handleExceed(files, fileList) {
      this.$message.info("\u6700\u591A\u53EA\u5141\u8BB8\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247"));
    },
    // 数据回显示
    echos: function echos(data) {
      var _this2 = this;

      data = data || '';
      var list = [];

      if (Array.isArray(data)) {
        list = data;
      } else {
        list = data.split(',');
      }

      list.map(function (item) {
        _this2.imgUrl.push(item);

        _this2.fileList.push({
          url: item
        });
      });
    },
    // 清空上传的文件
    emptyFeil: function emptyFeil() {
      var _this3 = this;

      this.$refs.upload.clearFiles();
      setTimeout(function () {
        _this3.imgUrl = [];
        _this3.fileList = [];
        _this3.showUpload = false;
      }, 1000);
    }
  }
};