import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
import echartMixins from "@/utils/resizeMixins";
export default {
  props: {
    orderNumList: {
      type: Object,
      required: true
    },
    height: {
      type: [String, Number],
      required: 0
    }
  },
  data: function data() {
    return {
      chart: null,
      orderData: {},
      categoryData: [],
      //横坐标
      rateData: [],
      //
      barData: [] //订单数量

    };
  },
  mixins: [echartMixins],
  created: function created() {
    this.orderData = this.orderNumList;

    for (var k in this.orderData) {
      this.categoryData.push(k);
      this.barData.push(this.orderData[k]);
    }
  },
  mounted: function mounted() {
    this.draw();
  },
  methods: {
    draw: function draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreRight2Chart2"), null, this.setEchartWH); //  ----------------------------------------------------------------

      var category = this.categoryData;
      var barData = this.barData;
      var option = {
        title: {
          text: "",
          x: "center",
          y: 10,
          textStyle: {
            color: "#B4B4B4",
            fontSize: 16,
            fontWeight: "normal"
          }
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            }
          }
        },
        legend: {
          data: ["订单数量"],
          textStyle: {
            color: "#B4B4B4"
          }
        },
        grid: {
          width: 'auto',
          height: 'auto',
          left: '0%',
          //默认10%
          right: '3%',
          //默认10%
          bottom: '0',
          //默认60
          containLabel: true
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [{
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          },
          interval: 50,
          axisLabel: {
            formatter: "{value} "
          }
        }, {
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisLabel: {
            formatter: "{value} "
          }
        }],
        series: [// {
        //   name: "贯通率",
        //   type: "line",
        //   smooth: true,
        //   showAllSymbol: true,
        //   symbol: "emptyCircle",
        //   symbolSize: 8,
        //   yAxisIndex: 1,
        //   itemStyle: {
        //     normal: {
        //       color: "#F02FC2",
        //     },
        //   },
        //   data: this.rateData,
        // },
        {
          name: "订单数量",
          type: "bar",
          barWidth: 10,
          itemStyle: {
            normal: {
              barBorderRadius: 5,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#956FD4"
              }, {
                offset: 1,
                color: "#3EACE5"
              }])
            }
          },
          data: barData
        }]
      };
      this.chart.setOption(option);
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  }
};