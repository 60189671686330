import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addIpWhite, getTableList, getTableConlumns, sqlExecutor, getIpData } from "@/api/dataAll/SQLinquire";
import { serchSelect } from "@/mixins/index";
export default {
  name: "SQLinquire",
  mixins: [serchSelect],
  data: function data() {
    return {
      searchData: {
        table: ''
      },
      ipValue: "",
      activeIndex: "1",
      dataList: [{
        value: ""
      }],
      conditionsList: [],
      conditionsDefult: [],
      groupList: [],
      orderbyList: [],
      TableList: [],
      fieldsList: [],
      tableData: [],
      Dynamic: [],
      pageSize: 10,
      currentPage: 1,
      tableCopyTableList: [],
      isTable: false,
      isSearch: false
    };
  },
  activated: function activated() {
    this.getIp();
    this.conditionsDefult = [{
      value1: "game",
      value2: "=",
      value3: this.rawData.game_mark
    }];
  },
  created: function created() {},
  methods: {
    addIp: function addIp() {
      var _this = this;

      if (!this.ipValue) {
        this.$message.error("请输入IP");
        return;
      } // let str = this.ipValue.replaceAll(",\n", ",");


      var str = this.ipValue.split(",");
      var list = [];
      str.forEach(function (i) {
        if (i) {
          list.push(i);
        }
      });
      addIpWhite(_objectSpread(_objectSpread({}, this.searchData), {}, {
        ip: list
      })).then(function (res) {
        if (res.status == 200) {
          _this.$message.success("保存成功");

          _this.handover("2");
        }
      });
    },
    tableClick: function tableClick() {
      var _this2 = this;

      if (!this.searchData.table) {
        this.$message.error("请选择表");
        return;
      }

      this.dataList = this.fieldsList.findIndex(function (i) {
        return i.isSelection;
      }) > -1 ? [] : [{
        value: ''
      }];
      this.fieldsList.map(function (i) {
        if (i.isSelection) {
          _this2.addData('dataList', {
            value: i.label
          });
        }
      });
      this.handover("3", true);
    },
    keySubmit: function keySubmit() {
      if (!this.dataList[0].value) {
        this.$message.error("请输入表字段");
        return;
      }

      if (!this.conditionsList[0].value1 || !this.conditionsList[0].value2 || !this.conditionsList[0].value3) {
        this.$message.error("请输入查询条件");
        return;
      }

      this.getsqlExecutor();
    },
    getIp: function getIp() {
      var _this3 = this;

      getIpData(_objectSpread({}, this.searchData)).then(function (res) {
        if (res.status == 200) {
          var str = res.data.toString(); // this.ipValue = str.replaceAll(",", `,\n`);

          _this3.ipValue = str;
        }
      });
    },
    fieldsChange: function fieldsChange(index) {
      this.fieldsList[index].isSelection = !this.fieldsList[index].isSelection;
    },
    tabChange: function tabChange(e) {
      var _this4 = this;

      if (e) {
        this.searchData.table = e;
        getTableConlumns(_objectSpread({}, this.searchData)).then(function (res) {
          if (res.status == 200) {
            var list = [];
            res.data.map(function (i) {
              list.push({
                label: i,
                isSelection: false
              });
            });
            _this4.fieldsList = [].concat(list);
          }
        });
      }
    },
    Returnlevel: function Returnlevel() {
      this.activeIndex = '2';
    },
    handover: function handover(index) {
      var isTable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var isSearch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var isClear = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      this.activeIndex = "".concat(index);
      this.isTable = index == '3' ? true : isTable;
      this.isSearch = isSearch;

      if (index == "1" || index == "2") {
        this.resetData();
      }

      if (index == "2") {
        this.fieldsList = [];
        this.searchData.table = '';
        this.getTable();
      }

      this.tableData = [];
    },
    getTable: function getTable() {
      var _this5 = this;

      this.TableList = [];
      getTableList(_objectSpread({}, this.searchData)).then(function (res) {
        if (res.status == 200) {
          _this5.resetData();

          for (var i in res.data) {
            _this5.TableList.push({
              label: res.data[i],
              value: i
            });
          }
        } else {
          _this5.searchData.table = '';
          _this5.activeIndex = '1';
        }
      });
    },
    getsqlExecutor: function getsqlExecutor() {
      var _this6 = this;

      var list = [];
      this.dataList.map(function (i) {
        if (i.value) {
          list.push(i);
        }
      });
      var groupList = [];
      this.groupList.map(function (i) {
        if (i.value) {
          groupList.push(i.value);
        }
      });

      var data = _objectSpread(_objectSpread({}, this.searchData), {}, {
        game: this.rawData.game_mark,
        columns: list.map(function (i) {
          return i.value;
        }),
        conditions: [],
        group_conditions: groupList,
        order_by_conditions: []
      });

      this.conditionsList.forEach(function (item) {
        if (item.value1 && item.value2 && item.value3) {
          var _list = [];

          for (var i in item) {
            _list.push(item[i]);
          }

          data.conditions.push(_list);
        }
      });

      if (data.conditions.filter(function (i) {
        return i[0] == 'game';
      }).length > 1) {
        this.$message.error('查询条件 game 不能重复');
        return;
      }

      this.handover("4", true, true);
      this.orderbyList.forEach(function (item) {
        if (item.value1 && item.value2) {
          var _list2 = [];

          for (var i in item) {
            _list2.push(item[i]);
          }

          data.order_by_conditions.push(_list2);
        }
      });
      data.order_by_conditions = !data.order_by_conditions[0] ? [] : data.order_by_conditions;
      sqlExecutor(data).then(function (res) {
        if (res.status == 200) {
          _this6.Dynamic = [];

          if (res.data.length) {
            for (var k in res.data[0]) {
              _this6.Dynamic.push({
                label: k,
                value: k
              });
            }
          }

          _this6.currentPage = 1;
          _this6.tableCopyTableList = JSON.parse(JSON.stringify(res.data));
          _this6.tableData = _this6.currentChangePage(_this6.pageSize, _this6.currentPage);
        }
      });
    },
    resetData: function resetData() {
      this.dataList = [{
        value: ""
      }];
      this.conditionsList = _toConsumableArray(this.conditionsDefult);
      this.groupList = [];
      this.orderbyList = [];
    },
    addData: function addData(type, obj) {
      this[type].push(obj);
    },
    removeDataItem: function removeDataItem(item, type) {
      var index = this[type].indexOf(item);

      if (index !== -1) {
        this[type].splice(index, 1);
      }

      if (type == 'conditionsList' && this.conditionsList.length == 1) {
        this.conditionsList[0].value1 = this.conditionsDefult[0].value1;
        this.conditionsList[0].value2 = this.conditionsDefult[0].value2;
        this.conditionsList[0].value3 = this.conditionsDefult[0].value3;
      }
    },
    exportdata: function exportdata() {
      var columns = [];

      for (var k in this.tableCopyTableList[0]) {
        columns.push({
          label: k,
          prop: k
        });
      }

      this.$export.excel({
        columns: columns,
        data: this.tableCopyTableList,
        title: this.$route.meta.title
      });
    },
    // 页数改变事件
    handleSizeChange: function handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.tableData = this.currentChangePage(pageSize, this.currentPage);
    },
    // 页码改变切换
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.tableData = this.currentChangePage(this.pageSize, currentPage);
    },
    // 分页方法
    currentChangePage: function currentChangePage(size, current) {
      var tablePush = [];
      this.tableCopyTableList.forEach(function (item, index) {
        if (size * (current - 1) <= index && index <= size * current - 1) {
          tablePush.push(item);
        }
      });
      return tablePush;
    }
  }
};