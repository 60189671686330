import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { bootDevices } from "@/api/dataAll/bootDevice";
export default {
  name: "bootDevice",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        activation_time: ""
      },
      tableData: [],
      guanka: [],
      Dynamic: [],
      intReq: ["getCountrys"]
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      var objToTitle = function objToTitle(obj) {
        return Object.keys(obj).map(function (k) {
          return obj[k];
        });
      };

      var objToKey = function objToKey(obj) {
        return Object.keys(obj).map(function (k) {
          return k;
        });
      };

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      bootDevices(this.searchData).then(function (res) {
        if (res.status == 200) {
          var pageData = res.data;
          var columns = [{
            label: "新手引导",
            prop: "start"
          }];
          var data = [];
          var complete = {
            start: ""
          };
          var number = {
            start: "当日新增人数"
          };
          var listNum = 0;
          pageData.map(function (item, index) {
            var k = objToKey(item)[0];
            columns.push.apply(columns, [{
              label: k,
              prop: index + "date"
            }, {
              label: "",
              prop: index + "complete"
            }, {
              label: "",
              prop: index + "runOff"
            }]);
            number[index + "date"] = +item[k].new_device_num;
            complete[index + "date"] = "开始人数";
            complete[index + "complete"] = "完成人数";
            complete[index + "runOff"] = "流失率";
            var IN = -1;

            for (var i in item[k].guanka) {
              data.push({});
              IN += 1;
              listNum = IN;
              var j = i.split(":");
              var taskData = {
                start: j[0]
              };
              taskData[index + "date"] = item[k].guanka[i].begin_num || 0;
              taskData[index + "complete"] = item[k].guanka[i].end_num || 0;
              taskData[index + "runOff"] = item[k].guanka[i].run_off || "0%";
              data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
            }
          });
          data.unshift(complete);
          data.unshift(number);
          data.splice(listNum + 3, data.length - listNum + 3);
          _this.Dynamic = columns;
          _this.tableData = data;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var columns = this.Dynamic;
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};