var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "配置详情",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData()
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "name", label: "原始数据" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    staticStyle: { height: "200px" },
                    attrs: {
                      type: "textarea",
                      disabled: "",
                      placeholder: "请输入名称"
                    },
                    model: {
                      value: _vm.paramsData.text1,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "text1", $$v)
                      },
                      expression: "paramsData.text1"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "amount_limit", label: "对比数据" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    staticStyle: { height: "200px" },
                    attrs: { type: "textarea", placeholder: "请输入名称" },
                    model: {
                      value: _vm.paramsData.text2,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "text2", $$v)
                      },
                      expression: "paramsData.text2"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.iscontentDom,
                expression: "iscontentDom"
              }
            ],
            attrs: { id: "content" }
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v("对比")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }