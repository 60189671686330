import request from '@/utils/request'; //   获取列表信息

export function getGameList(data) {
  return request({
    url: '/datum/game_classify',
    method: 'get',
    params: data
  });
} // 获取所有信息分类

export function getallClassify(data) {
  return request({
    url: '/datum/classify_all',
    method: 'get',
    params: data
  });
} // 获取弹窗反显

export function returnMsg(data) {
  return request({
    url: '/datum/up_game_classify',
    method: 'get',
    params: data
  });
} //编辑信息分类

export function udataMsg(data) {
  return request({
    url: '/datum/up_game_classify',
    method: 'post',
    params: data
  });
} //新增信息分类

export function addMsg(data) {
  return request({
    url: '/datum/add_game_classify',
    method: 'post',
    params: data
  });
} //删除信息分类

export function deledMsg(data) {
  return request({
    url: '/datum/del_game_classify',
    method: 'get',
    params: data
  });
}