var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "新增主播",
            visible: _vm.showDialog,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-title" }, [
                _vm._v("主播名称")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入主播名称" },
                    model: {
                      value: _vm.paramsData.host_name,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "host_name", $$v)
                      },
                      expression: "paramsData.host_name"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-title" }, [
                _vm._v("主播标签")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入主播标签" },
                    model: {
                      value: _vm.paramsData.host_flag,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "host_flag", $$v)
                      },
                      expression: "paramsData.host_flag"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hide()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.submitData()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }