import "core-js/modules/es.array.find-index";
import util from '@/libs/util.js';
export default {
  methods: {
    handleMenuSelect: function handleMenuSelect(index, indexPath) {
      if (/^d2-menu-empty-\d+$/.test(index) || index === undefined) {
        this.$message.warning('临时菜单');
      } else if (/^https:\/\/|http:\/\//.test(index)) {
        util.open(index);
      } else {
        this.$router.push({
          path: index
        });
      }
    },
    handleMenuOpen: function handleMenuOpen(index, indexPath) {
      var arr = document.getElementsByClassName('el-submenu__title');

      for (var i = 0; i < arr.length; i++) {
        arr[i].classList.remove('el-menu-sticky');
      }

      arr[this.aside.findIndex(function (i) {
        return i.path == indexPath;
      }) - 1].classList.add('el-menu-sticky');
    }
  }
};