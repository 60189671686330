var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addRole.init()
                    }
                  }
                },
                [_vm._v("添加角色")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "title", label: "角色名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "description", label: "角色描述" }
          }),
          _c("el-table-column", {
            attrs: { prop: "listorder", label: "主页显示" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.presentations.find(function(i) {
                            return i.value == row.is_show_home
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "group_num", label: "分组人数" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0
                      ? _c("span", { staticClass: "statusTag1" }, [
                          _vm._v("禁用")
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("span", { staticClass: "statusTag2" }, [
                          _vm._v("正常")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "410",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "x-c" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.$refs.roleGame.init(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("产品管理")]
                        ),
                        _c("div", { staticClass: "controlsBtn-vertical" }),
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.$refs.addRole.init(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("权限管理 ")]
                        ),
                        _c("div", { staticClass: "controlsBtn-vertical" }),
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.$refs.entrance.init(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("入口管理 ")]
                        ),
                        _c("div", { staticClass: "controlsBtn-vertical" }),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title:
                                "此操作将" +
                                (scope.row.status == 0 ? "启用" : "禁用") +
                                "该角色"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.handleDisable(scope.row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                attrs: { slot: "reference" },
                                slot: "reference"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == 0 ? "启用" : "禁用"
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "controlsBtn-vertical" }),
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.$refs.addRole.init(
                                  scope.row.id,
                                  true
                                )
                              }
                            }
                          },
                          [_vm._v("复制角色")]
                        ),
                        _c("div", { staticClass: "controlsBtn-vertical" }),
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.getData }
      }),
      _c("addRole", {
        ref: "addRole",
        attrs: { presentation: _vm.presentations, showing: _vm.showing },
        on: {
          changeShow: _vm.changeShow,
          refreshDataList: function($event) {
            return _vm.getData(_vm.searchData.page)
          }
        }
      }),
      _c("roleGame", { ref: "roleGame" }),
      _c("entrance", { ref: "entrance" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }