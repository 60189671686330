var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.gameshow,
              expression: "gameshow"
            }
          ],
          staticClass: "item_box"
        },
        [
          _c("div", { staticClass: "box_header" }, [
            _c("div", { staticClass: "titles" }, [_vm._v("产品")])
          ]),
          _c(
            "el-table",
            {
              staticClass: "table_box",
              staticStyle: { width: "100%" },
              attrs: { "highlight-current-row": "", data: _vm.productList },
              on: {
                "row-click": function($event) {
                  return _vm.platformclick($event, false)
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "产品名称" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.plshow,
              expression: "plshow"
            }
          ],
          staticClass: "item_box"
        },
        [
          _c("div", { staticClass: "box_header" }, [
            _c("div", { staticClass: "titles" }, [_vm._v("广告平台")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pkgshow == false,
                    expression: "pkgshow == false"
                  }
                ]
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.addplatId }
                  },
                  [_vm._v("设置关联")]
                )
              ],
              1
            )
          ]),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table_box",
              staticStyle: { width: "100%" },
              attrs: { "highlight-current-row": "", data: _vm.adv_platform },
              on: {
                "row-click": function($event) {
                  return _vm.advplatformClick($event, false)
                },
                "selection-change": _vm.selectionChange,
                toggleRowSelection: _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", label: "关联", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { prop: "adv_platform_name", label: "广告平台名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "adv_platform_mark", label: "广告平台标识" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pkgshow,
              expression: "pkgshow"
            }
          ],
          staticClass: "item_box"
        },
        [
          _c("div", { staticClass: "box_header" }, [
            _c("div", { staticClass: "titles" }, [_vm._v("广告位置")]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.addplatId }
                  },
                  [_vm._v("设置关联")]
                )
              ],
              1
            )
          ]),
          _c(
            "el-table",
            {
              ref: "multipleTable2",
              staticClass: "table_box",
              staticStyle: { width: "100%" },
              attrs: {
                border: false,
                "highlight-current-row": "",
                data: _vm.adv_place
              },
              on: { "selection-change": _vm.selectionChange2 }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", label: "关联", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { prop: "adv_place_name", label: "广告位置名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "adv_place_mark", label: "广告位置标识" }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }