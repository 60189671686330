//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogShow: false,
      data: {}
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      this.dialogShow = true;
      this.data = data;
    },
    clearData: function clearData() {}
  }
};