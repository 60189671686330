var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.titleName,
        visible: _vm.dialogFormVisible,
        width: "30%",
        "close-on-click-modal": false,
        modal: false,
        "modal-append-to-body": false,
        top: "13vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "add-headerdddd" },
        [
          _c(
            "el-form",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.names,
                    prop: "value",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入邮箱号..." },
                    on: { change: _vm.valChange },
                    model: {
                      value: _vm.form.value,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "value", $$v)
                      },
                      expression: "form.value"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "warning", round: "" },
              on: {
                click: function($event) {
                  return _vm.clearData()
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }