var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCurrency: true, isPayChannel: true } },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  "picker-options": _vm.pickerOptionsRadius,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "激活开始日期",
                  "end-placeholder": "激活结束日期",
                  "value-format": "yyyy-MM-dd",
                  align: "right"
                },
                model: {
                  value: _vm.value1,
                  callback: function($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "研发订单ID" },
                model: {
                  value: _vm.searchData.order_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "order_id", $$v)
                  },
                  expression: "searchData.order_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "渠道订单ID" },
                model: {
                  value: _vm.searchData.channel_order_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "channel_order_id", $$v)
                  },
                  expression: "searchData.channel_order_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入用户ID" },
                model: {
                  value: _vm.searchData.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "user_id", $$v)
                  },
                  expression: "searchData.user_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入角色ID" },
                model: {
                  value: _vm.searchData.role_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "role_id", $$v)
                  },
                  expression: "searchData.role_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入商品ID" },
                model: {
                  value: _vm.searchData.product_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "product_id", $$v)
                  },
                  expression: "searchData.product_id"
                }
              })
            ],
            1
          ),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择环境"
                      },
                      on: { change: _vm.severlistChange },
                      model: {
                        value: _vm.searchData.is_prod,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "is_prod", $$v)
                        },
                        expression: "searchData.is_prod"
                      }
                    },
                    _vm._l(_vm.severlist, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择服务器"
                      },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.name || item.serverName,
                          value: item.id || item.serverID
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "服务器渠道"
                      },
                      on: { change: _vm.serverChannelChange },
                      model: {
                        value: _vm.server_channel,
                        callback: function($$v) {
                          _vm.server_channel = $$v
                        },
                        expression: "server_channel"
                      }
                    },
                    _vm._l(_vm.serverChannel, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "服务器"
                      },
                      on: { change: _vm.severChange },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverListYJ, function(item) {
                      return _c("el-option", {
                        key: item.serverID,
                        attrs: { label: item.serverName, value: item.serverID }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("royalpirates") == -1 &&
          _vm.rawData.game_mark.indexOf("yjfm") == -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "服务器ID"
                    },
                    model: {
                      value: _vm.searchData.server_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "server_id", $$v)
                      },
                      expression: "searchData.server_id"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.exportLoading,
                    disabled: !_vm.tableCopyTableList.length
                  },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableCopyTableList.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  width: "60",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "game_reveal",
                  label: "游戏",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform_reveal",
                  label: "平台",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channel_reveal",
                  label: "渠道",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pkg_reveal",
                  label: "包名",
                  "show-overflow-tooltip": "",
                  "min-width": "220"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "new_date",
                  label: "充值时间",
                  "show-overflow-tooltip": "",
                  "min-width": "200"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "amountNew",
                  label: "付费金额",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_channel_reveal",
                  label: "广告渠道",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_placement_reveal",
                  label: "广告位置",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "product_name",
                  label: "商品名称",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "product_id",
                  label: "商品ID",
                  "show-overflow-tooltip": "",
                  "min-width": "200"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.product_id) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3979842037
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "country",
                  label: "国家",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_id",
                  label: "研发订单ID",
                  "show-overflow-tooltip": "",
                  "min-width": "250"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.order_id) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1519839088
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_channel",
                  label: "支付渠道",
                  "show-overflow-tooltip": "",
                  "min-width": "250"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.pay_channel) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3590906136
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_channel_order_id",
                  label: "渠道订单ID",
                  "show-overflow-tooltip": "",
                  "min-width": "280"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.pay_channel_order_id) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  603242555
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "user_id",
                  label: "用户ID",
                  "show-overflow-tooltip": "",
                  "min-width": "200"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.user_id) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2009992943
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "role_id",
                  label: "角色ID",
                  "show-overflow-tooltip": "",
                  "min-width": "200"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.role_id) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  132981642
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "device_id",
                  label: "设备ID",
                  "show-overflow-tooltip": "",
                  "min-width": "300"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.device_id) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1183923174
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "register_time",
                  label: "用户注册时间",
                  "show-overflow-tooltip": "",
                  "min-width": "200"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.register_time || "/") + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2166121920
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "activity_time",
                  label: "设备激活时间",
                  "show-overflow-tooltip": "",
                  "min-width": "200"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(" " + _vm._s(row.activity_time || "/") + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  221071492
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "server_id",
                  label: "服务器ID",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ip",
                  label: "IP",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.tableCopyTableList.length
        ? _c("el-pagination", {
            attrs: {
              layout: "total,sizes, prev, pager, next",
              "current-page": _vm.currentPage,
              total: _vm.tableCopyTableList.length,
              "page-size": _vm.pageSize
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }