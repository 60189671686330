import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { updateServerLogData } from "@/api/gmtool/packageCode";
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogShow: false,
      list: []
    };
  },
  created: function created() {},
  methods: {
    open: function open(id) {
      var _this = this;

      this.list = [];
      updateServerLogData(_objectSpread(_objectSpread({}, this.prod), {}, {
        serverID: id
      })).then(function (res) {
        if (res.status == 200) {
          _this.list = res.data;
          _this.dialogShow = true;
        }
      });
    },
    hiad: function hiad() {
      this.dialogShow = false;
    }
  }
};