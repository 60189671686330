import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { dataCompare, dataCompare_currency, compare_currency_other } from "@/api/dataAll/dataCompare";
import pageMinx from "./mixins/pageMinx";
export default {
  mixins: [tabulation, pageMinx],
  data: function data() {
    return {
      // finallyFn: ['integralPoint'],
      reqFn: 'integralPoint',
      originaltableList: [{
        name: '激活设备数',
        key: 'activate_device'
      }, {
        name: '新增设备付费金额',
        key: 'new_device_recharge_amount'
      }, {
        name: '新增设备付费数',
        key: 'new_device_recharge_num'
      }, {
        name: '充值总额(元)',
        key: 'recharge_amount'
      }, {
        name: '注册账号',
        key: 'register_num'
      }, {
        name: '登录账号',
        key: 'login_num'
      }, {
        name: '付费账号',
        key: 'recharge_num'
      }, {
        name: '在线账号',
        key: 'online_num'
      }, {
        name: 'ARPPU',
        key: 'arppu'
      }, {
        name: '新增付费账号数',
        key: 'register_recharge_pnum'
      }, {
        name: '新增账号付费金额(元)',
        key: 'register_recharge_amount'
      }],
      IntegralrawData: {},
      screen: [],
      tableList: []
    };
  },
  created: function created() {
    this.screen = this.originaltableList.map(function (item) {
      return item.key;
    });
  },
  mounted: function mounted() {},
  methods: {
    resettingData: function resettingData() {
      this.tableData = [];
      this.tableList = [];
      this.IntegralrawData = {};
    },
    // 整点对比
    integralPoint: function integralPoint() {
      var _this = this;

      var isverification = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!this.rawData.game_mark) {
        return;
      } // if(this.currencyClose && !this.searchData.currency_info){
      //   if(isverification){
      //     this.$message.error('请选择货币类型')
      //   }        
      //   return
      // }


      return new Promise(function (replace) {
        _this.reqLoading = true;

        var date = _this.$times.timestampToTime((new Date(_this.$times.zeroTimeZone()).getTime() - 3600 * 1000 * 24 * 2) / 1000, 'YY-MM-DD');

        var reqFn = dataCompare;

        if (_this.currencyClose && !_this.searchData.pay_channel) {
          reqFn = dataCompare_currency;
        } else if (_this.searchData.pay_channel) {
          reqFn = compare_currency_other;
        }

        reqFn(_objectSpread(_objectSpread({
          days: 3,
          time: date,
          kind: 1
        }, _this.searchData), _this.defaultData())).then(function (res) {
          if (res.status == 200) {
            _this.IntegralrawData = res.data;

            _this.IntegralscreenControls('IntegralrawData');
          }
        }).finally(function () {
          _this.reqLoading = false;
          replace();
        });
      });
    },
    IntegralscreenControls: function IntegralscreenControls() {
      var _this2 = this;

      var tableData = [];
      var tableList = [];
      var knum = -1;
      var screen = [];
      this.originaltableList.map(function (item) {
        if (_this2.screen.findIndex(function (t) {
          return t == item.key;
        }) > -1) {
          screen.push(item);
        }
      });
      screen.forEach(function (item) {
        tableList.push({
          label: item.name,
          prop: item.key,
          list: []
        });
      });

      var _loop = function _loop(i) {
        // 表格头部数据处理
        var d = {};

        for (var k in _this2.IntegralrawData[i]) {
          var c = k.substring(0, 1) == "0" ? k.substring(1, 2) : k;
          d[c] = _this2.IntegralrawData[i][k];
          d[c]['date'] = i;
        }

        tableList.map(function (item) {
          item.list.push({
            label: i,
            prop: item.prop + i
          });
        }); // 表格数据处理

        for (var _k in d) {
          knum += 1;
          var h = parseInt(_k) + 1;
          tableData.push({
            hours: (_k < 10 ? '0' + _k : _k) + '~' + (h < 10 ? '0' + h : h) + '点'
          });

          for (var j in d[_k]) {
            var text = d[_k][j];

            if (j == 'recharge_amount' || j == 'register_recharge_amount' || j == 'arppu' || j == 'new_device_recharge_amount') {
              text = _this2.$utils.conversion(d[_k][j]).toFixed(2);
            }

            tableData[knum][j + d[_k]['date']] = text;
          }

          if (knum == 23) {
            knum = -1;
          }
        }
      };

      for (var i in this.IntegralrawData) {
        _loop(i);
      }

      tableData.splice(24, tableData.length - 24);
      tableList.unshift({
        label: '',
        list: [{
          label: '时间',
          prop: 'hours',
          width: 100
        }]
      });
      this.tableData = tableData;
      this.tableList = tableList;
    }
  }
};