import request from '@/utils/request'; // 同群分析数据展示

export function getConAanData(data) {
  return request({
    url: '/data/same_group',
    method: 'get',
    params: data
  });
}
export function getConAanData_currency(data) {
  return request({
    url: '/data/same_group_currency',
    method: 'get',
    params: data
  });
}