import request from '@/utils/request'; //删除三方绑定配置

export function deleBindLogins(data) {
  return request({
    url: '/pay/delbind_login_type',
    method: 'get',
    params: data
  });
} //修改绑定登录

export function undataThree(data) {
  return request({
    url: '/pay/update_login_type',
    method: 'post',
    params: data
  });
}