var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "用户ID", clearable: "" },
                model: {
                  value: _vm.paramsData.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "user_id", $$v)
                  },
                  expression: "paramsData.user_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "用户名称", clearable: "" },
                model: {
                  value: _vm.paramsData.user_name,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "user_name", $$v)
                  },
                  expression: "paramsData.user_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticStyle: { display: "flex" }, attrs: { span: 8 } },
            [
              _c("uploadImagetow", {
                ref: "uploadImagetow",
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  filesname: "vip_call_log_file",
                  Url: "/sz-gm/uploadVipCallLogFile ",
                  title: "批量上传",
                  paramsData: { game_id: _vm.rawData.game_id }
                },
                on: {
                  change: function($event) {
                    return _vm.getList(1)
                  }
                }
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addCommunication.open({}, false)
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "user_id", label: "用户ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "user_name", label: "用户名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "vip_class", label: "VIP等级" }
          }),
          _c("el-table-column", {
            attrs: { prop: "contact_time", label: "联系时间" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "160", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.$refs.addCommunication.open(row)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete([row.id])
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      }),
      _c("addCommunication", {
        ref: "addCommunication",
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }