import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import axios from "axios";
import { get } from "lodash";
import util from "@/libs/util";
import store from "@/store";
import router from "@/router";
import { Message } from "element-ui";
import bus from "@/utils/bus";
/**
 * @description 创建请求实例
 */
// 创建一个 axios 实例

var service = axios.create({
  timeout: 300000,
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  }
});

function convertToUpperToLower(str) {
  var result = "";

  for (var i = 0; i < str.length; i++) {
    var char = str.charAt(i);

    if (char >= "A" && char <= "Z") {
      result += char.toLowerCase();
    } else {
      result += char;
    }
  }

  return result;
}

function encodeURIParams(config, url) {
  var urls = url;
  urls += "?";
  var keys = Object.keys(config);

  for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
    var key = _keys[_i];
    urls += "".concat(key, "=").concat(encodeURIComponent(config[key]), "&");
  }

  urls = urls.substring(0, urls.length - 1);
  return urls;
} // 请求拦截


service.interceptors.request.use(function (config) {
  var paramsData = config.params ? _objectSpread({}, config.params) : {};

  if (paramsData) {
    for (var i in paramsData) {
      if (paramsData[i] === "" || Array.prototype.isPrototypeOf(paramsData[i]) && !paramsData[i].length || Object.prototype.isPrototypeOf(paramsData[i]) && Object.keys(paramsData[i]).length === 0 || paramsData[i] == null) {
        delete paramsData[i];
      }
    }

    for (var _i2 in paramsData) {
      if (_i2 == "game" || _i2 == "platform" || _i2 == "channel" || _i2 == "pkg" || _i2 == "ad_channel" || _i2 == "ad_placement") {
        if (Array.prototype.isPrototypeOf(paramsData[_i2])) {
          paramsData[_i2].map(function (i) {
            i = i.toLowerCase();
          });
        } else {
          paramsData[_i2] = paramsData[_i2].toLowerCase();
        } // convertToUpperToLower(paramsData[i])

      }

      if (_i2 == "ad_placement" && paramsData[_i2].indexOf("valuets") > -1) {
        paramsData[_i2] = paramsData[_i2].toString().replace("valuets", "");
      }
    }
  } //


  if (config.method == "post") {
    for (var k in paramsData) {
      if (Array.prototype.isPrototypeOf(paramsData[k]) && (k == 'ad_channel' || k == 'ad_placement' || k == 'special_product_id')) {
        paramsData[k] = paramsData[k].toString().replace("valuets", "");
      }
    }

    config.data = paramsData; // 转为formdata数据格式

    if (config.url.indexOf("daily-task-flow-percent") > -1) {
      config.data.pkg && (config.data.pkg = config.data.pkg.toString());
    }
  }

  if (config.method == "get" && paramsData) {
    config.url = encodeURIParams(paramsData, config.url);
  }

  config.params = "";

  if (util.cookies.get("token")) {
    config.headers.Authorization = "Bearer " + util.cookies.get("token");
  }

  var WhiteList = ["/verification_code", "/login"];
  config.headers["Request-Url"] = WhiteList.findIndex(function (i) {
    return i == config.url;
  }) > -1 ? "" : router.history.current.path == "/login" ? "/index" : router.history.current.path;
  config.headers["Request-Url"] = router.history.current.path == "/" ? "/index" : config.headers["Request-Url"];
  return config;
}, function (error) {
  return Promise.reject(error);
}); // 响应拦截

service.interceptors.response.use(function (response) {
  // dataAxios 是 axios 返回数据中的 data
  var dataAxios = response.data; // 这个状态码是和后端约定的

  var code = dataAxios.code || dataAxios.status;
  var message = dataAxios.message ? dataAxios.message : dataAxios.msg; // 根据 code 进行判断

  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
    return dataAxios;
  } else {
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    switch (code) {
      case 200:
        // [ 示例 ] code === 0 代表没有错误
        break;

      case 1000:
        // [ 示例 ] code === 0 代表没有错误
        break;

      case 400:
        Message({
          type: "error",
          message: message
        });
        break;

      case 500:
        Message({
          type: "error",
          message: message
        });
        break;

      case 501:
        router.replace("/err");
        break;

      default:
        // 不是正确的 code
        Message({
          type: "error",
          message: message
        });
        break;
    }

    return dataAxios;
  }
}, function (error) {
  var status = get(error, "response.status");
  var data = get(error, "response.data");

  if (!error.response) {
    // Message({
    //   type: "error",
    //   message: '网络错误',
    // })
    return Promise.reject(data);
  }

  Message.closeAll();
  var message = data.message ? data.message : data.msg;

  switch (status) {
    case 400:
      error.message = "请求错误";
      break;

    case 401:
      Message({
        type: "error",
        message: "登录已失效,请重新登录"
      });
      setTimeout(function () {
        store.dispatch("d2admin/account/logout");
      }, 500);
      break;

    case 402:
      // if (document.getElementsByClassName("el-message").length == 0) {
      // }
      Message({
        type: "error",
        message: "暂无权限,请在入口重新选择游戏"
      });

      if (store.state.d2admin.user.is_show_home == 2) {
        bus.$emit("updProductS");
        store.dispatch("d2admin/serchSelect/clearData", 1);
        router.replace("/");
      }

      break;

    case 403:
      error.message = "拒绝访问";
      break;

    case 404:
      error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url); // if(router.history.current.path == '/404'){
      // }

      router.replace("*");
      break;

    case 408:
      error.message = "请求超时";
      break;

    case 500:
      error.message = message;
      Message({
        type: "error",
        message: message
      });
      break;

    case 501:
      error.message = "服务未实现";
      router.replace("/err");
      break;

    case 502:
      error.message = "网关错误";
      break;

    case 503:
      error.message = "服务不可用";
      break;

    case 504:
      error.message = "网关超时";
      break;

    case 505:
      error.message = "HTTP版本不受支持";
      break;

    default:
      break;
  }

  return Promise.reject(data);
});
export default service;