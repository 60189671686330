//
//
//
//
//
//
//
//
//
//
//
//
import systemEvents from "./retentionAnalysis/systemEvents";
import customizationEvents from "./retentionAnalysis/customizationEvents";
export default {
  name: "retentionAnalysis",
  components: {
    systemEvents: systemEvents,
    customizationEvents: customizationEvents
  },
  mixins: [],
  data: function data() {
    return {
      activeIndex: 'systemEvents'
    };
  },
  created: function created() {},
  activated: function activated() {},
  methods: {
    handover: function handover(index) {
      this.activeIndex = index;
    }
  }
};