import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogShow: false,
      paramsData: {}
    };
  },
  created: function created() {},
  methods: {
    init: function init(data) {
      if (data) {
        var range = JSON.stringify(data.range) != '[]' ? data.range : data.request_data[0].target.range || '';
        data.reward.map(function (i) {
          i.type_id = i.type_id.toString(); // i.expire = this.$times.timestampToTime(i.expire,"YY-MM-DD H")

          i.ispermanent = i.expire == 0 ? true : false;
        });
        var server_ids = data.server_ids.map(function (i) {
          return Number(i);
        });
        var d = {
          id: data.id,
          title: [],
          content: [],
          is_default: [],
          language: [],
          server_ids: server_ids,
          reward: data.reward,
          type: data.player_type,
          role_ids: data.args.length ? data.args.toString() : '',
          level: range.level || {
            minlevel: '',
            maxlevel: ''
          },
          sdk_type: range.sdktype,
          vip_level: range.viplevel || {
            minviplevel: '',
            maxviplevel: ''
          },
          content_Val: data.content_Val,
          logout_time: range.logouttime ? {
            minlogouttime: Number(range.logouttime.minlogouttime) * 1000,
            maxlogouttime: Number(range.logouttime.maxlogouttime) * 1000
          } : {
            minlogouttime: '',
            maxlogouttime: ''
          }
        };
        this.paramsData = d;
      }

      this.dialogShow = true;
    }
  }
};