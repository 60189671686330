import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { sendRepairOrder } from "@/api/gmToolNew/gmToolNew";
import { gmToolNew, gmToolNewCom } from "@/mixins/index";
import { jsonDiff } from "diff/lib/diff/json";
export default {
  mixins: [gmToolNew, gmToolNewCom],
  data: function data() {
    return {
      dialogShow: false,
      paramsData: {
        uid: "",
        order_id: "",
        product_id: "",
        pay_type: "",
        pay_channel_order_id: "",
        reward: []
      },
      rules: {
        uid: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        order_id: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        product_id: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        pay_type: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        reward: [{
          required: true,
          message: "请添加物品",
          trigger: "blur"
        }],
        item_type: [{
          required: true,
          message: "请选择物品类型",
          trigger: "blur"
        }],
        pay_channel_order_id: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        id: [{
          required: true,
          message: "请选择物品",
          trigger: "blur"
        }],
        item_num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }],
        item_quality: [{
          required: true,
          message: "请选择物品品质",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      var _this = this;

      this.itemType.map(function (i) {
        _this["itemType" + i.value] = _this.szgmItemList.filter(function (item) {
          return item.type == i.value;
        });
      });
      this.dialogShow = true;
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    addDomain: function addDomain() {
      this.paramsData.reward.push({
        num: "",
        type: "",
        id: "",
        quality: ""
      });
    },
    removeDomain: function removeDomain(item) {
      var index = this.paramsData.reward.indexOf(item);

      if (index !== -1) {
        this.paramsData.reward.splice(index, 1);
      }
    },
    domainType: function domainType(index, type) {
      this.paramsData.reward[index].id = "";

      if (this.paramsData.reward[index].num) {
        this.paramsData.reward[index].num = "";
      }
    },
    clearData: function clearData() {
      this.paramsData = {
        id: "",
        uid: "",
        order_id: "",
        pay_channel_order_id: "",
        product_id: "",
        pay_type: "",
        reward: []
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.paramsData));
          data.reward = JSON.stringify(data.reward);
          sendRepairOrder(_objectSpread(_objectSpread({}, data), _this2.propsData)).then(function (res) {
            if (res.code == 200 || res.status == 200) {
              _this2.$message({
                message: "操作成功",
                type: "success"
              });

              _this2.dialogShow = false;

              _this2.$emit("change");
            }
          });
        }
      });
    }
  }
};