var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogShow,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: { "label-position": "left", "label-width": "120px" }
            },
            _vm._l(_vm.content_Val, function(domain, index) {
              return _c(
                "div",
                { key: index + "k", staticClass: "content_ValClass" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "lodingItem-operationFrame flex",
                      staticStyle: { "margin-bottom": "20px" }
                    },
                    [
                      _vm.type != "version"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "lodingItem contentValitemt",
                              attrs: {
                                prop: "content_Val." + index + ".title",
                                label: "标题",
                                "label-width": "60px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "operationFrame-entity",
                                attrs: {
                                  disabled: true,
                                  clearable: "",
                                  placeholder: "请输入标题"
                                },
                                model: {
                                  value: domain.title,
                                  callback: function($$v) {
                                    _vm.$set(domain, "title", $$v)
                                  },
                                  expression: "domain.title"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.type != "version"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "lodingItem contentValitem",
                              attrs: {
                                prop: "content_Val." + index + ".is_default",
                                label: "是否为模板",
                                "label-width": "100px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "flex-1",
                                  attrs: {
                                    disabled: true,
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: domain.is_default,
                                    callback: function($$v) {
                                      _vm.$set(domain, "is_default", $$v)
                                    },
                                    expression: "domain.is_default"
                                  }
                                },
                                _vm._l(_vm.moduleList, function(item) {
                                  return _c("el-option", {
                                    key: item.label,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "lodingItem contentValitemt",
                          attrs: {
                            prop: "content_Val." + index + ".language",
                            label: "语言",
                            "label-width": "60px"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "flex-1",
                              attrs: {
                                disabled: true,
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: domain.language,
                                callback: function($$v) {
                                  _vm.$set(domain, "language", $$v)
                                },
                                expression: "domain.language"
                              }
                            },
                            _vm._l(_vm.langList, function(item) {
                              return _c("el-option", {
                                key: item.label,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex lodingItem-title first_box",
                      staticStyle: {
                        "margin-bottom": "20px",
                        width: "100%",
                        "align-items": "center"
                      }
                    },
                    [_vm._v(" 邮件内容: ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "content_Val." + index + ".content" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 12 },
                          disabled: true,
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: domain.content,
                          callback: function($$v) {
                            _vm.$set(domain, "content", $$v)
                          },
                          expression: "domain.content "
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }