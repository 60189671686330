var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-col",
        { attrs: { span: 4 } },
        [
          _c(
            "el-select",
            {
              staticClass: "flex-1",
              attrs: { filterable: "", placeholder: "请选择" },
              on: { change: _vm.switchProd },
              model: {
                value: _vm.paramsData.is_prod,
                callback: function($$v) {
                  _vm.$set(_vm.paramsData, "is_prod", $$v)
                },
                expression: "paramsData.is_prod"
              }
            },
            _vm._l(_vm.severlist, function(item) {
              return _c("el-option", {
                key: item.label,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex", justify: "end" } },
        [
          _c(
            "el-col",
            { staticClass: "x-je", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "admin_name", label: "操作员" }
          }),
          _c("el-table-column", {
            attrs: { prop: "type_name", label: "活动类型" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "server_list_name",
              label: "活动服务器",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1" }, [
                      _vm._v(_vm._s(row.server_list_name))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "发送状态",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.statusList.find(function(i) {
                            return i.value == row.status
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "updated_at", label: "修改时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.timestampToTime(
                            row.updated_at,
                            "YY-MM-DD HH"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.timestampToTime(
                            row.created_at,
                            "YY-MM-DD HH"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "120",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return row.status == 2
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.editingAc(row)
                                }
                              }
                            },
                            [_vm._v("修改")]
                          ),
                          _c("div", { staticClass: "controlsBtn-vertical" }),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: { title: "你确认发送这条活动吗？" },
                              on: {
                                confirm: function($event) {
                                  return _vm.publishAc(row)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "controlsBtn",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [_vm._v("发布")]
                              )
                            ]
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total,
          "page-size": _vm.paramsData.pageSize
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.getList }
      }),
      _c("listviewfileActivities", { ref: "listviewfileActivities" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }