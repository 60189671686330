var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "服务器" },
                  on: { change: _vm.severChange },
                  model: {
                    value: _vm.paramsData.server_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "server_id", $$v)
                    },
                    expression: "paramsData.server_id"
                  }
                },
                _vm._l(_vm.serverListYJ, function(item) {
                  return _c("el-option", {
                    key: item.serverID,
                    attrs: { label: item.serverName, value: item.serverID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "礼包ID", clearable: "" },
                model: {
                  value: _vm.paramsData.gift_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "gift_id", _vm._n($$v))
                  },
                  expression: "paramsData.gift_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "礼包名称", clearable: "" },
                model: {
                  value: _vm.paramsData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "name", $$v)
                  },
                  expression: "paramsData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "礼包描述", clearable: "" },
                model: {
                  value: _vm.paramsData.desc,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "desc", $$v)
                  },
                  expression: "paramsData.desc"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "刷新条件"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList(1)
                    }
                  },
                  model: {
                    value: _vm.paramsData.refresh,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "refresh", $$v)
                    },
                    expression: "paramsData.refresh"
                  }
                },
                _vm._l(_vm.refreshType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "失效时间"
                },
                on: { change: _vm.expireChange },
                model: {
                  value: _vm.paramsData.expire,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "expire", $$v)
                  },
                  expression: "paramsData.expire"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function() {
                      _vm.serverJud().then(function() {
                        _vm.$refs.addGift.open()
                      })
                    }
                  }
                },
                [_vm._v("发送礼包")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "80" }
          }),
          _c("el-table-column", {
            attrs: { prop: "gift_id", label: "礼包ID", "min-width": "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "礼包名字", "min-width": "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "desc", label: "礼包描述", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: { effect: "dark", placement: "top" }
                      },
                      [
                        _c("div", {
                          staticClass: "tooltip",
                          staticStyle: { "max-width": "500px" },
                          attrs: { slot: "content" },
                          domProps: { innerHTML: _vm._s(row.desc) },
                          slot: "content"
                        }),
                        _c("div", {
                          staticClass: "text-ellipsis-1",
                          domProps: { innerHTML: _vm._s(row.desc) }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "icon", label: "图标id", "min-width": "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "刷新类型", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("RefreshType")(row.refresh)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "num", label: "礼包数量", "min-width": "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cost", label: "折扣", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(row.has_discount == 1 ? "有折扣" : "无折扣")
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "cost", label: "原价", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.has_discount == 1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#409EFF",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.viewArticles.init(
                                  row.original_cost
                                )
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : _c("div", [_vm._v("/")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "cost", label: "售价", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#409EFF", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.viewArticles.init(row.cost)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "reward", label: "礼包内容", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#409EFF", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.viewArticles.init(row.reward)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "expire", label: "定时发送", "min-width": "190" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.is_delay == 1
                            ? _vm.$times.timestampToTime(
                                row.delay_time,
                                "YY-MM-DD HH"
                              )
                            : "/"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "created_at",
              label: "发送状态",
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.is_delay == 1
                      ? _c("div", {}, [
                          row.state == 1
                            ? _c("div", { staticStyle: { color: "#409EFF" } }, [
                                _vm._v("发送成功")
                              ])
                            : _vm._e(),
                          row.state == 2
                            ? _c(
                                "div",
                                { staticStyle: { color: "goldenrod" } },
                                [_vm._v("未发送")]
                              )
                            : _vm._e(),
                          row.state == 3
                            ? _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v("发送失败")
                              ])
                            : _vm._e()
                        ])
                      : _c("div", [_vm._v("/")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "expire", label: "失效时间", "min-width": "200" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "created_at",
              label: "创建时间",
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$times.formDate(row.created_at)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "70",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.deleHandle(row.gift_id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      }),
      _c("addGift", {
        ref: "addGift",
        attrs: { propsData: { server_id: _vm.paramsData.server_id } },
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      }),
      _c("viewArticles", { ref: "viewArticles" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }