import "core-js/modules/es.array.index-of";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pageMinx from "./mixins/pageMinx";
import { tabulation, gmToolNewPage } from "@/mixins/index";
import { rollData, rollData_currency } from "@/api/index/index";
import { mapState } from "vuex";
import { serverList as hdServerList } from "@/api/gmtool/packageCode";
import { serverList as yjServerList } from "@/api/gmToolNew/gmToolNew";
export default {
  mixins: [tabulation, pageMinx, gmToolNewPage],
  data: function data() {
    return {
      // finallyFn: ['getRollData'],
      PointTime: ['00', '23'],
      serverLists: [],
      Data: [],
      reqFn: 'getRollData'
    };
  },
  created: function created() {
    this.getServerLists();
  },
  computed: _objectSpread(_objectSpread({}, mapState('d2admin/serchSelect', ['royalpirates'])), mapState("d2admin", {
    severlist: function severlist(state) {
      return state.user.GMauthority;
    },
    is_show_homes: function is_show_homes(state) {
      return state.user.is_show_home;
    }
  })),
  mounted: function mounted() {},
  methods: {
    resettingData: function resettingData() {
      this.Data = [];
    },
    //请求服务器列表  用于指定海盗和御剑请求
    getServerLists: function getServerLists() {
      if (this.is_show_homes == 1 || !this.rawData) {
        return;
      }

      this.RollSuit = false;
      this.serverLists = [];
      this.searchData.server_id = '';
      this.searchData.is_prod = '';
      var game = this.rawData.game_mark;

      if (game.indexOf(this.royalpirates) > -1) {
        this.RollSuit = this.severlist.length ? true : false;
      } else if (game.indexOf('yjfm') > -1) {
        this.RollSuit = true;
      }
    },
    severlistChange: function severlistChange(e) {
      var _this = this;

      this.serverLists = [];
      this.searchData.server_id = '';

      if (e) {
        hdServerList({
          is_prod: this.searchData.is_prod
        }).then(function (res) {
          if (res.status == 200) {
            _this.serverLists = res.data;
          }
        });
      }
    },
    getRollData: function getRollData() {
      var _this2 = this;

      var isverification = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!this.rawData.game_mark) {
        return;
      } // if (this.currencyClose && !this.searchData.currency_info) {
      //   if (isverification) {
      //     this.$message.error('请选择货币类型')
      //   }
      //   return
      // }


      return new Promise(function (replace) {
        _this2.searchData.hour_start = parseInt(_this2.PointTime[0]);
        _this2.searchData.hour_end = parseInt(_this2.PointTime[1]);
        _this2.reqLoading = true;
        var reqFn = _this2.currencyClose ? rollData_currency : rollData;
        reqFn(_objectSpread(_objectSpread(_objectSpread({}, _this2.searchData), _this2.defaultData()), {}, {
          date: _this2.$times.timestampToTime(new Date(_this2.$times.zeroTimeZone()).getTime() / 1000, 'YY-MM-DD')
        })).then(function (res) {
          if (res.code == 200) {
            _this2.Data = []; // Data

            for (var i in res.data) {
              var arr = i.split(":");
              res.data[i].total.pay_percent = (res.data[i].total.pay_percent * 100).toFixed(2);
              res.data[i].total.register_arpu = _this2.$utils.conversion(res.data[i].total.register_arpu).toFixed(2);
              res.data[i].total.arppu = _this2.$utils.conversion(res.data[i].total.arppu).toFixed(2);
              res.data[i].total.arpu = _this2.$utils.conversion(res.data[i].total.arpu).toFixed(2);
              res.data[i].total.server_recharge_amount = _this2.$utils.conversion(res.data[i].total.server_recharge_amount).toFixed(2);
              res.data[i].roll.roll_arppu = _this2.$utils.conversion(res.data[i].roll.roll_arppu).toFixed(2);
              res.data[i].roll.roll_pay_percent = (res.data[i].roll.roll_pay_percent * 100).toFixed(2);
              res.data[i].roll.roll_arpu = _this2.$utils.conversion(res.data[i].roll.roll_arpu).toFixed(2);
              res.data[i].roll.roll_register_arpu = _this2.$utils.conversion(res.data[i].roll.roll_register_arpu).toFixed(2);
              res.data[i].roll.roll_server_recharge_amount = _this2.$utils.conversion(res.data[i].roll.roll_server_recharge_amount).toFixed(2);

              _this2.Data.push(_objectSpread(_objectSpread({
                server_id: arr[0],
                date: arr[1],
                hours: parseInt(arr[2]) + '~' + parseInt(Number(arr[2]) + 1)
              }, res.data[i].roll), res.data[i].total));
            }
          }
        }).finally(function () {
          _this2.reqLoading = false;
          replace();
        });
      });
    }
  }
};