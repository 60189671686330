// 新增加载流程对应
import request from '@/utils/request';
export function addLoadPross(data) {
  return request({
    url: '/data/add_load_table_header',
    method: 'post',
    params: data
  });
} // 获取加载流程表

export function getLoadData(data) {
  return request({
    url: '/data/load_table_header',
    method: 'get',
    params: data
  });
} // 删除加载流程

export function deleLoadData(data) {
  return request({
    url: 'data/del_load_table_header',
    method: 'get',
    params: data
  });
} // 编辑加载流程

export function updataLoadData(data) {
  return request({
    url: '/data/update_load_table_header',
    method: 'post',
    params: data
  });
} // 反显加载流程

export function updataLoadDataReturn(data) {
  return request({
    url: '/data/update_load_table_header',
    method: 'get',
    params: data
  });
}