import request from '@/utils/request'; //登录sdk配置 查询登录列表

export function getLoginLists(data) {
  return request({
    url: '/pay/login_sdk',
    method: 'get',
    params: data
  });
}
export function allLoginSdk(data) {
  return request({
    url: '/pay/all-login-sdk',
    method: 'get',
    params: data
  });
}