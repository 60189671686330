var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          staticStyle: { "pointer-events": "auto" },
          attrs: {
            title: _vm.searchData.id ? "修改" : "新增",
            visible: _vm.dialogFormVisible,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.searchData,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "game", label: "选择游戏" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择游戏"
                      },
                      on: { change: _vm.gamechangeManage },
                      model: {
                        value: _vm.searchData.game,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "game", $$v)
                        },
                        expression: "searchData.game"
                      }
                    },
                    _vm._l(_vm.searchgames, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          "value-key": item.id,
                          label: item.game_name,
                          value: item.game_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "platform", label: "选择平台" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择平台"
                      },
                      on: { change: _vm.platformchangeManage },
                      model: {
                        value: _vm.searchData.platform,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "platform", $$v)
                        },
                        expression: "searchData.platform"
                      }
                    },
                    _vm._l(_vm.searchplatforms, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.platform_name,
                          value: item.platform_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "channel", label: "选择渠道" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择渠道"
                      },
                      on: { change: _vm.channelchangeManage },
                      model: {
                        value: _vm.searchData.channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "channel", $$v)
                        },
                        expression: "searchData.channel"
                      }
                    },
                    _vm._l(_vm.searchchannels, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.channel_name,
                          value: item.channel_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "pkg", label: "选择投放包" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择投放包"
                      },
                      model: {
                        value: _vm.searchData.pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "pkg", $$v)
                        },
                        expression: "searchData.pkg"
                      }
                    },
                    _vm._l(_vm.searchpkgs, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg_name, value: item.pkg_mark }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "decrypt_key", label: "解密密钥" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入解密密钥" },
                    model: {
                      value: _vm.searchData.decrypt_key,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "decrypt_key", $$v)
                      },
                      expression: "searchData.decrypt_key"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }