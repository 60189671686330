import { render, staticRenderFns } from "./rolead copy.vue?vue&type=template&id=31cf7e67&scoped=true&"
import script from "./rolead copy.vue?vue&type=script&lang=js&"
export * from "./rolead copy.vue?vue&type=script&lang=js&"
import style0 from "./rolead copy.vue?vue&type=style&index=0&id=31cf7e67&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31cf7e67",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\admin\\datacenter_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31cf7e67')) {
      api.createRecord('31cf7e67', component.options)
    } else {
      api.reload('31cf7e67', component.options)
    }
    module.hot.accept("./rolead copy.vue?vue&type=template&id=31cf7e67&scoped=true&", function () {
      api.rerender('31cf7e67', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/auth/users/components/rolead copy.vue"
export default component.exports