import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation, Kamitaku } from "@/mixins/index";
import { accLtv, accLtv_currency } from "@/api/dataAll/accountLtv";
export default {
  name: "kamitakuLtv",
  mixins: [tabulation, Kamitaku],
  data: function data() {
    return {
      more: true,
      searchData: {
        kind: 1
      },
      reqFn: accLtv,
      dateList: ['1', '2', '3', '4', '5', '6', '7', '14', '30', '45', '60', '75', '90'],
      nums: [],
      Dynamic: [],
      cellStylekeys: ["n"]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.reqLoading = true;
      this.searchData.starttime = this.value2[0];
      this.searchData.endtime = this.value2[1];
      this.reqFn = this.currencyClose ? accLtv_currency : accLtv;
      this.searchData = _objectSpread(_objectSpread({}, this.searchData), this.defaultData());
      this.timeInterval();
      this.getList(function (res) {
        _this.tabData.ltv_data['总计'] = {
          ltv: _this.tabData.ltv_total,
          other: {
            new_account_num: _this.tabData.account_total
          }
        };

        for (var key in _this.tabData.ltv_data) {
          var data = {
            new_account_num: _this.tabData.ltv_data[key].other.new_account_num,
            date: key
          };
          var D = Object.keys(_this.tabData.ltv_data[key].ltv); // this.nums = D.filter(item => this.dateList.indexOf(item) > -1)

          _this.nums = D;

          for (var key1 in _this.tabData.ltv_data[key].ltv) {
            // if (this.dateList.findIndex(item => item == key1) > -1) {
            // }
            data['n' + key1] = _this.tabData.ltv_data[key].ltv[key1] == '/' ? _this.tabData.ltv_data[key].ltv[key1] : _this.$utils.conversion(_this.tabData.ltv_data[key].ltv[key1]).toFixed(3);
          }

          _this.tableData.push(_objectSpread({}, data));
        }

        _this.nums.forEach(function (i) {
          console.log(i);

          _this.Dynamic.push({
            label: 'LTV' + i,
            value: 'n' + i,
            tableText: i == 1 ? "LTV".concat(i, "A") : "LTV".concat(i),
            desc: i == 1 ? '首日用户平均价值' : ''
          });
        });

        _this.$nextTick(function () {
          _this.getTableRight();
        });

        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var d = {};
      var columns = [{
        label: '日期',
        prop: 'date'
      }, {
        label: '新增账号',
        prop: 'new_account_num'
      }];
      var data = [];
      this.Dynamic.map(function (item) {
        columns.push({
          label: item.label,
          prop: item.value
        });
        d[item.value] = 0;
      });
      this.tableData.forEach(function (item) {
        var s = _objectSpread(_objectSpread({}, d), {}, {
          new_account_num: item.new_account_num
        });

        for (var key in s) {
          s[key] = item[key];
        }

        s.date = item.date;
        data.push(s);
      });
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};