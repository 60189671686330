import request from '@/utils/request'; // 获取新增实名认证参数列表

export function getRealNameList(data) {
  return request({
    url: 'pay/real_name_config',
    method: 'get',
    params: data
  });
} // 新增实名认证参数

export function addRealNameList(data) {
  return request({
    url: 'pay/add_real_name_config',
    method: 'post',
    params: data
  });
} // 修改实名认证参数

export function updataRealNameList(data) {
  return request({
    url: 'pay/update_real_name_config',
    method: 'post',
    params: data
  });
} // 删除实名认证参数

export function deleRealNameList(data) {
  return request({
    url: 'pay/del_real_name_config',
    method: 'post',
    params: data
  });
}