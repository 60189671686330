var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      { staticClass: "models overview-top" },
      [
        _c(
          "div",
          { staticClass: "overview-search" },
          [
            _vm.rawData.game_show_type == 1 && _vm.isProduct
              ? _c(
                  "div",
                  { staticClass: "searchbs" },
                  [
                    _c("div", { staticClass: "search-t" }, [_vm._v(" 产品 ")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          multiple: _vm.MultipleOptions,
                          "collapse-tags": "",
                          filterable: "",
                          clearable: "",
                          placeholder: "请选择产品"
                        },
                        model: {
                          value: _vm.trendSearchData.special_product_id,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trendSearchData,
                              "special_product_id",
                              $$v
                            )
                          },
                          expression: "trendSearchData.special_product_id"
                        }
                      },
                      _vm._l(_vm.productsList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "searchbs" },
              [
                _c("div", { staticClass: "search-t" }, [_vm._v(" 日期 ")]),
                _c("el-date-picker", {
                  attrs: {
                    clearable: false,
                    "picker-options": _vm.optionType,
                    "value-format": "yyyy-MM-dd",
                    type: "daterange",
                    align: "right",
                    "range-separator": "-",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  on: {
                    change: function($event) {
                      return _vm.dateChange($event, "trendSearch")
                    }
                  },
                  model: {
                    value: _vm.trendSearchDate,
                    callback: function($$v) {
                      _vm.trendSearchDate = $$v
                    },
                    expression: "trendSearchDate"
                  }
                })
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "14px" },
                on: { click: _vm.getrend }
              },
              [
                _c("div", { staticClass: "flex" }, [
                  _c("i", { staticClass: "iconfont icon-shuaxin" }),
                  _c("div", { staticClass: "refresh" }, [_vm._v("立即刷新")])
                ])
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "overview-title" }, [_vm._v(" 数据概览 ")]),
        _c("el-card", { staticClass: "box-card " }, [
          _c(
            "div",
            { staticClass: "flex" },
            _vm._l(_vm.overviewList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "overview-item flex-1 y-c" },
                [
                  _c("div", { staticClass: "overview-item-l" }, [
                    _vm._v(_vm._s(item.label))
                  ]),
                  _c("div", { staticClass: "overview-item-v" }, [
                    _vm._v(_vm._s(item.num))
                  ]),
                  _c("div", { staticClass: "overview-item-p" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.Proportion == 0 ? "暂无环比数据" : "环比"
                          ) +
                          " "
                      )
                    ]),
                    !item.isGoup && item.Proportion != 0
                      ? _c("div", { staticClass: "gocommen goDown x-c" }, [
                          _c("div", { staticClass: "gocommen-percentage" }, [
                            _vm._v(" " + _vm._s(item.Proportion + "%") + " ")
                          ]),
                          _c("i", { staticClass: "iconfont icon-xiajiang" })
                        ])
                      : _vm._e(),
                    item.isGoup && item.Proportion != 0
                      ? _c("div", { staticClass: "gocommen goUp x-c" }, [
                          _c("div", { staticClass: "gocommen-percentage" }, [
                            _vm._v(" " + _vm._s(item.Proportion + "%") + " ")
                          ]),
                          _c("i", { staticClass: "iconfont icon-shangsheng" })
                        ])
                      : _vm._e()
                  ]),
                  index + 1 != _vm.overviewList.length
                    ? _c("div", { staticClass: "segmentation" })
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ]),
        _c("div", { staticClass: "overview-title" }, [_vm._v(" 趋势概览 ")]),
        _c("el-card", {}, [
          _c("div", {
            staticStyle: { height: "400px" },
            attrs: { id: "echarTrend" }
          })
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "models yesterday" },
      [
        _c(
          "div",
          { staticClass: "overview-search" },
          [
            _vm.rawData.game_show_type == 1 && _vm.isProduct
              ? _c(
                  "div",
                  { staticClass: "searchbs" },
                  [
                    _c("div", { staticClass: "search-t" }, [_vm._v(" 产品 ")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          multiple: _vm.MultipleOptions,
                          "collapse-tags": "",
                          filterable: "",
                          clearable: "",
                          placeholder: "请选择产品"
                        },
                        model: {
                          value: _vm.yesterdaySearchData.special_product_id,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.yesterdaySearchData,
                              "special_product_id",
                              $$v
                            )
                          },
                          expression: "yesterdaySearchData.special_product_id"
                        }
                      },
                      _vm._l(_vm.productsList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("el-button", { on: { click: _vm.getYesterday } }, [
              _c("div", { staticClass: "flex" }, [
                _c("i", { staticClass: "iconfont icon-shuaxin" }),
                _c("div", { staticClass: "refresh" }, [_vm._v("立即刷新")])
              ])
            ])
          ],
          1
        ),
        _c("div", { staticClass: "overview-title" }, [
          _vm._v(" 昨日分时对比 ")
        ]),
        _c("el-card", {}, [
          _c(
            "div",
            { staticClass: "searchbs" },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.YesterdayChange },
                  model: {
                    value: _vm.yesterdaySelect,
                    callback: function($$v) {
                      _vm.yesterdaySelect = $$v
                    },
                    expression: "yesterdaySelect"
                  }
                },
                _vm._l(_vm.yesterdaySelectAll, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", {
            staticStyle: { height: "400px" },
            attrs: { id: "echarYesterday" }
          })
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "models coreMetrics" },
      [
        _c(
          "div",
          { staticClass: " overview-search" },
          [
            _vm.rawData.game_show_type == 1 && _vm.isProduct
              ? _c(
                  "div",
                  { staticClass: "searchbs" },
                  [
                    _c("div", { staticClass: "search-t" }, [_vm._v(" 产品 ")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          placeholder: "请选择产品"
                        },
                        model: {
                          value: _vm.coreMetricsSearchData.special_product_id,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.coreMetricsSearchData,
                              "special_product_id",
                              $$v
                            )
                          },
                          expression: "coreMetricsSearchData.special_product_id"
                        }
                      },
                      _vm._l(_vm.productsList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "searchbs" },
              [
                _c("div", { staticClass: "search-t" }, [_vm._v(" 日期 ")]),
                _c("el-date-picker", {
                  attrs: {
                    clearable: false,
                    "picker-options": _vm.optionType,
                    "value-format": "yyyy-MM-dd",
                    type: "daterange",
                    align: "right",
                    "range-separator": "-",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  on: {
                    change: function($event) {
                      return _vm.dateChange($event, "coreMetricsSearch")
                    }
                  },
                  model: {
                    value: _vm.coreMetricsSearchDate,
                    callback: function($$v) {
                      _vm.coreMetricsSearchDate = $$v
                    },
                    expression: "coreMetricsSearchDate"
                  }
                })
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "14px" },
                on: { click: _vm.getcoreMetrics }
              },
              [
                _c("div", { staticClass: "flex" }, [
                  _c("i", { staticClass: "iconfont icon-shuaxin" }),
                  _c("div", { staticClass: "refresh" }, [_vm._v("立即刷新")])
                ])
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "overview-title" }, [
          _vm._v(" 核心指标效果 ")
        ]),
        _c("el-card", {}, [
          _c(
            "div",
            { staticClass: "searchbs" },
            [
              _c("div", { staticClass: "search-t" }, [_vm._v(" 指标 ")]),
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.coreMetricsChange },
                  model: {
                    value: _vm.coreMetricsSelect,
                    callback: function($$v) {
                      _vm.coreMetricsSelect = $$v
                    },
                    expression: "coreMetricsSelect"
                  }
                },
                _vm._l(_vm.overviewList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", {
            staticStyle: { height: "400px" },
            attrs: { id: "echarcoreMetrics" }
          })
        ])
      ],
      1
    ),
    _c("div", { staticClass: "coreChannel" }, [
      _c(
        "div",
        { staticClass: "overview-search" },
        [
          _vm.rawData.game_show_type == 1 && _vm.isProduct
            ? _c(
                "div",
                { staticClass: "searchbs" },
                [
                  _c("div", { staticClass: "search-t" }, [_vm._v(" 产品 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: _vm.MultipleOptions,
                        "collapse-tags": "",
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择产品"
                      },
                      model: {
                        value: _vm.coreChannelSearchData.special_product_id,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.coreChannelSearchData,
                            "special_product_id",
                            $$v
                          )
                        },
                        expression: "coreChannelSearchData.special_product_id"
                      }
                    },
                    _vm._l(_vm.productsList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.getcoreChannel } }, [
            _c("div", { staticClass: "flex" }, [
              _c("i", { staticClass: "iconfont icon-shuaxin" }),
              _c("div", { staticClass: "refresh" }, [_vm._v("立即刷新")])
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "overview-title" }, [_vm._v(" 核心渠道效果 ")]),
      _c(
        "div",
        { staticClass: "coreChannelBox" },
        [
          _c(
            "el-card",
            {
              staticClass: "coreChannel-item",
              staticStyle: { "margin-right": "20px" }
            },
            [
              _c(
                "div",
                { staticClass: "searchbs" },
                [
                  _c("div", { staticClass: "search-t" }, [_vm._v(" 指标 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      on: {
                        change: function($event) {
                          return _vm.coreChannelChange($event, "One")
                        }
                      },
                      model: {
                        value: _vm.ad_channelOne,
                        callback: function($$v) {
                          _vm.ad_channelOne = $$v
                        },
                        expression: "ad_channelOne"
                      }
                    },
                    _vm._l(_vm.overviewList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", {
                staticStyle: { height: "400px" },
                attrs: { id: "echarcoreChannelOne" }
              })
            ]
          ),
          _c("el-card", { staticClass: "coreChannel-item" }, [
            _c(
              "div",
              { staticClass: "searchbs" },
              [
                _c("div", { staticClass: "search-t" }, [_vm._v(" 指标 ")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择"
                    },
                    on: {
                      change: function($event) {
                        return _vm.coreChannelChange($event, "Tow")
                      }
                    },
                    model: {
                      value: _vm.ad_channelTow,
                      callback: function($$v) {
                        _vm.ad_channelTow = $$v
                      },
                      expression: "ad_channelTow"
                    }
                  },
                  _vm._l(_vm.overviewList, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", {
              staticStyle: { height: "400px" },
              attrs: { id: "echarcoreChannelTow" }
            })
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }