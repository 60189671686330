import Cookies from 'js-cookie';
var TokenKey = "d2admin-".concat(process.env.VUE_APP_VERSION, "-token");
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}