var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id !== "" ? "修改" : "新增",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData(true)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "name", label: "名称" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.paramsData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "name", $$v)
                      },
                      expression: "paramsData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "development_config_id", label: "配置ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: { placeholder: "请输入配置ID" },
                    model: {
                      value: _vm.paramsData.development_config_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "development_config_id", $$v)
                      },
                      expression: "paramsData.development_config_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "file", label: "配置文件名" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.file,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "file", $$v)
                        },
                        expression: "paramsData.file"
                      }
                    },
                    _vm._l(_vm.ConfigNameList, function(item, index) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }