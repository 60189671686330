import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accountRetain } from "@/api/dataAll/accountRetain";
import * as echarts from "echarts";
export default {
  name: "accountRetainPirate",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: [],
      dates: [],
      DynamicRR: [],
      seriesRR: [],
      screenRR: [],
      PrimitiveDyRR: [],
      DynamicRRn: [],
      seriesRRn: [],
      screenRRn: [],
      PrimitiveDyRRn: [],
      radio4: "RR",
      cellStylekeys: ["RR", "RRn"]
    };
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      _this.searchClick();
    });
  },
  methods: {
    toggle: function toggle(e) {
      this.tableData[this.tableData.length - 1].date = e == 'RR' ? '加权平均留存' : '总计';
      this.getechart();
    },
    searchClick: function searchClick() {
      var _this2 = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.kind = 1;
      this.reqLoading = true;
      this.timeInterval();
      accountRetain(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this2.tableData = [];
          _this2.dates = [];
          _this2.seriesRR = [];
          _this2.DynamicRR = [];
          _this2.seriesRRn = [];
          _this2.DynamicRRn = [];

          for (var k in res.data.data) {
            _this2.dates.push(k);

            var d = {
              date: k,
              register_num: res.data.other.register_num[k]
            };
            var length = Object.keys(res.data.data[k]).length;
            var oRR = {
              name: k,
              type: "line",
              data: [],
              smooth: true
            };
            var oRRn = {
              name: k,
              type: "line",
              data: [],
              smooth: true
            };

            for (var j in res.data.data[k]) {
              d["RR" + j] = res.data.data[k][j] == "/" ? "--" : _this2.zhuan([res.data.data[k][j]])[0];
              d["RRn" + j] = res.data.retain_pnum[k][j] == "/" ? "--" : res.data.retain_pnum[k][j];
              oRR.data.push(d["RR" + j]);
              oRRn.data.push(d["RRn" + j]);

              if (length > _this2.DynamicRR.length) {
                _this2.DynamicRR.push({
                  label: "R_".concat(j),
                  prop: "RR" + j
                });

                _this2.DynamicRRn.push({
                  label: "R_".concat(j),
                  prop: "RRn" + j
                });
              }
            }

            if (!_this2.screenRR.length) {
              _this2.screenRR = _this2.DynamicRR.map(function (item) {
                return item.prop;
              });
              _this2.screenRRn = _this2.DynamicRRn.map(function (item) {
                return item.prop;
              });
              _this2.PrimitiveDyRR = _this2.DynamicRR;
              _this2.PrimitiveDyRRn = _this2.DynamicRRn;
            }

            _this2.seriesRR.push(oRR);

            _this2.seriesRRn.push(oRRn);

            _this2.screenControls();

            _this2.tableData.push(d);

            _this2.$nextTick(function () {
              _this2.getTableRight();
            });
          } // 总计数据处理


          var total = {
            date: "加权平均留存",
            register_num: res.data.register_sum
          };

          for (var _k in res.data.avgData) {
            total["RR" + _k] = _this2.zhuan([res.data.avgData[_k]])[0];
          }

          for (var _k2 in res.data.retain_total) {
            total["RRn" + _k2] = res.data.retain_total[_k2];
          }

          _this2.tableData.push(total);
        }

        _this2.getechart();
      }).finally(function () {
        _this2.reqLoading = false;
      });
    },
    screenControls: function screenControls() {
      var _this3 = this;

      var lista = [];
      this.PrimitiveDyRR.forEach(function (item) {
        _this3.screenRR.forEach(function (citem) {
          if (item.prop == citem) {
            lista.push(item);
          }
        });
      });
      this.DynamicRR = lista;
      var listb = [];
      this.PrimitiveDyRRn.forEach(function (item) {
        _this3.screenRRn.forEach(function (citem) {
          if (item.prop == citem) {
            listb.push(item);
          }
        });
      });
      this.DynamicRRn = listb;
    },
    zhuan: function zhuan(list) {
      var n = [];
      list.forEach(function (item) {
        n.push(item.length > 0 ? Number(item.substr(0, item.length - 1)) : 0);
      });
      return n;
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("accountRetainPirateEchart");
      var myChart = echarts.init(chartDom);
      var yAxis = {};

      if (this.radio4 == "RR") {
        yAxis = {
          type: "value",
          min: 0,
          max: 100
        };
      } else {
        yAxis = {
          type: "value"
        };
      }

      var option;
      option = {
        title: {
          text: this.radio4 == "RR" ? "汇总留存率(%)" : "汇总留存数"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          // 这里显示点击搜索的日期--图表的最上面
          // selected: this.selectedArr,
          width: "80%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.radio4 == "RR" ? this.PrimitiveDyRR.map(function (item) {
            return item.prop;
          }) : this.PrimitiveDyRRn.map(function (item) {
            return item.prop;
          })
        },
        yAxis: yAxis,
        series: this.radio4 == "RR" ? this.seriesRR : this.seriesRRn
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var list = this.radio4 == "RR" ? this.DynamicRR : this.DynamicRRn;
      var columns = [{
        label: "日期",
        prop: "date"
      }, {
        label: "注册账号",
        prop: "register_num"
      }].concat(_toConsumableArray(list));
      var data = JSON.parse(JSON.stringify(this.tableData));

      if (this.radio4 == "RR") {
        this.DynamicRR.forEach(function (item) {
          data.forEach(function (i) {
            i[item.prop] = i[item.prop] != "--" ? i[item.prop] + "%" : i[item.prop];
          });
        });
      }

      this.$export.excel({
        columns: columns,
        data: data,
        title: this.radio4 == "RR" ? "汇总留存率" : "汇总留存数"
      });
    }
  }
};