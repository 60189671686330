import "core-js/modules/es.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmToolNew } from "@/mixins/index";
export default {
  mixins: [gmToolNew],
  data: function data() {
    return {
      data: [],
      itemList: [],
      dialogShow: false
    };
  },
  created: function created() {},
  methods: {
    init: function init(data) {
      var _this = this;

      this.itemList = [];
      var d = JSON.parse(data);

      var _loop = function _loop(key) {
        _this.itemList.push({
          label: _this.Language.find(function (i) {
            return i.value == key;
          }).label,
          url: d[key]
        });
      };

      for (var key in d) {
        _loop(key);
      }

      this.dialogShow = true;
    }
  }
};