var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "处理", visible: _vm.dialogShow, modal: false },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c(
              "div",
              { staticClass: "marginB", staticStyle: { width: "50%" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "flex-1",
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.data.is_prod,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "is_prod", $$v)
                      },
                      expression: "data.is_prod"
                    }
                  },
                  _vm._l(_vm.severlist, function(item) {
                    return _c("el-option", {
                      key: item.label,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: { minRows: 2, maxRows: 12 },
                    placeholder: "请输入回复内容"
                  },
                  model: {
                    value: _vm.data.content,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "content", $$v)
                    },
                    expression: "data.content"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }