// 多日对比
import request from "@/utils/request";
export function dataCompare(data) {
  return request({
    url: "/data/compare",
    method: "get",
    params: data
  });
}
export function dataCompare_currency(data) {
  return request({
    url: "/data/compare_currency",
    method: "get",
    params: data
  });
}
export function compare_currency_other(data) {
  return request({
    url: "/data/compare_currency_other",
    method: "get",
    params: data
  });
}
export function getEchearts(data) {
  return request({
    url: "/data/compare_chart",
    method: "get",
    params: data
  });
}
export function getEchearts_currency(data) {
  return request({
    url: "/data/compare_chart_currency",
    method: "get",
    params: data
  });
}
export function compare_chart_currency_other(data) {
  return request({
    url: "/data/compare_chart_currency_other",
    method: "get",
    params: data
  });
}