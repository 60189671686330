var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "latestToday" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: {
              propsData: {
                isCountry: false,
                isDate: false,
                isCurrency: true,
                isPayChannel: true
              }
            },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", filterable: "", "collapse-tags": "" },
                  on: { change: _vm.IntegralscreenControls },
                  model: {
                    value: _vm.screen,
                    callback: function($$v) {
                      _vm.screen = $$v
                    },
                    expression: "screen"
                  }
                },
                _vm._l(_vm.originaltableList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "end" }, attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getdataCompare(true)
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "sticky-table-header",
              rawName: "v-sticky-table-header",
              value: 0,
              expression: "0"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        _vm._l(_vm.tableList, function(item, index) {
          return _c(
            "el-table-column",
            {
              key: index,
              attrs: {
                label: item.label,
                "min-width": _vm.$utils.flexColumnWidth(item.label, false)
              }
            },
            _vm._l(item.list, function(titem, tindex) {
              return _c("el-table-column", {
                key: tindex,
                attrs: {
                  prop: titem.prop,
                  label: titem.label,
                  width: titem.width,
                  "min-width": 180
                }
              })
            }),
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }