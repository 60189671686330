// 返回所有第三方登录类型
import request from '@/utils/request';
export function getLoginType(data) {
  return request({
    url: '/pay/login_type_all',
    method: 'get',
    params: data
  });
}
export function undataBind(data) {
  return request({
    url: '/pay/upbind_login_type',
    method: 'post',
    params: data
  });
} // 返显

export function reutnundataBind(data) {
  return request({
    url: '/pay/upbind_login_type',
    method: 'get',
    params: data
  });
}