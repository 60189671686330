import { render, staticRenderFns } from "./gdtparams.vue?vue&type=template&id=2ff40866&scoped=true&"
import script from "./gdtparams.vue?vue&type=script&lang=js&"
export * from "./gdtparams.vue?vue&type=script&lang=js&"
import style0 from "./gdtparams.vue?vue&type=style&index=0&id=2ff40866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff40866",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\admin\\datacenter_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ff40866')) {
      api.createRecord('2ff40866', component.options)
    } else {
      api.reload('2ff40866', component.options)
    }
    module.hot.accept("./gdtparams.vue?vue&type=template&id=2ff40866&scoped=true&", function () {
      api.rerender('2ff40866', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/financial/gdtparams.vue"
export default component.exports