// 等级流失--账号
import request from '@/utils/request';
export function accLossLevel(data) {
  return request({
    url: '/data/account_loss_level',
    method: 'get',
    params: data
  });
} // 等级流失--设备

export function advLossLevel(data) {
  return request({
    url: '/data/device_loss_level',
    method: 'get',
    params: data
  });
} // 在线时长流失--账号

export function accRangeLoss(data) {
  return request({
    url: '/data/account_duration_range_loss',
    method: 'get',
    params: data
  });
} // 在线时长流失--设备

export function advRangeLoss(data) {
  return request({
    url: '/data/device_duration_range_loss',
    method: 'get',
    params: data
  });
}