import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { dataSummary } from "@/api/dataAll/dataSummary";
import pageMinx from "./mixins/pageMinx";
export default {
  mixins: [tabulation, pageMinx],
  data: function data() {
    return {
      screenArr: [{
        label: '新增设备',
        prop: 'new_device_num',
        width: '120'
      }, {
        label: '新增账号',
        prop: 'new_account_num',
        width: '120'
      }, {
        label: '登录账号',
        prop: 'login_account_num',
        width: '120'
      }, {
        label: '活跃账号',
        prop: 'active_account_num',
        width: '120'
      }, {
        label: '当日转化率',
        prop: 'sameday',
        width: '140'
      }, {
        label: '非当日转化率',
        prop: 'otherday',
        width: '155'
      }, {
        label: '新增付费账号数',
        prop: 'register_recharge_pnum',
        width: '160'
      }, {
        label: "新增账号付费金额",
        prop: 'register_recharge_amount',
        width: '180'
      }, {
        label: '付费账号数',
        prop: 'recharge_account_num',
        width: '140'
      }, {
        label: '付费总额',
        prop: 'recharge_account_amount',
        width: '120'
      }, {
        label: '付费率',
        prop: 'pay_account_percent',
        width: '120'
      }, {
        label: 'arpu',
        prop: 'pay_account_arpu',
        width: '110'
      }, {
        label: 'arppu',
        prop: 'pay_account_arppu',
        width: '110'
      }],
      reqFn: 'getDataSummary' // finallyFn:[ 'getDataSummary' ]

    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    resettingData: function resettingData() {
      this.tableData = [];
    },
    // 每日汇总  目前只有海盗版本不要
    getDataSummary: function getDataSummary() {
      var _this = this;

      return new Promise(function (replace) {
        _this.reqLoading = true;
        var reqFn = _this.searchData.currency_info ? dataSummary_currency : dataSummary;
        reqFn(_objectSpread({
          starttime: _this.date,
          endtime: _this.date,
          kind: 1
        }, _this.summarySearchData)).then(function (res) {
          if (res.status == 200) {
            res.data = res.data.data;
            _this.pageData = res.data;

            for (var key in res.data) {
              for (var i in res.data[key].other) {
                _this[i] = [];
              }

              break;
            }

            for (var _key in res.data) {
              res.data[_key].other.pay_account_arpu = _this.$utils.conversion(res.data[_key].other.pay_account_arpu).toFixed(2);
              res.data[_key].other.pay_account_arppu = _this.$utils.conversion(res.data[_key].other.pay_account_arppu).toFixed(2);
              res.data[_key].other.recharge_account_amount = _this.$utils.conversion(res.data[_key].other.recharge_account_amount).toFixed(2);
              res.data[_key].other.register_recharge_amount = _this.$utils.conversion(res.data[_key].other.register_recharge_amount).toFixed(2);
              res.data[_key].other.pay_account_percent = ((res.data[_key].other.pay_account_percent - 0) * 100).toFixed(2);
              res.data[_key].other.sameday = _this.zhuan(res.data[_key].other.sameday);
              res.data[_key].other.otherday = _this.zhuan(res.data[_key].other.otherday);

              for (var _i in res.data[_key].other) {
                _this[_i].push(res.data[_key].other[_i]);
              }
            }

            _this.screenControls();
          }
        }).finally(function () {
          _this.reqLoading = false;
          replace();
        });
      });
    },
    screenControls: function screenControls() {
      var _this2 = this;

      this.show = false;
      this.tableData = [];
      var data = JSON.parse(JSON.stringify(this.pageData));

      var _loop = function _loop(key) {
        var arr = {};

        _this2.screen.forEach(function (item) {
          arr[item] = data[key].other[item];
        });

        _this2.tableData.push(_objectSpread({
          date: key
        }, arr));
      };

      for (var key in data) {
        _loop(key);
      }

      this.getechart();
    },
    zhuan: function zhuan(data) {
      var d = 0;

      if (data) {
        d = Number(data.substr(0, data.length - 1));
      }

      return d;
    },
    getechart: function getechart() {
      var _this3 = this;

      var sericeArr = [];
      this.sericeArr = [];
      this.screenArr.forEach(function (item) {
        if (_this3.screen.findIndex(function (value) {
          return value == item.prop;
        }) > -1) {
          sericeArr.push({
            name: item.label,
            type: "line",
            data: _this3[item.prop],
            smooth: true,
            width: item.width
          });

          _this3.sericeArr.push(item);
        }
      });
    }
  }
};