var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.titleName,
            visible: _vm.isShow,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.isShow = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [_vm._v("游戏:")]),
                  _c(
                    "el-select",
                    {
                      ref: "",
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择游戏"
                      },
                      on: { change: _vm.gamechange },
                      model: {
                        value: _vm.searchData.game,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "game", $$v)
                        },
                        expression: "searchData.game"
                      }
                    },
                    _vm._l(_vm.searchgames, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: {
                            "value-key": item.id,
                            label: item.game_name,
                            value: item.id
                          }
                        },
                        [_vm._v(_vm._s(item.game_name) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [_vm._v("平台:")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择平台"
                      },
                      on: { change: _vm.platformchange },
                      model: {
                        value: _vm.searchData.platform,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "platform", $$v)
                        },
                        expression: "searchData.platform"
                      }
                    },
                    _vm._l(_vm.searchplatforms, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.platform_name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [_vm._v("渠道:")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择渠道"
                      },
                      on: { change: _vm.channelchange },
                      model: {
                        value: _vm.searchData.channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "channel", $$v)
                        },
                        expression: "searchData.channel"
                      }
                    },
                    _vm._l(_vm.searchchannels, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.channel_name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [_vm._v("包名:")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择投放包"
                      },
                      on: { change: _vm.pkgchange },
                      model: {
                        value: _vm.searchData.pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "pkg", $$v)
                        },
                        expression: "searchData.pkg"
                      }
                    },
                    _vm._l(_vm.searchpkgs, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg_name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [_vm._v("礼包码:")]),
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.searchData.channel,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "channel", $$v)
                      },
                      expression: "searchData.channel"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [
                    _vm._v("生效时间:")
                  ]),
                  _c("el-date-picker", {
                    staticClass: "flex-1",
                    attrs: { type: "date", placeholder: "选择日期" },
                    model: {
                      value: _vm.value1,
                      callback: function($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [
                    _vm._v("过期时间:")
                  ]),
                  _c("el-date-picker", {
                    staticClass: "flex-1",
                    attrs: { type: "date", placeholder: "选择日期" },
                    model: {
                      value: _vm.value1,
                      callback: function($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [
                    _vm._v("领取总量:")
                  ]),
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.searchData.channel,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "channel", $$v)
                      },
                      expression: "searchData.channel"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [
                    _vm._v("领取次数:")
                  ]),
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.searchData.channel,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "channel", $$v)
                      },
                      expression: "searchData.channel"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "flex", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "first_box" }, [_vm._v("数量:")]),
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.searchData.channel,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "channel", $$v)
                      },
                      expression: "searchData.channel"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }