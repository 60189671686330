// 在线时长统计账号数据online_account
import request from '@/utils/request';
export function accountDurations(data) {
  return request({
    url: '/data/account_online_duration',
    method: 'get',
    params: data
  });
} // 获取服务器列表

export function accountDurationsSetver(data) {
  return request({
    url: '/data/server_list_data',
    method: 'get',
    params: data
  });
}