var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    "collapse-tags": "",
                    placeholder: "请选择主播"
                  },
                  on: { change: _vm.hostChange },
                  model: {
                    value: _vm.params.hosts,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "hosts", $$v)
                    },
                    expression: "params.hosts"
                  }
                },
                _vm._l(_vm.hostList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.host_name, value: item.host_flag }
                    },
                    [_vm._v(_vm._s(item.host_name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                staticClass: "dataPicker",
                attrs: {
                  "value-format": "timestamp",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: { change: _vm.dataChange },
                model: {
                  value: _vm.data,
                  callback: function($$v) {
                    _vm.data = $$v
                  },
                  expression: "data"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getList } },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { "margin-left": "10px" },
              on: { input: _vm.tabTypeChange },
              model: {
                value: _vm.tabTypeActive,
                callback: function($$v) {
                  _vm.tabTypeActive = $$v
                },
                expression: "tabTypeActive"
              }
            },
            _vm._l(_vm.tabType, function(item, index) {
              return _c(
                "el-radio-button",
                { key: index, attrs: { label: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tabTypeActive == 0
        ? _c("div", [
            _vm.list.length
              ? _c(
                  "div",
                  { staticClass: "tableBox" },
                  _vm._l(_vm.list, function(listItem, listIndex) {
                    return _c(
                      "table",
                      {
                        key: listIndex,
                        style: { background: listItem.bgColor },
                        attrs: { border: "1" }
                      },
                      [
                        _c("tr", { attrs: { id: "header" } }, [
                          _c("td", { attrs: { colspan: "23" } }, [
                            _vm._v(_vm._s(listItem.title))
                          ])
                        ]),
                        _c(
                          "tr",
                          { attrs: { id: "header" } },
                          _vm._l(_vm.oneTabList, function(item, index) {
                            return _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.isShow,
                                    expression: "!item.isShow"
                                  }
                                ],
                                key: index,
                                attrs: {
                                  rowspan: !item.top ? 2 : "",
                                  colspan: item.colspan || ""
                                }
                              },
                              [_vm._v(_vm._s(item.top ? item.top : item.label))]
                            )
                          }),
                          0
                        ),
                        _c(
                          "tr",
                          { attrs: { id: "header" } },
                          _vm._l(_vm.oneTabList, function(item, index) {
                            return _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.top,
                                    expression: "item.top"
                                  }
                                ],
                                key: index
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }),
                          0
                        ),
                        _vm._l(listItem.list, function(dataItem, dataIndex) {
                          return _c("tr", { key: dataIndex }, [
                            _c("td", [_vm._v(_vm._s(dataItem.time))]),
                            _c("td", [_vm._v(_vm._s(dataItem.a))]),
                            _c("td", [_vm._v(_vm._s(dataItem.b))]),
                            _c("td", [_vm._v(_vm._s(dataItem.c))]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.fengche_register_num))
                            ]),
                            _c("td", [_vm._v(_vm._s(dataItem.d))]),
                            _c("td", [_vm._v(_vm._s(dataItem.e))]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.total_recharge_amount))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.total_recharge_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.total_register_num))
                            ]),
                            _c("td", [_vm._v(_vm._s(dataItem.f))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  dataItem.fengche_register_recharge_amount
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(dataItem.fengche_register_recharge_num)
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(dataItem.fengche_old_recharge_amount)
                              )
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.fengche_old_recharge_num))
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(dataItem.zhuli_register_recharge_amount)
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(dataItem.zhuli_register_recharge_num)
                              )
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.zhuli_old_recharge_amount))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.zhuli_old_recharge_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.android_register_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.android_recharge_amount))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.ios_register_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.ios_recharge_amount))
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.tabTypeActive == 1
        ? _c("div", [
            _vm.list.length
              ? _c(
                  "div",
                  { staticClass: "tableBox" },
                  _vm._l(_vm.list, function(listItem, listIndex) {
                    return _c(
                      "table",
                      {
                        key: listIndex,
                        style: { background: listItem.bgColor },
                        attrs: { border: "1" }
                      },
                      [
                        _c("tr", { attrs: { id: "header" } }, [
                          _c("td", { attrs: { colspan: "17" } }, [
                            _vm._v(_vm._s(listItem.title))
                          ])
                        ]),
                        _c(
                          "tr",
                          { attrs: { id: "header" } },
                          _vm._l(_vm.toTabList, function(item, index) {
                            return _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.isShow,
                                    expression: "!item.isShow"
                                  }
                                ],
                                key: index,
                                attrs: {
                                  rowspan: item.rowspan,
                                  colspan: item.colspan
                                }
                              },
                              [_vm._v(_vm._s(item.platform || item.label))]
                            )
                          }),
                          0
                        ),
                        _c(
                          "tr",
                          { attrs: { id: "header" } },
                          _vm._l(_vm.toTabList, function(item, index) {
                            return _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.isShowtow,
                                    expression: "!item.isShowtow"
                                  }
                                ],
                                key: index,
                                attrs: { colspan: item.towCol }
                              },
                              [_vm._v(_vm._s(item.top))]
                            )
                          }),
                          0
                        ),
                        _c(
                          "tr",
                          { attrs: { id: "header" } },
                          _vm._l(_vm.toTabKey, function(item, index) {
                            return _c("td", { key: index }, [
                              _vm._v(_vm._s(item.label))
                            ])
                          }),
                          0
                        ),
                        _vm._l(listItem.list, function(dataItem, dataIndex) {
                          return _c("tr", { key: dataIndex }, [
                            _c("td", [_vm._v(_vm._s(dataItem.time))]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.aggregate_income))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.aggregate_addition))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.bahuang_register_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.bahuang_activity_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.bahuang_recharge_amount))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.fengche_register_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.fengche_activity_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.fengche_recharge_amount))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.bhios_register_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.bhios_activity_num))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(dataItem.bhios_recharge_amount))
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }