import request from '@/utils/request';
export function roleGame(data) {
  return request({
    url: 'rule/ruleGame',
    method: 'get',
    params: data
  });
}
export function postroleGame(data) {
  return request({
    url: 'rule/ruleGame',
    method: 'post',
    params: data
  });
}
export function getRoutes() {
  return request({
    url: '/vue-element-admin/routes',
    method: 'get'
  });
}
export function getRoles() {
  return request({
    url: '/vue-element-admin/roles',
    method: 'get'
  });
}
export function addRole(data) {
  return request({
    url: '/vue-element-admin/role',
    method: 'post',
    params: data
  });
}
export function updateRole(id, data) {
  return request({
    url: "/vue-element-admin/role/".concat(id),
    method: 'put',
    params: data
  });
}
export function deleteRole(id) {
  return request({
    url: "/vue-element-admin/role/".concat(id),
    method: 'delete'
  });
}
export function rolesList(data) {
  return request({
    url: '/auth/roles',
    method: 'get',
    params: data
  });
}
export function queryAdd(data) {
  return request({
    url: '/rule/add',
    method: 'get',
    params: data
  });
}
export function roleAdd(data) {
  return request({
    url: '/rule/add',
    method: 'post',
    params: data
  });
}
export function disableRole(data) {
  return request({
    url: '/rule/disableRole',
    method: 'get',
    params: data
  });
}
export function delRole(data) {
  return request({
    url: '/rule/delRole',
    method: 'get',
    params: data
  });
}
export function queryupRole(data) {
  return request({
    url: '/rule/upRole',
    method: 'get',
    params: data
  });
}
export function upRole(data) {
  return request({
    url: '/rule/upRole',
    method: 'post',
    params: data
  });
} // 获取全部路由

export function getAllRouter(data) {
  return request({
    url: '/route/all',
    method: 'get',
    params: data
  });
}
export function routesList(data) {
  return request({
    url: '/auth/routes',
    method: 'get',
    params: data
  });
}
export function addRouter(data) {
  return request({
    url: '/route/add',
    method: 'post',
    params: data
  });
}
export function queryFisetRouter(data) {
  return request({
    url: '/route/add',
    method: 'get',
    params: data
  });
}
export function queryupRoute(data) {
  return request({
    url: '/route/upRoute',
    method: 'get',
    params: data
  });
}
export function updataRoute(data) {
  return request({
    url: '/route/upRoute',
    method: 'post',
    params: data
  });
}
export function delRoute(data) {
  return request({
    url: '/route/delRoute',
    method: 'get',
    params: data
  });
} // 获取平台是否与角色关联

export function rolePlatform(data) {
  return request({
    url: '/rule/role-platform',
    method: 'get',
    params: data
  });
} // 关联角色与平台

export function linkrolePlatform(data) {
  return request({
    url: '/rule/link-role-platform',
    method: 'post',
    params: data
  });
} // 获取渠道是否与角色关联

export function roleChannel(data) {
  return request({
    url: '/rule/role-channel',
    method: 'get',
    params: data
  });
} // 关联角色与渠道

export function linkroleChannel(data) {
  return request({
    url: '/rule/link-role-channel',
    method: 'post',
    params: data
  });
} // 获取包名是否与角色关联

export function rolePkg(data) {
  return request({
    url: '/rule/role-pkg',
    method: 'get',
    params: data
  });
} // 获取平台是否与角色关联

export function linkrolePkg(data) {
  return request({
    url: '/rule/link-role-pkg',
    method: 'post',
    params: data
  });
} // 获取所有游戏

export function roleProductData(data) {
  return request({
    url: '/rule/roleProductData',
    method: 'get',
    params: data
  });
} // 修改入口配置

export function roleProductUpd(data) {
  return request({
    url: '/rule/roleProductUpd',
    method: 'post',
    params: data
  });
} // 获取角色关联产品权限

export function getRoleProduct(data) {
  return request({
    url: '/rule/getRoleProduct',
    method: 'get',
    params: data
  });
} // 修改角色产品权限

export function updRoleProduct(data) {
  return request({
    url: '/rule/updRoleProduct',
    method: 'post',
    params: data
  });
}