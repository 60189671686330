import "core-js/modules/es.array.concat";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { rechargeRetain, rechargeRetain_currency } from "@/api/dataList";
import * as echarts from "echarts";
export default {
  name: "paidRetention",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        start_time: "",
        end_time: ""
      },
      tableData: [],
      cyclicData: [],
      dates: [],
      datas: [],
      series: [],
      intReq: [],
      cellStylekeys: ["retainnumAndretain"]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.searchData.start_time = this.$times.timeStamp(this.value2[0], "m");
      this.searchData.end_time = this.$times.timeStamp(this.value2[1], "m");
      this.reqLoading = true;
      var reqFn = this.currencyClose ? rechargeRetain_currency : rechargeRetain;
      this.timeInterval();
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.code == 200) {
          _this.dates = [];
          _this.series = [];
          _this.cyclicData = [];
          _this.datas = [];
          _this.tableData = [];
          var newData = JSON.parse(JSON.stringify(res.data));

          for (var k in newData.data) {
            var data = {
              date: k,
              recharge_num: newData.data[k].recharge_num
            };

            _this.dates.push(k);

            var length = Object.keys(newData.data[k].retain).length;
            var seriesList = [];

            for (var i in newData.data[k].retain) {
              var retains = ((newData.data[k].retain[i] == "/" ? 0 : newData.data[k].retain[i]) * 100).toFixed(2);
              seriesList.push(retains);
              data["retainnumAndretain" + i] = (newData.data[k].retain_num[i] == "/" ? 0 : newData.data[k].retain_num[i]) + " / " + (retains == "0.00" ? 0 : retains) + "%";

              if (_this.cyclicData.length < length) {
                var t = i == 2 ? "次" : i;

                _this.cyclicData.push({
                  label: "(".concat(t, "\u65E5) \u7559\u5B58\u6570 / \u7559\u5B58\u7387"),
                  prop: "retainnumAndretain" + i,
                  tableText: i == 2 ? "(".concat(t, "\u65E5) \u7559\u5B58\u6570 / \u7559\u5B58\u7387A") : "(".concat(t, "\u65E5) \u7559\u5B58\u6570 / \u7559\u5B58\u7387"),
                  desc: i == 2 ? "次日账号留存率" : ""
                });

                _this.datas.push(t + "日留存");
              }
            }

            _this.series.push({
              name: k,
              type: "line",
              data: seriesList,
              smooth: true
            });

            _this.tableData.push(data);
          } // 总计数据处理


          var total = {
            date: "加权平均留存",
            recharge_num: newData.total_sum
          };

          for (var _k in newData.avgData) {
            total["retainnumAndretain" + _k] = newData.avgData[_k];
          }

          _this.tableData.push(total);
        }

        _this.$nextTick(function () {
          _this.getTableRight();
        });

        _this.getechart();
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("paidRetentionEchart");
      var myChart = echarts.init(chartDom);
      var option;
      var dates = this.dates;
      var datas = this.datas;
      var series = this.series;
      option = {
        title: {
          text: "日留存(%)"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: dates,
          // 这里显示点击搜索的日期--图表的最上面
          // selected: this.selectedArr,
          width: "80%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: datas
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100
        },
        series: series
      };
      option && myChart.setOption(option, true);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.page = val;
      this.searchClick();
    },
    exportdata: function exportdata() {
      var data = this.tableData;
      var columns = [{
        label: "时间",
        prop: "date"
      }, {
        label: "注册数量",
        prop: "recharge_num"
      }].concat(_toConsumableArray(this.cyclicData));
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};