var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "retentionAnalysisTitle commonalitText commonalityBox" },
        [_vm._v(" 显示满足如下行为模式用户 ")]
      ),
      _c(
        "div",
        { staticClass: " commonalityBox" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 1.5 } }, [
                _c("div", { staticClass: "commonalitText" }, [
                  _vm._v(" 初始行为是: ")
                ])
              ]),
              _c(
                "el-col",
                { attrs: { span: 3.5 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "开始行为" },
                      on: {
                        change: function($event) {
                          return _vm.beginactionChange($event, "1")
                        }
                      },
                      model: {
                        value: _vm.searchData.begin_action,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "begin_action", $$v)
                        },
                        expression: "searchData.begin_action"
                      }
                    },
                    _vm._l(_vm.behaviorList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: " commonalityBox" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 1.5 } }, [
                _c("div", { staticClass: "commonalitText" }, [
                  _vm._v(" 后续行为是: ")
                ])
              ]),
              _c(
                "el-col",
                { attrs: { span: 3.5 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "后续行为" },
                      on: {
                        change: function($event) {
                          return _vm.beginactionChange($event, "2")
                        }
                      },
                      model: {
                        value: _vm.searchData.retain_action,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "retain_action", $$v)
                        },
                        expression: "searchData.retain_action"
                      }
                    },
                    _vm._l(_vm.rehaviorList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: " commonalityBox" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 1.5 } }, [
                _c("div", { staticClass: "commonalitText" }, [
                  _vm._v(" 且用户符合: ")
                ])
              ]),
              _c(
                "el-col",
                { attrs: { span: 3.5 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择广告渠道"
                      },
                      on: { change: _vm.advChannelchangeMore },
                      model: {
                        value: _vm.searchData.ad_channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_channel", $$v)
                        },
                        expression: "searchData.ad_channel"
                      }
                    },
                    _vm._l(_vm.advchannels, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.adv_platform_name,
                          value: item.adv_platform_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3.5 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择广告位置"
                      },
                      model: {
                        value: _vm.searchData.ad_placement,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_placement", $$v)
                        },
                        expression: "searchData.ad_placement"
                      }
                    },
                    _vm._l(_vm.advLocation, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.adv_place_name,
                          value: item.adv_place_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3.5 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "广告主"
                      },
                      model: {
                        value: _vm.searchData.ad_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_id", $$v)
                        },
                        expression: "searchData.ad_id"
                      }
                    },
                    _vm._l(_vm.advertiserLists, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.ad_name, value: item.ad_id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3.5 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "分析类型" },
                      model: {
                        value: _vm.searchData.analyze_type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "analyze_type", $$v)
                        },
                        expression: "searchData.analyze_type"
                      }
                    },
                    _vm._l(_vm.analyzetypeList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          disabled: item.disabled,
                          label: item.label,
                          value: item.value
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: " commonalityBox" },
        [
          _c(
            "el-row",
            {
              staticClass: "commonalityJust",
              staticStyle: { padding: "30px 0" },
              attrs: { gutter: 20 }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      clearable: false,
                      "picker-options": _vm.pickerOptionsRadius,
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      placeholder: "请选择操作时间"
                    },
                    model: {
                      value: _vm.value2,
                      callback: function($$v) {
                        _vm.value2 = $$v
                      },
                      expression: "value2"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "Tipss" }, [
                _vm._v(
                  " 用户" +
                    _vm._s(
                      _vm.behaviorList.find(function(i) {
                        return i.value == _vm.searchData.begin_action
                      }).label
                    ) +
                    "后," +
                    _vm._s(
                      _vm.retentionPrimitive.find(function(i) {
                        return i.value == _vm.searchData.retain_days
                      }).value + 1
                    ) +
                    "日" +
                    _vm._s(
                      _vm.behaviorList.find(function(i) {
                        return i.value == _vm.searchData.retain_action
                      })
                        ? _vm.behaviorList.find(function(i) {
                            return i.value == _vm.searchData.retain_action
                          }).label
                        : ""
                    ) +
                    "行为留存分析 "
                )
              ]),
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        "collapse-tags": "",
                        filterable: "",
                        placeholder: "留存"
                      },
                      model: {
                        value: _vm.searchData.retain_days,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "retain_days", $$v)
                        },
                        expression: "searchData.retain_days"
                      }
                    },
                    _vm._l(_vm.retentionPrimitive, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingBtn },
                      on: { click: _vm.searchClick }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "cell-class-name": _vm.cellStyle
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "date",
              fixed: "",
              "class-name": "font-bold",
              label: "日期",
              "render-header": _vm.renderheader,
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "tableDataText " }, [
                      _vm._v(" " + _vm._s(row.date) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "totalNum",
              label: "总人数",
              "render-header": _vm.renderheader,
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "tableDataText " }, [
                      _vm._v(" " + _vm._s(row.totalNum) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._l(_vm.Dynamic, function(i, ind) {
            return _c("el-table-column", {
              key: "retent" + ind,
              attrs: {
                prop: i.prop,
                label: i.label,
                "render-header": function(h, obj) {
                  return _vm.renderheader(h, obj, i.desc)
                },
                "min-width": "120"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "tableDataText " }, [
                          _c("div", { staticClass: "tableDataText-c" }, [
                            _vm._v(_vm._s(row[i.prop.split(",")[0]]) + "人")
                          ]),
                          _c("div", { staticClass: "tableDataText-b" }, [
                            _vm._v(_vm._s(row[i.prop.split(",")[1]]) + "%")
                          ])
                        ])
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }