import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { getBackpackDetail } from "@/api/dataAll/backPack";
export default {
  name: "backPackDetail",
  mixins: [tabulation],
  data: function data() {
    return {
      searchData: {
        server_id: "",
        device_id: "",
        user_id: "",
        role_id: "",
        name: "",
        desc_name: "",
        desc_kind: ""
      },
      currentPage: 1,
      pageSize: 13,
      tableData: [],
      // 表格数据
      tableCopyTableList: [],
      // 表格copy数据b
      isgetdataCurrency: true,
      adPlacementSingleChoice: true
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.searchData.kind = 1;
      var searchData = JSON.parse(JSON.stringify(this.searchData));
      searchData.ad_placement = searchData.ad_placement == "valuets" ? [""] : searchData.ad_placement;
      this.reqLoading = true;
      getBackpackDetail(searchData).then(function (res) {
        if (res.status == 200) {
          var list = ["game", "platform", "channel", "pkg", "ad_channel", "ad_placement"];
          _this.tableData = res.data.length ? _this.$utils.dataConversion(res.data, list) : [];
          _this.currentPage = 1;
          _this.tableCopyTableList = JSON.parse(JSON.stringify(_this.tableData));
          _this.tableData = _this.currentChangePage(_this.pageSize, _this.currentPage);

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    // 页数改变事件
    handleSizeChange: function handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.tableData = this.currentChangePage(pageSize, this.currentPage);
    },
    // 页码改变切换
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.tableData = this.currentChangePage(this.pageSize, currentPage);
    },
    // 分页方法
    currentChangePage: function currentChangePage(size, current) {
      var tablePush = [];
      this.tableCopyTableList.forEach(function (item, index) {
        if (size * (current - 1) <= index && index <= size * current - 1) {
          tablePush.push(item);
        }
      });
      return tablePush;
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "游戏",
        prop: "game_reveal"
      }, {
        label: "平台",
        prop: "platform_reveal"
      }, {
        label: "渠道",
        prop: "channel_reveal"
      }, {
        label: "包名",
        prop: "pkg_reveal"
      }, {
        label: "广告平台",
        prop: "ad_channel_reveal"
      }, {
        label: "广告位置",
        prop: "ad_placement_reveal"
      }, {
        label: "服务器ID",
        prop: "server_id"
      }, {
        label: "设备号",
        prop: "device_id"
      }, {
        label: "账号",
        prop: "user_id"
      }, {
        label: "角色ID",
        prop: "role_id"
      }, {
        label: "角色名称",
        prop: "name"
      }, {
        label: "物品类型",
        prop: "desc_kind"
      }, {
        label: "物品名称",
        prop: "desc_name"
      }, {
        label: "物品数量",
        prop: "num"
      }];
      var data = this.tableCopyTableList;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    },
    headerZh: function headerZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      var num = k > 0 ? letterTable[k - 1] : "";

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[k <= 1 ? index * 2 : index];
        } else {
          num = num + letterTable[k <= 1 ? index * 2 + 1 : index + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2];
        } else {
          num = num + letterTable[index % 13 * 2 + 1];
        }
      }

      return num + "1";
    }
  }
};