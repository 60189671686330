var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { staticClass: "d2-mr", attrs: { size: "small", trigger: "click" } },
    [
      _c("span", { staticClass: "btn-text" }, [
        _vm._v(_vm._s(_vm.info.name ? "你好 " + _vm.info.name : "未登录"))
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.logOff($event)
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "power-off" }
              }),
              _vm._v(" 退出登录 ")
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.goG("2")
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "delicious" }
              }),
              _vm._v(" 功能菜单 ")
            ],
            1
          ),
          _c(
            "div",
            { on: { mouseover: _vm.mouseenters, mouseleave: _vm.mouseleaves } },
            [
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function($event) {
                      return _vm.goG("1")
                    }
                  }
                },
                [
                  _c("d2-icon", {
                    staticClass: "d2-mr-5",
                    attrs: { name: "life-buoy" }
                  }),
                  _vm._v(" 游戏入口 ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.goG("3")
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "retweet" }
              }),
              _vm._v(" 重置路由 ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }