var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rawData.game_show_type == 1 && _vm.isProduct
        ? _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: _vm.MultipleOptions,
                    "collapse-tags": "",
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择产品"
                  },
                  on: { change: _vm.productChange },
                  model: {
                    value: _vm.searchData.special_product_id,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "special_product_id", $$v)
                    },
                    expression: "searchData.special_product_id"
                  }
                },
                _vm._l(_vm.productsList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.adChannel
        ? _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    "collapse-tags": "",
                    filterable: "",
                    placeholder: "请选择广告渠道"
                  },
                  on: { change: _vm.advChannelchangeMore },
                  model: {
                    value: _vm.searchData.ad_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_channel", $$v)
                    },
                    expression: "searchData.ad_channel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_platform_name,
                      value: item.adv_platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.adPlacement
        ? _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    "collapse-tags": "",
                    filterable: "",
                    placeholder: "请选择广告位置"
                  },
                  model: {
                    value: _vm.searchData.ad_placement,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_placement", $$v)
                    },
                    expression: "searchData.ad_placement"
                  }
                },
                _vm._l(_vm.advLocation, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_place_name,
                      value: item.adv_place_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isPayChannel && _vm.payChannel.length
        ? _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择支付渠道"
                  },
                  model: {
                    value: _vm.searchData.pay_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pay_channel", $$v)
                    },
                    expression: "searchData.pay_channel"
                  }
                },
                _vm._l(_vm.payChannel, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isCurrency && _vm.searchCurrency.length
        ? _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择货币类型"
                  },
                  model: {
                    value: _vm.searchData.currency_info,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "currency_info", $$v)
                    },
                    expression: "searchData.currency_info"
                  }
                },
                _vm._l(_vm.searchCurrency, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.currency_name, value: item.currency }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isCountry
        ? _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: _vm.countryMultiple,
                    clearable: "",
                    "collapse-tags": "",
                    filterable: "",
                    placeholder: "请选择国家"
                  },
                  model: {
                    value: _vm.searchData.country,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "country", $$v)
                    },
                    expression: "searchData.country"
                  }
                },
                _vm._l(_vm.searchCountry, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.country, value: item.country }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isDate
        ? _c(
            "el-col",
            { staticStyle: { position: "relative" }, attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: false,
                  "picker-options": _vm.optionType,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.value2,
                  callback: function($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2"
                }
              }),
              _vm.isTips
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "tipss",
                      attrs: { effect: "dark", placement: "top" }
                    },
                    [
                      _c("div", {
                        attrs: { slot: "content" },
                        domProps: {
                          innerHTML: _vm._s("此时间范围为开服时间范围")
                        },
                        slot: "content"
                      }),
                      _c("i", { staticClass: "el-icon-question " })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }