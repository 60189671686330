import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";

function timestampToTime(timestamp, type) {
  var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  var Y = date.getFullYear();
  var M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  if (type == "YY-MM-DD") {
    return Y + "-" + M + "-" + D;
  } else if (type == "HH:MM:SS") {
    return h + ":" + m + ":" + s;
  } else {
    return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s;
  }
} // 获取两个日期之间的所有日期


function getDayAll(starDay, endDay) {
  var arr = [];
  var dates = []; // 设置两个日期UTC时间

  var db = new Date(starDay);
  var de = new Date(endDay); // 获取两个日期GTM时间

  var s = db.getTime() - 24 * 60 * 60 * 1000;
  var d = de.getTime() - 24 * 60 * 60 * 1000; // 获取到两个日期之间的每一天的毫秒数

  for (var i = s; i <= d;) {
    i = i + 24 * 60 * 60 * 1000;
    arr.push(parseInt(i));
  } // 获取每一天的时间  YY-MM-DD


  for (var j in arr) {
    var time = new Date(arr[j]);
    var year = time.getFullYear(time);
    var mouth = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1);
    var day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
    var YYMMDD = year + "-" + mouth + "-" + day;
    dates.push(YYMMDD);
  }

  return dates;
} // 获取0时时间


function zeroTimeZone() {
  // let zeroTime = new Date();
  // const day = String(zeroTime.getUTCDate()).padStart(2, "0"); // 获取日期，并在需要时补零
  // const month = String(zeroTime.getUTCMonth() + 1).padStart(2, "0"); // 获取月份，并在需要时补零
  // const year = zeroTime.getUTCFullYear(); // 获取年份
  // const hours = String(zeroTime.getUTCHours()).padStart(2, "0"); // 获取小时，并在需要时补零
  // const minutes = String(zeroTime.getUTCMinutes()).padStart(2, "0"); // 获取分钟，并在需要时补零
  // const seconds = String(zeroTime.getUTCSeconds()).padStart(2, "0"); // 获取秒钟，并在需要时补零
  // return  `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  var date = new Date();
  var utcDate = Date.now() + date.getTimezoneOffset() * 60 * 1000;
  return utcDate + process.env.VUE_APP_DISTRICT * 60 * 60 * 1000;
}

function formDate(dateForm) {
  var time = new Date(dateForm).getTime();
  var times = new Date(time);
  var y = times.getFullYear();
  var m = times.getMonth() + 1;
  var d = times.getDate();
  var h = times.getHours();
  var mm = times.getMinutes();
  var s = times.getSeconds();
  return y + "-" + repair0(m) + "-" + repair0(d) + " " + repair0(h) + ":" + repair0(mm) + ":" + repair0(s);
}

function repair0(m) {
  return m < 10 ? "0" + m : m;
}

function timeStamp(date) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "m";
  var dates = new Date(date).getTime();

  if (type == "m") {
    dates = dates / 1000;
  }

  return dates;
}

function intervalTime(startTime, endTime) {
  var t = (startTime - endTime) / (24 * 60 * 60 * 1000);
  return Math.abs(t);
}

function TimeTheDay(type) {
  // type 1 时间日期 2 时间戳
  var stamp1 = new Date().setHours(0, 0, 0, 0);
  var stamp2 = new Date().setHours(23, 59, 59, 59);
  var date = [type ? this.timestampToTime(stamp1, "YY-MM-DD") : stamp1, type ? this.timestampToTime(stamp2, "YY-MM-DD") : stamp2];
  return date;
}

function getElapsedTime(startTimes, endTimes) {
  var startTime = new Date(startTimes); // 开始时间

  var endTime = endTimes ? new Date(endTimes) : new Date(); // 结束时间

  var usedTime = endTime - startTime; // 相差的毫秒数

  var days = Math.floor(usedTime / (24 * 3600 * 1000)); // 计算出天数

  var leavel = usedTime % (24 * 3600 * 1000); // 计算天数后剩余的时间

  var hours = Math.floor(leavel / (3600 * 1000)); // 计算剩余的小时数

  var leavel2 = leavel % (3600 * 1000); // 计算剩余小时后剩余的毫秒数

  var minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数

  var str = "";

  if (days) {
    str += days + "天";
  }

  if (hours) {
    str += hours + "时";
  }

  if (minutes) {
    str += minutes + "分";
  }

  return str;
}

function differenceTime(startTimes, endTimes) {
  var startTime = new Date(startTimes); // 开始时间

  var endTime = endTimes ? new Date(endTimes) : new Date(); // 结束时间

  var usedTime = endTime - startTime; // 相差的毫秒数

  var days = Math.floor(usedTime / (24 * 3600 * 1000)); // 计算出天数

  return days;
}

function dateTimeToTimeStamp(timeStamp) {
  var reg = /^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})$/;
  var res = timeStamp.match(reg);

  if (res == null) {
    var reg2 = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4}) ([0-9]{2}):([0-9]{2}):([0-9]{2})$/;
    var res2 = timeStamp.match(reg2);

    if (res2 == null) {
      console.log("时间格式错误 E001");
      return false;
    } else {
      var year = parseInt(res2[3]);
      var month = parseInt(res2[1]);
      var day = parseInt(res2[2]);
      var h = parseInt(res2[4]);
      var i = parseInt(res2[5]);
      var s = parseInt(res2[6]);
    }
  } else {
    var year = parseInt(res[1]);
    var month = parseInt(res[2]);
    var day = parseInt(res[3]);
    var h = parseInt(res[4]);
    var i = parseInt(res[5]);
    var s = parseInt(res[6]);
  }

  if (year < 1000) {
    console.log("时间格式错误");
    return false;
  }

  if (h < 0 || h > 24) {
    console.log("时间格式错误");
    return false;
  }

  if (i < 0 || i > 60) {
    console.log("时间格式错误");
    return false;
  }

  if (s < 0 || s > 60) {
    console.log("时间格式错误");
    return false;
  }

  return Date.parse(new Date(year, month - 1, day, h, i, s));
}

export default {
  timestampToTime: timestampToTime,
  formDate: formDate,
  intervalTime: intervalTime,
  timeStamp: timeStamp,
  TimeTheDay: TimeTheDay,
  getElapsedTime: getElapsedTime,
  dateTimeToTimeStamp: dateTimeToTimeStamp,
  differenceTime: differenceTime,
  zeroTimeZone: zeroTimeZone,
  getDayAll: getDayAll
};