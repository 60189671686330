import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accrechargeVip } from "@/api/dataAll/recharge"; //充值vip搜索接口

export default {
  name: "accrechargeVip",
  mixins: [tabulation],
  data: function data() {
    return {
      num: 1,
      tableData: [],
      show: false,
      dates: [],
      datas: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.reqLoading = true;
      accrechargeVip(this.searchData).then(function (res) {
        _this.tableData = res.data || [];

        _this.$nextTick(function () {
          _this.getTableRight();
        });
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: 'VIP等级',
        prop: 'vip'
      }, {
        label: '数量',
        prop: 'count'
      }];
      var data = this.tableData;
      data.map(function (item, index) {
        item.id = index + 1;
      });
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};