var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: {
              propsData: {
                isCountry: false,
                adChannel: false,
                adPlacement: false
              }
            },
            on: { searchChange: _vm.searchChange }
          })
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSource()
                    }
                  }
                },
                [_vm._v("新增")]
              ),
              _vm.tableData.length
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exportdata }
                    },
                    [_vm._v("导出")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "60" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "game_reveal",
                  label: "游戏",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform_reveal",
                  label: "平台",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channel_reveal",
                  label: "渠道",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pkg_reveal",
                  label: "包名",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "timestamp", label: "日期" }
              }),
              _c("el-table-column", {
                attrs: { prop: "email_list", label: "邮箱列表" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "160",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "x-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleSource(scope.row)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _c("div", { staticClass: "controlsBtn-vertical" }),
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1331420832
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c("el-pagination", {
            attrs: {
              layout: "total,sizes, prev, pager, next",
              "current-page": _vm.searchData.page,
              total: _vm.total
            },
            on: {
              "current-change": _vm.searchClick,
              "size-change": _vm.sizeChange
            }
          })
        : _vm._e(),
      _c("addNotic", {
        ref: "addWayConfig",
        attrs: { showing: _vm.showing },
        on: {
          refreshDataList: function($event) {
            return _vm.searchClick(_vm.searchData.page)
          },
          changeShow: _vm.changeShow
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }