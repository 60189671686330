//监测原始数据
import request from '@/utils/request';
export function getRowData(data) {
  return request({
    url: '/datum/monitoring_raw_data',
    method: 'get',
    params: data
  });
}
export function getRowDatajl(data) {
  return request({
    url: '/datum/monitoring_jl_raw_data',
    method: 'get',
    params: data
  });
}
export function getRowDatagdt(data) {
  return request({
    url: '/datum/monitoring_gdt_raw_data',
    method: 'get',
    params: data
  });
}