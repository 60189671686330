import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { activityDevireLtvCurrencyOther } from "@/api/dataAll/accountLtv";
import * as echarts from "echarts";
export default {
  name: "dataLtvac",
  mixins: [tabulation],
  data: function data() {
    return {
      value2: [],
      tableData: [],
      dates: [],
      series: [],
      intReq: [],
      Dynamic: [],
      PrimitiveDy: [],
      screen: [],
      cellStylekeys: ["LTV"]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.searchData.starttime = this.value2 ? this.value2[0] : '';
      this.searchData.endtime = this.value2 ? this.value2[1] : '';
      this.searchData.kind = 1;
      this.reqLoading = true;
      this.timeInterval();
      activityDevireLtvCurrencyOther(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this.tableData = [];
          _this.dates = [];
          _this.screen = [];
          _this.series = [];
          _this.Dynamic = [];
          _this.PrimitiveDy = [];
          delete res.data.ltv_total['0'];
          res.data.ltv_data['总计'] = {
            ltv: res.data.ltv_total,
            other: {
              new_device_num: res.data.device_total
            }
          };

          for (var k in res.data.ltv_data) {
            var data = {
              date: k,
              new_device_num: res.data.ltv_data[k].other.new_device_num
            };

            _this.dates.push(k);

            var obj = {
              name: k,
              type: "line",
              data: [],
              smooth: true
            };
            var length = Object.keys(res.data.ltv_data[k].ltv).length;

            for (var j in res.data.ltv_data[k].ltv) {
              data['LTV' + j] = res.data.ltv_data[k].ltv[j] == '/' ? 0.000 : _this.$utils.conversion(res.data.ltv_data[k].ltv[j]).toFixed(3);
              obj.data.push(data['LTV' + j]);

              if (_this.Dynamic.length < length) {
                _this.Dynamic.push({
                  label: "\u6FC0\u6D3B\u8BBE\u5907LTV".concat(j),
                  prop: 'LTV' + j,
                  tableText: j == 1 ? "\u6FC0\u6D3B\u8BBE\u5907LTV".concat(j, "A") : "\u6FC0\u6D3B\u8BBE\u5907LTV".concat(j),
                  desc: j == 1 ? '首日激活设备平均价值' : ''
                });
              }
            }

            if (!_this.screen.length) {
              _this.screen = _this.Dynamic.map(function (item) {
                return item.prop;
              });
            }

            _this.PrimitiveDy = _this.Dynamic;

            _this.screenControls();

            _this.series.push(obj);

            _this.tableData.push(data);
          }

          _this.$nextTick(function () {
            _this.getechart();

            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    screenControls: function screenControls() {
      var _this2 = this;

      var list = [];
      this.Dynamic = [];
      this.PrimitiveDy.forEach(function (item) {
        _this2.screen.forEach(function (citem) {
          if (item.prop == citem) {
            list.push(item);
          }
        });
      });
      setTimeout(function () {
        _this2.Dynamic = list;
      }, 20);
      this.setTableRight(0);
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("dataLtvacEchart");
      var myChart = echarts.init(chartDom);
      var option;
      var dateCp = JSON.parse(JSON.stringify(this.dates)).splice(0, this.dates.length - 1);
      option = {
        title: {
          text: "\u6FC0\u6D3B\u8BBE\u5907LTV"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: dateCp,
          width: '80%'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.PrimitiveDy.map(function (item) {
            return item.prop;
          })
        },
        yAxis: {
          type: "value"
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }, {
        label: '新增激活设备',
        prop: 'new_device_num'
      }].concat(_toConsumableArray(this.Dynamic));
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};