var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "内容详情", visible: _vm.dialogShow },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: { minRows: 2, maxRows: 12 },
                    disabled: ""
                  },
                  model: {
                    value: _vm.data.context,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "context", $$v)
                    },
                    expression: "data.context"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }