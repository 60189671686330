import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { payDeviceRetain, payDeviceRetain_currency } from "@/api/dataAll/payAccountRetain";
import * as echarts from "echarts";
export default {
  name: "payDeviceRetain",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value1: "",
      value2: [],
      tableData: [],
      tableLtv: [],
      tableDate: "",
      pageData: {},
      show: false,
      dates: [],
      datas: [],
      series: [],
      Dynamic: [],
      selectedArr: {},
      intReq: [],
      screen: [],
      PrimitiveDy: [],
      cellStylekeys: ["rem"]
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.kind = 1;
      this.reqLoading = true;
      this.timeInterval();
      var reqFn = this.currencyClose ? payDeviceRetain_currency : payDeviceRetain;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this.tableData = [];
          _this.dates = [];
          _this.series = [];

          for (var k in res.data.data) {
            _this.dates.push(k);

            var d = {
              date: k // num:res.data.num[k]

            };
            var i = [];

            for (var j in res.data.data[k]) {
              if (j.indexOf("pnum_") == -1) {
                i.push(j);
              }
            }

            var o = {
              name: k,
              type: "line",
              data: [],
              smooth: true
            };

            for (var _j in res.data.data[k]) {
              if (_j.indexOf("pnum_") == -1) {
                d["rem" + _j] = _this.zhuan([res.data.data[k][_j]])[0];
                o.data.push(d["rem" + _j]);

                if (_this.Dynamic.length < i.length) {
                  var a = _j == 2 ? "次" : _j;

                  _this.Dynamic.push({
                    label: a + "留.RR" + _j,
                    prop: "rem" + _j,
                    tableText: _j == 2 ? "RR".concat(_j, "A") : "RR".concat(_j),
                    desc: _j == 2 ? "次日付费设备留存率" : ""
                  });
                }
              }
            }

            if (!_this.screen.length) {
              _this.screen = _this.Dynamic.map(function (item) {
                return item.prop;
              });
              _this.PrimitiveDy = _this.Dynamic;
            }

            _this.screenControls();

            _this.series.push(o);

            _this.tableData.push(d);
          }
        } // 总计数据处理


        var total = {
          date: "加权平均留存" // num: res.data.totalSum,

        };

        for (var _k in res.data.avgData) {
          total["rem" + _k] = _this.zhuan([res.data.avgData[_k]])[0];
        }

        _this.tableData.push(total);

        _this.$nextTick(function () {
          _this.getTableRight();
        });

        _this.getechart();
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    screenControls: function screenControls() {
      var _this2 = this;

      var list = [];
      this.Dynamic = [];
      this.PrimitiveDy.forEach(function (item) {
        _this2.screen.forEach(function (citem) {
          if (item.prop == citem) {
            list.push(item);
          }
        });
      });
      setTimeout(function () {
        _this2.Dynamic = list;
      }, 50);
    },
    zhuan: function zhuan(list) {
      var n = [];
      list.forEach(function (item) {
        n.push(item.length > 0 ? Number(item.substr(0, item.length - 1)) : 0);
      });
      return n;
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("payDeviceRetainEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "付费设备留存率(%)"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          width: "80%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.PrimitiveDy.map(function (item) {
            return item.label;
          })
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "日期",
        prop: "date"
      }].concat(_toConsumableArray(this.Dynamic));
      var data = JSON.parse(JSON.stringify(this.tableData));
      this.Dynamic.forEach(function (item) {
        data.map(function (i) {
          i[item.prop] = i[item.prop] + "%";
        });
      });
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};