var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.pramas.game,
                    callback: function($$v) {
                      _vm.$set(_vm.pramas, "game", $$v)
                    },
                    expression: "pramas.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        "value-key": item.id,
                        label: item.game_name,
                        value: item.id
                      }
                    },
                    [_vm._v(_vm._s(item.game_name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.pramas.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.pramas, "platform", $$v)
                    },
                    expression: "pramas.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择渠道"
                  },
                  on: { change: _vm.channelchange },
                  model: {
                    value: _vm.pramas.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.pramas, "channel", $$v)
                    },
                    expression: "pramas.channel"
                  }
                },
                _vm._l(_vm.searchchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  on: { change: _vm.pkgchange },
                  model: {
                    value: _vm.pramas.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.pramas, "pkg", $$v)
                    },
                    expression: "pramas.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "ID" },
                on: { change: _vm.pkgchange },
                model: {
                  value: _vm.pramas.pkg,
                  callback: function($$v) {
                    _vm.$set(_vm.pramas, "pkg", $$v)
                  },
                  expression: "pramas.pkg"
                }
              })
            ],
            1
          ),
          _vm.advanced
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "创建管理员"
                    },
                    on: { change: _vm.pkgchange },
                    model: {
                      value: _vm.pramas.pkg,
                      callback: function($$v) {
                        _vm.$set(_vm.pramas, "pkg", $$v)
                      },
                      expression: "pramas.pkg"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.advanced
            ? _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.searchClick }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "moreOp ",
                      on: {
                        click: function($event) {
                          _vm.advanced = !_vm.advanced
                        }
                      }
                    },
                    [
                      _c("div", {}, [
                        _vm._v(_vm._s(_vm.advanced ? "收起" : "展开"))
                      ]),
                      _c("i", {
                        class: _vm.advanced
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down"
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.advanced,
                expression: "advanced"
              }
            ]
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", placeholder: "创建时间" },
                      model: {
                        value: _vm.value1,
                        callback: function($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", placeholder: "生效时间" },
                      model: {
                        value: _vm.value1,
                        callback: function($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", placeholder: "过期时间" },
                      model: {
                        value: _vm.value1,
                        callback: function($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "数量"
                      },
                      on: { change: _vm.pkgchange },
                      model: {
                        value: _vm.pramas.pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.pramas, "pkg", $$v)
                        },
                        expression: "pramas.pkg"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { display: "flex" }, attrs: { span: 4 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.getList }
                      },
                      [_vm._v("搜索")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "moreOp",
                        on: {
                          click: function($event) {
                            _vm.advanced = !_vm.advanced
                          }
                        }
                      },
                      [
                        _c("div", {}, [
                          _vm._v(_vm._s(_vm.advanced ? "收起" : "展开"))
                        ]),
                        _c("i", {
                          class: _vm.advanced
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.dialogShow } },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.list, border: "" } },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "ID", width: "80", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "game", label: "礼包码", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "platform", label: "创建时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "channel", label: "生效时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "pkg", label: "过期时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "passwd_secret", label: "数量", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "passwd_secret",
              label: "玩家最高领取次数",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "passwd_secret", label: "礼包内容", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "passwd_secret",
              label: "创建管理员",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "100",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.deleHandle(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.pramas.page,
          total: _vm.total,
          "page-size": 10
        },
        on: { "current-change": _vm.handleCurrentChange }
      }),
      _c("addCode", {
        attrs: { show: _vm.dialog },
        on: { changeShow: _vm.changeShow }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }