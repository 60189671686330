import request from '@/utils/request'; // 包名列表接口

export function packagenameList(data) {
  return request({
    url: '/pkg/list',
    method: 'get',
    params: data
  });
} // 增加包名

export function addPackagename(data) {
  return request({
    url: '/pkg/add',
    method: 'post',
    params: data
  });
} //添加查询包名

export function upPackagename(data) {
  return request({
    url: '/pkg/upPkg',
    method: 'get',
    params: data
  });
} // 修改包名

export function uPackagename(data) {
  return request({
    url: '/pkg/upPkg',
    method: 'post',
    params: data
  });
} //  禁用

export function disablePackagename(data) {
  return request({
    url: '/pkg/disablePkg',
    method: 'get',
    params: data
  });
} // 删除

export function delPackagename(data) {
  return request({
    url: '/pkg/delPkg',
    method: 'get',
    params: data
  });
}