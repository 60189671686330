// 广告渠道
import request from '@/utils/request';
export function advChannels(data) {
  return request({
    url: '/poster/lists',
    method: 'get',
    params: data
  });
} // 增加广告

export function addAdvertisement(data) {
  return request({
    url: 'poster/add',
    method: 'post',
    params: data
  });
} // 获取修改广告方式

export function getPoster(data) {
  return request({
    url: '/poster/upPoster',
    method: 'get',
    params: data
  });
} // 修改广告渠道

export function edtPoster(data) {
  return request({
    url: '/poster/upPoster',
    method: 'post',
    params: data
  });
} //   删除广告

export function delPoster(data) {
  return request({
    url: '/poster/delPoster',
    method: 'get',
    params: data
  });
} //   禁用广告

export function isablePoster(data) {
  return request({
    url: '/poster/disablePoster',
    method: 'get',
    params: data
  });
}