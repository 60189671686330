import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import sheet from "@/components/sheet/sheet.vue";
import util from '@/libs/util';
import axios from 'axios';
export default {
  components: {
    sheet: sheet
  },
  props: {
    filesname: {
      type: String,
      default: function _default() {
        return 'items';
      }
    },
    Url: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    paramsData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      action: process.env.VUE_APP_API + this.Url,
      showUpload: false,
      //控制limit最大值之后 关闭上传按钮
      headers: {
        'Authorization': '',
        'Request-Url': ''
      },
      fileList: [],
      fileLoading: false,
      dialogShow: false
    };
  },
  created: function created() {
    this.headers['Authorization'] = 'Bearer ' + (util.cookies.get('token') || '');
  },
  methods: {
    open: function open() {
      this.dialogShow = true;
    },
    fileChang: function fileChang(type) {
      var flieData = document.getElementById("file" + type).files;
      this.$set(this.fileList, type, flieData[0]); // for (let key in flieData) {
      //   if (flieData[key].size && this.fileList.findIndex(i => i.name == flieData[key].name) == -1 && this.fileList.length < 2) {
      //     this.fileList.push(document.getElementById("file").files[key])
      //   }
      // }

      document.getElementById("file" + type).value = '';
    },
    closeDialog: function closeDialog() {
      this.fileList = [];
    },
    removeFile: function removeFile(index) {
      this.fileList.splice(index, 1);
    },
    uploadFile: function uploadFile() {
      var _this = this;

      var fileList = this.fileList;

      if (fileList.length !== 2) {
        this.$message.warning('请选取文件');
        return;
      }

      this.fileLoading = true;
      var formDate = new FormData();
      formDate.append("email_file", fileList[0]);
      formDate.append("item_recycle_file", fileList[1]);

      for (var key in this.paramsData) {
        formDate.append(key, this.paramsData[key]);
      }

      this.headers['Request-Url'] = this.$route.path;
      axios.post(this.action, formDate, {
        headers: this.headers
      }).then(function (res) {
        if (res.data.status == 200) {
          _this.$message.success('上传成功');

          _this.$emit('change');

          _this.dialogShow = false;
        } else {
          _this.$message.error(res.data.message);
        }
      }).finally(function () {
        _this.fileLoading = false;
      });
    },
    //文件超出个数限制时的函数
    handleExceed: function handleExceed(files, fileList) {
      this.$message.info("\u6700\u591A\u53EA\u5141\u8BB8\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247"));
    }
  }
};