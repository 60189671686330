import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  data: function data() {
    return {};
  },
  computed: {},
  methods: _objectSpread({}, mapActions('d2admin/account', ['ResetRoute']))
};