var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex", justify: "start" } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: false,
                  "picker-options": _vm.pickerOptionsRadius,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.value2,
                  callback: function($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptionsRadius,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "开始统计日期",
                  "end-placeholder": "结束统计日期"
                },
                model: {
                  value: _vm.value3,
                  callback: function($$v) {
                    _vm.value3 = $$v
                  },
                  expression: "value3"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(2)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-row",
            { attrs: { gutter: 20, type: "flex", justify: "start" } },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exportdata }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              attrs: { "max-height": "650", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "game_reveal",
                  label: "游戏",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform_reveal",
                  label: "平台",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channel_reveal",
                  label: "渠道",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pkg_reveal",
                  label: "包名",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "device_id",
                  label: "设备号",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "duration",
                  label: "在线时长",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_times",
                  label: "广告次数",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "conversion_ratio",
                  label: "激活时间",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { staticClass: "exParam" }, [
                            _vm._v(_vm._s(scope.row.timestamp))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  462264956
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }