import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { advRangeLoss } from "@/api/dataAll/lossLevel";
export default {
  name: "advRangeLoss",
  mixins: [tabulation],
  data: function data() {
    return {
      tableData: {},
      tableTime: [],
      show: false,
      formData: {}
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      advRangeLoss(this.searchData).then(function (res) {
        var date_array = res.data.date_array;
        var key = res.data.key;
        _this.tableTime = [];

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objToKey = function objToKey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        _this.formData = res.data;
        _this.tableTime = objToKey(res.data.data);
        _this.tableData = objToTitle(res.data.data);
        var newNum = [];
        var table_bodoy = "<tr><td></td>";

        _this.tableData.forEach(function (val, index) {
          table_bodoy += "<td>流失人数</td><td>流失率</td>";
        });

        table_bodoy += "</tr>";
        var table_newNum = "<th style='width:100px;'>当日新增人数</th>";
        var table_top = "<th style='width:250px;'>游戏时长</th>";

        var _iterator = _createForOfIteratorHelper(_this.tableTime),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var date = _step.value;
            table_top += "<th colspan='2' style='width:250px;line-height:40px;'>" + date + "</th>";
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        _this.tableData.forEach(function (item, i) {
          newNum.push(item.new_device_num);
        });

        newNum.forEach(function (item) {
          table_newNum += "<th colspan='2' style='width:250px;line-height:40px;'>" + item + "</th>";
        });

        if (key.length > 0) {
          key.forEach(function (item, i) {
            table_bodoy += "<tr>";
            table_bodoy += "<td style='width:100px;'>" + item + "</td>";

            _this.tableData.forEach(function (val, index) {
              var c = objToTitle(val.data);
              var d = val.data[item];

              if (d != undefined) {
                table_bodoy += "<td>" + d.number_loss + "</td>";
                table_bodoy += "<td>" + d.run_off + "</td>";
              } else {
                table_bodoy += "<td>0</td>";
                table_bodoy += "<td>0</td>";
              }
            });

            table_bodoy += "</tr>";
          });
        } else {
          _this.$message({
            message: "此时间段没有数据",
            type: "success"
          });
        }

        var header = document.getElementById("header");
        var bodoy = document.getElementById("bodoy");
        var num = document.getElementById("newNum");
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        num.innerHTML = table_newNum;
        _this.show = true;
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var objToTitle = function objToTitle(obj) {
        return Object.keys(obj).map(function (k) {
          return obj[k];
        });
      };

      var objToKey = function objToKey(obj) {
        return Object.keys(obj).map(function (k) {
          return k;
        });
      };

      var pageData = this.formData.data;
      var columns = [{
        label: '游戏时长',
        prop: 'taskId'
      }];
      var data = [];
      var number = {
        taskId: '当日新增人数'
      };
      var complete = {
        taskId: ''
      };
      var merges = [];
      var mergesTow = [];
      var iN = -1;
      var k = -1;

      for (var i in pageData) {
        iN += 1;
        columns.push.apply(columns, [{
          label: i,
          prop: iN + 'userid'
        }, {
          label: '',
          prop: iN + 'upMoney'
        }]);
        number[iN + 'userid'] = pageData[i].new_device_num;
        number[iN + 'upMoney'] = '';
        complete[iN + 'userid'] = '流失人数';
        complete[iN + 'upMoney'] = '流失率';
        var kN = -1;

        for (var _k in pageData[i].data) {
          data.push({});
          kN += 1;
          var taskData = {
            taskId: _k
          };
          taskData[iN + 'userid'] = pageData[i].data[_k].number_loss;
          taskData[iN + 'upMoney'] = pageData[i].data[_k].run_off;
          data[kN] = _objectSpread(_objectSpread({}, data[kN]), taskData);
        }
      }

      for (var n = 0; n < parseInt((columns.length - 1) / 2); n++) {
        merges.push([this.headerZh(n, 0), this.headerZh(n, 1)]);
        mergesTow.push([this.towHeaderZh(n, 0), this.towHeaderZh(n, 1)]);
      }

      merges = [].concat(_toConsumableArray(merges), mergesTow);
      data.unshift(complete);
      data.unshift(number);
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title,
        merges: merges
      });
    },
    headerZh: function headerZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      var num = k > 0 ? letterTable[k - 1] : '';

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[(k <= 1 ? index * 2 : index) + 1];
        } else {
          num = num + letterTable[(k <= 1 ? index * 2 + 1 : index + 1) + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2 + 1];
        } else {
          num = num + letterTable[index % 13 * 2 + 1 + 1];
        }
      }

      return num + '1';
    },
    towHeaderZh: function towHeaderZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      var num = k > 0 ? letterTable[k - 1] : '';

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[(k <= 1 ? index * 2 : index) + 1];
        } else {
          num = num + letterTable[(k <= 1 ? index * 2 + 1 : index + 1) + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2 + 1];
        } else {
          num = num + letterTable[index % 13 * 2 + 1 + 1];
        }
      }

      return num + '2';
    }
  }
};