import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bottomLeftChart from "./chart/bottom/bottomLeftChart";
import centerChart from "./chart/center/centerChartRate";
export default {
  props: {
    priformAmout: {
      type: Number,
      default: 0
    },
    rateData: {
      type: Array,
      required: true
    },
    dataCompare: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      // 通过率和达标率的组件复用数据
      rate: [],
      tipShow: false,
      clientHeight: 0,
      Amout: ''
    };
  },
  watch: {},
  components: {
    centerChart: centerChart,
    bottomLeftChart: bottomLeftChart
  },
  created: function created() {
    var _this = this;

    this.rate = this.rateData;
    var auts = Number(this.priformAmout) / 100;

    if (auts.toString().length < 5) {
      this.Amout = auts + '元';
    } else if (auts.toString().length > 8 && auts.toString().length > 5) {
      this.Amout = auts / 100 + '万';
    }

    this.$nextTick(function () {
      var rankingBoard = document.getElementById('perFormance');
      _this.clientHeight = rankingBoard.clientHeight; // console.log(this.clientHeight,'perFormance')
    });
  },
  mounted: function mounted() {
    this.scrollInit();
  },
  methods: {
    scrollInit: function scrollInit() {
      var that = this; // 获取要绑定事件的元素

      var nav = document.getElementById("nav"); // document.addEventListener('DOMMouseScroll', handler, false)
      // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法

      nav.addEventListener("mousewheel", handler, false); // 滚动事件的出来函数

      function handler(event) {
        // 获取滚动方向
        var detail = event.wheelDelta || event.detail; // 定义滚动方向，其实也可以在赋值的时候写

        var moveForwardStep = 1;
        var moveBackStep = -1; // 定义滚动距离

        var step = 0; // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的

        if (detail < 0) {
          step = moveForwardStep * 100;
          that.tipShow = false;
        } else {
          step = moveBackStep * 100;
          that.tipShow = false;
        } // 对需要滚动的元素进行滚动操作


        nav.scrollLeft += step;
      }
    },
    mouseOver: function mouseOver() {
      this.tipShow = true;
    },
    // 移出
    mouseLeave: function mouseLeave() {
      this.tipShow = false;
    }
  }
};