var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: {
                    change: function($event) {
                      return _vm.getList(1)
                    }
                  },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "版本号" },
                model: {
                  value: _vm.paramsData.version,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "version", $$v)
                  },
                  expression: "paramsData.version"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "渠道" },
                model: {
                  value: _vm.paramsData.channel_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "channel_id", $$v)
                  },
                  expression: "paramsData.channel_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "operationFrame-entity",
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.paramsData.state,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "state", $$v)
                    },
                    expression: "paramsData.state"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "已发送", value: "1" } }),
                  _c("el-option", { attrs: { label: "未发送", value: "2" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addedition.open()
                    }
                  }
                },
                [_vm._v("新增")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", disabled: !_vm.sels.length },
                  on: {
                    click: function($event) {
                      return _vm.handleDelete(_vm.sels)
                    }
                  }
                },
                [_vm._v("批量删除")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.list, border: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "id",
              type: "selection",
              selectable: function(row) {
                return row.state == 2
              },
              width: "50"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "admin_name", label: "操作员" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.admin_name || "/") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "version", label: "版本" } }),
          _c("el-table-column", {
            attrs: { prop: "channel_id", label: "渠道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.channel_id == 0 ? "全部" : row.channel_id
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "state", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(row.state == 1 ? "已发送" : "未发送") + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "request_data", label: "内容详情" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#409EFF", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.openDetail(row.introduction)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.formDate(row.created_at, "YY-MM-DD HH")
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "操作", width: "160" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return row.state == 2
                      ? _c("div", { staticClass: "x-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.sendEdition(row.id)
                                }
                              }
                            },
                            [_vm._v("发送")]
                          ),
                          _c("div", { staticClass: "controlsBtn-vertical" }),
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.$refs.addedition.open(row)
                                }
                              }
                            },
                            [_vm._v("修改")]
                          ),
                          _c("div", { staticClass: "controlsBtn-vertical" }),
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete([row.id])
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ])
                      : _vm._e()
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("contentView", { ref: "contentView" }),
      _c("addedition", {
        ref: "addedition",
        attrs: { prod: { is_prod: _vm.paramsData.is_prod } },
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      }),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }