import request from '@/utils/request'; //获取登陆渠道列表

export function getChannelList(data) {
  return request({
    url: '/pay/login_channel',
    method: 'get',
    params: data
  });
} // 新增登录渠道

export function addChannel(data) {
  return request({
    url: 'pay/add_login_channel',
    method: 'post',
    params: data
  });
} // 修改登录渠道

export function updataChannel(data) {
  return request({
    url: '/pay/uplogin_channel',
    method: 'post',
    params: data
  });
} // 删除登录渠道

export function deleChannel(data) {
  return request({
    url: '/pay/dellogin_channel',
    method: 'get',
    params: data
  });
} // 返回所有登录渠道

export function allChannel(data) {
  return request({
    url: '/pay/login_channel_all',
    method: 'get',
    params: data
  });
}