import request from '@/utils/request'; //获取支付中心换包配置列表

export function paymentConfigList(data) {
  return request({
    url: '/apply/pay_replace',
    method: 'get',
    params: data
  });
} //新增支付中心换包配置

export function addpaymentConfig(data) {
  return request({
    url: '/apply/add_pay_replace',
    method: 'post',
    params: data
  });
} //修改支付中心换包配置

export function updatapaymentConfig(data) {
  return request({
    url: '/apply/up_pay_replace',
    method: 'post',
    params: data
  });
} // 反显数据

export function returnpaymentConfig(data) {
  return request({
    url: '/apply/up_pay_replace',
    method: 'get',
    params: data
  });
} // 删除支付中心配置

export function delepaymentConfig(data) {
  return request({
    url: '/apply/del_pay_replace',
    method: 'post',
    params: data
  });
}