var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCountry: false } },
            on: { searchChange: _vm.searchChange }
          }),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择环境"
                      },
                      on: { change: _vm.severlistChange },
                      model: {
                        value: _vm.searchData.is_prod,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "is_prod", $$v)
                        },
                        expression: "searchData.is_prod"
                      }
                    },
                    _vm._l(_vm.severlist, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择服务器"
                      },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.name || item.serverName,
                          value: item.id || item.serverID
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "服务器渠道" },
                      on: { change: _vm.serverChannelChange },
                      model: {
                        value: _vm.server_channel,
                        callback: function($$v) {
                          _vm.server_channel = $$v
                        },
                        expression: "server_channel"
                      }
                    },
                    _vm._l(_vm.serverChannel, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "服务器"
                      },
                      on: { change: _vm.severChange },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverListYJ, function(item) {
                      return _c("el-option", {
                        key: item.serverID,
                        attrs: { label: item.serverName, value: item.serverID }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.searchData.server_id.length
            ? _c(
                "el-col",
                {
                  staticClass: "tipsClass",
                  staticStyle: { position: "relative" },
                  attrs: { span: 4 }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入操作标志", clearable: "" },
                    model: {
                      value: _vm.searchData.action,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "action", $$v)
                      },
                      expression: "searchData.action"
                    }
                  }),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "tipss",
                      attrs: { effect: "dark", placement: "top" }
                    },
                    [
                      _c("div", {
                        attrs: { slot: "content" },
                        domProps: {
                          innerHTML: _vm._s("多个操作以英文逗号隔开：1001,1002")
                        },
                        slot: "content"
                      }),
                      _c("i", { staticClass: "el-icon-question " })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            {
              staticClass: "tipsClass",
              staticStyle: { position: "relative" },
              attrs: { span: 4 }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入资源ID", clearable: "" },
                model: {
                  value: _vm.searchData.resources,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "resources", $$v)
                  },
                  expression: "searchData.resources"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "tipss",
                  attrs: { effect: "dark", placement: "top" }
                },
                [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: {
                      innerHTML: _vm._s("多个资源以英文逗号隔开：1001,1002")
                    },
                    slot: "content"
                  }),
                  _c("i", { staticClass: "el-icon-question " })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            _vm._l(_vm.Dynamic, function(item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  fixed: item.prop == "controls" || item.prop == "items",
                  prop: item.prop,
                  label: item.label,
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }