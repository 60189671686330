import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { banUser } from '@/api/gmToolNew/gmToolNew';
import { gmToolNew, gmToolNewCom } from "@/mixins/index";
export default {
  mixins: [gmToolNew, gmToolNewCom],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var validateuids = function validateuids(rule, value, callback) {
      var regex = /[\u4e00-\u9fa5，]/;

      if (regex.test(value) || !value) {
        callback(new Error('角色ID不正确(以英文逗号隔开 1,2,3)'));
      } else {
        callback();
      }
    };

    return {
      dialogShow: false,
      paramsData: {
        uids: '',
        is_abnormal_op: '',
        expire_time: '',
        reason: ''
      },
      isForever: false,
      whetherNot: [{
        label: '是',
        value: true
      }, {
        label: '否',
        value: false
      }],
      rules: {
        op_type: [{
          required: true,
          message: "请选择封禁时间",
          trigger: "blur"
        }],
        uids: [{
          required: true,
          validator: validateuids,
          trigger: "blur"
        }],
        expire_time: [{
          required: true,
          message: "请选择封禁时间",
          trigger: "blur"
        }],
        reason: [{
          required: true,
          message: "请输入原因",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    open: function open(data, type) {
      this.paramsData.is_abnormal_op = type;
      this.paramsData.uids = data.uid || '';

      if (type) {
        this.isForever = data.expire_time == 0 ? true : false;
        this.paramsData.expire_time = data.expire_time ? data.expire_time * 1000 : "";
      }

      this.dialogShow = true;
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    clearData: function clearData() {
      this.paramsData = {
        id: this.paramsData.id,
        uids: '',
        expire_time: '',
        // isForever: false,
        reason: ''
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this.paramsData));
          data.expire_time = data.isForever ? 0 : data.expire_time / 1000;
          data.uids = data.uids.indexOf(",") !== -1 ? data.uids.split(",") : [data.uids];
          data.uids = data.uids.map(function (i) {
            return i = Number(i);
          });
          banUser(_objectSpread(_objectSpread({}, data), _this.propsData)).then(function (res) {
            if (res.code == 200 || res.status == 200) {
              _this.$message({
                message: '操作成功',
                type: 'success'
              });

              _this.dialogShow = false;

              _this.$emit('change', data.uids);
            }
          }).finally(function () {
            _this.issubmit = false;
          });
        }
      });
    }
  }
};