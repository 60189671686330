import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getAllUser } from "@/api/dataAll/actionLog";
import { debugReportData } from "@/api/auth/onlineUser";
export default {
  name: "userOption",
  data: function data() {
    return {
      value2: [],
      tableData: [],
      userList: [],
      total: 0,
      searchData: {
        page_size: 10,
        page: 1,
        user_id: ""
      },
      drawer: false,
      content: ""
    };
  },
  created: function created() {
    this.getAll();
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    sizeChange: function sizeChange(e) {
      this.searchData.page_size = e;
      this.searchClick(1);
    },
    openParms: function openParms(row) {
      this.content = row.params;
      this.drawer = true;
    },
    datechange: function datechange() {
      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }
    },
    getAll: function getAll() {
      var _this = this;

      getAllUser().then(function (res) {
        _this.userList = res.data;
      });
    },
    searchClick: function searchClick(e) {
      var _this2 = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.page = e;
      debugReportData(this.searchData).then(function (res) {
        _this2.tableData = res.data.data || [];
        _this2.total = res.data.total - 0 || 0;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.searchClick();
    }
  }
};