//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    gameRechargeData: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      config: {
        lineWidth: 30,
        activeRadius: "75%",
        radius: "75%",
        unit: "元",
        waitTime: 5000,
        showValue: true,
        data: []
      },
      clientHeight: 0
    };
  },
  components: {},
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      var rankingBoard = document.getElementById('ranking-board');
      _this.clientHeight = rankingBoard.clientHeight;
      _this.config.data = _this.gameRechargeData;
    });
  },
  mounted: function mounted() {},
  methods: {}
};