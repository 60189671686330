// 创建检测链接
import request from '@/utils/request';
export function addtecLinks(data) {
  return request({
    url: '/datum/add_advertising_monitor',
    method: 'post',
    params: data
  });
} // 修改检测链接

export function updatatecLinks(data) {
  return request({
    url: '/datum/up_advertising_monitor',
    method: 'post',
    params: data
  });
} // 获取返显修改检测链接数据

export function getReturnData(data) {
  return request({
    url: '/datum/up_advertising_monitor',
    method: 'get',
    params: data
  });
} // 检测链接列表

export function getLinksList(data) {
  return request({
    url: '/datum/advertising_monitor',
    method: 'get',
    params: data
  });
} // 删除链接列表

export function deleLinksList(data) {
  return request({
    url: '/datum/del_advertising_monitor',
    method: 'get',
    params: data
  });
}