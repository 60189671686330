//创建af监测链接
import request from '@/utils/request';
export function addafLinks(data) {
  return request({
    url: '/apply/add_af_monitor_url',
    method: 'post',
    params: data
  });
} // 获取af监测链接列表

export function getafLinksList(data) {
  return request({
    url: '/apply/af_monitor_url',
    method: 'get',
    params: data
  });
} // 反显af监测链接列表

export function returnafLinks(data) {
  return request({
    url: '/apply/update_af_monitor_url',
    method: 'get',
    params: data
  });
} //修改af监测链接列表

export function updateafLinks(data) {
  return request({
    url: '/apply/update_af_monitor_url',
    method: 'post',
    params: data
  });
} //删除af监测链接列表

export function deleafLinks(data) {
  return request({
    url: '/apply/delete_af_monitor_url',
    method: 'post',
    params: data
  });
}