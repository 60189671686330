var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
          on: { select: _vm.modelChange }
        },
        [
          _c("el-menu-item", { attrs: { index: "0" } }, [_vm._v("渠道管理")]),
          _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("版本管理")]),
          _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("审核列表")])
        ],
        1
      ),
      _vm.activeIndex == 0
        ? _c(
            "div",
            { staticClass: "modleItem" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "渠道名称" },
                        model: {
                          value: _vm.pageList[0].paramsData.gm_channel_name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.pageList[0].paramsData,
                              "gm_channel_name",
                              $$v
                            )
                          },
                          expression: "pageList[0].paramsData.gm_channel_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "渠道ID" },
                        model: {
                          value: _vm.pageList[0].paramsData.pkgData,
                          callback: function($$v) {
                            _vm.$set(_vm.pageList[0].paramsData, "pkgData", $$v)
                          },
                          expression: "pageList[0].paramsData.pkgData"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "operationBut", attrs: { span: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.reqLoading },
                          on: {
                            click: function($event) {
                              return _vm.getList(1)
                            }
                          }
                        },
                        [_vm._v("搜索")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.addExaminePkg.open()
                            }
                          }
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "warning", disabled: !_vm.selsString },
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(_vm.selsString)
                            }
                          }
                        },
                        [_vm._v("批量删除")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.reqLoading,
                      expression: "reqLoading"
                    }
                  ],
                  key: "index0",
                  attrs: { data: _vm.pageList[0].tableData, border: "" },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", type: "selection", width: "50" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "gm_channel_name", label: "渠道名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pkgData", label: "渠道ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "updated_at", label: "修改时间" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$times.formDate(row.updated_at)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1438555087
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "created_at", label: "创建时间" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.created_at
                                      ? _vm.$times.formDate(row.created_at)
                                      : "/"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2668606974
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "120",
                      align: "center",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "x-c" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.addExaminePkg.open(row)
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                ),
                                _c("div", {
                                  staticClass: "controlsBtn-vertical"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDelete(
                                          row.id,
                                          row.type
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3868333694
                    )
                  })
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  layout: "total,sizes, prev, pager, next",
                  "current-page": _vm.pageList[0].page,
                  total: _vm.pageList[0].total,
                  "page-size": _vm.pageSize
                },
                on: {
                  "current-change": _vm.getList,
                  "size-change": function($event) {
                    return _vm.ListsizeChange($event, 0)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.activeIndex == 1
        ? _c(
            "div",
            { staticClass: "modleItem" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-1",
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: {
                            change: function($event) {
                              return _vm.getList(1)
                            }
                          },
                          model: {
                            value: _vm.pageList[1].paramsData.is_prod,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.pageList[1].paramsData,
                                "is_prod",
                                $$v
                              )
                            },
                            expression: "pageList[1].paramsData.is_prod"
                          }
                        },
                        _vm._l(_vm.severlist, function(item) {
                          return _c("el-option", {
                            key: item.label,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-1",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "渠道"
                          },
                          model: {
                            value: _vm.pageList[1].paramsData.pkgData,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.pageList[1].paramsData,
                                "pkgData",
                                $$v
                              )
                            },
                            expression: "pageList[1].paramsData.pkgData"
                          }
                        },
                        _vm._l(_vm.examinePkgList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.gm_channel_name || item.pkgData,
                              value: item.pkgData
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "版本号" },
                        model: {
                          value: _vm.pageList[1].paramsData.version,
                          callback: function($$v) {
                            _vm.$set(_vm.pageList[1].paramsData, "version", $$v)
                          },
                          expression: "pageList[1].paramsData.version"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-1",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "状态"
                          },
                          model: {
                            value: _vm.pageList[1].paramsData.onoff,
                            callback: function($$v) {
                              _vm.$set(_vm.pageList[1].paramsData, "onoff", $$v)
                            },
                            expression: "pageList[1].paramsData.onoff"
                          }
                        },
                        _vm._l(_vm.onoffList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "operationBut", attrs: { span: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.reqLoading },
                          on: {
                            click: function($event) {
                              return _vm.getList(1)
                            }
                          }
                        },
                        [_vm._v("搜索")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.addExamine.open()
                            }
                          }
                        },
                        [_vm._v("新增")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.reqLoading,
                      expression: "reqLoading"
                    }
                  ],
                  key: "index1",
                  attrs: { data: _vm.pageList[1].tableData, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "admin_name", label: "操作员" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "gm_channel_name", label: "渠道名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pkgData", label: "渠道ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "version", label: "版本" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "onoff", label: "状态" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.onoffList.find(function(i) {
                                        return i.value == row.onoff
                                      }).label
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3971530421
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "updated_at", label: "修改时间" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$times.formDate(row.updated_at)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1438555087
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "created_at", label: "创建时间" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$times.formDate(row.created_at)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      998300506
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "120",
                      align: "center",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "x-c" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.addExamine.open(row)
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2719793842
                    )
                  })
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  layout: "total,sizes, prev, pager, next",
                  "current-page": _vm.pageList[1].paramsData.page,
                  total: _vm.pageList[1].total,
                  "page-size": _vm.pageSize
                },
                on: {
                  "current-change": _vm.getList,
                  "size-change": function($event) {
                    return _vm.ListsizeChange($event, 1)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.activeIndex == 2
        ? _c(
            "div",
            { staticClass: "modleItem" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-1",
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: {
                            change: function($event) {
                              return _vm.getList(1)
                            }
                          },
                          model: {
                            value: _vm.pageList[2].paramsData.is_prod,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.pageList[2].paramsData,
                                "is_prod",
                                $$v
                              )
                            },
                            expression: "pageList[2].paramsData.is_prod"
                          }
                        },
                        _vm._l(_vm.severlist, function(item) {
                          return _c("el-option", {
                            key: item.label,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.reqLoading,
                      expression: "reqLoading"
                    }
                  ],
                  attrs: { data: _vm.tableData, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "ios_server_id", label: "IOS审核服ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "server_id", label: "服务器ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "gm_channel_name", label: "渠道名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pkg_name", label: "渠道ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "version_list", label: "配置" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "text-cursor",
                                  staticStyle: { color: "#409EFF" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openView(row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      174305083
                    )
                  })
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  layout: "total,sizes, prev, pager, next",
                  "current-page": _vm.currentPage,
                  total: _vm.tableCopyTableList.length,
                  "page-size": _vm.pageSize
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("addExamine", {
        ref: "addExamine",
        attrs: {
          onoffs: _vm.onoffList,
          prod: { is_prod: _vm.pageList[1].paramsData.is_prod },
          examinePkgLists: _vm.examinePkgList
        },
        on: {
          update: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      }),
      _c("addExaminePkg", {
        ref: "addExaminePkg",
        attrs: {
          prod: { is_prod: _vm.pageList[2].paramsData.is_prod },
          serverList: _vm.serverLists
        },
        on: { update: _vm.pkgChange }
      }),
      _c(
        "el-drawer",
        {
          attrs: { title: "版本详情", visible: _vm.drawer },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.versionList, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "channelNmae", label: "渠道名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pkgName", label: "渠道ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "value", label: "版本号" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }