var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          staticStyle: { width: "60%" },
          attrs: {
            "label-position": "left",
            rules: _vm.rules,
            model: _vm.paramsData,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "config_type_id", label: "配置类型" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  on: {
                    change: function($event) {
                      return _vm.configTypeChange(true)
                    }
                  },
                  model: {
                    value: _vm.paramsData.config_type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "config_type_id", $$v)
                    },
                    expression: "paramsData.config_type_id"
                  }
                },
                _vm._l(_vm.TypeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "file", label: "配置表" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  on: {
                    change: function($event) {
                      return _vm.nameChange(true)
                    }
                  },
                  model: {
                    value: _vm.paramsData.file,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "file", $$v)
                    },
                    expression: "paramsData.file"
                  }
                },
                _vm._l(_vm.ConfigNameList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              staticStyle: { width: "100%" },
              attrs: { prop: "config_id", label: "活动ID" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "360px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  on: {
                    change: function($event) {
                      return _vm.idChange(true)
                    }
                  },
                  model: {
                    value: _vm.paramsData.config_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "config_id", $$v)
                    },
                    expression: "paramsData.config_id"
                  }
                },
                _vm._l(_vm.ConfigActList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem ",
              staticStyle: { width: "100% !important" },
              attrs: { prop: "config", label: "活动配置" }
            },
            [
              _c(
                "div",
                { staticStyle: { width: "100% !important" } },
                [
                  _c("el-input", {
                    staticStyle: { height: "200px" },
                    attrs: {
                      type: "textarea",
                      disabled: !_vm.isediting,
                      placeholder: "内容"
                    },
                    model: {
                      value: _vm.paramsData.config,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "config", $$v)
                      },
                      expression: "paramsData.config"
                    }
                  }),
                  _c(
                    "div",
                    [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "是否确认开始编辑" },
                          on: {
                            confirm: function($event) {
                              _vm.isediting = true
                            }
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                size: "mini"
                              },
                              slot: "reference"
                            },
                            [_vm._v("编辑")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              _vm.isediting = false
                            }
                          }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.claerData } },
                [_vm._v("重置表单")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v(_vm._s(_vm.paramsData.id ? "修改配置" : "提交配置"))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }