import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { mapState, mapActions } from 'vuex';
import { getTaskDescDesc } from "@/api/kamitaku/kamitaku";
export default {
  data: function data() {
    return {
      tabData: null,
      reqFn: null,
      TaskDescDescList: [],
      SelectCallback: null,
      searchData: {
        start_time: 0,
        end_time: 0,
        country: '',
        ad_channel: 'default',
        ad_placement: 'default'
      },
      currentPage: 1,
      total: 0,
      pageSize: 10,
      tableData: [],
      // 表格数据
      tableCopyTableList: [] // 表格copy数据b

    };
  },
  created: function created() {},
  watch: {
    'searchData.pkg': {
      deep: true,
      handler: function handler(newVal, oldVal) {
        this.SelectCallback && this.SelectCallback();
      }
    }
  },
  computed: {},
  methods: {
    // 默认时间
    defaultDate: function defaultDate() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var currentDate = parseInt(new Date().getTime() / 1000);
        var aftDate = currentDate - 6 * 24 * 3600;
        var date = [_this.$times.timestampToTime(aftDate, 'YY-MM-DD'), _this.$times.timestampToTime(currentDate, 'YY-MM-DD')];
        _this.searchData.start_time = date[0];
        _this.searchData.end_time = date[1];
        _this.searchTime = date;
        resolve();
      });
    },
    getgTaskDescDesc: function getgTaskDescDesc() {
      var _this2 = this;

      return new Promise(function (resolve) {
        getTaskDescDesc({
          game: _this2.searchData.game,
          data_type: _this2.data_type || ''
        }).then(function (res) {
          if (res.code == 200) {
            _this2.TaskDescDescList = res.data;
            resolve();
          }
        });
      });
    },
    getList: function getList(callback) {
      var _this3 = this;

      this.SelectCallback = null;
      this.reqLoading = true;
      this.searchData.start_time = this.value2 ? this.value2[0] : '';
      this.searchData.end_time = this.value2 ? this.value2[1] : '';
      this.myChart && this.myChart.clear();
      this.reqFn(_objectSpread({}, this.searchData)).then(function (res) {
        if (res.status == 200) {
          _this3.tableData = [];
          _this3.Dynamic = [];
          _this3.tabData = res.data;
          callback && callback(res.data);
        }
      }).finally(function () {
        _this3.reqLoading = false;
      });
    },
    // 页数改变事件
    handleSizeChange: function handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.tableData = this.currentChangePage(pageSize, this.currentPage);
    },
    // 页码改变切换
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.tableData = this.currentChangePage(this.pageSize, currentPage);
    },
    // 分页方法
    currentChangePage: function currentChangePage(size, current) {
      var tablePush = [];
      this.tableCopyTableList.forEach(function (item, index) {
        if (size * (current - 1) <= index && index <= size * current - 1) {
          tablePush.push(item);
        }
      });
      return tablePush;
    }
  }
};