import request from '@/utils/request'; // 获取网关订单列表

export function getGateOrderList(data) {
  return request({
    url: '/order/gate_order_list',
    method: 'get',
    params: data
  });
} // 获取商品列表

export function getProductList(data) {
  return request({
    url: '/order/gate-product-list',
    method: 'get',
    params: data
  });
}