//
//
//
//
import { getSummerDatajl } from "@/api/dataAll/monSummer";
import montorSummData from './montorSummData';
export default {
  name: "montorSummDatajl",
  components: {
    montorSummData: montorSummData
  },
  data: function data() {
    return {
      reqFns: null
    };
  },
  created: function created() {
    this.reqFns = getSummerDatajl;
  },
  methods: {}
};