export default {
  namespaced: true,
  state: {
    gmtoolType: false
  },
  mutations: {
    setGmtoolType: function setGmtoolType(state, data) {
      state.gmtoolType = data;
    }
  }
};