import request from '@/utils/request'; //新增用户每日广告收益国外

export function addAdvReven(data) {
  return request({
    url: '/datum/advertising_revenue',
    method: 'get',
    params: data
  });
} //新增用户每日广告收益国内

export function addAdvRevenHome(data) {
  return request({
    url: '/datum/home_advertising_revenue',
    method: 'get',
    params: data
  });
}