var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addType } },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "login_name", label: "名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "login_mark", label: "标志" }
          }),
          _c("el-table-column", {
            attrs: { prop: "login_icon", label: "缩略图" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: { src: scope.row.login_icon, fit: "cover" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.editType(scope.row)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.deleType(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.page,
          total: _vm.total,
          "page-size": _vm.pagSize
        },
        on: { "current-change": _vm.handleCurrentChange }
      }),
      _c("addthree-login", {
        ref: "addthree",
        attrs: { isShow: _vm.dialog },
        on: { changeShow: _vm.changeShow }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }