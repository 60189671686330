import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pickerOption from '@/mixins/module/pickerOption';
import { searchgame, searchplatform, searchchannel, searchpkg } from "@/api/dataAll/dataAll";
import { getadvLocation } from "@/api/dataAll/advLocation";
import { getGateUserMangList } from "@/api/gateWayMang";
export default {
  name: "gateWayUserMange",
  mixins: [pickerOption],
  data: function data() {
    return {
      advanced: false,
      searchgames: [],
      searchplatforms: [],
      searchchannels: [],
      searchpkgs: [],
      tableTitle: [],
      advchannels: [],
      advLocation: [],
      searchcountry: [],
      gameId: "",
      value1: "",
      value2: [],
      value3: "",
      searchData: {
        game: "",
        platform: "",
        channel: "",
        account_id: "",
        user_id: "",
        pkg: "",
        starttime: "",
        endtime: "",
        advChannel: "",
        advLocation: ""
      },
      searchMark: {
        page: 1
      },
      tableData: [],
      tableLtv: [],
      tableDate: "",
      show: false,
      tableHeader: ["新增人数", "活跃人数", "总登录次数", "最高在线人数"],
      echarts: false,
      dates: [],
      datas: [],
      series: [],
      selectedArr: {},
      total: 0
    };
  },
  created: function created() {
    var _this = this;

    // this.getsearchDate()
    searchgame().then(function (res) {
      _this.searchgames = res.data;
      _this.gameId = res.data.id;
    });
  },
  methods: {
    timestampToTime: function timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchMark.page = val;
      this.searchClick();
    },
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    gamechange: function gamechange(id) {
      var _this2 = this;

      if (id != "") {
        searchplatform({
          game_id: id
        }).then(function (res) {
          _this2.searchplatforms = res.data;
          _this2.searchData.platform = "";
          _this2.searchData.channel = "";
          _this2.searchData.pkg = "";
          _this2.searchMark.platform = "";
          _this2.searchMark.channel = "";
          _this2.searchMark.pkg = "";
          _this2.searchData.advChannel = "";
          _this2.searchData.advLocation = "";
          _this2.searchMark.ad_placement = "";
          _this2.searchMark.ad_channel = "";
          _this2.searchchannels = [];
          _this2.advchannels = [];
          _this2.advLocation = [];
          _this2.searchpkgs = [];

          _this2.searchgames.forEach(function (item) {
            if (item.id == id) {
              _this2.searchMark.game = item.game_mark;
            }
          });
        });
      } else {
        this.searchData.platform = "";
        this.searchData.channel = "";
        this.searchData.pkg = "";
        this.searchMark.pkg = "";
        this.searchMark.platform = "";
        this.searchMark.channel = "";
        this.searchplatforms = [];
        this.searchchannels = [];
        this.searchpkgs = [];
        this.searchMark.game = "";
        this.searchData.advChannel = "";
        this.searchData.advLocation = "";
        this.searchMark.ad_channel = "";
        this.searchMark.ad_placement = "";
        this.advchannels = [];
        this.advLocation = [];
      }
    },
    platformchange: function platformchange(id) {
      var _this3 = this;

      if (id != "") {
        searchchannel({
          platform_id: id
        }).then(function (res) {
          _this3.searchchannels = res.data.channel;
          _this3.advchannels = res.data.adv_platform; //广告平台

          _this3.searchData.channel = "";
          _this3.searchData.pkg = "";
          _this3.searchData.advChannel = "";
          _this3.searchData.advLocation = "";
          _this3.searchMark.channel = "";
          _this3.searchMark.pkg = "";
          _this3.searchMark.ad_placement = "";
          _this3.searchMark.ad_channel = "";
          _this3.advLocation = [];
          _this3.searchpkgs = [];

          _this3.searchplatforms.forEach(function (item) {
            if (item.id == id) {
              _this3.searchMark.platform = item.platform_mark;
              _this3.searchData.channel = "";
              _this3.searchMark.channel = "";
            }
          });
        });
      } else {
        this.searchData.channel = "";
        this.searchData.pkg = "";
        this.searchMark.channel = "";
        this.searchMark.pkg = "";
        this.searchMark.platform = "";
        this.searchpkgs = [];
        this.searchchannels = [];
        this.searchData.advChannel = "";
        this.searchData.advLocation = "";
        this.searchMark.ad_placement = "";
        this.searchMark.ad_channel = "";
        this.advchannels = [];
        this.advLocation = [];
      }
    },
    channelchange: function channelchange(id) {
      var _this4 = this;

      if (id != "") {
        searchpkg({
          channel_id: id
        }).then(function (res) {
          _this4.searchpkgs = res.data;

          _this4.searchchannels.forEach(function (item) {
            if (item.id == id) {
              _this4.searchMark.channel = item.channel_mark;
              _this4.searchData.pkg = "";
              _this4.searchMark.pkg = "";
            }
          });
        });
      } else {
        this.searchData.pkg = "";
        this.searchMark.pkg = "";
        this.searchMark.channel = "";
        this.searchData.channel = "";
        this.searchpkgs = [];
      }
    },
    pkgchange: function pkgchange(id) {
      var _this5 = this;

      if (id != "") {
        this.searchpkgs.forEach(function (item) {
          if (item.id == id) {
            _this5.searchMark.pkg = item.pkg_mark;
          }
        });
      } else {
        this.searchMark.pkg = "";
      }
    },
    advChannelchange: function advChannelchange(id) {
      var _this6 = this;

      if (id != "") {
        getadvLocation({
          advplatform_id: id
        }).then(function (res) {
          _this6.advLocation = res.data;
          _this6.searchMark.ad_channel = "";

          _this6.advchannels.forEach(function (item) {
            if (item.id == id) {
              _this6.searchMark.ad_channel = item.adv_platform_mark;
              _this6.searchData.advLocation = "";
              _this6.searchMark.ad_placement = "";
            }
          });
        });
      } else {
        this.searchData.advLocation = "";
        this.searchMark.ad_placement = "";
        this.searchMark.ad_channel = "";
        this.searchData.advChannel = "";
        this.advLocation = [];
      }
    },
    advLocationchange: function advLocationchange(id) {
      var _this7 = this;

      if (id != "") {
        this.advLocation.forEach(function (item) {
          if (item.id == id) {
            _this7.searchMark.ad_placement = item.adv_place_mark;
          }
        });
      } else {
        this.searchMark.ad_placement = "";
      }
    },
    countrychange: function countrychange(id) {
      if (id != "") {
        this.searchMark.country = id;
      } else {
        this.searchMark.country = "";
      }
    },
    searchClick: function searchClick() {
      var _this8 = this;

      this.searchMark.user_id = this.searchData.user_id;
      this.searchMark.account_id = this.searchData.account_id;

      if (Array.isArray(this.value2) && this.value2.length > 0) {
        this.searchMark.start_time = this.value2[0];
        this.searchMark.end_time = this.value2[1];
      } else {
        this.searchMark.start_time = "";
        this.searchMark.end_time = "";
      }

      if (this.value3 && this.value3.length > 0) {
        this.searchMark.last_login_time = this.value3;
      } else {
        this.searchMark.last_login_time = "";
      } // this.searchMark.kind = 1;


      getGateUserMangList(this.searchMark).then(function (res) {
        if (res.status == 200) {
          var list = ['game', 'platform', 'channel', 'pkg', 'ad_channel', 'ad_placement'];
          _this8.tableData = res.data.data.length ? _this8.$utils.dataConversion(res.data.data, list) : [];
          _this8.total = res.data.total || 0;
        }
      });
    }
  }
};