var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      attrs: {
        title: _vm.titleName,
        visible: _vm.dialogFormVisible,
        width: "60%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "detailllss add-header" },
        [
          _c(
            "el-form",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "游戏", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      ref: "",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择游戏"
                      },
                      on: { change: _vm.classifychange },
                      model: {
                        value: _vm.form.game,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "game", $$v)
                        },
                        expression: "form.game"
                      }
                    },
                    _vm._l(_vm.gameData, function(item, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { "value-key": item, label: item, value: item }
                        },
                        [_vm._v(_vm._s(item) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "详情",
                    prop: "details",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("mavon-editor", {
                    attrs: { toolbars: _vm.toolbars, ishljs: true },
                    model: {
                      value: _vm.form.details,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "details", $$v)
                      },
                      expression: "form.details"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "warning", round: "" },
              on: {
                click: function($event) {
                  return _vm.clearData()
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }