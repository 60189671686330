import request from '@/utils/request'; //新增三方绑定配置

export function getPayorderList(data) {
  return request({
    url: '/order/pay_order_list',
    method: 'get',
    params: data
  });
} // 获取商品列表

export function getPayList(data) {
  return request({
    url: '/order/pay-product-list',
    method: 'get',
    params: data
  });
}