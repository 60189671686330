var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        "value-key": item.id,
                        label: item.game_name,
                        value: item.id
                      }
                    },
                    [_vm._v(_vm._s(item.game_name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择渠道"
                  },
                  on: { change: _vm.channelchange },
                  model: {
                    value: _vm.searchData.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "channel", $$v)
                    },
                    expression: "searchData.channel"
                  }
                },
                _vm._l(_vm.searchchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  on: { change: _vm.pkgchange },
                  model: {
                    value: _vm.searchData.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pkg", $$v)
                    },
                    expression: "searchData.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告渠道"
                  },
                  on: { change: _vm.advChannelchange },
                  model: {
                    value: _vm.searchData.ad_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_channel", $$v)
                    },
                    expression: "searchData.ad_channel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.adv_platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.advanced
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择广告位置"
                      },
                      on: { change: _vm.advLocationchange },
                      model: {
                        value: _vm.searchData.ad_placement,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_placement", $$v)
                        },
                        expression: "searchData.ad_placement"
                      }
                    },
                    _vm._l(_vm.advLocation, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.adv_place_name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.advanced
            ? _c(
                "el-col",
                { staticClass: "operationBut", attrs: { span: 4 } },
                [
                  _c("unpack", {
                    attrs: { advanced: _vm.advanced },
                    on: {
                      advancedChange: function($event) {
                        _vm.advanced = $event
                      },
                      searchChange: function($event) {
                        return _vm.searchClick()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.advanced
        ? _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请输入支付平台订单号",
                          clearable: ""
                        },
                        on: { change: _vm.orderChang },
                        model: {
                          value: _vm.searchData.third_part_order_id,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "third_part_order_id", $$v)
                          },
                          expression: "searchData.third_part_order_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请输入网关订单ID",
                          clearable: ""
                        },
                        on: { change: _vm.cpordChang },
                        model: {
                          value: _vm.searchData.gate_order_id,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "gate_order_id", $$v)
                          },
                          expression: "searchData.gate_order_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请输入服务器ID"
                        },
                        on: { change: _vm.serveIdchang },
                        model: {
                          value: _vm.searchData.server_id,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "server_id", $$v)
                          },
                          expression: "searchData.server_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择订单状态"
                          },
                          on: { change: _vm.orderstateChang },
                          model: {
                            value: _vm.searchData.order_state,
                            callback: function($$v) {
                              _vm.$set(_vm.searchData, "order_state", $$v)
                            },
                            expression: "searchData.order_state"
                          }
                        },
                        _vm._l(_vm.orderStateArr, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.lable, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择发货状态"
                          },
                          on: { change: _vm.deliverchang },
                          model: {
                            value: _vm.searchData.deliver_state,
                            callback: function($$v) {
                              _vm.$set(_vm.searchData, "deliver_state", $$v)
                            },
                            expression: "searchData.deliver_state"
                          }
                        },
                        _vm._l(_vm.deliverStateArr, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.lable, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择商品ID"
                          },
                          on: { change: _vm.commChang },
                          model: {
                            value: _vm.searchData.product_id,
                            callback: function($$v) {
                              _vm.$set(_vm.searchData, "product_id", $$v)
                            },
                            expression: "searchData.product_id"
                          }
                        },
                        _vm._l(_vm.commitList, function(item) {
                          return _c("el-option", {
                            key: item.product_id,
                            attrs: {
                              label: item.product_name,
                              value: item.product_id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "picker-options": _vm.pickerOptionsRadius,
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          align: "right",
                          "range-separator": "至",
                          "start-placeholder": "创建开始日期",
                          "end-placeholder": "创建结束日期"
                        },
                        on: { change: _vm.timeChang },
                        model: {
                          value: _vm.value2,
                          callback: function($$v) {
                            _vm.value2 = $$v
                          },
                          expression: "value2"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "operationBut", attrs: { span: 4 } },
                    [
                      _c("unpack", {
                        attrs: { advanced: _vm.advanced },
                        on: {
                          advancedChange: function($event) {
                            _vm.advanced = $event
                          },
                          searchChange: function($event) {
                            return _vm.searchClick()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length > 0
        ? _c(
            "el-row",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        { attrs: { "max-height": "500", data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "game_reveal",
              label: "游戏",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "platform_reveal",
              label: "平台",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel_reveal",
              label: "渠道",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pkg_reveal",
              label: "包名",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_placement_reveal",
              label: "广告位置",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.ad_placement_reveal
                            ? scope.row.ad_placement_reveal
                            : "defalut"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_channel_reveal",
              label: "广告渠道",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.ad_channel_reveal
                            ? scope.row.ad_channel_reveal
                            : "defalut"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "server_id", label: "服务器ID", "min-width": "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "account_id",
              label: "网关账号ID",
              "min-width": "180"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "user_id", label: "用户中心ID", "min-width": "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gate_order_id",
              label: "网关订单ID",
              "min-width": "180"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "order_id",
              label: "支付中心订单ID",
              "min-width": "180"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "third_part_order_id",
              label: "支付中心订单号",
              "min-width": "180"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "product_id", label: "商品ID", "min-width": "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "price", label: "商品价格", "min-width": "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "product_name",
              label: "商品名称",
              "min-width": "180"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "pay_type", label: "支付类型", "min-width": "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "order_state",
              label: "订单状态",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.order_state == 1
                            ? "创建"
                            : scope.row.order_state == 2
                            ? "已回调"
                            : scope.row.order_state == 3
                            ? "回调成功"
                            : scope.row.order_state == 4
                            ? "回调失败"
                            : "/"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "conversion_ratio",
              label: "创建时间",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(_vm._s(_vm.timestampToTime(scope.row.create_time)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliver_state",
              label: "回调状态",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.order_state == 1
                            ? "创建"
                            : scope.row.order_state == 2
                            ? "已回调"
                            : scope.row.order_state == 3
                            ? "回调成功"
                            : scope.row.order_state == 4
                            ? "回调失败"
                            : "/"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "conversion_ratio",
              label: "回调时间",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(_vm.timestampToTime(scope.row.callback_time))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliver_state",
              label: "发货状态",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.deliver_state == 1
                            ? "未回调"
                            : scope.row.deliver_state == 2
                            ? "开始回调研发"
                            : scope.row.deliver_state == 3
                            ? "回调成功"
                            : scope.row.deliver_state == 4
                            ? "回调失败"
                            : scope.row.deliver_state == 5
                            ? "回调次数超过允许值"
                            : "/"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "conversion_ratio",
              label: "发货时间",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(_vm.timestampToTime(scope.row.deliver_time))
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": 1,
          total: _vm.total,
          "page-size": 10
        },
        on: { "current-change": _vm.handleCurrentChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }