//
//
//
//
//
//
//
//
//
//
import uploadImagetow from '@/components/uploadImagetow';
export default {
  components: {
    uploadImagetow: uploadImagetow
  },
  data: function data() {
    return {
      dialogShow: false,
      paramsData: {}
    };
  },
  created: function created() {},
  methods: {
    open: function open() {
      this.dialogShow = true;
    },
    upList: function upList() {
      this.dialogShow = false;
      this.$emit('updFile');
    }
  }
};