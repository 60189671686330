var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.searchData.id ? "修改" : "提交",
            visible: _vm.dialogShow
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData()
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.searchData,
                "label-width": "100px"
              }
            },
            [
              _vm.rawData.game_show_type == 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      attrs: { prop: "special_product_id", label: "选择产品" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择产品"
                          },
                          model: {
                            value: _vm.searchData.special_product_id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchData,
                                "special_product_id",
                                $$v
                              )
                            },
                            expression: "searchData.special_product_id"
                          }
                        },
                        _vm._l(_vm.productsList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "服务器ID" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入服务器ID" },
                    model: {
                      value: _vm.searchData.server_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "server_id", $$v)
                      },
                      expression: "searchData.server_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "设备ID" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入设备ID" },
                    model: {
                      value: _vm.searchData.device_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "device_id", $$v)
                      },
                      expression: "searchData.device_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "user_id", label: "账号ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入账号ID" },
                    model: {
                      value: _vm.searchData.user_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "user_id", $$v)
                      },
                      expression: "searchData.user_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "角色ID" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入角色ID" },
                    model: {
                      value: _vm.searchData.role_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "role_id", $$v)
                      },
                      expression: "searchData.role_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入订单号" },
                    model: {
                      value: _vm.searchData.order_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "order_id", $$v)
                      },
                      expression: "searchData.order_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "等级" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入等级(选填，默认0)" },
                    model: {
                      value: _vm.searchData.level,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "level", _vm._n($$v))
                      },
                      expression: "searchData.level"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "amount", label: "金额" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      type: "number",
                      placeholder: "请输入金额（数字，分）"
                    },
                    model: {
                      value: _vm.searchData.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "amount", _vm._n($$v))
                      },
                      expression: "searchData.amount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "货币类型" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入货币类型（选填，默认CNY）" },
                    model: {
                      value: _vm.searchData.currency,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "currency", $$v)
                      },
                      expression: "searchData.currency"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "product_id", label: "商品ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入商品ID" },
                    model: {
                      value: _vm.searchData.product_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "product_id", $$v)
                      },
                      expression: "searchData.product_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "product_name", label: "商品名称" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.searchData.product_name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "product_name", $$v)
                      },
                      expression: "searchData.product_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "充值用户IP" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入充值用户IP" },
                    model: {
                      value: _vm.searchData.ip,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "ip", $$v)
                      },
                      expression: "searchData.ip"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "timestamp", label: "充值时间" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "flex-1",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      "picker-options": _vm.pickerOptions,
                      type: "datetime",
                      "value-format": "timestamp",
                      placeholder: "请选择",
                      align: "right"
                    },
                    model: {
                      value: _vm.searchData.timestamp,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "timestamp", $$v)
                      },
                      expression: "searchData.timestamp"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "", loading: _vm.issubmit },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }