var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          staticStyle: { "pointer-events": "auto" },
          attrs: {
            title: _vm.isNum == 1 ? "新增实名认证参数" : "修改实名认证参数",
            visible: _vm.dialogFormVisible,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.clearData
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                model: _vm.searchData,
                rules: _vm.rules,
                "label-width": "90px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "游戏", prop: "game" }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择游戏"
                      },
                      on: { change: _vm.gamechange },
                      model: {
                        value: _vm.searchData.game,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "game", $$v)
                        },
                        expression: "searchData.game"
                      }
                    },
                    _vm._l(_vm.searchgames, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.game, value: item.game }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "平台", prop: "platform" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择平台"
                      },
                      on: { change: _vm.platformchange },
                      model: {
                        value: _vm.searchData.platform,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "platform", $$v)
                        },
                        expression: "searchData.platform"
                      }
                    },
                    _vm._l(_vm.searchplatforms, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.platform, value: item.platform }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "渠道", prop: "channel" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择渠道"
                      },
                      on: { change: _vm.channelchange },
                      model: {
                        value: _vm.searchData.channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "channel", $$v)
                        },
                        expression: "searchData.channel"
                      }
                    },
                    _vm._l(_vm.searchchannels, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.channel, value: item.channel }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "包名", prop: "pkg" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择包名"
                      },
                      model: {
                        value: _vm.searchData.pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "pkg", $$v)
                        },
                        expression: "searchData.pkg"
                      }
                    },
                    _vm._l(_vm.searchpkgs, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg, value: item.pkg }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "appid", prop: "appid" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入appid"
                    },
                    model: {
                      value: _vm.searchData.appid,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "appid", $$v)
                      },
                      expression: "searchData.appid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "bizid", prop: "bizid" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入bizid"
                    },
                    model: {
                      value: _vm.searchData.bizid,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "bizid", $$v)
                      },
                      expression: "searchData.bizid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "secretkey", prop: "secretkey" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请输入secretkey"
                    },
                    model: {
                      value: _vm.searchData.secretkey,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "secretkey", $$v)
                      },
                      expression: "searchData.secretkey"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "是否实名", prop: "if_real_name" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.searchData.if_real_name,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "if_real_name", $$v)
                        },
                        expression: "searchData.if_real_name"
                      }
                    },
                    _vm._l(_vm.realName, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.OffmOD()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.isNum == 1 ? "新增" : "修改") + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }