// 获取全部游戏分类
import request from '@/utils/request';
export function getGameType(data) {
  return request({
    url: '/datum/game_all',
    method: 'get',
    params: data
  });
} //   获取详情展示页面

export function getGamedetailing(data) {
  return request({
    url: '/datum/game_details',
    method: 'get',
    params: data
  });
} //   新增游戏详情

export function addGamedetailing(data) {
  return request({
    url: '/datum/add_game_details',
    method: 'post',
    params: data
  });
} //   修改游戏详情

export function updataGamedetailing(data) {
  return request({
    url: '/datum/up_game_details',
    method: 'post',
    params: data
  });
} //   弹窗反显数据

export function returnGamedetailing(data) {
  return request({
    url: '/datum/up_game_details',
    method: 'get',
    params: data
  });
} //   删除游戏详情

export function deleGamedetailing(data) {
  return request({
    url: '/datum/del_game_details',
    method: 'get',
    params: data
  });
}