import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.is";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { dataSummary } from "@/api/dataAll/dataSummary";
import * as echarts from "echarts";
export default {
  name: "dataSummary",
  mixins: [serchSelect],
  data: function data() {
    return {
      intReq: ['getCountrys', 'getdataCurrency'],
      value2: [],
      tableData: [],
      dates: [],
      Dynamic: [],
      primt: [{
        label: '日期.Date',
        prop: 'date',
        tabText: '2020-01-01'
      }, {
        label: '付费总额.Revenue',
        prop: 'recharge_account_amount',
        tabText: '付费总额'
      }, {
        label: '活跃账号.DAU',
        prop: 'active_account_num',
        tabText: '活跃账号'
      }, {
        label: '新增账号.DNU',
        prop: 'new_account_num',
        tabText: '新增账号'
      }, {
        label: 'DNU/DAU',
        prop: 'DNU_DAU',
        tabText: 'DNU/DAU'
      }, {
        label: '付费账号数.DPU',
        prop: 'recharge_account_num',
        tabText: '付费账号数'
      }, {
        label: '付费率.PUR',
        prop: 'pay_account_percent',
        tabText: '付费率'
      }, {
        label: 'ARPU',
        prop: 'pay_account_arpu',
        tabText: 'ARPU'
      }, {
        label: 'ARPPU',
        prop: 'pay_account_arppu',
        tabText: 'ARPPU'
      }, {
        label: '新增设备.DND',
        prop: 'new_device_num',
        tabText: '新增设备'
      }, {
        label: 'DND/DNU',
        prop: 'DND_DNU',
        tabText: 'DND/DNU'
      }, {
        label: "新增账号付费总额.RevenueNU",
        prop: 'register_recharge_amount',
        tabText: '新增账号付费总额'
      }, {
        label: '新增付费账号数.PNU',
        prop: 'register_recharge_pnum',
        tabText: '新增付费账号数'
      }, {
        label: '登录账号',
        prop: 'login_account_num',
        tabText: '登录账号'
      }, {
        label: 'arpnu',
        prop: 'arpnu',
        tabText: 'arpnu'
      }, {
        label: 'arppnu',
        prop: 'arppnu',
        tabText: 'arppnu'
      }, {
        label: '新增安装量.Install',
        prop: 'Install',
        tabText: '新增安装量'
      }, {
        label: '新增投放量.I-Ads',
        prop: 'I-Ads',
        tabText: '新增投放量'
      }, {
        label: '新增自然量.I-Organic',
        prop: 'I-Organic',
        tabText: 'I-Organic'
      }, {
        label: '自然量K因子.K-Factory',
        prop: 'K-Factory',
        tabText: '自然量K因子'
      }, {
        label: '安装注册转化率.CR',
        prop: 'CR',
        tabText: '安装注册转化率'
      }, {
        label: '活跃付费率.PR',
        prop: 'PR',
        tabText: '活跃付费率'
      }, {
        label: '新增付费率.NPR',
        prop: 'NPR',
        tabText: '新增付费率'
      }, {
        label: '充值付费总额.Rev-IAP',
        prop: 'Rev-IAP',
        tabText: '充值付费总额'
      }, {
        label: '广告付费额.Rev-IAA',
        prop: 'Rev-IAA',
        tabText: '广告付费额'
      }, {
        label: '活跃人均贡献.DARPU',
        prop: 'DARPU',
        tabText: '活跃人均贡献'
      }, {
        label: '新增安装成本.eCPI',
        prop: 'eCPI',
        tabText: '新增安装成本'
      }, {
        label: '新增账号成本.eCPA',
        prop: 'eCPA',
        tabText: '新增账号成本'
      }, {
        label: '新增次流成本.eCPR',
        prop: 'eCPR',
        tabText: '新增次流成本'
      }, {
        label: '新增付费成本.eCPP',
        prop: 'eCPP',
        tabText: '新增付费成本'
      }, {
        label: '次留用户数',
        prop: 'eCPR_count',
        tabText: '次留用户数'
      }, {
        label: 'LTV1',
        prop: 'LTV1',
        tabText: 'LTV1'
      }, {
        label: 'LTV2',
        prop: 'LTV2',
        tabText: 'LTV2'
      }, {
        label: 'LTV3',
        prop: 'LTV3',
        tabText: 'LTV3'
      }, {
        label: 'LTV4',
        prop: 'LTV4',
        tabText: 'LTV4'
      }, {
        label: 'LTV5',
        prop: 'LTV5',
        tabText: 'LTV5'
      }, {
        label: 'LTV6',
        prop: 'LTV6',
        tabText: 'LTV6'
      }, {
        label: 'LTV7',
        prop: 'LTV7',
        tabText: 'LTV7'
      }, {
        label: 'LTV14',
        prop: 'LTV14',
        tabText: 'LTV14'
      }, {
        label: 'LTV30',
        prop: 'LTV30',
        tabText: 'LTV30'
      }, {
        label: 'LTV60',
        prop: 'LTV60',
        tabText: 'LTV60'
      }, {
        label: 'LTV90',
        prop: 'LTV90',
        tabText: 'LTV90'
      }, {
        label: 'LTV120',
        prop: 'LTV120',
        tabText: 'LTV120'
      }, {
        label: 'ROI1',
        prop: 'ROI1',
        tabText: 'ROI1'
      }, {
        label: 'ROI2',
        prop: 'ROI2',
        tabText: 'ROI2'
      }, {
        label: 'ROI3',
        prop: 'ROI3',
        tabText: 'ROI3'
      }, {
        label: 'ROI4',
        prop: 'ROI4',
        tabText: 'ROI4'
      }, {
        label: 'ROI5',
        prop: 'ROI5',
        tabText: 'ROI5'
      }, {
        label: 'ROI6',
        prop: 'ROI6',
        tabText: 'ROI6'
      }, {
        label: 'ROI7',
        prop: 'ROI7',
        tabText: 'ROI7'
      }, {
        label: 'ROI14',
        prop: 'ROI14',
        tabText: 'ROI14'
      }, {
        label: 'ROI30',
        prop: 'ROI30',
        tabText: 'ROI30'
      }, {
        label: 'ROI60',
        prop: 'ROI60',
        tabText: 'ROI60'
      }, {
        label: 'ROI90',
        prop: 'ROI90',
        tabText: 'ROI90'
      }, {
        label: 'ROI120',
        prop: 'ROI120',
        tabText: 'ROI120'
      }],
      columns: [],
      sericeArr: [],
      screen: [],
      turnList: ['CR', 'PR', 'NPR', 'pay_account_percent', 'sameday', 'otherday'],
      sortable: null
    };
  },
  created: function created() {
    this.getsearchDate(); // 列的拖拽初始化
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.kind = 1;
      dataSummary(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this.dates = [];
          _this.tableData = []; // 格式化 单位 数据

          var list = ['register_recharge_amount', 'recharge_account_amount', 'pay_account_arpu', 'pay_account_arppu'];
          list.forEach(function (item) {
            _this.primt.map(function (kitem) {
              if (kitem.prop == item) {
                kitem.label = "".concat(kitem.label);
              }
            });
          });
          _this.tableData = [];

          for (var i in res.data) {
            _this.dates.push(i);

            var DNUDAU = Number(res.data[i].other.new_account_num) / Number(res.data[i].other.active_account_num);
            var DNDDNU = Number(res.data[i].other.new_device_num) / Number(res.data[i].other.new_account_num);

            var d = _objectSpread(_objectSpread({
              date: i
            }, res.data[i].other), {}, {
              new_device_num: res.data[i].other.new_device_num,
              //新增设备
              new_account_num: res.data[i].other.new_account_num,
              //  新增账号,
              login_account_num: res.data[i].other.login_account_num,
              //  登录账号,
              active_account_num: res.data[i].other.active_account_num,
              //  活跃账号,
              sameday: _this.zhuan([res.data[i].other.sameday])[0],
              //  当日转化率,
              otherday: _this.zhuan([res.data[i].other.otherday])[0],
              // 非当日转化率,
              register_recharge_pnum: res.data[i].other.register_recharge_pnum,
              //  新增付费账号数,
              register_recharge_amount: _this.$utils.conversion(res.data[i].other.register_recharge_amount),
              // 新增账号付费金额 
              recharge_account_num: res.data[i].other.recharge_account_num,
              //  付费账号数,
              recharge_account_amount: _this.$utils.conversion(res.data[i].other.recharge_account_amount),
              //  	付费总额
              pay_account_percent: (res.data[i].other.pay_account_percent * 100).toFixed(2),
              //  付费率 
              pay_account_arpu: _this.$utils.conversion(res.data[i].other.pay_account_arpu).toFixed(2),
              //  arpu 
              pay_account_arppu: _this.$utils.conversion(res.data[i].other.pay_account_arppu).toFixed(2),
              //  arppu 
              arpnu: _this.$utils.conversion(res.data[i].other.arpnu).toFixed(3),
              //  arpnu,
              arppnu: _this.$utils.conversion(res.data[i].other.arppnu).toFixed(3),
              //  arppnu,
              CR: (res.data[i].other.CR * 100).toFixed(2),
              PR: (res.data[i].other.PR * 100).toFixed(2),
              NPR: (res.data[i].other.NPR * 100).toFixed(2),
              DNU_DAU: (DNUDAU === Infinity || Object.is(DNUDAU, NaN) ? Number(res.data[i].other.new_account_num) : Number(res.data[i].other.new_account_num) / Number(res.data[i].other.active_account_num)).toFixed(3),
              DND_DNU: (DNDDNU === Infinity || Object.is(DNDDNU, NaN) ? Number(res.data[i].other.new_device_num) : Number(res.data[i].other.new_device_num) / Number(res.data[i].other.new_account_num)).toFixed(3)
            }); // LTV


            for (var li in res.data[i].LTV) {
              d['LTV' + li] = res.data[i].LTV[li];
            } // ROI


            for (var _li in res.data[i].ROI) {
              d['ROI' + _li] = res.data[i].ROI[_li];
            }

            var length = Object.keys(JSON.parse(JSON.stringify(res.data[i].data))).length;

            for (var k in res.data[i].data) {
              d['retention' + k] = res.data[i].data[k];

              if (_this.Dynamic.length < length) {
                var a = k == 2 ? '次' : k;

                _this.Dynamic.push({
                  label: a + '留.RR' + k,
                  prop: 'retention' + k,
                  tabText: (a + '留.RR' + k).split('.')[1]
                });
              }
            }

            _this.tableData.push(d);
          }
        }

        _this.columns = [].concat(_toConsumableArray(_this.primt), _toConsumableArray(_this.Dynamic));
        _this.sericeArr = _this.columns;
        _this.screen = _this.sericeArr.map(function (i) {
          return i.prop;
        }); // this.$nextTick(() => {
        //   this.columnDropInit();
        // })

        _this.getechart();
      });
    },
    screenControls: function screenControls() {
      var _this2 = this;

      var list = [];
      this.columns.forEach(function (i) {
        if (_this2.screen.findIndex(function (t) {
          return t == i.prop;
        }) !== -1) {
          list.push(i);
        }
      });
      this.sericeArr = list;
    },
    zhuan: function zhuan(list) {
      var n = [];
      list.forEach(function (item) {
        n.push(item.length > 0 ? Number(item.substr(0, item.length - 1)) : 0);
      });
      return n;
    },
    getechart: function getechart() {
      var _this3 = this;

      var chartDom = document.getElementById("dataSummaryEchart");
      var myChart = echarts.init(chartDom);
      var option;
      var data = [];
      var series = [];
      this.primt.forEach(function (item) {
        if (item.prop !== 'date') {
          var label = item.label.split('.')[0];
          data.push(label);
          var d = {
            name: label,
            type: "line",
            data: [],
            smooth: true
          };

          _this3.tableData.forEach(function (k) {
            d.data.push(k[item.prop]);
          });

          series.push(d);
        }
      });
      option = {
        title: {
          text: "数据汇总"
        },
        animationDuration: 3000,
        tooltip: {
          trigger: "axis",
          confine: true,
          formatter: function formatter(params) {
            // 提示内容太多隔行显示内容
            var astr = '';
            astr += "".concat(params[0].axisValue, "<br/>");
            params.forEach(function (ele, index) {
              astr += "<div style=\"display: block;height:20px;".concat(index % 2 === 0 ? 'width: 50%;' : 'width: 38%;', "float:left;\">\n                        <i style=\"width: 10px;height: 10px;display: inline-block;background: ").concat(ele.color, ";border-radius: 10px;\"></i>\n                        <span>").concat(ele.seriesName, ": ").concat(ele.data, "</span>\n                        </div>");
            });
            var b = '<div style="width: 360px;">' + astr + '<div>';
            return b;
          }
        },
        legend: {
          data: data,
          top: "4%",
          icon: 'circle',
          itemHeight: 12,
          itemWidth: 15,
          inactiveColor: '#666666',
          formatter: ['{a|{name}}'].join('\n'),
          textStyle: {
            fontSize: 12,
            lineHeight: 12,
            rich: {
              a: {
                width: 120,
                verticalAlign: 'middle'
              }
            },
            padding: [0, 0, -2, -4] //[上、右、下、左]

          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "20%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.dates
        },
        yAxis: {
          type: "value"
        },
        series: series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var _this4 = this;

      var columns = this.columns;
      var data = JSON.parse(JSON.stringify(this.tableData));
      data.map(function (item) {
        _this4.turnList.forEach(function (kitem) {
          item[kitem] = item[kitem] + '%';
        });
      });
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};