var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "修改记录",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { width: "1400px" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.list, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "admin_id",
                      label: "操作员",
                      align: "center",
                      "min-width": "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "expire",
                      label: "服务器名称",
                      align: "center",
                      "min-width": "140"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " + _vm._s(row.params.request.serverName) + " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "config_state",
                      label: "配置服务器状态",
                      "min-width": "140"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.configState.find(function(i) {
                                    return (
                                      i.value == row.params.request.config_state
                                    )
                                  }).label
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "new_tag",
                      label: "是否是新服",
                      "min-width": "130"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.params.request.new_tag ? "是" : "否"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "is_recomm",
                      label: "推荐服务器标记",
                      "min-width": "180"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.recommList.find(function(i) {
                                    return (
                                      i.value == row.params.request.is_recomm
                                    )
                                  }).label
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "push_msg",
                      label: "服务器推送信息",
                      "min-width": "190",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "text-ellipsis-1 " }, [
                              _vm._v(
                                " " +
                                  _vm._s(row.params.request.push_msg || "/") +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "timestamp",
                      label: "提交时间",
                      align: "center",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "is_timing",
                      label: "是否定时",
                      align: "center",
                      "min-width": "140"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "text-ellipsis-1 " }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.params.request.is_timing == 1
                                      ? "定时修改任务"
                                      : "普通修改"
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "result",
                      label: "修改状态",
                      align: "center",
                      "min-width": "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "text-ellipsis-1 " }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.params.request.is_timing == 1 &&
                                      !row.params.result
                                      ? "定时任务"
                                      : row.params.result.result == 1
                                      ? "修改成功"
                                      : "修改失败"
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }