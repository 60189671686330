var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page advvsorigindd section dataAll-content" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _vm.game_show_type == 1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择投放包"
                      },
                      on: { change: _vm.pkgChange },
                      model: {
                        value: _vm.searchData.pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "pkg", $$v)
                        },
                        expression: "searchData.pkg"
                      }
                    },
                    _vm._l(_vm.searchpkgs, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg_name, value: item.pkg_mark }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告渠道"
                  },
                  on: { change: _vm.advChannelchange },
                  model: {
                    value: _vm.searchData.ad_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_channel", $$v)
                    },
                    expression: "searchData.ad_channel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_platform_name,
                      value: item.adv_platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告位置"
                  },
                  model: {
                    value: _vm.searchData.ad_placement,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_placement", $$v)
                    },
                    expression: "searchData.ad_placement"
                  }
                },
                _vm._l(_vm.advLocation, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_place_name,
                      value: item.adv_place_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: false,
                  "picker-options": _vm.pickerOptionsRadius,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "操作开始日期",
                  "end-placeholder": "操作结束日期"
                },
                model: {
                  value: _vm.value2,
                  callback: function($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptionsRadius,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "激活开始日期",
                  "end-placeholder": "激活结束日期",
                  "value-format": "yyyy-MM-dd",
                  align: "right"
                },
                model: {
                  value: _vm.value1,
                  callback: function($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入广告渠道" },
                model: {
                  value: _vm.searchData.ad_action_channel,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "ad_action_channel", $$v)
                  },
                  expression: "searchData.ad_action_channel"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                staticClass: "pubil",
                attrs: { placeholder: "请输入广告位置" },
                model: {
                  value: _vm.searchData.ad_action_placement,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "ad_action_placement", $$v)
                  },
                  expression: "searchData.ad_action_placement"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                staticClass: "pubilc",
                attrs: { placeholder: "请输入操作" },
                model: {
                  value: _vm.searchData.ad_action,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "ad_action", $$v)
                  },
                  expression: "searchData.ad_action"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                staticClass: "pubilc",
                attrs: { placeholder: "请输入拓展关键字" },
                model: {
                  value: _vm.searchData.ext,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "ext", $$v)
                  },
                  expression: "searchData.ext"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchClick } },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ]
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "levelDetails-body"
        },
        [
          _c("div", { staticClass: "data-box" }, [
            _c(
              "div",
              { staticClass: "titles" },
              [
                _c("div", { staticClass: "widthing" }, [_vm._v("分类")]),
                _vm._l(_vm.tableData, function(item, index) {
                  return _c("div", { key: index, staticClass: "widthing" }, [
                    _vm._v(" " + _vm._s(item.title) + " ")
                  ])
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "proportion" },
              [
                _c("div", { staticClass: "widthing" }, [_vm._v("占比")]),
                _vm._l(_vm.tableData, function(item, index) {
                  return _c("div", { key: index, staticClass: "widthing" }, [
                    _vm._v(" " + _vm._s(item.proportion) + " ")
                  ])
                })
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }