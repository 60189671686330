import request from "@/utils/request"; //平台 -广告平台- 广告位置列表

export function getAll(data) {
  return request({
    url: "/adv_relevance/lists",
    method: "get",
    params: data
  });
} //平台关联广告平台 -- 获取已关联的广告平台id

export function getGamePlatformId(data) {
  return request({
    url: "/adv_relevance/platform_relevance_advplatform",
    method: "get",
    params: data
  });
} // 平台关联广告平台

export function getGamePlatform(data) {
  return request({
    url: "/adv_relevance/platform_relevance_advplatform",
    method: "post",
    params: data
  });
} // 广告平台关联广告位置 -- 获取已关联的广告位置id

export function getRelevanceAdvplaceId(data) {
  return request({
    url: "/adv_relevance/advplatform_relevance_advplace",
    method: "get",
    params: data
  });
} // 平台关联渠道展示

export function getRelevanceAdvplace(data) {
  return request({
    url: "/adv_relevance/advplatform_relevance_advplace",
    method: "post",
    params: data
  });
} // 关联设置更新版接口

export function updeadvRelativeings(data) {
  return request({
    url: "/adv/advertising_relevance",
    method: "post",
    params: data
  });
} // 产品列表 无权限

export function listNo(data) {
  return request({
    url: "/game-product/listNo",
    method: "get",
    params: data
  });
} // 通过产品id查看绑定的广告平台

export function productRelevanceAdvplatform(data) {
  return request({
    url: "/adv_relevance/productRelevanceAdvplatform",
    method: "get",
    params: data
  });
} // 产品与广告平台设置关联

export function productAdvertisingRelevance(data) {
  return request({
    url: "/adv/productAdvertisingRelevance",
    method: "post",
    params: data
  });
} // 获取所有广告位置和广告渠道

export function advPlatformAll(data) {
  return request({
    url: "/user/advPlatformAll",
    method: "get",
    params: data
  });
} // 获取用户绑定的广告平台和广告位置

export function getBindUserAdv(data) {
  return request({
    url: "/user/getBindUserAdv",
    method: "get",
    params: data
  });
} // 绑定广告渠道 广告位置

export function userBindAdv(data) {
  return request({
    url: "/user/userBindAdv",
    method: "post",
    params: data
  });
}