// 聊天监控获取聊天类型
import request from '@/utils/request';
export function chartTypeList(data) {
  return request({
    url: '/datum/chat_type',
    method: 'get',
    params: data
  });
}
export function getchartList(data) {
  return request({
    url: '/datum/chat',
    method: 'get',
    params: data
  });
}
export function chatGroupType(data) {
  return request({
    url: '/datum/chat_group_type',
    method: 'get',
    params: data
  });
}