//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogShow: false,
      list: []
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      this.list = JSON.parse(data);
      this.dialogShow = true;
    },
    hiad: function hiad() {
      this.dialogShow = false;
    }
  }
};