//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    orderList: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      config: {
        header: ["订单ID", "游戏", "渠道", "平台", "包名", "价格", "产品ID"],
        orderArr: [],
        hoverPause: true,
        data: [],
        rowNum: 0,
        //表格行数
        headerHeight: 35,
        headerBGC: "rgba(0, 0, 0, 0.2)",
        //表头
        oddRowBGC: "rgba(0, 0, 0, 0.1)",
        //奇数行
        evenRowBGC: "rgba(0, 0, 0, 0.1)",
        //偶数行
        // index: true,
        align: ["center"]
      },
      otherhWidth: 0,
      otherhHeight: 0
    };
  },
  components: {},
  created: function created() {
    var _this = this;

    this.orderArr = this.orderList;
    this.config.rowNum = this.orderArr.length;
    this.$nextTick(function () {
      var rankingBoard = document.getElementById('therList');
      _this.otherhHeight = rankingBoard.clientHeight;
      _this.otherhWidth = rankingBoard.clientWidth; // console.log(this.otherhHeight,'therList')
    });
  },
  mounted: function mounted() {
    for (var k = 0; k < this.orderArr.length; k++) {
      var arr = [];
      arr.push(this.orderArr[k].order_id);
      arr.push(this.orderArr[k].game);
      arr.push(this.orderArr[k].channel);
      arr.push(this.orderArr[k].platform);
      arr.push(this.orderArr[k].pkg);
      arr.push(this.orderArr[k].price);
      arr.push(this.orderArr[k].product_id);
      this.config.data.push(arr);
    }
  },
  methods: {}
};