// 订单展示数据
import request from '@/utils/request';
export function orderDisplayList(data) {
  return request({
    url: '/apply/recharge_game',
    method: 'get',
    params: data
  });
}
export function orderDisplayList_currency(data) {
  return request({
    url: '/apply/recharge_game_currency',
    method: 'get',
    params: data
  });
}
export function recharge_game_all(data) {
  return request({
    url: '/apply/recharge_game_all',
    method: 'get',
    params: data
  });
}
export function recharge_game_currency_all(data) {
  return request({
    url: '/apply/recharge_game_currency_all',
    method: 'get',
    params: data
  });
} // 退款订单

export function refundOrderList(data) {
  return request({
    url: '/data/refundOrderList',
    method: 'get',
    params: data
  });
} // 登录列表 数据展示

export function loginListShow(data) {
  return request({
    url: '/apply/login_record',
    method: 'get',
    params: data
  });
} // 注册列表数据展示

export function registListShow(data) {
  return request({
    url: '/apply/register_record',
    method: 'get',
    params: data
  });
} // 礼包列表数据展示

export function giftListShow(data) {
  return request({
    url: '/apply/gift_bag_list',
    method: 'get',
    params: data
  });
}