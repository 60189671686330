//
//
//
//
//
//
import echartMixins from "@/utils/resizeMixins";
export default {
  data: function data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  mounted: function mounted() {
    this.draw();
  },
  methods: {
    draw: function draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreRight2Chart1")); //  ----------------------------------------------------------------

      var dataBJ = [[94, 69, 114, 2.08, 73, 39, 22], [99, 73, 110, 2.43, 76, 48, 23], [31, 12, 30, 0.5, 32, 16, 24], [42, 27, 43, 1, 53, 22, 25], [154, 117, 157, 3.05, 92, 58, 26], [234, 185, 230, 4.09, 123, 69, 27], [160, 120, 186, 2.77, 91, 50, 28]];
      var dataGZ = [[84, 94, 140, 2.238, 68, 18, 22], [93, 77, 104, 1.165, 53, 7, 23], [99, 130, 227, 3.97, 55, 15, 24], [146, 84, 139, 1.094, 40, 17, 25], [113, 108, 137, 1.481, 48, 15, 26], [81, 48, 62, 1.619, 26, 3, 27], [56, 48, 68, 1.336, 37, 9, 28]];
      var dataSH = [[91, 45, 125, 0.82, 34, 23, 1], [65, 27, 78, 0.86, 45, 29, 2], [83, 60, 84, 1.09, 73, 27, 3], [109, 81, 121, 1.28, 68, 51, 4], [106, 77, 114, 1.07, 55, 51, 5], [109, 81, 121, 1.28, 68, 51, 6], [106, 77, 114, 1.07, 55, 51, 7]];
      var lineStyle = {
        normal: {
          width: 1,
          opacity: 0.5
        }
      };
      var option = {
        radar: {
          indicator: [{
            name: "AQI",
            max: 300
          }, {
            name: "PM2.5",
            max: 250
          }, {
            name: "PM10",
            max: 300
          }, {
            name: "CO",
            max: 5
          }, {
            name: "NO2",
            max: 200
          }, {
            name: "SO2",
            max: 100
          }],
          shape: "circle",
          splitNumber: 5,
          name: {
            textStyle: {
              color: "rgb(238, 197, 102)"
            }
          },
          splitLine: {
            lineStyle: {
              color: ["rgba(238, 197, 102, 0.1)", "rgba(238, 197, 102, 0.2)", "rgba(238, 197, 102, 0.4)", "rgba(238, 197, 102, 0.6)", "rgba(238, 197, 102, 0.8)", "rgba(238, 197, 102, 1)"].reverse()
            }
          },
          splitArea: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "rgba(238, 197, 102, 0.5)"
            }
          }
        },
        series: [{
          name: "北京",
          type: "radar",
          lineStyle: lineStyle,
          data: dataBJ,
          symbol: "none",
          itemStyle: {
            normal: {
              color: "#F9713C"
            }
          },
          areaStyle: {
            normal: {
              opacity: 0.1
            }
          }
        }, {
          name: "上海",
          type: "radar",
          lineStyle: lineStyle,
          data: dataSH,
          symbol: "none",
          itemStyle: {
            normal: {
              color: "#B3E4A1"
            }
          },
          areaStyle: {
            normal: {
              opacity: 0.05
            }
          }
        }, {
          name: "广州",
          type: "radar",
          lineStyle: lineStyle,
          data: dataGZ,
          symbol: "none",
          itemStyle: {
            normal: {
              color: "rgb(238, 197, 102)"
            }
          },
          areaStyle: {
            normal: {
              opacity: 0.05
            }
          }
        }]
      };
      this.chart.setOption(option);
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  }
};