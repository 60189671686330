var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  "picker-options": _vm.pickerOptionsRadius,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "创建开始日期",
                  "end-placeholder": "创建结束日期"
                },
                model: {
                  value: _vm.value2,
                  callback: function($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.switchSerive },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function() {
                      _vm.tableData = []
                      _vm.getList()
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addCode.open()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "admin_name", label: "操作员", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.admin_name || "/") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cp_announce_id",
              label: "研发公告ID",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(row.cp_announce_id.toString() || "/") + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "language", label: "公告类型", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.announcementTypes.find(function(i) {
                            return i.value == row.pause
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: "标题",
              "show-overflow-tooltip": "",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.title || "/"))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "language", label: "语言", "min-width": "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.language || "/") + " ")]
                }
              }
            ])
          }),
          _vm._v(" " + _vm._s(_vm.serverLists) + " "),
          _c("el-table-column", {
            attrs: {
              prop: "server_name",
              label: "开始服务器",
              "min-width": "120",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.server_rangeList[0]
                            ? _vm.serverLists.find(function(i) {
                                return i.serverID == row.server_rangeList[0]
                              })
                              ? _vm.serverLists.find(function(i) {
                                  return i.serverID == row.server_rangeList[0]
                                }).serverName
                              : row.server_rangeList[0]
                            : "全部"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "server_name",
              label: "结束服务器",
              "min-width": "120",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.server_rangeList[1]
                            ? _vm.serverLists.find(function(i) {
                                return i.serverID == row.server_rangeList[1]
                              })
                              ? _vm.serverLists.find(function(i) {
                                  return i.serverID == row.server_rangeList[1]
                                }).serverName
                              : row.server_rangeList[1]
                            : "全部"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "server_name",
              label: "开始渠道",
              "min-width": "120",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.channelList[0] || "全部") + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "server_name",
              label: "结束渠道",
              "min-width": "120",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.channelList[1] || "全部") + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "label", label: "标识", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tagggList.find(function(i) {
                              return i.value == row.label
                            })
                              ? _vm.tagggList.find(function(i) {
                                  return i.value == row.label
                                }).label
                              : row.label
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "priority", label: "优先级", "min-width": "120" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "force_push",
              label: "是否强提醒",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.force_push == 1 ? "是" : "否") +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_del_sign",
              label: "是否已覆盖",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.is_del_sign == 0
                            ? "/"
                            : row.is_del_sign == 1
                            ? "未覆盖"
                            : "已覆盖"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "show_switch",
              label: "切换按钮",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.pause == 1
                            ? row.show_switch
                              ? "显示"
                              : "隐藏"
                            : "/"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "start_time",
              label: "开始时间",
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.start_time != -1
                            ? _vm.$times.timestampToTime(
                                scope.row.start_time,
                                "YY-MM-DD H"
                              )
                            : "无限制"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "end_time", label: "结束时间", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.end_time != -1
                            ? _vm.$times.timestampToTime(
                                scope.row.end_time,
                                "YY-MM-DD H"
                              )
                            : "无限制"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "end_time", label: "时间", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.pause == 0 ? _c("div", [_vm._v(" / ")]) : _vm._e(),
                    row.pause == 1
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$times.timestampToTime(
                                  row.pause_start_time,
                                  "YY-MM-DD HH"
                                ) +
                                  " - 持续 " +
                                  (row.pause_duration || 0) +
                                  " 分钟"
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    row.pause == 2
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                "" +
                                  _vm.$times.timestampToTime(
                                    row.pause_start_time,
                                    "YY-MM-DD HH"
                                  )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "end_time", label: "内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "viewText",
                        on: {
                          click: function($event) {
                            return _vm.$refs.richTextDetails.open(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "创建时间", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.timestampToTime(
                            row.created_at,
                            "YY-MM-DD HH"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.type == "old"
                      ? _c("div", { staticClass: "x-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.$refs.addCode.open(row)
                                }
                              }
                            },
                            [_vm._v("修改")]
                          ),
                          _c("div", { staticClass: "controlsBtn-vertical" }),
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete(row.id, row.type)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ])
                      : _vm._e(),
                    row.type == "new" && row.status == 2
                      ? _c("div", { staticClass: "x-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.sendAnnounced(row.id)
                                }
                              }
                            },
                            [_vm._v("发布")]
                          ),
                          _c("div", { staticClass: "controlsBtn-vertical" }),
                          _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.$refs.addCode.open(row)
                                }
                              }
                            },
                            [_vm._v("修改")]
                          ),
                          _c("div", { staticClass: "controlsBtn-vertical" })
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "controlsBtn",
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row.id, row.type)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.currentPage,
          total: _vm.tableCopyTableList.length,
          "page-size": _vm.pageSize
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      }),
      _c("addCode", {
        ref: "addCode",
        attrs: {
          prod: { is_prod: _vm.paramsData.is_prod },
          serverList: _vm.serverLists
        },
        on: { update: _vm.getList }
      }),
      _c("richTextDetails", { ref: "richTextDetails" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }