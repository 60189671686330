import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { swallowPrimitive } from "@/api/dataAll/new";
export default {
  name: "swallowPrimitive",
  mixins: [serchSelect],
  intReq: ['getCountrys'],
  data: function data() {
    var _this = this;

    return {
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          if (_this.searchData.activation != "") {
            return time.getTime() > Date.now() || time.getTime() > _this.searchData.activation;
          } else {
            return time.getTime() > Date.now();
          }
        }
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < _this.searchData.operation || time.getTime() > Date.now();
        }
      },
      value1: [],
      value2: [],
      intReq: ['getCountrys'],
      searchData: {
        operation_keyword: "",
        expand_keyword: "",
        state: ''
      },
      statusArr: [{
        value: "1",
        label: "失败"
      }, {
        value: "2",
        label: "成功"
      }],
      num: "",
      show: false,
      tableData: [],
      totalshow: false,
      per_page: 10,
      total: 0,
      page: 1,
      adPlacementSingleChoice: true
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this2 = this;

      if (this.value2.length > 0) {
        this.searchData.operation_time = this.value2[0];
        this.searchData.operation_endtime = this.value2[1];
      }

      if (this.value1 && Array.isArray(this.value1) && this.value1.length > 0) {
        this.searchData.activation_time = this.value1[0];
        this.searchData.activation_endtime = this.value1[1];
      } else {
        this.searchData.activation_time = "";
        this.searchData.activation_endtime = "";
      }

      var searchData = JSON.parse(JSON.stringify(this.searchData));
      searchData.ad_placement = searchData.ad_placement == 'valuets' ? [''] : searchData.ad_placement;
      swallowPrimitive(searchData).then(function (res) {
        if (res.status == 200) {
          _this2.tableData = res.data.data || [];

          _this2.tableData.map(function (item) {
            item.timestamp = _this2.$times.timestampToTime(item.timestamp);
          });

          _this2.total = res.data.total || 0;
          _this2.page = res.data.current_page;
          _this2.per_page = res.data.per_page;
          _this2.totalshow = true;
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.page = val;
      this.searchClick();
    },
    exportdata: function exportdata() {
      var data = this.tableData;
      var columns = [{
        label: 'ID',
        prop: 'id'
      }, {
        label: '游戏',
        prop: 'game'
      }, {
        label: '平台',
        prop: 'platform'
      }, {
        label: '渠道',
        prop: 'channel'
      }, {
        label: '包名',
        prop: 'pkg'
      }, {
        label: '操作',
        prop: 'action'
      }, {
        label: '日期',
        prop: 'timestamp'
      }];
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};