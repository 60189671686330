var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id ? "修改邮件" : "添加邮件",
            visible: _vm.dialogShow,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  key: "type",
                  staticClass: "lodingItem",
                  attrs: { prop: "type", label: "发送类型" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        disabled: _vm.isshot,
                        placeholder: "请选择发送类型"
                      },
                      on: {
                        change: function($event) {
                          return _vm.clearData(false, false)
                        }
                      },
                      model: {
                        value: _vm.paramsData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "type", $$v)
                        },
                        expression: "paramsData.type"
                      }
                    },
                    _vm._l(_vm.whetherNot, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.paramsData.type == "list_role"
                ? _c(
                    "el-form-item",
                    {
                      key: "role_ids",
                      staticClass: "lodingItem",
                      attrs: { prop: "role_ids", label: "角色ID" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: {
                          disabled: _vm.isshot,
                          placeholder: "角色ID(1,2,3)"
                        },
                        model: {
                          value: _vm.paramsData.role_ids,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "role_ids", $$v)
                          },
                          expression: "paramsData.role_ids"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  key: "server_ids",
                  staticClass: "lodingItem",
                  attrs: { prop: "server_ids", label: "服务器(可多选)" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        disabled: _vm.isshot,
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择"
                      },
                      on: {
                        change: _vm.changeSelect,
                        "remove-tag": _vm.removeTag
                      },
                      model: {
                        value: _vm.paramsData.server_ids,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "server_ids", $$v)
                        },
                        expression: "paramsData.server_ids"
                      }
                    },
                    [
                      _vm.serverList.length
                        ? _c("el-option", {
                            attrs: { label: "全选", value: "全选" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.selectAll($event)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._l(_vm.serverList, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.serverID,
                            attrs: {
                              label: item.serverName,
                              value: item.serverID
                            }
                          },
                          [
                            _c("div", { staticClass: "serverOption" }, [
                              _c("div", [_vm._v(_vm._s(item.serverName))]),
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(item.is_recomm == 1 ? "推荐" : "")
                                )
                              ])
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "is_delay",
                  staticClass: "lodingItem",
                  attrs: { prop: "is_delay", label: "定时发送" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { placeholder: "请选择是否定时发送" },
                      model: {
                        value: _vm.paramsData.is_delay,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "is_delay", $$v)
                        },
                        expression: "paramsData.is_delay"
                      }
                    },
                    _vm._l(_vm.delayList, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.paramsData.is_delay == 1
                ? _c(
                    "el-form-item",
                    {
                      key: "delay_time",
                      staticClass: "lodingItem",
                      attrs: { prop: "delay_time", label: "定时时间" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "请选择",
                          align: "right"
                        },
                        model: {
                          value: _vm.paramsData.delay_time,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "delay_time", _vm._n($$v))
                          },
                          expression: "paramsData.delay_time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    { staticClass: "lodingItem", attrs: { label: "等级" } },
                    [
                      _c(
                        "el-form-item",
                        { key: "maxlevel" },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number leftItem",
                            attrs: { type: "number", placeholder: "等级上限" },
                            model: {
                              value: _vm.paramsData.level.maxlevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.level,
                                  "maxlevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.level.maxlevel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { key: "minlevel" },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number ",
                            attrs: { type: "number", placeholder: "等级下限" },
                            model: {
                              value: _vm.paramsData.level.minlevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.level,
                                  "minlevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.level.minlevel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    {
                      key: "sdk_type",
                      staticClass: "lodingItem",
                      attrs: { label: "sdk_type" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "flex-1 no-number",
                        attrs: { type: "number", placeholder: "0 - 65535" },
                        model: {
                          value: _vm.paramsData.sdk_type,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "sdk_type", _vm._n($$v))
                          },
                          expression: "paramsData.sdk_type"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    { staticClass: "lodingItem", attrs: { label: "VIP等级" } },
                    [
                      _c(
                        "el-form-item",
                        { key: "maxviplevel" },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number leftItem",
                            attrs: { type: "number", placeholder: "等级上限" },
                            model: {
                              value: _vm.paramsData.vip_level.maxviplevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.vip_level,
                                  "maxviplevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.vip_level.maxviplevel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { key: "minviplevel" },
                        [
                          _c("el-input", {
                            staticClass: "flex-1 no-number",
                            attrs: { type: "number", placeholder: "等级下限" },
                            model: {
                              value: _vm.paramsData.vip_level.minviplevel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paramsData.vip_level,
                                  "minviplevel",
                                  _vm._n($$v)
                                )
                              },
                              expression: "paramsData.vip_level.minviplevel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    {
                      key: "maxlogouttime",
                      staticClass: "lodingItem",
                      attrs: { label: "时间上限" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "时间上限",
                          align: "right"
                        },
                        model: {
                          value: _vm.paramsData.logout_time.maxlogouttime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.paramsData.logout_time,
                              "maxlogouttime",
                              _vm._n($$v)
                            )
                          },
                          expression: "paramsData.logout_time.maxlogouttime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "limit"
                ? _c(
                    "el-form-item",
                    {
                      key: "minlogouttime",
                      staticClass: "lodingItem",
                      attrs: { label: "时间下限" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "时间下限",
                          align: "right"
                        },
                        model: {
                          value: _vm.paramsData.logout_time.minlogouttime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.paramsData.logout_time,
                              "minlogouttime",
                              _vm._n($$v)
                            )
                          },
                          expression: "paramsData.logout_time.minlogouttime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addDomain }
                    },
                    [_vm._v("添加物品")]
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.reward, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index + "r",
                    staticClass: "lodingItemT",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-switch", {
                          staticStyle: {
                            "margin-right": "10px",
                            "min-width": "120px"
                          },
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                            "active-text": "永久",
                            "inactive-text": "有效"
                          },
                          model: {
                            value: domain.ispermanent,
                            callback: function($$v) {
                              _vm.$set(domain, "ispermanent", $$v)
                            },
                            expression: "domain.ispermanent"
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "reward." + index + ".type_id",
                              rules: _vm.rules.item_id
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { filterable: "", placeholder: "物品" },
                                model: {
                                  value: domain.type_id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "type_id", $$v)
                                  },
                                  expression: "domain.type_id"
                                }
                              },
                              _vm._l(_vm.articleList, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: { label: items.kind, value: items.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "reward." + index + ".expire",
                              rules: domain.ispermanent
                                ? null
                                : _vm.rules.expire_time
                            }
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: {
                                width: "200px",
                                "margin-right": "10px"
                              },
                              attrs: {
                                "popper-class": "no-atTheMoment",
                                disabled: domain.ispermanent,
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "日期"
                              },
                              model: {
                                value: domain.expire,
                                callback: function($$v) {
                                  _vm.$set(domain, "expire", $$v)
                                },
                                expression: "domain.expire"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "reward." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", _vm._n($$v))
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        ),
                        _c("el-button", {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain(domain)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              _c(
                "div",
                { staticClass: "flex", staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem ",
                      staticStyle: { width: "13%" },
                      attrs: { prop: "content_Val" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.paramsData.template_type == 1
                          },
                          on: { click: _vm.addContent }
                        },
                        [_vm._v("添加内容 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem ",
                      staticStyle: { width: "40%", "margin-right": "40px" },
                      attrs: { label: "是否为模板", "label-width": "100px" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-1",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.isTemplateChange },
                          model: {
                            value: _vm.isTemplate,
                            callback: function($$v) {
                              _vm.isTemplate = $$v
                            },
                            expression: "isTemplate"
                          }
                        },
                        _vm._l(_vm.moduleList, function(item) {
                          return _c("el-option", {
                            key: item.label,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isTemplate == 1
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "lodingItem ",
                          staticStyle: { width: "40%" },
                          attrs: { label: "模板类型", "label-width": "100px" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "flex-1",
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.templateTypeChange },
                              model: {
                                value: _vm.paramsData.template_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.paramsData, "template_type", $$v)
                                },
                                expression: "paramsData.template_type"
                              }
                            },
                            _vm._l(_vm.templateList, function(item) {
                              return _c("el-option", {
                                key: item.label,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.paramsData.content_Val, function(domain, index) {
                return _c(
                  "div",
                  { key: index + "k", staticClass: "content_ValClass" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "lodingItem-operationFrame flex",
                        staticStyle: { "margin-bottom": "20px" }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem contentValitem",
                            attrs: {
                              prop: "content_Val." + index + ".title",
                              rules: _vm.rules.title,
                              label: "标题",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "operationFrame-entity",
                              attrs: {
                                disabled: _vm.paramsData.template_type != 0,
                                clearable: "",
                                placeholder: "请输入标题"
                              },
                              model: {
                                value: domain.title,
                                callback: function($$v) {
                                  _vm.$set(domain, "title", $$v)
                                },
                                expression: "domain.title "
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem contentValitem",
                            attrs: {
                              prop: "content_Val." + index + ".language",
                              rules: _vm.rules.language,
                              label: "语言",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "flex-1",
                                attrs: {
                                  disabled: _vm.paramsData.template_type != 0,
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: domain.language,
                                  callback: function($$v) {
                                    _vm.$set(domain, "language", $$v)
                                  },
                                  expression: "domain.language "
                                }
                              },
                              _vm._l(_vm.langList, function(item) {
                                return _c("el-option", {
                                  key: item.label,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.paramsData.template_type != 1
                          ? _c("el-button", {
                              staticStyle: { margin: "0 0 16px 10px" },
                              attrs: {
                                size: "mini",
                                type: "danger",
                                circle: "",
                                icon: "el-icon-delete"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeContent(domain)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex first_box lodingItem-title",
                        staticStyle: {
                          "margin-bottom": "20px",
                          width: "100%",
                          "align-items": "center"
                        }
                      },
                      [
                        _vm._v("邮件内容: "),
                        _vm.paramsData.template_type == 0
                          ? _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { "padding-left": "20px" }
                              },
                              _vm._l(_vm.tagList, function(items, indexs) {
                                return _c(
                                  "div",
                                  {
                                    key: indexs,
                                    staticClass: "addtag",
                                    on: {
                                      click: function($event) {
                                        return _vm.addtag(items.content, index)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          prop: "content_Val." + index + ".content",
                          rules: _vm.rules.content
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 2, maxRows: 12 },
                            disabled: _vm.paramsData.template_type != 0,
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: domain.content,
                            callback: function($$v) {
                              _vm.$set(domain, "content", $$v)
                            },
                            expression: "domain.content "
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("保存草稿")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }