import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.is-integer";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { hostdata, hostList } from "@/api/liveData/liveData";
export default {
  name: "liveData",
  data: function data() {
    return {
      oneTabList: [{
        label: "日期",
        prop: 'time',
        top: ""
      }, {
        label: "组件点击量",
        prop: 'a',
        top: ""
      }, {
        label: "落地页点击量",
        prop: 'b',
        top: ""
      }, {
        label: "点击人数",
        prop: 'c',
        top: ""
      }, {
        label: "小风车新增注册",
        prop: 'fengche_register_num',
        top: ""
      }, {
        label: "小风车下单价格",
        prop: 'd',
        top: ""
      }, {
        label: "小风车回款额",
        prop: 'e',
        top: ""
      }, {
        label: "付费总额",
        prop: 'total_recharge_amount',
        top: ""
      }, {
        label: "付费人数",
        prop: 'total_recharge_num',
        top: ""
      }, {
        label: "总新增注册",
        prop: 'total_register_num',
        top: ""
      }, {
        label: "小风车首日ROI",
        prop: 'f',
        top: ""
      }, {
        label: "新增玩家付费金额",
        prop: 'fengche_register_recharge_amount',
        top: "小风车",
        colspan: "4"
      }, {
        label: "新增玩家付费人数",
        prop: 'fengche_register_recharge_num',
        top: "小风车",
        isShow: true
      }, {
        label: "老玩家付费金额",
        prop: 'fengche_old_recharge_amount',
        top: "小风车",
        isShow: true
      }, {
        label: "老玩家付费人数",
        prop: 'fengche_old_recharge_num',
        top: "小风车",
        isShow: true
      }, {
        label: "新增玩家付费金额",
        prop: 'zhuli_register_recharge_amount',
        top: "小助理链接",
        colspan: "4"
      }, {
        label: "新增玩家付费人数",
        prop: 'zhuli_register_recharge_num',
        top: "小助理链接",
        isShow: true
      }, {
        label: "老玩家付费金额",
        prop: 'zhuli_old_recharge_amount',
        top: "小助理链接",
        isShow: true
      }, {
        label: "老玩家付费人数",
        prop: 'zhuli_old_recharge_num',
        top: "小助理链接",
        isShow: true
      }, {
        label: "新增",
        prop: 'android_register_num',
        top: "安卓",
        colspan: "2"
      }, {
        label: "流水",
        prop: 'android_recharge_amount',
        top: "安卓",
        isShow: true
      }, {
        label: "新增",
        prop: 'ios_register_num',
        top: "IOS",
        colspan: "2"
      }, {
        label: "流水",
        prop: 'ios_recharge_amount',
        top: "IOS",
        isShow: true
      }],
      toTabList: [{
        label: "日期",
        prop: 'time',
        rowspan: 3,
        top: "",
        isShowtow: true
      }, {
        label: "汇总收入",
        prop: 'aggregate_income',
        colspan: 2,
        rowspan: 2,
        top: "",
        isShowtow: true
      }, {
        label: "汇总新增",
        prop: 'aggregate_addition',
        top: "",
        isShow: true,
        isShowtow: true
      }, {
        label: "新增",
        prop: 'bahuang_register_num',
        top: "八荒",
        colspan: 6,
        towCol: 3,
        platform: '安卓'
      }, {
        label: "DAU",
        prop: 'bahuang_activity_num',
        top: "八荒",
        platform: '安卓',
        isShow: true,
        isShowtow: true
      }, {
        label: "收入数据",
        prop: 'bahuang_recharge_amount',
        top: "八荒",
        platform: '安卓',
        isShow: true,
        isShowtow: true
      }, {
        label: "新增",
        prop: 'fengche_register_num',
        top: "绝世仙王",
        platform: '安卓',
        isShow: true,
        towCol: 3
      }, {
        label: "DAU",
        prop: 'fengche_activity_num',
        top: "绝世仙王",
        platform: '安卓',
        isShow: true,
        isShowtow: true
      }, {
        label: "收入数据",
        prop: 'fengche_recharge_amount',
        top: "绝世仙王",
        platform: '安卓',
        isShow: true,
        isShowtow: true
      }, {
        label: "新增",
        prop: 'bhios_register_num',
        top: "八荒",
        platform: 'IOS',
        colspan: 3,
        towCol: 3
      }, {
        label: "DAU",
        prop: 'bhios_activity_num',
        top: "八荒",
        platform: 'IOS',
        isShow: true,
        isShowtow: true
      }, {
        label: "收入数据",
        prop: 'bhios_recharge_amount',
        top: "八荒",
        platform: 'IOS',
        isShow: true,
        isShowtow: true
      }],
      toTabKey: [{
        label: "汇总收入"
      }, {
        label: "汇总新增"
      }, {
        label: "新增"
      }, {
        label: "DAU"
      }, {
        label: "收入数据"
      }, {
        label: "新增"
      }, {
        label: "DAU"
      }, {
        label: "收入数据"
      }, {
        label: "新增"
      }, {
        label: "DAU"
      }, {
        label: "收入数据"
      }],
      tabType: [{
        name: '小风车数据记录',
        value: 0
      }, {
        name: '绝世仙王(汇总)',
        value: 1
      }],
      tabTypeActive: 1,
      list: [],
      total: 0,
      pagrSize: 10,
      currentPage2: 5,
      gameList: [],
      hostList: [],
      params: {
        start_time: 0,
        end_time: 0,
        hosts: ''
      },
      data: [],
      letterTable: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    };
  },
  created: function created() {
    this.getHostList(); // this.getList()
  },
  methods: {
    tabTypeChange: function tabTypeChange(e) {},
    hostChange: function hostChange(e) {},
    getHostList: function getHostList() {
      var _this = this;

      hostList().then(function (res) {
        if (res.status == 200) {
          _this.hostList = res.data;
        }
      });
    },
    dataChange: function dataChange(e) {
      if (e.length) {
        this.params.start_time = e[0] / 1000;
        this.params.end_time = e[1] / 1000;
      }
    },
    gamechange: function gamechange(e) {},
    getList: function getList() {
      var _this2 = this;

      if (!this.params.hosts.length) {
        this.$message({
          type: "error",
          message: "请选择主播!"
        });
        return;
      }

      if (!this.params.start_time) {
        this.$message({
          type: "error",
          message: "请选择日期!"
        });
        return;
      }

      var data = _objectSpread({}, this.params);

      data.hosts = data.hosts.toString();
      hostdata(data).then(function (res) {
        if (res.status == 200) {
          var list = [];
          var num = 0;

          for (var key in res.data.hostData) {
            num++;
            list.push({
              title: key,
              bgColor: num % 2 - 1 == 0 ? '#E1EAFF' : '#FAF1D1',
              list: _this2.newArray(res.data.hostData[key], res.data.monthData[key])
            });
          }

          _this2.list = list;
        }
      });
    },
    newArray: function newArray(hostData, monthData) {
      var data = {
        a: '/',
        b: '/',
        c: '/',
        d: '/',
        e: '/',
        f: '/',
        "total_recharge_amount": '/',
        "total_recharge_num": '/',
        "total_register_num": '/',
        "fengche_register_num": 0,
        "fengche_register_recharge_amount": '/',
        "fengche_register_recharge_num": '/',
        "fengche_old_recharge_amount": '/',
        "fengche_old_recharge_num": '/',
        "fengche_activity_num": '/',
        "fengche_recharge_user": '/',
        "fengche_recharge_amount": 0,
        "zhuli_register_recharge_amount": '/',
        "zhuli_register_recharge_num": '/',
        "zhuli_old_recharge_amount": '/',
        "zhuli_old_recharge_num": '/',
        "zhuli_activity_num": '/',
        "zhuli_recharge_user": '/',
        "zhuli_recharge_amount": '/',
        "android_register_num": '/',
        "android_recharge_amount": '/',
        "ios_register_num": '/',
        "ios_recharge_amount": '/',
        "bahuang_register_num": 0,
        "bahuang_recharge_amount": 0,
        "bahuang_recharge_num": '/',
        "bahuang_activity_num": '/',
        "bhios_register_num": 0,
        "bhios_recharge_amount": 0,
        "bhios_recharge_num": '/',
        "bhios_activity_num": '/'
      };
      var list = [];

      for (var monthKey in monthData) {
        list.push(_objectSpread(_objectSpread({
          time: monthKey
        }, data), monthData[monthKey]));
      }

      for (var hostKey in hostData) {
        list.push(_objectSpread(_objectSpread({
          time: hostKey
        }, data), hostData[hostKey]));
      }

      list.map(function (item) {
        item.aggregate_income = item["bahuang_recharge_amount"] + item["fengche_recharge_amount"] + item["bhios_recharge_amount"], item.aggregate_addition = item["bahuang_register_num"] + item["fengche_register_num"] + item["bhios_register_num"];
      });
      return list;
    },
    // 导出
    exportdata: function exportdata() {
      if (!this.list.length) {
        this.$message({
          type: "success",
          message: "没有数据!"
        });
        return;
      }

      if (this.tabTypeActive == 0) {
        this.fengcheDerive();
      } else if (this.tabTypeActive == 1) {
        this.unearthly();
      }
    },
    // 绝世先王汇总
    unearthly: function unearthly() {
      var _this3 = this;

      var list = _toConsumableArray(this.list);

      var newList = [];
      var columns = [];
      var data = [];
      var merges = [];
      var d = {};
      list.map(function (item, index) {
        _this3.toTabList.map(function (items, indexs) {
          var mapData = {};
          mapData.prop = items.prop + index;
          mapData.label = item.title;
          d[items.prop + index] = items.label;
          columns.push(mapData);
        });

        merges.push.apply(merges, _toConsumableArray(_this3.MergeFirstRow(index, 12)));
        merges.push.apply(merges, _toConsumableArray(_this3.columnMerge(index, {
          generalColumn: 12,
          fewLines: [2, 4],
          cyclicBit: [0]
        })));
        merges.push.apply(merges, _toConsumableArray(_this3.pinwheelMerge(index, {
          generalColumn: 12,
          column: 4,
          line: 2,
          mergeNumber: 6
        })));
        merges.push.apply(merges, _toConsumableArray(_this3.pinwheelMerge(index, {
          generalColumn: 12,
          column: 10,
          line: 2,
          mergeNumber: 3
        })));
        merges.push.apply(merges, _toConsumableArray(_this3.pinwheelMerge(index, {
          generalColumn: 12,
          column: 4,
          line: 3,
          mergeNumber: 3
        })));
        merges.push.apply(merges, _toConsumableArray(_this3.pinwheelMerge(index, {
          generalColumn: 12,
          column: 7,
          line: 3,
          mergeNumber: 3
        })));
        merges.push.apply(merges, _toConsumableArray(_this3.pinwheelMerge(index, {
          generalColumn: 12,
          column: 10,
          line: 3,
          mergeNumber: 3
        })));
        merges.push.apply(merges, _toConsumableArray(_this3.combinedLarge(index, {
          generalColumn: 12,
          colToClo: [2, 3],
          linesToLines: [2, 3]
        })));
        newList[index] = [];
        item.list.map(function (s) {
          var newDatga = {};

          for (var k in s) {
            newDatga[k + index] = s[k];
          }

          newList[index].push(newDatga);
        });
      });
      data.push(_objectSpread({}, d));
      data.push(_objectSpread({}, d));
      data.push(_objectSpread({}, d));
      newList[0].map(function (item, index) {
        var nd = {};
        newList.map(function (items) {
          nd = _objectSpread(_objectSpread({}, nd), items[index]);
        });
        data.push(nd);
      });

      for (var k in data[0]) {
        if (k.indexOf("bahuang_register_num") != -1) {
          data[0][k] = "安卓";
        }

        if (k.indexOf("bhios_register_num") != -1) {
          data[0][k] = "IOS";
        }

        if (k.indexOf("bahuang_register_num") != -1) {
          data[1][k] = "八荒";
        }

        if (k.indexOf("fengche_register_num") != -1) {
          data[1][k] = "绝世仙王";
        }

        if (k.indexOf("bhios_register_num") != -1) {
          data[1][k] = "八荒";
        }
      }

      this.$export.excel({
        title: "绝世仙王(汇总)",
        columns: columns,
        data: data,
        merges: merges
      });
    },
    // 小风车导出
    fengcheDerive: function fengcheDerive() {
      var _this4 = this;

      var list = _toConsumableArray(this.list);

      var newList = [];
      var columns = [];
      var data = [];
      var merges = [];
      var d = {};
      list.map(function (item, index) {
        _this4.oneTabList.map(function (items, indexs) {
          var mapData = {};
          mapData.prop = items.prop + index;
          mapData.label = item.title;
          d[items.prop + index] = items.label;
          columns.push(mapData);
        });

        merges.push.apply(merges, _toConsumableArray(_this4.MergeFirstRow(index, 23)));
        merges.push.apply(merges, _toConsumableArray(_this4.columnMerge(index, {
          generalColumn: 23,
          fewLines: [2, 3],
          cyclicBit: [0, 11]
        })));
        merges.push.apply(merges, _toConsumableArray(_this4.pinwheelMerge(index, {
          generalColumn: 23,
          column: 12,
          line: 2,
          mergeNumber: 4
        })));
        merges.push.apply(merges, _toConsumableArray(_this4.pinwheelMerge(index, {
          generalColumn: 23,
          column: 16,
          line: 2,
          mergeNumber: 4
        })));
        merges.push.apply(merges, _toConsumableArray(_this4.platformMerge(index)));
        newList[index] = [];
        item.list.map(function (s) {
          var newDatga = {};

          for (var k in s) {
            newDatga[k + index] = s[k];
          }

          newList[index].push(newDatga);
        });
      });
      data.push(_objectSpread({}, d));
      data.push(_objectSpread({}, d));
      newList[0].map(function (item, index) {
        var nd = {};
        newList.map(function (items) {
          nd = _objectSpread(_objectSpread({}, nd), items[index]);
        });
        data.push(nd);
      });

      for (var k in data[0]) {
        if (k.indexOf("fengche_register_recharge_amount") != -1) {
          data[0][k] = "小风车";
        }

        if (k.indexOf("zhuli_register_recharge_amount") != -1) {
          data[0][k] = "小助理链接";
        }

        if (k.indexOf("android_register_num") != -1) {
          data[0][k] = "安卓";
        }

        if (k.indexOf("ios_register_num") != -1) {
          data[0][k] = "IOS";
        }
      }

      this.$export.excel({
        title: "小风车数据记录",
        columns: columns,
        data: data,
        merges: merges
      });
    },
    // 顶部标题合并
    MergeFirstRow: function MergeFirstRow(index, generalColumn) {
      var list = []; // 第一位数

      var num = index > 0 ? index * generalColumn + 1 : 0; //第二位数

      var towNum = (index + 1) * generalColumn;
      list.push([this.character(num) + '1', this.character(towNum) + '1']); // 当前位数下标

      return list;
    },
    // 竖列合并
    columnMerge: function columnMerge(index, data) {
      // data generalColumn 总列, fewLines 几行与几行合并 ,  cyclicBit 循环位 ,
      var generalColumn = data.generalColumn,
          fewLines = data.fewLines,
          cyclicBit = data.cyclicBit;
      var list = [];
      var newNum = (index > 0 ? index * generalColumn + 1 : 0) + cyclicBit[0];

      if (cyclicBit.length > 1) {
        for (var i = 0; i < cyclicBit[1]; i++) {
          list.push([this.character(newNum + i < generalColumn ? newNum + i + 1 : newNum + i) + fewLines[0], this.character(newNum + i < generalColumn ? newNum + i + 1 : newNum + i) + fewLines[1], newNum + i]);
        }
      } else {
        list.push([this.character(newNum + cyclicBit[0] < generalColumn ? newNum + cyclicBit[0] + 1 : newNum + cyclicBit[0]) + fewLines[0], this.character(newNum + cyclicBit[0] < generalColumn ? newNum + cyclicBit[0] + 1 : newNum + cyclicBit[0]) + fewLines[1], newNum + cyclicBit[0]]);
      }

      return list;
    },
    // 横列合并
    pinwheelMerge: function pinwheelMerge(index, data) {
      // data generalColumn 总列, column 第几列, line 第几行 ，mergeNumber 合并数
      var generalColumn = data.generalColumn,
          column = data.column,
          line = data.line,
          mergeNumber = data.mergeNumber;
      var list = []; // 第一位数

      var num = index > 0 ? index * generalColumn + column : column; //第二位数

      var towNum = index > 0 ? index * generalColumn + (column + mergeNumber - 1) : column + mergeNumber - 1;
      list.push([this.character(num) + String(line), this.character(towNum) + String(line)]);
      return list;
    },
    // 横竖合并单元
    combinedLarge: function combinedLarge(index, data) {
      // data generalColumn 总列, colToClo 几列到几列， linesToLines 几行到几行
      var generalColumn = data.generalColumn,
          colToClo = data.colToClo,
          linesToLines = data.linesToLines;
      var list = [];
      var num = index > 0 ? index * generalColumn + colToClo[0] : colToClo[0];
      var towNum = index > 0 ? index * generalColumn + colToClo[1] : colToClo[1];
      list.push([this.character(num) + String(linesToLines[0]), this.character(towNum) + String(linesToLines[1])]);
      return list;
    },
    platformMerge: function platformMerge(index, type) {
      var list = []; // 第一位数

      var num = index > 0 ? index * 23 + 20 : 20; //第二位数

      var towNum = index > 0 ? index * 23 + 21 : 21;
      list.push([this.character(num) + '2', this.character(towNum) + '2']);
      list.push([this.character(num + 2) + '2', this.character(towNum + 2) + '2']);
      return list;
    },
    character: function character(newNum) {
      var letterTable = this.letterTable;
      var multiple = newNum / 26;
      var k = parseInt(multiple);
      var letter = multiple > 1 ? Number.isInteger(multiple) ? letterTable[k > 0 ? k - 2 : 0] : letterTable[k - 1] : '';
      var tIndex = 0;

      if (multiple > 1) {
        tIndex = newNum % 26 ? newNum % 26 - 1 : newNum % 26 + 25;
      } else {
        tIndex = newNum - 1 < 0 ? 0 : newNum - 1;
      }

      var t = letter + letterTable[tIndex];
      return t;
    }
  }
};