// 创建参数管理
import request from '@/utils/request';
export function addPamseMange(data) {
  return request({
    url: '/datum/add_advertising_monitor_url',
    method: 'post',
    params: data
  });
} // 获取列表

export function getPamseList(data) {
  return request({
    url: '/datum/advertising_monitor_url',
    method: 'get',
    params: data
  });
} // 删除参数管理列表

export function delePamseMange(data) {
  return request({
    url: '/datum/del_advertising_monitor_url',
    method: 'get',
    params: data
  });
} // 参数反显

export function returnPamseMange(data) {
  return request({
    url: '/datum/up_advertising_monitor_url',
    method: 'get',
    params: data
  });
} // 更新参数管理

export function updataPamseMange(data) {
  return request({
    url: '/datum/up_advertising_monitor_url',
    method: 'post',
    params: data
  });
}