var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uploadImage" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: { hide: _vm.showUpload },
          attrs: {
            limit: _vm.limit,
            multiple: _vm.limit > 1 ? true : false,
            action: _vm.action,
            name: _vm.filesname,
            accept: "image/*",
            "list-type": "picture-card",
            "on-exceed": _vm.handleExceed,
            headers: _vm.headers,
            "before-upload": _vm.beforeUpload,
            "on-success": _vm.upSuccess,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.remove,
            disabled: _vm.disabled,
            "file-list": _vm.fileList
          }
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _c(
        "el-dialog",
        {
          attrs: { "append-to-body": "", visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [_c("img", { attrs: { width: "100%", src: _vm.imageUrl, alt: "" } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }