// 广告位置
import request from '@/utils/request'; // 广告位置列表

export function advList(data) {
  return request({
    url: '/adv_place/list',
    method: 'get',
    params: data
  });
} // // 广告位置添加广告

export function advAdd(data) {
  return request({
    url: '/adv_place/add',
    method: 'post',
    params: data
  });
} // // 广告位置修改广告

export function upAdv(data) {
  return request({
    url: '/adv_place/update',
    method: 'post',
    params: data
  });
} // // 广告位置回填修改广告

export function upgetAdv(data) {
  return request({
    url: '/adv_place/update',
    method: 'get',
    params: data
  });
} // // 广告位置禁用广告

export function disableAdvPlace(data) {
  return request({
    url: '/adv_place/disableAdvPlace',
    method: 'get',
    params: data
  });
} // // 广告位置删除广告

export function delAdvPlace(data) {
  return request({
    url: '/adv_place/delAdvPlace',
    method: 'get',
    params: data
  });
} // 广告平台列表

export function advPlatform(data) {
  return request({
    url: '/adv_platform/list',
    method: 'get',
    params: data
  });
} // // 广告平台添加广告

export function addPlatform(data) {
  return request({
    url: '/adv_platform/add',
    method: 'post',
    params: data
  });
} // // 广告平台修改广告

export function upplatform(data) {
  return request({
    url: '/adv_platform/update',
    method: 'post',
    params: data
  });
} // // 广告平台回填修改广告

export function upplatforms(data) {
  return request({
    url: '/adv_platform/update',
    method: 'get',
    params: data
  });
} // // 广告平台禁用广告

export function disableAdvPlatform(data) {
  return request({
    url: '/adv_platform/disableAdvPlatform',
    method: 'get',
    params: data
  });
} // // 广告平台删除广告

export function delPlatform(data) {
  return request({
    url: '/adv_platform/delPlatform',
    method: 'get',
    params: data
  });
}