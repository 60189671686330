import { render, staticRenderFns } from "./viewArticles.vue?vue&type=template&id=561bad99&scoped=true&"
import script from "./viewArticles.vue?vue&type=script&lang=js&"
export * from "./viewArticles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561bad99",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\admin\\datacenter_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('561bad99')) {
      api.createRecord('561bad99', component.options)
    } else {
      api.reload('561bad99', component.options)
    }
    module.hot.accept("./viewArticles.vue?vue&type=template&id=561bad99&scoped=true&", function () {
      api.rerender('561bad99', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/gmToolNew/serverManagement/components/viewArticles.vue"
export default component.exports