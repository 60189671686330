var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.titleName,
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "megggg add-header" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.form, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类名称",
                        prop: "parameter",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "256px" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 12 },
                          placeholder: "请输入分类"
                        },
                        on: { change: _vm.textChange },
                        model: {
                          value: _vm.form.classify,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "classify", $$v)
                          },
                          expression: "form.classify"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }