import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { advShowData } from "@/api/dataAll/advtistShow";
export default {
  name: "advShowDetail",
  mixins: [serchSelect],
  data: function data() {
    return {
      value2: [],
      searchData: {
        keyword: "",
        status: ""
      },
      tableData: [],
      ad: [],
      guankaArr: [],
      gkTd: "",
      tableTime: [],
      statusArr: [{
        id: "1",
        name: "开始"
      }, {
        name: "成功",
        id: "2"
      }],
      show: false,
      more: true
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      this.guankaArr = [];

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      advShowData(this.searchData).then(function (res) {
        _this.tableTime = [];

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objToKey = function objToKey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        _this.tableData = objToTitle(res.data);

        _this.tableData.forEach(function (item) {
          var k = objToKey(item);

          _this.tableTime.push(k[0]);
        });

        var table_bodoy = "<tr><td>广告操作</td>";

        _this.tableData.forEach(function (val, index) {
          table_bodoy += "<td>人数</td></td><td>次数</td>";
        });

        table_bodoy += "</tr>";
        var table_top = "<th style='width:250px;'>日期</th>";

        var _iterator = _createForOfIteratorHelper(_this.tableTime),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var date = _step.value;
            table_top += "<th colspan='2' style='width:250px;line-height:40px;'>" + date + "</th>";
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        _this.tableData.forEach(function (item, i) {
          var key = _this.tableTime[i];
          var ad = objToKey(item[key].ad);
          _this.ad = [];
          ad.forEach(function (val, index) {
            if (_this.ad.indexOf(val) > -1) {} else {
              _this.ad.push(val);
            }
          });
        });

        if (_this.ad.length > 0) {
          _this.ad.forEach(function (item, i) {
            table_bodoy += "<tr>";
            table_bodoy += "<td style='width:100px;WORD-WRAP:break-word'>" + item + "</td>"; // 列第一格

            _this.tableData.forEach(function (val, index) {
              var a = _this.tableTime[index];
              var b = val[a];
              var c = objToTitle(b.ad);

              if (c[i] != undefined) {
                table_bodoy += "<td>" + c[i].pnum + "</td>";
                table_bodoy += "<td>" + c[i].times + "</td>";
              } else {
                table_bodoy += "<td>/</td>";
                table_bodoy += "<td>/</td>";
              }
            });

            table_bodoy += "</tr>";
          });
        }

        var header = document.getElementById("header");
        var bodoy = document.getElementById("bodoy");
        var num = document.getElementById("newNum");
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        _this.show = true;
      });
    },
    exportdata: function exportdata() {
      var objToTitle = function objToTitle(obj) {
        return Object.keys(obj).map(function (k) {
          return obj[k];
        });
      };

      var objToKey = function objToKey(obj) {
        return Object.keys(obj).map(function (k) {
          return k;
        });
      };

      var pageData = this.tableData;
      var columns = [{
        label: "任务ID",
        prop: "taskId"
      }];
      var data = [];
      var complete = {
        taskId: "广告操作"
      };
      var merges = [];
      pageData.map(function (item, index) {
        var k = objToKey(item)[0];
        columns.push.apply(columns, [{
          label: k,
          prop: index + "userid"
        }, {
          label: "",
          prop: index + "upMoney"
        }]);
        complete[index + "userid"] = "人数";
        complete[index + "upMoney"] = "次数";
        var IN = -1;

        for (var i in item[k].ad) {
          data.push({});
          IN += 1;
          var taskData = {
            taskId: i
          };
          taskData[index + "userid"] = item[k].ad[i].pnum;
          taskData[index + "upMoney"] = item[k].ad[i].times;
          data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
        }
      });

      for (var n = 0; n < parseInt((columns.length - 1) / 2); n++) {
        merges.push([this.headerZh(n, 0), this.headerZh(n, 1)]);
      }

      data.unshift(complete);
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title,
        merges: merges
      });
    },
    headerZh: function headerZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      var num = k > 0 ? letterTable[k - 1] : "";

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[(k <= 1 ? index * 2 : index) + 1];
        } else {
          num = num + letterTable[(k <= 1 ? index * 2 + 1 : index + 1) + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2 + 1];
        } else {
          num = num + letterTable[index % 13 * 2 + 1 + 1];
        }
      }

      return num + "1";
    }
  }
};