var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c("div", { staticClass: "y-f" }, [
              _c("div", { staticClass: "y-f-title" }, [_vm._v(" 问题: ")]),
              _c("div", [_vm._v(_vm._s(_vm.data.question))])
            ]),
            _c("div", { staticClass: "y-f" }, [
              _c("div", { staticClass: "y-f-title" }, [_vm._v(" 回复: ")]),
              _c("div", [_vm._v(_vm._s(_vm.data.answer))])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }