import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmToolNewCom } from "@/mixins/index";
import uploadImagetow from '@/components/uploadImagetow';
import { szgmdoSend } from '@/api/gmToolNew/gmToolNew';
export default {
  components: {
    uploadImagetow: uploadImagetow
  },
  mixins: [gmToolNewCom],
  data: function data() {
    var validateuids = function validateuids(rule, value, callback) {
      var regex = /[\u4e00-\u9fa5，]/;

      if (regex.test(value) || !value) {
        callback(new Error('角色ID不正确(以英文逗号隔开 1,2,3)'));
      } else {
        callback();
      }
    };

    return {
      paramsData: {
        uids: '',
        type: 'to_users',
        id: '',
        expire: '',
        start_time: '',
        end_time: ''
      },
      expires: [],
      dialogShow: false,
      emailType: [{
        label: '发送所有人',
        value: 'all'
      }, {
        label: '发送目标账号',
        value: 'to_users'
      }, {
        label: '补偿邮件',
        value: 'compensate'
      }],
      rules: {
        uids: [{
          required: true,
          validator: validateuids,
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择类型",
          trigger: "blur"
        }],
        expire: [{
          required: true,
          message: "请选择过期时间",
          trigger: "blur"
        }],
        expires: [{
          required: true,
          message: "请选择时间",
          trigger: "blur"
        }]
      },
      isPermissions: false
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      var isPermissions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.paramsData.id = data.id;
      var nowD = (new Date(this.$times.zeroTimeZone()).getTime() + 3600 * 1000 * 24 * 7) / 1000;
      this.paramsData.expire = this.$times.timestampToTime(nowD, 'YY-MM-DD HH:II:SS');
      this.paramsData.start_time = this.$times.timestampToTime(nowD, 'YY-MM-DD');
      this.paramsData.end_time = this.$times.timestampToTime(nowD, 'YY-MM-DD');
      this.expires = [this.$times.timestampToTime(nowD, 'YY-MM-DD'), this.$times.timestampToTime(nowD, 'YY-MM-DD')];
      this.dialogShow = true;

      if (isPermissions) {
        this.isPermissions = isPermissions;
        this.paramsData.type = this.emailType[1].value;
      }
    },
    searchTimeChange: function searchTimeChange(e) {
      this.paramsData.start_time = e[0];
      this.paramsData.end_time = e[1];
    },
    fileUp: function fileUp(e) {
      this.paramsData.uids = e.data.toString();
    },
    clearData: function clearData(isClearId) {
      this.paramsData = {
        uids: '',
        type: 'to_users',
        id: isClearId ? '' : this.paramsData.id,
        expire: this.$times.timestampToTime((new Date(this.$times.zeroTimeZone()).getTime() + 3600 * 1000 * 24 * 7) / 1000, 'YY-MM-DD HH:II:SS')
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this.paramsData));
          var uids = data.uids.split(',');
          uids = uids.map(function (i) {
            return i = parseInt(i);
          });
          console.log(uids);
          data.uids = data.uids ? JSON.stringify(uids) : '';
          szgmdoSend(_objectSpread(_objectSpread({}, data), _this.propsData)).then(function (res) {
            if (res.code == 200) {
              _this.$message({
                type: "success",
                message: '操作成功'
              });

              _this.dialogShow = false;

              _this.$emit('change');
            }
          });
        }
      });
    }
  }
};