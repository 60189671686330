var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "详情", visible: _vm.dialogShow },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popover" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.data, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "类型", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.itemType.findIndex(function(i) {
                                    return i.value == row.type
                                  }) > -1
                                    ? _vm.itemType.find(function(i) {
                                        return i.value == row.type
                                      }).label
                                    : row.type
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "名称", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.szgmItemList.findIndex(function(item) {
                                    return item.id == row.id
                                  }) > -1
                                    ? _vm.szgmItemList.find(function(item) {
                                        return item.id == row.id
                                      }).kind
                                    : row.id
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "num", label: "数量", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "quality", label: "品质", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(row.quality ? row.quality : "/") +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }