var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCountry: false, isDate: false } },
            on: { searchChange: _vm.searchChange }
          }),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addCdtconversion.init()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "game_reveal",
                  label: "游戏",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform_reveal",
                  label: "平台",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channel_reveal",
                  label: "渠道",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pkg_reveal",
                  label: "包名",
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_channel_reveal",
                  label: "广告渠道",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ad_placement_reveal",
                  label: "广告位置",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "serial_number",
                  label: "序号",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "serial_name",
                  label: "对应名称",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "160",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "x-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleSource(scope.row)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _c("div", { staticClass: "controlsBtn-vertical" }),
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1331420832
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c("el-pagination", {
            attrs: {
              layout: "total, prev, pager, next",
              "current-page": _vm.searchData.page,
              total: _vm.total,
              "page-size": 12
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        : _vm._e(),
      _c("addload-name", {
        ref: "addCdtconversion",
        on: { refreshDataList: _vm.searchClick }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }