import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Message } from "element-ui";
import { searchgame, searchplatform, searchchannel, searchpkg } from "@/api/dataAll/dataAll";
import { SelectType, adarelationPayType } from "@/api/aymentsdk/aymentsdk";
import { addLginConfig } from "@/api/loginConfig/addLogin";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    num: {
      type: String,
      default: "1"
    }
  },
  data: function data() {
    return {
      titleName: "",
      id: "",
      searchData: {
        game: "",
        platform: "",
        channel: "",
        pkg: ""
      },
      searchgames: [],
      searchplatforms: [],
      searchchannels: [],
      searchpkgs: [],
      advchannels: [],
      pay_types: [],
      gameId: "",
      searchMark: {}
    };
  },
  created: function created() {
    var _this = this;

    if (this.num == 1) {
      this.searchData = {};
      this.titleName = "新增礼包";
    }

    searchgame().then(function (res) {
      _this.searchgames = res.data;
      _this.gameId = res.data.id;
    });
    SelectType().then(function (res) {
      _this.pay_types = res.data;
    });
  },
  computed: {
    isShow: {
      get: function get() {
        if (this.show) {
          return this.show;
        }
      },
      set: function set(val) {
        this.$emit("changeShow", false);
      }
    }
  },
  methods: {
    gamechange: function gamechange(id) {
      var _this2 = this;

      if (id != "") {
        this.$set(this.searchData, this.searchData.game, id);
        searchplatform({
          game_id: id
        }).then(function (res) {
          _this2.searchplatforms = res.data;

          _this2.$set(_this2.searchplatforms);

          _this2.searchData.platform = "";
          _this2.searchData.channel = "";
          _this2.searchData.pkg = "";
          _this2.searchMark.platform = "";
          _this2.searchMark.channel = "";
          _this2.searchMark.pkg = "";
          _this2.searchchannels = [];
          _this2.advchannels = [];
          _this2.searchpkgs = [];

          _this2.searchgames.forEach(function (item) {
            if (item.id == id) {
              _this2.searchMark.game = item.game_mark;
            }
          });
        });
      } else {
        this.$set(this.searchData, this.searchData.game, "");
        this.searchData.platform = "";
        this.searchData.channel = "";
        this.searchData.pkg = "";
        this.searchMark.pkg = "";
        this.searchMark.platform = "";
        this.searchMark.channel = "";
        this.searchplatforms = [];
        this.searchchannels = [];
        this.searchpkgs = [];
        this.searchMark.game = "";
        this.advchannels = [];
      }
    },
    platformchange: function platformchange(id) {
      var _this3 = this;

      if (id != "") {
        this.$set(this.searchData, this.searchData.platform, id);
        searchchannel({
          platform_id: id
        }).then(function (res) {
          _this3.searchchannels = res.data.channel;
          _this3.advchannels = res.data.adv_platform; //广告平台

          _this3.$set(_this3.searchchannels);

          _this3.$set(_this3.advchannels);

          _this3.searchData.channel = "";
          _this3.searchData.pkg = "";
          _this3.searchData.advChannel = "";
          _this3.searchData.advLocation = "";
          _this3.searchMark.channel = "";
          _this3.searchMark.pkg = "";
          _this3.searchpkgs = [];

          _this3.searchplatforms.forEach(function (item) {
            if (item.id == id) {
              _this3.searchMark.platform = item.platform_mark;
              _this3.searchData.channel = "";
              _this3.searchMark.channel = "";
            }
          });
        });
      } else {
        this.$set(this.searchData, this.searchData.platform, "");
        this.searchData.channel = "";
        this.searchData.pkg = "";
        this.searchMark.channel = "";
        this.searchMark.pkg = "";
        this.searchMark.platform = "";
        this.searchpkgs = [];
        this.searchchannels = [];
        this.advchannels = [];
      }
    },
    channelchange: function channelchange(id) {
      var _this4 = this;

      if (id != "") {
        this.$set(this.searchData, this.searchData.channel, id);
        searchpkg({
          channel_id: id
        }).then(function (res) {
          _this4.searchpkgs = res.data;

          _this4.searchchannels.forEach(function (item) {
            if (item.id == id) {
              _this4.searchMark.channel = item.channel_mark;
              _this4.searchData.pkg = "";
              _this4.searchMark.pkg = "";
            }
          });
        });
      } else {
        this.searchData.pkg = "";
        this.searchMark.pkg = "";
        this.searchMark.channel = "";
        this.searchData.channel = "";
        this.searchpkgs = [];
      }
    },
    pkgchange: function pkgchange(id) {
      var _this5 = this;

      if (id != "") {
        this.$set(this.searchpkgs);
        this.$set(this.searchData, this.searchData.pkg, id);
        this.searchpkgs.forEach(function (item) {
          if (item.id == id) {
            _this5.searchMark.pkg = item.pkg_mark;
          }
        });
      } else {
        this.searchMark.pkg = "";
        this.$set(this.searchData, this.searchData.pkg, "");
      }
    },
    resetForm: function resetForm(formName) {
      this.searchData = {};
      this.isShow = false;
      this.formName = ""; // 

      this.$emit("changeShow", false);
    },
    dataReset: function dataReset() {
      this.dialogFormVisible = false;
    },
    // 重置
    clearData: function clearData() {
      this.searchData = {};
    },
    dataFormSubmit: function dataFormSubmit(formName) {
      var _this6 = this;

      if (this.titleName == "新增配置") {
        addLginConfig(this.searchMark).then(function (res) {
          if (res.status == 200) {
            Message.success(res.message); // this.dialogFormVisible = false;
            // this.$emit("refreshDataList");

            _this6.resetForm("ruleForm");

            _this6.searchData = {};
          } else {
            Message.warning(res.message);
            _this6.searchData = {};
          }
        });
      }
    }
  }
};