var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "配置详情",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  staticStyle: { width: "100%" },
                  attrs: { prop: "name", label: "活动配置" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    staticStyle: { height: "200px" },
                    attrs: {
                      type: "textarea",
                      disabled: "",
                      placeholder: "请输入名称"
                    },
                    model: {
                      value: _vm.paramsData.content,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "content", $$v)
                      },
                      expression: "paramsData.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }