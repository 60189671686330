var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex", justify: "start" } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.game_name, value: item.game_mark }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.platform_name,
                      value: item.platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchClick } },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      this.sumData.length > 0
        ? _c("div", { staticClass: "box-sum" }, [
            _c("div", { staticClass: "sum-item" }, [_vm._v("合计")]),
            _c("div", { staticClass: "sum-item" }, [
              _vm._v(
                " 预估收益:" +
                  _vm._s(this.sumData[0].estimated_income || 0) +
                  " "
              )
            ]),
            _c("div", { staticClass: "sum-item" }, [
              _vm._v(
                " 广告请求量:" +
                  _vm._s(this.sumData[0].ad_request_times || 0) +
                  " "
              )
            ]),
            _c("div", { staticClass: "sum-item" }, [
              _vm._v(
                " 广告返回量:" +
                  _vm._s(this.sumData[0].ad_response_times || 0) +
                  " "
              )
            ])
          ])
        : _vm._e(),
      _c(
        "el-table",
        { ref: "table", attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "80", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "time", label: "日期", align: "center", width: "93" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "game_reveal",
              label: "游戏",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "platform_reveal",
              label: "平台",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel_reveal",
              label: "渠道",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pkg_reveal",
              label: "包名",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "estimated_income",
              label: "预估收益",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "ecpm", label: "ecpm", align: "center", width: "93" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "show_times",
              label: "展示量",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "click_times",
              label: "点击量",
              align: "center",
              width: "103"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "click_percent",
              label: "点击率",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_request_times",
              label: "广告请求量",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_response_times",
              label: "广告返回量",
              align: "center",
              width: "103"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "filling_rate",
              label: "广告填充率",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "material_request_times",
              label: "物料请求量",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "material_response_times",
              label: "物料返回量",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "material_filling_rate",
              label: "物料填充率",
              align: "center",
              width: "93"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "material_show_percent",
              label: "物料展示率",
              align: "center",
              width: "93"
            }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total,
          "page-size": _vm.pagrSize
        },
        on: { "current-change": _vm.handleCurrentChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }