import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import channelComMixins from "./mixins/channelComMixins";
import { hourlySummary, hourlySummary_currency } from "@/api/dataPirate/channelData";
import * as echarts from "echarts";
export default {
  mixins: [tabulation, channelComMixins],
  data: function data() {
    return {
      hour: '01:00'
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    getDate: function getDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.searchData.date = date;
    },

    /**
     * 整点详情 star
     */
    searchClick: function searchClick() {
      var _this = this;

      var isverification = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      // if(this.currencyClose && !this.searchData.currency_info){
      //   if(isverification){
      //     this.$message.error('请选择货币类型')
      //   }  
      //   return
      // }
      this.searchData.hour = parseInt(this.hour);
      this.reqLoading = true;
      var reqFn = this.currencyClose ? hourlySummary_currency : hourlySummary;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.code == 200) {
          _this.tableData = [];

          var _loop = function _loop(i) {
            _this.processingList.forEach(function (item) {
              res.data[i][item] = _this.$utils.conversion(res.data[i][item]).toFixed(2);
            });

            var h = Number(i);

            var data = _objectSpread({
              hours: (h < 10 ? '0' + h : h) + ':00'
            }, res.data[i]);

            _this.tableData.push(data);
          };

          for (var i in res.data) {
            _loop(i);
          }

          _this.geteDetailsChart();
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    geteDetailsChart: function geteDetailsChart() {
      var _this2 = this;

      var chartDom = document.getElementById("echarthourDetails");

      if (this.echarthourDetails != null && this.echarthourDetails != "" && this.echarthourDetails != undefined) {
        this.echarthourDetails.dispose(); //销毁
      }

      this.echarthourDetails = echarts.init(chartDom); // let data = [this.searchpkgs.find((i) => i.pkg_mark == this.searchData.pkg).pkg_name]

      var series = [];
      this.screenList.forEach(function (item, index) {
        series.push({
          name: item.label,
          type: "bar",
          yAxisIndex: _this2.collectNum.findIndex(function (i) {
            return i == item.prop;
          }) > -1 ? 1 : 0,
          data: _this2.tableData.map(function (i) {
            return i[item.prop];
          }),
          smooth: true,
          barMaxWidth: '50',
          symbol: "none"
        });
      });
      var option;
      option = {
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "item",
          formatter: function formatter(params) {
            return "".concat(params.seriesName, ":").concat(params.value);
          }
        },
        legend: {
          width: '100%',
          icon: 'circle',
          itemHeight: 12,
          bottom: '0%',
          itemWidth: 15,
          inactiveColor: '#666666',
          formatter: ['{a|{name}}'].join('\n'),
          textStyle: {
            fontSize: 12,
            lineHeight: 12,
            rich: {
              a: {
                width: 120,
                verticalAlign: 'middle'
              }
            },
            padding: [0, 0, -2, -4] //[上、右、下、左]

          },
          selected: this.Detailsselected
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false
          },
          data: ['数据详情']
        },
        yAxis: [{
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          },
          splitNumber: 5,
          axisLabel: {
            formatter: function formatter(value, index) {
              if (value >= 10000) {
                return (value / 10000).toFixed(2) + "万";
              } else if (value >= 1000) {
                return value;
              } else {
                return value;
              }
            }
          }
        }, {
          type: 'value',
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          }
        }],
        series: series
      };
      option && this.echarthourDetails.setOption(option, true);
      var that = this;
      this.echarthourDetails.on('legendselectchanged', function (params) {
        that.Detailsselected = JSON.parse(JSON.stringify(params.selected));
      });
    }
    /**
    * 整点详情 end
    */

  }
};