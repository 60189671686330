var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "title", label: "角色名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "description", label: "角色描述" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "300",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.$refs.addgmAuthority.init(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("环境权限")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.getData }
      }),
      _c("addgmAuthority", { ref: "addgmAuthority" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }