import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { getRoi, getRoi_currency } from "@/api/dataAll/roi";
import * as echarts from "echarts";
export default {
  name: "roi",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        start_time: "",
        end_time: "",
        country: "",
        self_design_day: ""
      },
      tableData: [],
      cyclicData: [],
      dates: [],
      datas: [],
      series: [],
      cellStylekeys: ["roiAndrecharge"]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.searchData.start_time = this.value2[0];
      this.searchData.end_time = this.value2[1];
      this.reqLoading = true;
      var reqFn = this.currencyClose ? getRoi_currency : getRoi;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.code == 200) {
          (function () {
            _this.dates = [];
            _this.series = [];
            _this.cyclicData = [];
            _this.datas = [];
            _this.tableData = [];

            _this.timeInterval();

            var newData = JSON.parse(JSON.stringify(res.data));

            for (var i in newData.roi) {
              var roiList = [];
              var rechargeList = [];

              for (var k in newData.roi[i]) {
                roiList.push({
                  label: k,
                  value: newData.roi[i][k]
                });
                rechargeList.push({
                  label: k,
                  value: newData.recharge[i][k]
                });
              } // for (let k in newData.recharge[i]) {
              //   rechargeList.push({
              //     label: k,
              //     value: newData.recharge[i][k],
              //   });
              // }


              newData.roi[i] = roiList;
              newData.recharge[i] = rechargeList;
            }

            var _loop = function _loop(_k) {
              var data = {
                date: _k,
                cost: _this.$utils.conversion(newData.cost[_k]),
                recharge_all: _this.$utils.conversion(newData.recharge_all[_k])
              };

              _this.dates.push(_k);

              newData.roi[_k].map(function (item, index) {
                var n = item.label;
                data["roiAndrecharge" + n] = item.value.toFixed(3) + " / " + _this.$utils.conversion(newData.recharge[_k][index].value).toFixed(3);
                data["roi" + n] = item.value;

                if (_this.cyclicData.length < newData.roi[_k].length) {
                  _this.cyclicData.push({
                    label: "ROI".concat(item.label, " / \u5F53\u65E5\u5145\u503C"),
                    prop: "roiAndrecharge" + n,
                    desc: n == 0 ? "首日投放回本率" : "",
                    tableText: n == 0 ? "ROI".concat(item.label, " / \u5F53\u65E5\u5145\u503CA") : "ROI".concat(item.label, " / \u5F53\u65E5\u5145\u503C")
                  });

                  _this.datas.push("roi" + item.label);
                }
              });

              _this.series.push({
                name: _k,
                type: "line",
                data: newData.roi[_k].map(function (i) {
                  return i.value.toFixed(3);
                }),
                smooth: true
              });

              _this.tableData.push(data);
            };

            for (var _k in newData.roi) {
              _loop(_k);
            }

            _this.$nextTick(function () {
              _this.getechart();

              _this.getTableRight();
            });
          })();
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("roiEchart");
      var myChart = echarts.init(chartDom);
      var option;
      var dates = this.dates;
      var datas = this.datas;
      var series = this.series;
      option = {
        title: {
          text: "ROI"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: dates,
          // selected: this.selectedArr,
          width: "80%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: datas
        },
        yAxis: {
          type: "value"
        },
        series: series
      };
      option && myChart.setOption(option, true);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.page = val;
      this.searchClick();
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "日期 / Date",
        prop: "date"
      }, {
        label: "内购纯收入 / Earnings",
        prop: "recharge_all"
      }, {
        label: "实际消耗 / PAY",
        prop: "cost"
      }].concat(_toConsumableArray(this.cyclicData));
      this.$export.excel({
        columns: columns,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};