//
//
//
//
import { getSummerDatagdt } from "@/api/dataAll/monSummer";
import montorSummData from './montorSummData';
export default {
  name: "montorSummDatagdt",
  components: {
    montorSummData: montorSummData
  },
  data: function data() {
    return {
      reqFns: null
    };
  },
  created: function created() {
    this.reqFns = getSummerDatagdt;
  },
  methods: {}
};