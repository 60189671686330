var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: _vm.titleName, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "platform_name", label: "平台名称" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入平台名称" },
                    model: {
                      value: _vm.form.platform_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "platform_name", $$v)
                      },
                      expression: "form.platform_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "platform_mark", label: "平台标志" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入平台标志" },
                    model: {
                      value: _vm.form.platform_mark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "platform_mark", $$v)
                      },
                      expression: "form.platform_mark"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "status", label: "状态" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "1", border: "" } }, [
                        _vm._v("启用")
                      ]),
                      _c("el-radio", { attrs: { label: "0", border: "" } }, [
                        _vm._v("禁用")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }