import request from '@/utils/request'; // 付费留存

export function rechargeRetain(data) {
  return request({
    url: '/recharge-retain',
    method: 'get',
    params: data
  });
}
export function rechargeRetain_currency(data) {
  return request({
    url: '/recharge-retain-currency',
    method: 'get',
    params: data
  });
}