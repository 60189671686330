import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from "@/utils/request";
import { getToken } from "@/utils/auth";
import { batchImporatFile } from "@/api/aymentsdk/aymentsdk";
import axios from "axios";
export default {
  props: {
    showFile: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      dialogFormVisible: false,
      titleName: "",
      id: "",
      baseUrl: "",
      form: {
        game: "",
        platform: "",
        channel: "",
        pkg: "",
        ad_channel: "",
        pay_name: "",
        pay_mark: ""
      },
      searchData: {
        pay_name: "",
        pay_mark: "",
        icon: ""
      },
      fileList: [],
      imageUrl: "",
      searchgames: [],
      searchplatforms: [],
      searchchannels: [],
      searchpkgs: [],
      advchannels: [],
      advLocation: [],
      gameId: "",
      value1: "",
      value2: [],
      searchMark: {},
      url: "",
      dialogImageUrl: "",
      file: '',
      upmsaseg: '',
      img: '',
      forming: {
        file: ''
      }
    };
  },
  created: function created() {
    this.searchMark = {};
    this.url = request.defaults.baseURL;
  },
  methods: {
    Submit: function Submit() {
      this.$refs.upload.submit();
    },
    fileRemove: function fileRemove(file, fileList) {
      if (fileList.length < 1) {
        this.uploadDisabled = true; //未选择文件则禁用上传按钮
      }
    },
    // 文件发生改变
    fileChange: function fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
      }
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.img = res.data.image;
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      this.forming.file = file;
      var isIMAGE = file.name.substring(file.name.lastIndexOf('.') + 1); // const isLt1M = file.size < 100;

      if (isIMAGE !== 'xlsx') {
        this.$message.error("只能上传以.xlsx为后缀得文件!");
      } // if (!isLt1M) {
      //   this.$message.error("上传文件大小不能超过 10M!");
      // }


      return isIMAGE;
    },
    resetForm: function resetForm(formName) {
      this.$emit("changeShowing", false);
      this.$emit("changeShow", false);
    },
    dataFormSubmit: function dataFormSubmit(formName) {
      var _this = this;

      var formData = new FormData();
      formData.append("file", formName.file);
      formData.append("token", getToken());
      var config = {
        headers: {
          "Content-Type": "multipart/json"
        }
      };
      axios.post(this.url + "/pay/import_goods_conversion", formData, config).then(function (res) {
        _this.upmsaseg = res.data.message;

        if (res.data.status == 200) {
          _this.$message({
            message: res.data.message,
            type: 'success'
          });

          _this.dialogFormVisible = false;

          _this.$emit("refreshDataList");

          _this.resetForm("ruleForm");

          _this.$emit("changeShowing", false);

          _this.$emit("changeShow", false);
        } else {
          _this.$message({
            message: res.data.message,
            type: 'info'
          });
        }
      });
    },
    clearData: function clearData() {
      this.searchData = {};
      this.imageUrl = '';
    }
  },
  computed: {
    Myheader: function Myheader() {
      return {
        token: getToken()
      };
    },
    showFileing: {
      get: function get() {
        if (this.showFile) {
          return this.showFile;
        }
      },
      set: function set(val) {
        this.$emit("changeShow", false);
      }
    }
  }
};