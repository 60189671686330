import request from '@/utils/request'; //查询用户列表

export function querUserList(data) {
  return request({
    url: '/loginsdk/users',
    method: 'get',
    params: data
  });
} // 用户封禁

export function banUser(data) {
  return request({
    url: '/loginsdk/banned',
    method: 'post',
    params: data
  });
} // 更改密码

export function changePass(data) {
  return request({
    url: '/loginsdk/up_passwd',
    method: 'post',
    params: data
  });
} // 换绑手机号

export function changeBindPhone(data) {
  return request({
    url: '/loginsdk/replace_phone',
    method: 'post',
    params: data
  });
} // 换绑邮箱

export function changeBindEmail(data) {
  return request({
    url: '/loginsdk/replace_email',
    method: 'post',
    params: data
  });
} // 反显数据

export function returnData(data) {
  return request({
    url: '/loginsdk/up_passwd',
    method: 'get',
    params: data
  });
} // 获取用户信息

export function getUserInfo(data) {
  return request({
    url: '/loginsdk/user-info',
    method: 'get',
    params: data
  });
} // 用户信息修稿

export function updataUserInfo(data) {
  return request({
    url: '/loginsdk/user-info',
    method: 'post',
    params: data
  });
}