import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { examineServerUpd, replaceAccountBind } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      paramsData: {
        id: '',
        old_acc_id: '',
        new_acc_id: ''
      },
      paramsDataMark: {},
      dialogShow: false,
      rules: {
        old_acc_id: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        new_acc_id: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.paramsDataMark = _objectSpread({}, this.paramsData);
  },
  methods: {
    open: function open(data) {
      if (data) {
        this.paramsData = _objectSpread(_objectSpread({}, this.paramsData), data);
      }

      this.dialogShow = true;
    },
    clearData: function clearData(isClearId) {
      this.paramsData = _objectSpread(_objectSpread({}, this.paramsDataMark), {}, {
        id: isClearId ? '' : this.paramsData.id
      });
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var reqFn = _this.paramsData.id !== '' ? examineServerUpd : replaceAccountBind;
          reqFn(_objectSpread(_objectSpread({}, _this.paramsData), _this.prod)).then(function (res) {
            if (res.status == 200) {
              _this.$message({
                message: '操作成功',
                type: 'success'
              });

              _this.dialogShow = false;

              _this.$emit('update');
            }
          });
        }
      });
    }
  }
};