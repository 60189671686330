import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import pickerOption from '@/mixins/module/pickerOption';
export default {
  mixins: [pickerOption],
  data: function data() {
    return {
      pageSize: 10,
      currentPage: 1,
      tableCopyTableList: []
    };
  },
  methods: {
    // 页数改变事件
    handleSizeChange: function handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.tableData = this.currentChangePage(pageSize, this.currentPage);
    },
    // 页码改变切换
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.tableData = this.currentChangePage(this.pageSize, currentPage);
    },
    // 分页方法
    currentChangePage: function currentChangePage(size, current) {
      var tablePush = [];
      this.tableCopyTableList.forEach(function (item, index) {
        if (size * (current - 1) <= index && index <= size * current - 1) {
          tablePush.push(item);
        }
      });
      return tablePush;
    }
  }
};