var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("aside", { staticStyle: { "margin-top": "15px" } }, [
        _vm._v(
          " Here we use window.print() to implement the feature of downloading PDF. "
        )
      ]),
      _c(
        "router-link",
        { attrs: { target: "_blank", to: "/pdf/download" } },
        [
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v(" Click to download PDF ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }