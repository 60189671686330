var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.searchData.id ? "修改" : "新增",
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                model: _vm.searchData,
                rules: _vm.rules,
                "label-width": "80px"
              }
            },
            [
              _vm.rawData.game_show_type == 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      attrs: { prop: "special_product_id", label: "选择产品" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.isDisplay,
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择产品"
                          },
                          on: { change: _vm.productChange },
                          model: {
                            value: _vm.searchData.special_product_id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchData,
                                "special_product_id",
                                $$v
                              )
                            },
                            expression: "searchData.special_product_id"
                          }
                        },
                        _vm._l(_vm.productsList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "广告渠道", prop: "ad_channel" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        disabled: _vm.isDisplay,
                        placeholder: "广告渠道"
                      },
                      on: { change: _vm.advChannelchange },
                      model: {
                        value: _vm.searchData.ad_channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_channel", $$v)
                        },
                        expression: "searchData.ad_channel"
                      }
                    },
                    _vm._l(_vm.advchannels, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.adv_platform_name,
                          value: item.adv_platform_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "广告位置", prop: "ad_placement" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        disabled: _vm.isDisplay,
                        placeholder: "广告位置"
                      },
                      model: {
                        value: _vm.searchData.ad_placement,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_placement", $$v)
                        },
                        expression: "searchData.ad_placement"
                      }
                    },
                    _vm._l(_vm.advLocation, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.adv_place_name,
                          value: item.adv_place_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "日期", prop: "date" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      disabled: _vm.isDisplay,
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.searchData.date,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "date", $$v)
                      },
                      expression: "searchData.date"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "国家", prop: "country" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        disabled: _vm.isDisplay,
                        filterable: "",
                        placeholder: "请选择国家"
                      },
                      model: {
                        value: _vm.searchData.country,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "country", $$v)
                        },
                        expression: "searchData.country"
                      }
                    },
                    _vm._l(_vm.searchCountry, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.country, value: item.country }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "消耗金额", prop: "amount" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      type: "number",
                      placeholder: "请输入消耗金额(分)"
                    },
                    model: {
                      value: _vm.searchData.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "amount", _vm._n($$v))
                      },
                      expression: "searchData.amount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    disabled: _vm.isDisplay,
                    round: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }