// 用户日志
import request from '@/utils/request';
export function getActionLog(data) {
  return request({
    url: '/user/operation_log',
    method: 'get',
    params: data
  });
} //  获取所有用户

export function getAllUser(data) {
  return request({
    url: '/user/all',
    method: 'get',
    params: data
  });
}