var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { kamitaku: "" },
            on: { searchChange: _vm.searchChange }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: false,
                    filterable: "",
                    placeholder: "统计内容"
                  },
                  on: { change: _vm.getListnew },
                  model: {
                    value: _vm.StatisticalContentValue,
                    callback: function($$v) {
                      _vm.StatisticalContentValue = $$v
                    },
                    expression: "StatisticalContentValue"
                  }
                },
                _vm._l(_vm.StatisticalContent, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.StatisticalContentValue == "1"
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: false,
                        filterable: "",
                        placeholder: "计算方式"
                      },
                      on: { change: _vm.getListnew },
                      model: {
                        value: _vm.CalculationValue,
                        callback: function($$v) {
                          _vm.CalculationValue = $$v
                        },
                        expression: "CalculationValue"
                      }
                    },
                    _vm._l(_vm.Calculation, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    filterable: "",
                    "collapse-tags": "",
                    placeholder: "筛选条件"
                  },
                  on: { change: _vm.totalDynamicChange },
                  model: {
                    value: _vm.totalDynamicValue,
                    callback: function($$v) {
                      _vm.totalDynamicValue = $$v
                    },
                    expression: "totalDynamicValue"
                  }
                },
                _vm._l(_vm.totalDynamic, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.getListnew }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isShow
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                },
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  sortable: "",
                  "class-name": "font-bold",
                  prop: "date",
                  label: "日期",
                  "min-width": "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "register_num",
                  label: "新用户数",
                  "min-width": "120"
                }
              }),
              _vm._l(_vm.Dynamic, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.value,
                    label: item.label,
                    "min-width": _vm.$utils.flexColumnWidth(
                      item.label + "1",
                      false
                    )
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(" " + _vm._s(row[item.value] || 0) + " ")
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }