import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { searchdataAll } from "@/api/dataAll/dataAll";
import { tabulation } from "@/mixins/index";
export default {
  name: "dataAll",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value1: [],
      value2: [],
      tableData: [],
      show: false,
      Dynamic: [{
        label: "开始加载人数",
        prop: "load"
      }, {
        label: "加载完成数",
        prop: "load_complete"
      }, {
        label: "异常退出加载数",
        prop: "crash_abort_load"
      }, {
        label: "手动退出加载数",
        prop: "device_manual_abort_init"
      }, {
        label: "开始初始化设备数",
        prop: "device_init"
      }, {
        label: "开始初始化账号数",
        prop: "account_init"
      }, {
        label: "初始化完成设备数",
        prop: "device_init_complete"
      }, {
        label: "初始化完成账号数",
        prop: "account_init_complete"
      }, {
        label: "初始化异常退出设备数",
        prop: "device_crash_abort_init"
      }, {
        label: "初始化异常退出账号数",
        prop: "account_crash_abort_init"
      }, {
        label: "初始化手动退出设备数",
        prop: "device_manual_abort_init"
      }, {
        label: "初始化手动退出账号数",
        prop: "account_manual_abort_init"
      }],
      intReq: ["getCountrys"]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.searchData.activation_time = "";
      this.searchData.activation_endtime = "";

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      searchdataAll(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this.tableData = res.data.data;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "日期",
        prop: "date"
      }].concat(_toConsumableArray(this.Dynamic));
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};