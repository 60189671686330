var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.searchData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.searchData,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _vm.rawData.game_show_type == 1
            ? _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "special_product_id", label: "选择产品" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.interdits,
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择产品"
                      },
                      on: { change: _vm.productChange },
                      model: {
                        value: _vm.searchData.special_product_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "special_product_id", $$v)
                        },
                        expression: "searchData.special_product_id"
                      }
                    },
                    _vm._l(_vm.productsList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "广告渠道", prop: "ad_channel" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    disabled: _vm.interdits,
                    placeholder: "广告渠道"
                  },
                  on: {
                    change: function($event) {
                      return _vm.advChannelchange($event, false)
                    }
                  },
                  model: {
                    value: _vm.searchData.ad_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_channel", $$v)
                    },
                    expression: "searchData.ad_channel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_platform_name,
                      value: item.adv_platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "广告位置", prop: "ad_placement" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    disabled: _vm.interdits,
                    placeholder: "广告位置"
                  },
                  model: {
                    value: _vm.searchData.ad_placement,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_placement", $$v)
                    },
                    expression: "searchData.ad_placement"
                  }
                },
                _vm._l(_vm.advLocation, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_place_name,
                      value: item.adv_place_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "广告组ID", prop: "advertiser_id" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.searchData.advertiser_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "advertiser_id", $$v)
                  },
                  expression: "searchData.advertiser_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "APPID", prop: "app_id" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.searchData.app_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "app_id", $$v)
                  },
                  expression: "searchData.app_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "密钥", prop: "secret" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.searchData.secret,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "secret", $$v)
                  },
                  expression: "searchData.secret"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.resetForm("ruleForm")
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.interdits, type: "warning" },
              on: {
                click: function($event) {
                  return _vm.clearData()
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }