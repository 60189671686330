import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect, serchSelectManage } from "@/mixins/index";
import { facebookMetaList, delFacebookMeta } from "@/api/management/facebookData";
import addfacebookData from "./components/facebookData";
export default {
  name: "facebookData",
  mixins: [serchSelect, serchSelectManage],
  components: {
    addfacebookData: addfacebookData
  },
  data: function data() {
    return {
      searchData: {
        page: 1,
        page_size: 10
      },
      total: 0,
      tableData: [],
      InitializeOrNot: false
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick(page) {
      var _this = this;

      this.searchData.page = page;
      this.reqLoading = true;
      facebookMetaList(this.searchData).then(function (res) {
        if (res.code == 200) {
          var list = ['game', 'platform', 'channel', 'pkg'];
          _this.tableData = res.data.list.length ? _this.$utils.dataConversion(res.data.list, list) : [];
          _this.total = res.data.count || 0;
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    handleDelete: function handleDelete(id) {
      var _this2 = this;

      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delFacebookMeta({
          id: id
        }).then(function (res) {
          if (res.code == 200) {
            _this2.$message({
              type: 'success',
              message: '删除成功!'
            });

            _this2.searchClick(_this2.searchData.page);
          }
        });
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }].concat(_toConsumableArray(this.sericeArr));
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    } // handleCurrentChange(val) {},

  }
};