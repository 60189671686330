var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "incommChannle section dataAll-content" }, [
    _c(
      "div",
      { staticClass: "levelDetails-body" },
      [
        _c(
          "div",
          { staticClass: "button_box" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "success" },
                on: {
                  click: function($event) {
                    return _vm.handleSource("t", 1)
                  }
                }
              },
              [_vm._v("新增")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { width: "98%", "margin-left": "40px" },
            attrs: { data: _vm.tableData, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "id", label: "ID", width: "60", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "timestamp",
                label: "日期",
                align: "center",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "game", label: "游戏", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "platform", label: "平台", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "channel", label: "渠道", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "ad_channel", label: "广告渠道", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "ad_placement",
                label: "广告位置",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "pkg",
                label: "包名",
                align: "center",
                "min-width": "120"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "put_channel", label: "投放平台", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "put_revenue", label: "单次收益", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                align: "center",
                label: "操作",
                width: "200"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", round: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row.id, 2)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", round: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            layout: "total, prev, pager, next",
            "current-page": _vm.searchData.page,
            total: _vm.total,
            "page-size": _vm.pagrSize
          },
          on: { "current-change": _vm.handleCurrentChange }
        })
      ],
      1
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showing,
          expression: "showing"
        }
      ],
      staticClass: "mask"
    }),
    _c(
      "div",
      { staticStyle: { "pointer-events": "none" } },
      [
        _vm.addWayConfig
          ? _c("addincomChannle", {
              ref: "addWayConfig",
              attrs: { showing: _vm.showing },
              on: {
                refreshDataList: _vm.searchClick,
                changeShow: _vm.changeShow
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }