//
//
//
//
//
//
//
//
//
//
//
var jsDiff = require("diff");

import uploadImagetow from "@/components/uploadImagetow";
export default {
  components: {
    uploadImagetow: uploadImagetow
  },
  data: function data() {
    return {
      dialogShow: false,
      paramsData: {
        content: ''
      }
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      // JSON.stringify(data, null, 2)
      this.paramsData.content = data;
      this.dialogShow = true;
    }
  }
};