import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect, serchSelectManage, tabulation } from "@/mixins/index";
import { productList, delProduct } from "@/api/management/productMapping";
import addproductMapping from "./components/productMapping";
import { listNo } from "@/api/advRelation";
export default {
  name: "productMapping",
  mixins: [serchSelect, serchSelectManage, tabulation],
  components: {
    addproductMapping: addproductMapping
  },
  data: function data() {
    return {
      total: 0,
      tableData: [],
      InitializeOrNot: false
    };
  },
  created: function created() {},
  activated: function activated() {
    this.searchClick();
  },
  methods: {
    searchClick: function searchClick(e) {
      var _this = this;

      this.searchData.page = e;
      this.reqLoading = true;
      listNo(this.searchData).then(function (res) {
        if (res.status == 200) {
          if (!res.data.length && e > 1) {
            _this.searchClick(e - 1);
          } else {
            var list = ['game', 'platform', 'channel', 'pkg'];
            _this.tableData = res.data.length ? _this.$utils.dataConversion(res.data, list) : [];

            _this.$nextTick(function () {
              _this.getTableRight();
            });
          }
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    handleClipboard: function handleClipboard(text, event) {
      var _this2 = this;

      if (!text) {
        return;
      }

      this.$copyText(text).then(function (e) {
        _this2.$Message.success('复制成功');
      }, function (e) {
        this.$Message.success('复制失败');
      });
    },
    handleDelete: function handleDelete(id) {
      var _this3 = this;

      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delProduct({
          id: id
        }).then(function (res) {
          if (res.status == 200) {
            _this3.$message({
              type: 'success',
              message: '删除成功!'
            });

            _this3.$store.dispatch('d2admin/serchSelect/getAllGame');

            _this3.$store.dispatch('d2admin/serchSelect/getAllProduct');

            _this3.searchClick(_this3.searchData.page);
          }
        });
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: '日期',
        prop: 'date'
      }].concat(_toConsumableArray(this.sericeArr));
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    } // handleCurrentChange(val) {},

  }
};