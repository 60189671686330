import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect, tabulation } from "@/mixins/index";
import { getUserActiveList } from "@/api/dataAll/userActiveSum";
export default {
  name: "activUserAdvSumm",
  mixins: [serchSelect, tabulation],
  data: function data() {
    return {
      value2: [],
      value3: [],
      searchData: {
        activity_starttime: "",
        activity_endtime: "",
        ad_starttime: "",
        ad_endtime: "",
        kind: 1
      },
      tableData: [],
      tableHeader: ["新增人数", "活跃人数", "总登录次数", "最高在线人数"]
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    timestampToTime: function timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    searchClick: function searchClick() {
      var _this = this;

      this.searchData.activity_starttime = this.value2[0] || "";
      this.searchData.activity_endtime = this.value2[1] || "";
      this.searchData.ad_starttime = this.value3[0] || "";
      this.searchData.ad_endtime = this.value3[1] || "";
      this.reqLoading = true;
      getUserActiveList(this.searchData).then(function (res) {
        if (res.status == 200) {
          res.data.map(function (item) {
            item.timestamp = _this.timestampToTime(item.timestamp);
          });
          var list = ["game", "platform", "channel", "pkg"];
          _this.tableData = res.data.length ? _this.$utils.dataConversion(res.data, list) : [];
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "游戏",
        prop: "game_reveal"
      }, {
        label: "平台",
        prop: "platform_reveal"
      }, {
        label: "渠道",
        prop: "channel_reveal"
      }, {
        label: "包名",
        prop: "pkg_reveal"
      }, {
        label: "设备号",
        prop: "device_id"
      }, {
        label: "在线时长",
        prop: "duration"
      }, {
        label: "广告次数",
        prop: "ad_times"
      }, {
        label: "激活时间",
        prop: "timestamp"
      }];

      var data = _toConsumableArray(this.tableData);

      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};