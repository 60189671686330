var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "其他配置",
            visible: _vm.dialogShow,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            touchmove: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { width: "1050px" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.list, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "reward_num",
                      label: "可领取次数",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "reg_begintime",
                      label: "可领取礼包的玩家注册时间(开始)",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "reg_endtime",
                      label: "可领取礼包的玩家注册时间(结束)",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "礼包类型", align: "center" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }