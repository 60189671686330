var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "page" }, [
    _c("div", { staticClass: "navigation flex" }, [
      _c("div", { staticClass: "navigation-title" }, [
        _c("img", {
          staticStyle: {
            width: "75px",
            height: "23px",
            "margin-right": "10px"
          },
          attrs: { src: require("../../assets/tap.png") }
        }),
        _vm._v("开发者服务")
      ]),
      _c(
        "div",
        { staticClass: "navigation-name flex" },
        [
          _vm._v(" 上宅 > 极限逃脱之罪生梦死 "),
          _c(
            "el-dropdown",
            { staticClass: "navigation-dro" },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v(" 测试"),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [_c("el-dropdown-item", [_vm._v("测试")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("el-card", { attrs: { shadow: "always" } }, [
          _c(
            "div",
            { staticClass: "content-nav" },
            [
              _c("div", { staticClass: "content-title" }, [_vm._v("数据")]),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "timestamp",
                  "default-time": ["00:00:00", "00:00:00"],
                  editable: false,
                  type: "daterange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: { change: _vm.datachange },
                model: {
                  value: _vm.defaultDate,
                  callback: function($$v) {
                    _vm.defaultDate = $$v
                  },
                  expression: "defaultDate"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "content-navtow" }, [
            _c("div", { staticClass: "content-titletow" }, [
              _vm._v("商店数据 "),
              _c("div", { staticClass: "BotSlide" })
            ])
          ]),
          _c("div", { staticClass: "realTimeData" }, [
            _c("div", { staticClass: "realTimeData-title" }, [
              _vm._v("实时数据总量")
            ]),
            _c("div", { staticClass: "realTimeData-content" }, [
              _c("div", { staticClass: "realTimeData-item" }, [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 浏览量"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "realTimeData-num" }, [
                  _vm._v(" " + _vm._s(_vm.watch_timesTol || 0) + " ")
                ])
              ]),
              _c("div", { staticClass: "realTimeData-item" }, [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 下载请求量"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "realTimeData-num" }, [
                  _vm._v(" " + _vm._s(_vm.download_timesTol || 0) + " ")
                ])
              ]),
              _c("div", { staticClass: "realTimeData-item" }, [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 预约量"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "realTimeData-num" }, [
                  _vm._v(" " + _vm._s(_vm.booking_detailTol || 0) + " ")
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "tables" }, [
            _c(
              "div",
              { staticClass: "tables-item" },
              [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 浏览量"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "tables-data" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.datas[0]) +
                      " 至 " +
                      _vm._s(_vm.datas[1]) +
                      " "
                  )
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { height: "300", data: _vm.watch_times, border: "" }
                  },
                  _vm._l(_vm.watch_timesNum, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.key,
                        label: item.name,
                        width: _vm.labWidth
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tables-item" },
              [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 下载请求量"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "tables-data" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.datas[0]) +
                      " 至 " +
                      _vm._s(_vm.datas[1]) +
                      " "
                  )
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: "300",
                      data: _vm.download_times,
                      border: ""
                    }
                  },
                  _vm._l(_vm.download_timesNum, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.key,
                        label: item.name,
                        width: _vm.labWidth
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tables-item" },
              [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 预约量"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "tables-data" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.datas[0]) +
                      " 至 " +
                      _vm._s(_vm.datas[1]) +
                      " "
                  )
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: "300",
                      data: _vm.booking_detail,
                      border: ""
                    }
                  },
                  _vm._l(_vm.booking_detailNum, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: { prop: item.key, label: item.name, width: 190 }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tables-item" },
              [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 转化效果数据"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "tables-data" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.datas[0]) +
                      " 至 " +
                      _vm._s(_vm.datas[1]) +
                      " "
                  )
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: "300",
                      data: _vm.trans_percent,
                      border: ""
                    }
                  },
                  _vm._l(_vm.trans_percentNum, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.key,
                        label: item.name,
                        width: _vm.labWidth
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tables-item" },
              [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 排行数据"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "tables-data" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.datas[0]) +
                      " 至 " +
                      _vm._s(_vm.datas[1]) +
                      " "
                  )
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { height: "300", data: _vm.rankingData, border: "" }
                  },
                  _vm._l(_vm.rankingNum, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.key,
                        label: item.name,
                        "min-width": _vm.labWidth
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tables-item" },
              [
                _c("div", { staticClass: "realTimeData-tle" }, [
                  _vm._v(" 投放数据"),
                  _c("i", { staticClass: "el-icon-warning-outline" })
                ]),
                _c("div", { staticClass: "tables-data" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.datas[0]) +
                      " 至 " +
                      _vm._s(_vm.datas[1]) +
                      " "
                  )
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { height: "300", data: _vm.releaseData, border: "" }
                  },
                  _vm._l(_vm.releaseNum, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.key,
                        label: item.name,
                        "min-width": _vm.labWidth
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }