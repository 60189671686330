import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { searchgame, searchplatform, searchchannel, searchpkg } from '@/api/dataAll/dataAll';
import { advrechargeVip } from '@/api/dataAll/recharge';
import { getadvLocation } from '@/api/dataAll/advLocation';
export default {
  name: "advrechargeVip",
  data: function data() {
    return {
      num: 1,
      tableTitle: [],
      advanced: false,
      gameId: '',
      searchData: {
        game: '',
        platform: '',
        channel: '',
        pkg: '',
        advChannel: '',
        advLocation: ''
      },
      searchMark: {
        game: '',
        platform: '',
        channel: '',
        pkg: '',
        ad_channel: '',
        ad_placement: ''
      },
      tableData: [],
      tableLtv: [],
      tableDate: '',
      show: false,
      dates: [],
      datas: []
    };
  },
  created: function created() {
    var _this = this;

    searchgame().then(function (res) {
      _this.searchgames = res.data;
      _this.gameId = res.data.id;
    });
  },
  methods: {
    gamechange: function gamechange(id) {
      var _this2 = this;

      if (id != '') {
        searchplatform({
          game_id: id
        }).then(function (res) {
          _this2.searchplatforms = res.data;
          _this2.searchData.platform = '';
          _this2.searchData.channel = '';
          _this2.searchData.pkg = '';
          _this2.searchMark.platform = '';
          _this2.searchMark.channel = '';
          _this2.searchMark.pkg = '';
          _this2.searchData.advChannel = '';
          _this2.searchData.advLocation = '';
          _this2.searchMark.ad_placement = '';
          _this2.searchMark.ad_channel = '';
          _this2.searchchannels = [];
          _this2.advchannels = [];
          _this2.advLocation = [];
          _this2.searchpkgs = [];

          _this2.searchgames.forEach(function (item) {
            if (item.id == id) {
              _this2.searchMark.game = item.game_mark;
            }
          });
        });
      } else {
        this.searchData.platform = '';
        this.searchData.channel = '';
        this.searchData.pkg = '';
        this.searchMark.pkg = '';
        this.searchMark.platform = '';
        this.searchMark.channel = '';
        this.searchplatforms = [];
        this.searchchannels = [];
        this.searchpkgs = [];
        this.searchMark.game = '';
        this.searchData.advChannel = '';
        this.searchData.advLocation = '';
        this.searchMark.ad_channel = '';
        this.searchMark.ad_placement = '';
        this.advchannels = [];
        this.advLocation = [];
      }
    },
    platformchange: function platformchange(id) {
      var _this3 = this;

      if (id != '') {
        searchchannel({
          platform_id: id
        }).then(function (res) {
          _this3.searchchannels = res.data.channel;
          _this3.advchannels = res.data.adv_platform; //广告平台

          _this3.searchData.channel = '';
          _this3.searchData.pkg = '';
          _this3.searchData.advChannel = '';
          _this3.searchData.advLocation = '';
          _this3.searchMark.channel = '';
          _this3.searchMark.pkg = '';
          _this3.searchMark.ad_placement = '';
          _this3.searchMark.ad_channel = '';
          _this3.advLocation = [];
          _this3.searchpkgs = [];

          _this3.searchplatforms.forEach(function (item) {
            if (item.id == id) {
              _this3.searchMark.platform = item.platform_mark;
              _this3.searchData.channel = '';
              _this3.searchMark.channel = '';
            }
          });
        });
      } else {
        this.searchData.channel = '';
        this.searchData.pkg = '';
        this.searchMark.channel = '';
        this.searchMark.pkg = '';
        this.searchMark.platform = '';
        this.searchpkgs = [];
        this.searchchannels = [];
        this.searchData.advChannel = '';
        this.searchData.advLocation = '';
        this.searchMark.ad_placement = '';
        this.searchMark.ad_channel = '';
        this.advchannels = [];
        this.advLocation = [];
      }
    },
    channelchange: function channelchange(id) {
      var _this4 = this;

      if (id != '') {
        searchpkg({
          channel_id: id
        }).then(function (res) {
          _this4.searchpkgs = res.data;

          _this4.searchchannels.forEach(function (item) {
            if (item.id == id) {
              _this4.searchMark.channel = item.channel_mark;
              _this4.searchData.pkg = '';
              _this4.searchMark.pkg = '';
            }
          });
        });
      } else {
        this.searchData.pkg = '';
        this.searchMark.pkg = '';
        this.searchMark.channel = '';
        this.searchData.channel = '';
        this.searchpkgs = [];
      }
    },
    pkgchange: function pkgchange(id) {
      var _this5 = this;

      if (id != '') {
        this.searchpkgs.forEach(function (item) {
          if (item.id == id) {
            _this5.searchMark.pkg = item.pkg_mark;
          }
        });
      } else {
        this.searchMark.pkg = '';
      }
    },
    advChannelchange: function advChannelchange(id) {
      var _this6 = this;

      if (id != '') {
        getadvLocation({
          advplatform_id: id
        }).then(function (res) {
          _this6.advLocation = res.data;
          _this6.searchMark.ad_channel = '';

          _this6.advchannels.forEach(function (item) {
            if (item.id == id) {
              _this6.searchMark.ad_channel = item.adv_platform_mark;
              _this6.searchData.advLocation = '';
              _this6.searchMark.ad_placement = '';
            }
          });
        });
      } else {
        this.searchData.advLocation = '';
        this.searchMark.ad_placement = '';
        this.searchMark.ad_channel = '';
        this.searchData.advChannel = '';
        this.advLocation = [];
      }
    },
    advLocationchange: function advLocationchange(id) {
      var _this7 = this;

      if (id != '') {
        this.advLocation.forEach(function (item) {
          if (item.id == id) {
            _this7.searchMark.ad_placement = item.adv_place_mark;
          }
        });
      } else {
        this.searchMark.ad_placement = '';
      }
    },
    searchClick: function searchClick() {
      var _this8 = this;

      advrechargeVip(this.searchMark).then(function (res) {
        _this8.tableData = res.data || [];
      });
    }
  }
};