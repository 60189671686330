//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  data: function data() {
    return {
      dialogShow: false,
      data: {
        content: '',
        is_prod: 0
      }
    };
  },
  created: function created() {},
  methods: {
    init: function init(data) {
      // this.data = data
      this.dialogShow = true;
    }
  }
};