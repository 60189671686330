var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "ID", label: "ID", width: "80", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "timestamp",
              label: "日期",
              align: "center",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.timestampToTime(scope.row.timestamp)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "game", label: "游戏", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "platform", label: "平台", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "channel", label: "渠道", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "ad_channel", label: "广告渠道", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "ad_placement", label: "广告位置", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pkg",
              label: "包名",
              align: "center",
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "advertiser_id", label: "广告主ID", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          attrs: { click: "handleSource(scope.row, 2)" }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row.ID)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.addWayConfig
        ? _c("addApp", {
            ref: "addWayConfig",
            attrs: { showing: _vm.showing, obj: _vm.obj },
            on: {
              refreshDataList: _vm.searchClick,
              changeShow: _vm.changeShow,
              changeObj: _vm.changeObj
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }