import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _defineProperty from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import Editor from "@/components/Editor";
import { szgmAddGift } from '@/api/gmToolNew/gmToolNew';
import { gmToolNew, gmToolNewCom } from "@/mixins/index";
export default {
  components: {
    Editor: Editor
  },
  mixins: [gmToolNew, gmToolNewCom],
  data: function data() {
    var _paramsData;

    return {
      paramsData: (_paramsData = {
        gift_id: '',
        name: '',
        desc: '',
        icon: '',
        refresh: 0,
        expire: '',
        num: '',
        cost: [],
        delay_time: ''
      }, _defineProperty(_paramsData, "expire", ''), _defineProperty(_paramsData, "is_delay", 2), _defineProperty(_paramsData, "has_discount", 2), _defineProperty(_paramsData, "original_cost", []), _defineProperty(_paramsData, "reward", []), _paramsData),
      dialogShow: false,
      expire_time: null,
      Price: [],
      ex_Val: [],
      refreshType: [{
        label: '不刷新',
        value: 0
      }, {
        label: '日刷新',
        value: 1
      }, {
        label: '周刷新',
        value: 2
      }, {
        label: '月刷新',
        value: 3
      }],
      discount: [{
        label: '是',
        value: 1
      }, {
        label: '否',
        value: 2
      }],
      timing: [{
        label: '否',
        value: 2
      }, {
        label: '是',
        value: 1
      }],
      currencyItemList: [],
      equipmentItemList: [],
      rules: {
        gift_id: [{
          required: true,
          message: "请输入礼包ID",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "请输入名字",
          trigger: "blur"
        }],
        desc: [{
          required: true,
          message: "请输入描述",
          trigger: "blur"
        }],
        icon: [{
          required: true,
          message: "请输入ID",
          trigger: "blur"
        }],
        refresh: [{
          required: true,
          message: "请选择刷新类型",
          trigger: "blur"
        }],
        expire: [{
          required: true,
          message: "请选择失效时间",
          trigger: "blur"
        }],
        num: [{
          required: true,
          message: "请输入礼包数量",
          trigger: "blur"
        }],
        item_id: [{
          required: true,
          message: "请选择物品",
          trigger: "blur"
        }],
        item_num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择类型",
          trigger: "blur"
        }],
        item_quality: [{
          required: true,
          message: "请选择品质",
          trigger: "blur"
        }],
        has_discount: [{
          required: true,
          message: "是否打折",
          trigger: "blur"
        }],
        original_cost: [{
          required: true,
          message: "请添加原价",
          trigger: "blur"
        }],
        cost: [{
          required: true,
          message: "请添加售价",
          trigger: "blur"
        }],
        reward: [{
          required: true,
          message: "请添加物品",
          trigger: "blur"
        }],
        delay_time: [{
          required: true,
          message: "请选择发送时间",
          trigger: "blur"
        }],
        is_delay: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    expireChange: function expireChange(e) {// this.paramsData.expire = e / 1000
    },
    discountChange: function discountChange() {
      this.$set(this.paramsData, 'original_cost', []);
      this.paramsData.original_cost = [];
    },
    open: function open(data) {
      var _this = this;

      this.dialogShow = true;
      this.itemType.map(function (i) {
        _this['itemType' + i.value] = _this.szgmItemList.filter(function (item) {
          return item.type == i.value;
        });
      });

      if (data) {
        this.paramsData = _objectSpread({}, data);
        this.ex_Val = data.reward ? JSON.parse(data.reward) : [];
      }
    },
    clearData: function clearData() {
      var _this$paramsData;

      this.paramsData = (_this$paramsData = {
        gift_id: '',
        name: '',
        desc: '',
        icon: '',
        refresh: 0,
        expire: '',
        num: '',
        delay_time: ''
      }, _defineProperty(_this$paramsData, "expire", ''), _defineProperty(_this$paramsData, "is_delay", 2), _defineProperty(_this$paramsData, "cost", []), _defineProperty(_this$paramsData, "has_discount", 2), _defineProperty(_this$paramsData, "original_cost", []), _defineProperty(_this$paramsData, "reward", []), _this$paramsData);
      this.$refs.ruleForm.clearValidate();
    },
    domainType: function domainType(type, index) {
      this.paramsData[type][index].id = "";

      if (this.paramsData[type][index].num) {
        this.paramsData[type][index].num = '';
      }
    },
    addDomain: function addDomain(type) {
      this.paramsData[type].push({
        num: '',
        type: '',
        id: '',
        quality: ''
      });
    },
    removeDomain: function removeDomain(type, index) {
      this.paramsData[type].splice(index, 1);
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.paramsData));
          data.reward.map(function (item) {
            if (item.type == 1) {
              delete item.quality;
            }

            for (var i in item) {
              item[i] = parseInt(item[i]);
            }
          });
          data.cost.map(function (item) {
            if (item.type == 1) {
              delete item.quality;
            }

            for (var i in item) {
              item[i] = parseInt(item[i]);
            }
          });
          data.original_cost.map(function (item) {
            if (item.type == 1) {
              delete item.quality;
            }

            for (var i in item) {
              item[i] = parseInt(item[i]);
            }
          }); // 物品ID为 int 没有品质不需要 quality 字段

          data.delay_time = data.delay_time ? data.delay_time / 1000 : '';
          data.reward = JSON.stringify(data.reward);
          data.original_cost = JSON.stringify(data.original_cost);
          data.cost = JSON.stringify(data.cost);
          szgmAddGift(_objectSpread(_objectSpread({}, data), _this2.propsData)).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                message: '添加成功',
                type: 'success'
              });

              _this2.dialogShow = false;

              _this2.$emit('change');
            }
          });
        }
      });
    }
  }
};