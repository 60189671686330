import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { vipCallLogAdd, vipCallLogUpdate } from "@/api/gmToolNew/gmToolNew";
import { gmToolNew } from "@/mixins/index";
import { jsonDiff } from "diff/lib/diff/json";
export default {
  mixins: [gmToolNew],
  data: function data() {
    return {
      dialogShow: false,
      paramsData: {
        id: "",
        user_id: "",
        user_name: "",
        contact_time: "",
        vip_class: ""
      },
      rules: {
        user_id: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        user_name: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        vip_class: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        contact_time: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      this.paramsData = _objectSpread({}, data);
      this.dialogShow = true;
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    clearData: function clearData() {
      this.paramsData = {
        id: "",
        user_id: "",
        user_name: "",
        contact_time: "",
        vip_class: ""
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this.paramsData));
          var reqFn = data.id ? vipCallLogUpdate : vipCallLogAdd;
          reqFn(_objectSpread(_objectSpread({}, data), {}, {
            game_id: _this.rawData.game_id
          })).then(function (res) {
            if (res.code == 200 || res.status == 200) {
              _this.$message({
                message: "操作成功",
                type: "success"
              });

              _this.dialogShow = false;

              _this.$emit("change");
            }
          });
        }
      });
    }
  }
};