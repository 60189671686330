import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accLossLevel } from "@/api/dataAll/lossLevel";
export default {
  name: "accLossLevel",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value2: [],
      tableData: [],
      guanka: [],
      guankaArr: [],
      gkTd: "",
      tableTime: [],
      show: false
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.guankaArr = [];

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      accLossLevel(this.searchData).then(function (res) {
        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objToKey = function objToKey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        _this.tableTime = objToKey(res.data.data);
        _this.tableData = objToTitle(res.data.data);
        var newNum = [];
        var table_bodoy = "<tr><td></td>";

        _this.tableData.forEach(function (val, index) {
          table_bodoy += "<td>流失人数</td><td>流失率</td>";
        });

        table_bodoy += "</tr>";
        var table_newNum = "<th style='width:100px;'>当日新增人数</th>";
        var table_top = "<th style='width:250px;'>游戏时长</th>";

        var _iterator = _createForOfIteratorHelper(_this.tableTime),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var date = _step.value;
            table_top += "<th colspan='2' style='width:250px;line-height:40px;'>" + date + "</th>";
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        var key = [];

        _this.tableData.forEach(function (item, i) {
          if (item.data) {
            var t = objToKey(item.data);
            t.forEach(function (item) {
              if (key.indexOf(item) > -1) {} else {
                key.push(item);
              }
            });
          }

          newNum.push(item.new_account_num);
        });

        newNum.forEach(function (item) {
          table_newNum += "<th colspan='2' style='width:250px;line-height:40px;'>" + item + "</th>";
        });

        if (key.length > 0) {
          key.forEach(function (item, i) {
            table_bodoy += "<tr>";
            table_bodoy += "<td style='width:100px;'>" + item + "级" + "</td>";

            _this.tableData.forEach(function (val, index) {
              var c = objToTitle(val.data);
              var d = val.data[item];

              if (d != undefined) {
                table_bodoy += "<td>" + d.number_loss + "</td>";
                table_bodoy += "<td>" + d.run_off + "</td>";
              } else {
                table_bodoy += "<td>0</td>";
                table_bodoy += "<td>0</td>";
              }
            });

            table_bodoy += "</tr>";
          });
        } else {
          _this.$message({
            message: "此时间段没有数据",
            type: "success"
          });
        }

        var header = document.getElementById("header");
        var bodoy = document.getElementById("bodoy");
        var num = document.getElementById("newNum");
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        num.innerHTML = table_newNum;
        _this.show = true;
      }).finally(function () {
        _this.reqLoading = false;
      });
    }
  }
};