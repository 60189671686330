import request from '@/utils/request'; // 流程数据

export function flowPercent(data) {
  return request({
    url: 'data/flow-percent',
    method: 'get',
    params: data
  });
} // 活跃用户留存

export function activityRetain(data) {
  return request({
    url: '/data/activity-retain',
    method: 'get',
    params: data
  });
} // 充值

export function loginRechargeData(data) {
  return request({
    url: '/data/login-recharge-data',
    method: 'get',
    params: data
  });
}
export function loginRechargeData_currency(data) {
  return request({
    url: '/data/login-recharge-data-currency',
    method: 'get',
    params: data
  });
} // 关卡

export function dailyTaskFlowPercent(data) {
  return request({
    url: '/data/daily-task-flow-percent',
    method: 'post',
    params: data
  });
} // 获取任务详情

export function getTaskDescDesc(data) {
  return request({
    url: '/data/get-task-desc-desc',
    method: 'get',
    params: data
  });
} // 删除任务详情

export function deleteTaskDesc(data) {
  return request({
    url: '/data/delete-task-desc',
    method: 'post',
    params: data
  });
} // 新增补单记录

export function addReplenishment(data) {
  return request({
    url: '/data/replenishmentAdd',
    method: 'post',
    params: data
  });
} // 查询补单记录

export function replenishmentSelect(data) {
  return request({
    url: '/data/replenishmentSelect',
    method: 'get',
    params: data
  });
} // 礼包ID映射

export function productList(data) {
  return request({
    url: '/data/productList',
    method: 'get',
    params: data
  });
} // 礼包ID映射删除

export function productDel(data) {
  return request({
    url: '/data/productDel',
    method: 'post',
    params: data
  });
}