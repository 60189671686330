import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { getRoi } from "@/api/dataAll/roi";
import * as echarts from "echarts";
export default {
  name: "retentionAnalysis",
  mixins: [serchSelect],
  data: function data() {
    return {
      value2: [],
      searchData: {
        begin_date: '',
        end_date: '',
        country: '',
        beginaction: '',
        retain_action: '',
        analyzetype: ''
      },
      tableData: [],
      cyclicData: [],
      dates: [],
      datas: [],
      series: [],
      intReq: ['getCountrys'],
      retentionPrimitive: [],
      retentionSelect: [],
      retention: [],
      analyzetypeList: [{
        label: '用户ID',
        value: 'user_id',
        disabled: false
      }, {
        label: '设备ID',
        value: 'deviceid',
        disabled: false
      }],
      behaviorList: [{
        label: 'activity',
        value: 'activity'
      }, {
        label: 'register',
        value: 'register'
      }, {
        label: 'createrole',
        value: 'createrole'
      }, {
        label: 'login',
        value: 'login'
      }, {
        label: 'recharge',
        value: 'recharge'
      }, {
        label: 'required',
        value: 'required'
      }]
    };
  },
  created: function created() {
    this.getsearchDate();

    for (var i = 0; i < 30; i++) {
      this.retentionPrimitive.push({
        label: i + 1 + '日留存',
        prop: i + 1 + 't'
      });
    }

    this.retentionSelect = this.retentionPrimitive.map(function (i) {
      return i.prop;
    });
    this.retention = this.retentionPrimitive;
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    beginactionChange: function beginactionChange(e) {
      this.analyzetypeList[0].disabled = e == 'activity' ? true : false;
      this.searchData.analyzetype = '';
    },
    retentionSelectChange: function retentionSelectChange(e) {
      var list = [];
      this.retentionPrimitive.forEach(function (i) {
        if (e.findIndex(function (t) {
          return t == i.prop;
        }) > -1) {
          list.push(i);
        }
      });
      this.retention = list;
    },
    searchClick: function searchClick() {
      var _this = this;

      this.searchData.begin_date = this.value2[0];
      this.searchData.end_date = this.value2[1];
      getRoi(this.searchData).then(function (res) {
        if (res.code == 200) {}

        _this.getechart();
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("retentionAnalysisEchart");
      var myChart = echarts.init(chartDom);
      var option;
      var dates = this.dates;
      var datas = this.datas;
      var series = this.series;
      option = {
        title: {
          text: "ROI"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: dates,
          // 这里显示点击搜索的日期--图表的最上面
          // selected: this.selectedArr,
          width: '80%'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: datas
        },
        yAxis: {
          type: "value"
        },
        series: series
      };
      option && myChart.setOption(option, true);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.page = val;
      this.searchClick();
    },
    exportdata: function exportdata() {
      var data = this.tableData;

      var columns = _toConsumableArray(this.retention);

      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};