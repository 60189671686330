import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pickerOption from '@/mixins/module/pickerOption';
import { searchgame, searchplatform, searchchannel, searchpkg } from "@/api/dataAll/dataAll";
import { getadvLocation } from "@/api/dataAll/advLocation";
import { getPayorderList, getPayList } from "@/api/aymentsdk/payCenter";
import * as echarts from "echarts";
export default {
  name: "paycenterOrderMang",
  mixins: [pickerOption],
  data: function data() {
    return {
      advanced: false,
      searchgames: [],
      searchplatforms: [],
      searchchannels: [],
      searchpkgs: [],
      tableTitle: [],
      advchannels: [],
      advLocation: [],
      searchcountry: [],
      commitList: [],
      gameId: "",
      value1: "",
      value2: [],
      value3: "",
      orderStateArr: [{
        id: 1,
        lable: "创建"
      }, {
        id: 2,
        lable: "已回调"
      }, {
        id: 3,
        lable: "回调成功"
      }, {
        id: 4,
        lable: "回调失败"
      }],
      deliverStateArr: [{
        id: 1,
        lable: "未回调"
      }, {
        id: 2,
        lable: "开始回调研发"
      }, {
        id: 3,
        lable: "回调成功"
      }, {
        id: 4,
        lable: "回调失败"
      }, {
        id: 5,
        lable: "回调次数超过允许值"
      }],
      searchData: {
        game: "",
        platform: "",
        channel: "",
        product_id: "",
        ad_channel: "",
        pkg: "",
        ad_placement: "",
        gate_order_id: "",
        third_part_order_id: "",
        deliver_state: "",
        order_state: "",
        server_id: "",
        start_time: "",
        end_time: ""
      },
      searchMark: {
        page: 1
      },
      fields: [],
      proData: {
        game: "",
        start_time: "",
        end_time: ""
      },
      tableData: [],
      tableLtv: [],
      tableDate: "",
      show: false,
      echarts: false,
      dates: [],
      datas: [],
      series: [],
      selectedArr: {},
      total: 0
    };
  },
  created: function created() {
    var _this = this;

    // this.getsearchDate()
    searchgame().then(function (res) {
      _this.searchgames = res.data;
      _this.gameId = res.data.id;
    });
  },
  methods: {
    timestampToTime: function timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    timeChang: function timeChang(val) {
      this.searchMark.page = 1;

      if (Array.isArray(val) && val.length > 0) {
        this.proData.start_time = val[0];
        this.proData.end_time = val[1];
      } else {
        this.proData.start_time = "";
        this.proData.end_time = "";
      }

      if (this.proData.game && this.proData.start_time) {
        this.getPayLists();
      }
    },
    getPayLists: function getPayLists() {
      var _this2 = this;

      getPayList(this.proData).then(function (res) {
        _this2.commitList = res.data;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchMark.page = val;
      this.searchClick();
    },
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
      this.proData.start_time = this.value2[0];
      this.proData.end_time = this.value2[1];
    },
    gamechange: function gamechange(id) {
      var _this3 = this;

      this.searchMark.page = 1;

      if (id != "") {
        searchplatform({
          game_id: id
        }).then(function (res) {
          _this3.searchplatforms = res.data;
          _this3.searchData.platform = "";
          _this3.searchData.channel = "";
          _this3.searchData.pkg = "";
          _this3.searchMark.platform = "";
          _this3.searchMark.channel = "";
          _this3.searchMark.pkg = "";
          _this3.searchData.advChannel = "";
          _this3.searchData.advLocation = "";
          _this3.searchMark.ad_placement = "";
          _this3.searchMark.ad_channel = "";
          _this3.searchchannels = [];
          _this3.advchannels = [];
          _this3.advLocation = [];
          _this3.searchpkgs = [];

          _this3.searchgames.forEach(function (item) {
            if (item.id == id) {
              _this3.searchMark.game = item.game_mark;
              _this3.proData.game = item.game_mark;

              if (_this3.proData.game && _this3.proData.start_time) {
                _this3.getPayLists();
              }
            }
          });
        });
      } else {
        this.searchData.platform = "";
        this.searchData.channel = "";
        this.searchData.pkg = "";
        this.searchMark.pkg = "";
        this.searchMark.platform = "";
        this.searchMark.channel = "";
        this.searchplatforms = [];
        this.searchchannels = [];
        this.searchpkgs = [];
        this.searchMark.game = "";
        this.searchData.advChannel = "";
        this.searchData.advLocation = "";
        this.searchMark.ad_channel = "";
        this.searchMark.ad_placement = "";
        this.advchannels = [];
        this.advLocation = [];
      }
    },
    platformchange: function platformchange(id) {
      var _this4 = this;

      this.searchMark.page = 1;

      if (id != "") {
        searchchannel({
          platform_id: id
        }).then(function (res) {
          _this4.searchchannels = res.data.channel;
          _this4.advchannels = res.data.adv_platform; //广告平台

          _this4.searchData.channel = "";
          _this4.searchData.pkg = "";
          _this4.searchData.advChannel = "";
          _this4.searchData.advLocation = "";
          _this4.searchMark.channel = "";
          _this4.searchMark.pkg = "";
          _this4.searchMark.ad_placement = "";
          _this4.searchMark.ad_channel = "";
          _this4.advLocation = [];
          _this4.searchpkgs = [];

          _this4.searchplatforms.forEach(function (item) {
            if (item.id == id) {
              _this4.searchMark.platform = item.platform_mark;
              _this4.searchData.channel = "";
              _this4.searchMark.channel = "";
            }
          });
        });
      } else {
        this.searchData.channel = "";
        this.searchData.pkg = "";
        this.searchMark.channel = "";
        this.searchMark.pkg = "";
        this.searchMark.platform = "";
        this.searchpkgs = [];
        this.searchchannels = [];
        this.searchData.advChannel = "";
        this.searchData.advLocation = "";
        this.searchMark.ad_placement = "";
        this.searchMark.ad_channel = "";
        this.advchannels = [];
        this.advLocation = [];
      }
    },
    channelchange: function channelchange(id) {
      var _this5 = this;

      this.searchMark.page = 1;

      if (id != "") {
        searchpkg({
          channel_id: id
        }).then(function (res) {
          _this5.searchpkgs = res.data;

          _this5.searchchannels.forEach(function (item) {
            if (item.id == id) {
              _this5.searchMark.channel = item.channel_mark;
              _this5.searchData.pkg = "";
              _this5.searchMark.pkg = "";
            }
          });
        });
      } else {
        this.searchData.pkg = "";
        this.searchMark.pkg = "";
        this.searchMark.channel = "";
        this.searchData.channel = "";
        this.searchpkgs = [];
      }
    },
    pkgchange: function pkgchange(id) {
      var _this6 = this;

      this.searchMark.page = 1;

      if (id != "") {
        this.searchpkgs.forEach(function (item) {
          if (item.id == id) {
            _this6.searchMark.pkg = item.pkg_mark;
          }
        });
      } else {
        this.searchMark.pkg = "";
      }
    },
    advChannelchange: function advChannelchange(id) {
      var _this7 = this;

      this.searchMark.page = 1;

      if (id != "") {
        getadvLocation({
          advplatform_id: id
        }).then(function (res) {
          _this7.advLocation = res.data;
          _this7.searchMark.ad_channel = "";

          _this7.advchannels.forEach(function (item) {
            if (item.id == id) {
              _this7.searchMark.ad_channel = item.adv_platform_mark;
              _this7.searchData.advLocation = "";
              _this7.searchMark.ad_placement = "";
            }
          });
        });
      } else {
        this.searchData.advLocation = "";
        this.searchMark.ad_placement = "";
        this.searchMark.ad_channel = "";
        this.searchData.advChannel = "";
        this.advLocation = [];
      }
    },
    advLocationchange: function advLocationchange(id) {
      var _this8 = this;

      this.searchMark.page = 1;

      if (id != "") {
        this.advLocation.forEach(function (item) {
          if (item.id == id) {
            _this8.searchMark.ad_placement = item.adv_place_mark;
          }
        });
      } else {
        this.searchMark.ad_placement = "";
      }
    },
    orderChang: function orderChang(val) {
      this.searchMark.page = 1;
      this.searchMark.third_part_order_id = val;
    },
    cpordChang: function cpordChang(val) {
      this.searchMark.page = 1;
      this.searchMark.gate_order_id = val;
    },
    serveIdchang: function serveIdchang(val) {
      this.searchMark.page = 1;
      this.searchMark.server_id = val;
      this.fields.push("server_id");
    },
    commChang: function commChang(val) {
      this.searchMark.page = 1;
      this.searchMark.product_id = val;
      this.fields.push("product_id");
    },
    orderstateChang: function orderstateChang(val) {
      this.searchMark.page = 1;
      this.searchMark.order_state = val;
      this.fields.push("order_state");
    },
    deliverchang: function deliverchang(val) {
      this.searchMark.page = 1;
      this.searchMark.deliver_state = val;
      this.fields.push("deliver_state");
    },
    searchClick: function searchClick() {
      var _this9 = this;

      if (Array.isArray(this.value2) && this.value2.length > 0) {
        this.searchMark.start_time = this.value2[0];
        this.searchMark.end_time = this.value2[1];
      } else {
        this.searchMark.start_time = "";
        this.searchMark.end_time = "";
      } // this.searchMark.kind = 1;


      getPayorderList(this.searchMark).then(function (res) {
        if (res.status == 200) {
          var list = ['game', 'platform', 'channel', 'pkg', 'ad_channel', 'ad_placement'];
          _this9.tableData = res.data.orderlist.data.length ? _this9.$utils.dataConversion(res.data.orderlist.data, list) : [];
          _this9.total = res.data.orderlist.total || 0;
        }
      });
    },
    exportdata: function exportdata() {
      if (this.value2.length > 0) {
        this.searchMark.start_time = this.value2[0];
        this.searchMark.end_time = this.value2[1];
      } else {
        this.searchMark.start_time = "";
        this.searchMark.end_time = "";
      }

      if (this.searchMark.game != "" && this.searchMark.game != undefined && this.searchMark.platform != "" && this.searchMark.platform != undefined && this.searchMark.start_time != "" && this.searchMark.start_time != undefined && this.searchMark.end_time != "" && this.searchMark.end_time != undefined) {
        if (process.env.NODE_ENV) {
          window.location.href = process.env.VUE_APP_API + "/pay_order_export" + "?game=" + (this.searchMark.game == "" ? "" : this.searchMark.game == undefined ? "" : this.searchMark.game) + "&" + "platform=" + (this.searchMark.platform == "" ? "" : this.searchMark.platform == undefined ? "" : this.searchMark.platform) + "&" + "start_time=" + this.searchMark.start_time + "&" + "end_time=" + this.searchMark.end_time + "&" + "page=" + this.searchMark.page + "&" + "channel=" + (this.searchMark.channel == "" ? "" : this.searchMark.channel == undefined ? "" : this.searchMark.channel) + "&" + "pkg=" + (this.searchMark.pkg == "" ? "" : this.searchMark.pkg == undefined ? "" : this.searchMark.pkg) + "&ad_channel=" + (this.searchMark.ad_channel == "" ? "" : this.searchMark.ad_channel == undefined ? "" : this.searchMark.ad_channel) + "&ad_placement=" + (this.searchMark.ad_placement == "" ? "" : this.searchMark.ad_placement == undefined ? "" : this.searchMark.ad_placement) + "&gate_order_id=" + (this.searchMark.gate_order_id == "" ? "" : this.searchMark.gate_order_id == undefined ? "" : this.searchMark.gate_order_id) + "&third_part_order_id=" + (this.searchMark.third_part_order_id == "" ? "" : this.searchMark.third_part_order_id == undefined ? "" : this.searchMark.third_part_order_id) + "&fields=" + (this.fields == [] ? [] : this.fields == undefined ? [] : this.fields);
        }
      }
    }
  }
};