import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { activateDeviceretain } from "@/api/dataAll/deviceRetain";
import * as echarts from "echarts";
export default {
  name: "activateRetention",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      tableData: [],
      dates: [],
      datas: [],
      series: [],
      Dynamic: [],
      PrimitiveDy: [],
      intReq: [],
      screen: [],
      cellStylekeys: ["rem"]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.kind = 1;
      this.reqLoading = true;
      activateDeviceretain(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this.dates = [];
          _this.series = [];
          _this.tableData = [];
          _this.Dynamic = [];

          for (var k in res.data.data) {
            _this.dates.push(k);

            var d = {
              date: k,
              register_num: res.data.other.activity_num[k]
            };
            var length = Object.keys(res.data.data[k]).length;
            var o = {
              name: k,
              type: "line",
              data: [],
              smooth: true
            };

            for (var j in res.data.data[k]) {
              d["rem" + j] = _this.zhuan([res.data.data[k][j]])[0];
              o.data.push(d["rem" + j]);

              if (length > _this.Dynamic.length) {
                var a = j == 2 ? "次" : j;

                _this.Dynamic.push({
                  label: a + "留",
                  prop: "rem" + j,
                  tableText: j == 2 ? "RRA" + j : "RR" + j,
                  desc: j == 2 ? "次日激活设备留存率" : ""
                });
              }
            }

            if (!_this.screen.length) {
              _this.screen = _this.Dynamic.map(function (item) {
                return item.prop;
              });
              _this.PrimitiveDy = _this.Dynamic;
            }

            _this.screenControls();

            _this.series.push(o);

            _this.tableData.push(d);
          } // 总计数据处理


          var total = {
            date: "加权平均留存",
            register_num: res.data.activity_num
          };

          for (var _k in res.data.avgData) {
            total["rem" + _k] = _this.zhuan([res.data.avgData[_k]])[0];
          }

          _this.tableData.push(total);

          _this.timeInterval();

          _this.$nextTick(function () {
            _this.getTableRight();
          });

          _this.getechart();
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    screenControls: function screenControls() {
      var _this2 = this;

      var list = [];
      this.Dynamic = [];
      this.PrimitiveDy.forEach(function (item) {
        _this2.screen.forEach(function (citem) {
          if (item.prop == citem) {
            list.push(item);
          }
        });
      });
      setTimeout(function () {
        _this2.Dynamic = list;
      }, 50);
    },
    zhuan: function zhuan(list) {
      var n = [];
      list.forEach(function (item) {
        n.push(item.length > 0 ? Number(item.substr(0, item.length - 1)) : 0);
      });
      return n;
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("activateRetentionEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "激活设备留存率(%)"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          // 这里显示点击搜索的日期--图表的最上面
          width: "80%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.PrimitiveDy.map(function (item) {
            return item.label;
          })
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "日期 / Date",
        prop: "date"
      }, {
        label: "激活设备",
        prop: "register_num"
      }].concat(_toConsumableArray(this.Dynamic));
      var data = JSON.parse(JSON.stringify(this.tableData));
      this.Dynamic.forEach(function (item) {
        data.forEach(function (i) {
          i[item.prop] = i[item.prop] + "%";
        });
      });
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};