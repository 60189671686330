var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
          on: { select: _vm.modelChange }
        },
        [
          _c("el-menu-item", { attrs: { index: "0" } }, [
            _vm._v("福利账号白名单管理")
          ]),
          _c("el-menu-item", { attrs: { index: "1" } }, [
            _vm._v("福利等级管理")
          ]),
          _c("el-menu-item", { attrs: { index: "2" } }, [
            _vm._v("白名单和福利等级管理")
          ]),
          _c("el-menu-item", { attrs: { index: "3" } }, [
            _vm._v("福利领取操作记录")
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex == 0,
              expression: "activeIndex == 0"
            }
          ],
          staticClass: "modleItem"
        },
        [_c("benefitAccount")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex == 1,
              expression: "activeIndex == 1"
            }
          ],
          staticClass: "modleItem"
        },
        [_c("welfareClass")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex == 2,
              expression: "activeIndex == 2"
            }
          ],
          staticClass: "modleItem"
        },
        [_c("associationManagement", { ref: "associationManagement" })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex == 3,
              expression: "activeIndex == 3"
            }
          ],
          staticClass: "modleItem"
        },
        [_c("welfareOperation", { ref: "welfareOperation" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }