import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import pickerOption from '@/mixins/module/pickerOption';
import { serverList, articlesList, getUserProd } from "@/api/gmtool/packageCode";
import { mapState } from 'vuex';
export default {
  mixins: [pickerOption],
  data: function data() {
    return {
      announcementTypes: [{
        label: '普通公告',
        value: 0
      }, {
        label: '停服公告',
        value: 1
      }, {
        label: '新服开服',
        value: 2
      }],
      serverLists: [],
      paramsData: {
        is_prod: '',
        page_size: 10
      },
      langList: [{
        label: '简体中文',
        value: 'cn'
      }, {
        label: '繁体中文',
        value: 'tc'
      }, {
        label: '英文',
        value: 'en'
      }, {
        label: '泰语',
        value: 'th'
      }, {
        label: '越南语',
        value: 'vn'
      }],
      configState: [{
        label: '动态计算',
        value: 0
      }, {
        label: '维护',
        value: 1
      }, {
        label: '新',
        value: 2
      }, {
        label: '爆满(70%)',
        value: 3
      }, {
        label: '拥挤(50%)',
        value: 4
      }, {
        label: '流畅',
        value: 5
      }],
      recommList: [{
        label: '既不推荐也非活动专区',
        value: 0
      }, {
        label: '只推荐',
        value: 1
      }, {
        label: '只活动专区',
        value: 2
      }, {
        label: '既是推荐也是活动专区',
        value: 3
      }],
      refreshDuration: [{
        label: '每日刷新',
        value: 'd'
      }, {
        label: '每周刷新',
        value: 'w'
      }, {
        label: '每月刷新',
        value: 'm'
      }],
      tagggList: [{
        label: '无标签',
        value: 0
      }, {
        label: '推荐',
        value: 1
      }, {
        label: '新',
        value: 2
      }, {
        label: '火',
        value: 3
      }, {
        label: '停服公告',
        value: 4
      }],
      FillIn: {
        en: 'Dear Captain, the current server will undergo maintenance starting at UTC time %s. The expected maintenance duration is %s. Please be patient.',
        enT: 'Dear Captain, the current server will undergo maintenance starting at UTC time %s. The expected maintenance duration is %sM. Please be patient.',
        cn: '亲爱的船长，当前服务器于UTC时间 %s开始停服维护，预计维护时间%s，请耐心等待。',
        cnT: '亲爱的船长，当前服务器于UTC时间 %s开始停服维护，预计维护时间%s分钟，请耐心等待。',
        tc: '親愛的船長，當前伺服器於UTC時間 %s開始停服維護，預計維護時間%s，請耐心等待。',
        tcT: '親愛的船長，當前伺服器於UTC時間 %s開始停服維護，預計維護時間%s分鐘，請耐心等待。',
        th: 'เรียนกัปตัน เซิร์ฟเวอร์ปัจจุบันจะไม่ให้บริการเพื่อการบำรุงรักษาในเวลา UTC %s เวลาในการบำรุงรักษาคาดว่าจะเท่ากับ %s โปรดอดทนรอ.',
        vn: 'Dear Captain, the current server will undergo maintenance starting at UTC time %s. The expected maintenance duration is %s. Please be patient.',
        vnT: 'Dear Captain, the current server will undergo maintenance starting at UTC time %s. The expected maintenance duration is %sM. Please be patient.'
      },
      FillInTitle: {
        en: "Notice",
        cn: "停服公告",
        tc: "停服公告",
        th: "ประกาศแจ้งปิดปรับปรุง",
        vn: "Notice"
      },
      NewSuitlIn: {
        en: 'Welcome to Royal Pirates! The server will open at UTC0 time %s.',
        cn: '欢迎来到《皇室海盗》，服务器将于UTC0时间 %s 开启。',
        tc: '歡迎來到《皇室海盜》，伺服器將於UTC0時間 %s 開啟。',
        th: 'Welcome to Royal Pirates! The server will open at UTC0 time %s.',
        vn: 'Welcome to Royal Pirates! The server will open at UTC0 time %s.'
      },
      NewSuitlInTitle: {
        en: 'Take a new suit',
        cn: '新服开服',
        tc: '新服開服',
        th: 'ชุดใหม่',
        vn: 'Take a new suit'
      },
      moduleList: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }],
      templateList: [{
        label: '默认',
        value: 0
      }, {
        label: '更新补偿',
        value: 1
      }, {
        label: '终生累充奖励发放',
        value: 2
      }],
      awardDistribution: [{
        value: "cn",
        title: "终生累充奖励",
        content: "\u4EB2\u7231\u7684VIP,\n     \u611F\u8C22\u60A8\u5BF9\u6E38\u620F\u7684\u559C\u7231\u4E0E\u652F\u6301,\u4EE5\u4E0B\u662F\u60A8\u5728VIP\u7EC8\u751F\u7D2F\u5145\u6D3B\u52A8\u4E2D\u6240\u83B7\u5F97\u7684\u5956\u52B1,\u8BF7\u5C3D\u5FEB\u67E5\u6536\u3002\u6709\u4EFB\u4F55\u7591\u95EE\u8BF7\u8054\u7EDCVIP\u5BA2\u670D\u3002"
      }, {
        value: "tc",
        title: "終身累充獎勵",
        content: "\u89AA\u611B\u7684VIP,\n     \u611F\u8B1D\u60A8\u5C0D\u904A\u6232\u7684\u559C\u611B\u8207\u652F\u6301,\u4EE5\u4E0B\u662F\u60A8\u5728VIP\u7D42\u8EAB\u7D2F\u5145\u6D3B\u52D5\u4E2D\u6240\u7372\u5F97\u7684\u734E\u52F5,\u8ACB\u76E1\u5FEB\u67E5\u6536\u3002\u6709\u4EFB\u4F55\u7591\u554F\u8ACB\u806F\u7D61VIP\u5BA2\u670D\u3002"
      }, {
        value: "en",
        title: "Life Time Recharge Activity",
        content: "Dear VIP Captain,\n     Thanks for your love and support! Here is the gift for life time recharge activity of VIP captain, please check and claim! Any further concerns please contact VIP service anytime."
      }, {
        value: "vn",
        title: "Phần Thưởng Tích Nạp Trọn Đời",
        content: "K\xEDnh th\u01B0a thuy\u1EC1n tr\u01B0\u1EDFng VIP,\n     Ch\xE2n th\xE0nh c\u1EA3m b\u1EA1n \u0111\xE3 y\xEAu th\xEDch v\xE0 \u1EE7ng h\u1ED9 tr\xF2 ch\u01A1i, sau \u0111\xE2y l\xE0 ph\u1EA7n th\u01B0\u1EDFng c\u1EE7a b\u1EA1n trong s\u1EF1 ki\u1EC7n t\xEDch n\u1EA1p tr\u1ECDn \u0111\u1EDDi d\xE0nh cho VIP, vui l\xF2ng ki\u1EC3m tra v\xE0 nh\u1EADn s\u1EDBm nh\u1EA5t c\xF3 th\u1EC3. N\u1EBFu c\xF3 b\u1EA5t k\u1EF3 th\u1EAFc m\u1EAFc n\xE0o vui l\xF2ng li\xEAn h\u1EC7 CSKH VIP."
      }],
      compensationList: [{
        value: "cn",
        title: "更新补偿",
        content: "\u8239\u957F\uFF1A\n    \u4E3A\u4E86\u7ED9\u60A8\u66F4\u597D\u7684\u6E38\u620F\u4F53\u9A8C\uFF0C\u6E38\u620F\u5DF2\u5B8C\u6210\u7248\u672C\u66F4\u65B0\uFF0C\u8239\u957F\u53EF\u66F4\u65B0\u65B0\u7248\u672C\u7EE7\u7EED\u4EAB\u53D7\u6E38\u620F\uFF01\u7ED9\u60A8\u5E26\u6765\u7684\u4E0D\u4FBF\u6211\u4EEC\u6DF1\u8868\u6B49\u610F\uFF0C\u8BF7\u67E5\u6536\u672C\u6B21\u66F4\u65B0\u8865\u507F\u3002\n    \u5177\u4F53\u66F4\u65B0\u5185\u5BB9\u53EF\u901A\u8FC7\u3010\u7CFB\u7EDF\u8BBE\u7F6E\u3011-\u3010\u516C\u544A\u3011\u67E5\u770B\u3002"
      }, {
        value: "tc",
        title: "更新補償",
        content: "\u8239\u9577\uFF1A\n    \u70BA\u4E86\u7D66\u60A8\u66F4\u597D\u7684\u904A\u6232\u9AD4\u9A57\uFF0C\u904A\u6232\u5DF2\u5B8C\u6210\u7248\u672C\u66F4\u65B0\uFF0C\u8239\u9577\u53EF\u66F4\u65B0\u65B0\u7248\u672C\u7E7C\u7E8C\u4EAB\u53D7\u904A\u6232\uFF01 \u9020\u6210\u60A8\u7684\u4E0D\u4FBF\u6211\u5011\u6DF1\u8868\u6B49\u610F\uFF0C\u8ACB\u67E5\u6536\u672C\u6B21\u66F4\u65B0\u88DC\u511F\u3002\n    \u5177\u9AD4\u66F4\u65B0\u5167\u5BB9\u53EF\u900F\u904E\u3010\u7CFB\u7D71\u8A2D\u5B9A\u3011-\u3010\u516C\u544A\u3011\u67E5\u770B\u3002"
      }, {
        value: "en",
        title: "Update compensation",
        content: "Dear Captains,\n    To provide you with a better gaming experience, the game has completed the version update.Captains can update to the new version and continue to enjoy the game.We apologize for any inconvenience and kindly accept this compensation for the update.\n    The updated content can be viewed through [Settings]-[Notice]."
      }, {
        value: "vn",
        title: "Bồi Thường Cập Nhật",
        content: "Thuy\u1EC1n tr\u01B0\u1EDFng:\n     \u0110\u1EC3 mang \u0111\u1EBFn cho b\u1EA1n tr\u1EA3i nghi\u1EC7m t\u1ED1t h\u01A1n, tr\xF2 ch\u01A1i \u0111\xE3 ho\xE0n t\u1EA5t c\u1EADp nh\u1EADt phi\xEAn b\u1EA3n, thuy\u1EC1n tr\u01B0\u1EDFng c\xF3 th\u1EC3 update phi\xEAn b\u1EA3n m\u1EDBi ti\u1EBFp t\u1EE5c t\u1EADn h\u01B0\u1EDFng tr\xF2 ch\u01A1i! Ch\xFAng t\xF4i v\xF4 c\xF9ng xin l\u1ED7i v\xEC mang l\u1EA1i s\u1EF1 b\u1EA5t ti\u1EC7n n\xE0y, vui l\xF2ng ki\u1EC3m tra v\xE0 nh\u1EADn b\u1ED3i th\u01B0\u1EDDng c\u1EADp nh\u1EADt l\u1EA7n n\xE0y.\n    N\u1ED9i dung c\u1EADp nh\u1EADt c\u1EE5 th\u1EC3 c\xF3 th\u1EC3 xem qua C\xE0i \u0111\u1EB7t h\u1EC7 th\u1ED1ng- Th\xF4ng B\xE1o"
      }],
      statusList: [{
        label: '已发送',
        value: '1'
      }, {
        label: '未发送',
        value: '2'
      }],
      sels: [],
      selsString: '',
      currentPage: 1,
      total: 0,
      pageSize: 10,
      pageFn: null,
      tableData: [],
      // 表格数据
      tableCopyTableList: [],
      // 表格copy数据b
      tagList: [{
        name: '添加a标签',
        content: '<a href=></a>'
      }, {
        name: '添加b标签',
        content: '<b></b>'
      }, {
        name: '添加i标签',
        content: '<i></i>'
      }, {
        name: '添加size标签',
        content: '<size=50></size>'
      }, {
        name: '添加color标签',
        content: '<color=#FFFFFF></color>'
      }, {
        name: '添加material标签',
        content: '<material=2></material>'
      }, {
        name: '添加quad标签',
        content: '<quad material=1 size=20 x=0.1 y=0.1 width=0.5 height=0.5>'
      }],
      reqLoading: false,
      pageFnName: 'getList'
    };
  },
  created: function created() {
    this.pageFn = this[this.pageFnName];
    this.paramsData.is_prod = this.severlist[0].value;

    if (!this.$store.state.gmTool.init.isreqItemList) {
      this.$store.dispatch('gmTool/init/getItemList');
    }
  },
  activated: function activated() {},
  computed: _objectSpread(_objectSpread({}, mapState("gmTool", {
    articleList: function articleList(state) {
      return state.init.itemList;
    }
  })), mapState("d2admin", {
    severlist: function severlist(state) {
      return state.user.GMauthority;
    },
    rawData: function rawData(state) {
      return state.serchSelect.rawData;
    }
  })),
  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      var _this = this;

      this.sels = [];
      val.forEach(function (e) {
        _this.sels.push(e.id);
      });
      this.selsString = this.sels.toString();
    },
    getserverList: function getserverList(e) {
      var _this2 = this;

      console.log(1);
      return new Promise(function (resolve, reject) {
        serverList({
          is_prod: _this2.searchData ? _this2.searchData.is_prod : _this2.paramsData.is_prod
        }).then(function (res) {
          if (res.status == 200) {
            _this2.serverLists = res.data;
            resolve();
          }
        });
      });
    },
    sizeChange: function sizeChange(e) {
      this.paramsData.page_size = e;
      this.pageFn && this.pageFn(1);
    },
    // 页数改变事件
    handleSizeChange: function handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.tableData = this.currentChangePage(pageSize, this.currentPage);
    },
    // 页码改变切换
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.tableData = this.currentChangePage(this.pageSize, currentPage);
    },
    // 分页方法
    currentChangePage: function currentChangePage(size, current) {
      var tablePush = [];
      this.tableCopyTableList.forEach(function (item, index) {
        if (size * (current - 1) <= index && index <= size * current - 1) {
          tablePush.push(item);
        }
      });
      return tablePush;
    }
  }
};