var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dataAll-content" }, [
    _c(
      "div",
      { staticClass: "accountRetain-header" },
      [
        _c(
          "el-select",
          {
            ref: "",
            attrs: {
              clearable: "",
              filterable: "",
              placeholder: "第三方登陆类型"
            },
            on: { change: _vm.gamechange },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          },
          _vm._l(_vm.searchgames, function(item) {
            return _c(
              "el-option",
              {
                key: item.id,
                attrs: {
                  "value-key": item.id,
                  label: item.game_name,
                  value: item.id
                }
              },
              [_vm._v(_vm._s(item.game_name) + " ")]
            )
          }),
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "btnn",
            attrs: { type: "success", round: "" },
            on: { click: _vm.searchClick }
          },
          [_vm._v("搜索")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table_box" },
      [
        _c(
          "div",
          { staticClass: "top_btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "success", round: "" },
                on: { click: _vm.dialogShow }
              },
              [_vm._v("新增")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%", "margin-left": "30px" },
            attrs: { data: _vm.tableData, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "ID",
                width: "200",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "game", label: "游戏", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "platform", label: "平台", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "channel", label: "渠道", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "pkg", label: "包名", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "secret_key_id",
                label: "密钥ID",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            color: "#606266",
                            "font-size": "14px"
                          },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleJoinPeople(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(scope.row.secret_key_id))]
                      ),
                      _c("el-button", {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleJoinPeople(scope.row)
                          }
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                align: "center",
                width: "100",
                fixed: "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "x-c" }, [
                        _c(
                          "div",
                          {
                            staticClass: "controlsBtn",
                            on: {
                              click: function($event) {
                                return _vm.deleHandle(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _c("div", {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "levelDetails-body"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }