import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogShow: false,
      list: []
    };
  },
  created: function created() {},
  methods: {
    init: function init(data) {
      var _this = this;

      this.list = [];
      data.map(function (i) {
        return [_this.list.push({
          id: i
        })];
      });
      this.dialogShow = true;
    }
  }
};