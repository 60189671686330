import "core-js/modules/es.array.every";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Papa from "papaparse";
export default {
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [["is_default", "language", "title", "content"], ["0", "cn", "测试批量", "测试批量"]];
      }
    }
  },
  data: function data() {
    return {
      dialogShow: false,
      fileArreys: []
    };
  },
  created: function created() {},
  methods: {
    open: function open(files) {
      var that = this;
      Papa.parse(files, {
        complete: function complete(results) {
          var arrs = results.data;
          var lastItem = arrs[arrs.length - 1].every(function (val) {
            return val === "";
          });
          lastItem && arrs.pop();
          that.fileArreys = arrs;
          that.dialogShow = true;
        }
      });
    }
  }
};