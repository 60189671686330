var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCurrency: true, isPayChannel: true } },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", filterable: "", "collapse-tags": "" },
                  on: {
                    change: function($event) {
                      return _vm.screenControls()
                    }
                  },
                  model: {
                    value: _vm.screen,
                    callback: function($$v) {
                      _vm.screen = $$v
                    },
                    expression: "screen"
                  }
                },
                _vm._l(_vm.PrimitiveDy, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.prop }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "tipsClass",
              staticStyle: { position: "relative" },
              attrs: { span: 4 }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "自定义范围" },
                model: {
                  value: _vm.searchData.self_design_day,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "self_design_day", $$v)
                  },
                  expression: "searchData.self_design_day"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "tipss",
                  attrs: { effect: "dark", placement: "top" }
                },
                [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: {
                      innerHTML: _vm._s(
                        "1. 单独一个数字 1 <br /> 2. 范围1-3 <br /> 3. 多个范围1-3,5-9,7-10"
                      )
                    },
                    slot: "content"
                  }),
                  _c("i", { staticClass: "el-icon-question " })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                },
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "tableRef",
                data: _vm.tableData,
                "cell-class-name": _vm.cellStyle,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  fixed: "",
                  "class-name": "font-bold",
                  prop: "date",
                  label: "日期.Date",
                  "render-header": _vm.renderheader,
                  "min-width": _vm.$utils.flexColumnWidth("2020-01-01", true)
                }
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "new_account_num",
                  label: "新增账号.DNU",
                  "render-header": _vm.renderheader,
                  "min-width": _vm.$utils.flexColumnWidth("新增账号", true)
                }
              }),
              _vm._l(_vm.Dynamic, function(item, index) {
                return _c("el-table-column", {
                  key: "key" + index,
                  attrs: {
                    sortable: "",
                    "sort-method": function(a, b) {
                      return a[item.prop] - b[item.prop]
                    },
                    prop: item.prop,
                    label: item.label,
                    "render-header": function(h, obj) {
                      return _vm.renderheader(h, obj, item.desc)
                    },
                    "min-width": _vm.$utils.flexColumnWidth(
                      item.tableText,
                      true
                    )
                  }
                })
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", {
        staticClass: "margin-top",
        staticStyle: { height: "500px" },
        attrs: { id: "dataLtvEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }