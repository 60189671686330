import "core-js/modules/es.array.concat";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { income, income_currency } from "@/api/dataAll/IncomeDetail";
import * as echarts from "echarts";
export default {
  name: "IncomeDetail",
  mixins: [tabulation],
  data: function data() {
    return {
      searchData: {
        start_time: "",
        end_time: ""
      },
      tableData: [],
      cyclicData: [{
        label: "充值金额(包含退款)",
        prop: "recharge_amount"
      }, {
        label: "退款金额",
        prop: "refund_amount"
      }, {
        label: "分成收益",
        prop: "revenue_amount"
      }],
      dates: [],
      datas: [],
      series: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.searchData.start_time = this.$times.timeStamp(this.value2[0]);
      this.searchData.end_time = this.$times.timeStamp(this.value2[1]);
      this.reqLoading = true;
      var reqFn = this.currencyClose ? income_currency : income;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.code == 200) {
          // res.data = {
          //   "2023-11-23": {
          //     "recharge_amount": 1,
          //     "revenue_amount": 50,
          //     "refund_amount": 100
          //   },
          //   "2023-11-24": {
          //     "recharge_amount": 0,
          //     "revenue_amount": 0,
          //     "refund_amount": 0
          //   },
          //   "2023-11-25": {
          //     "recharge_amount": 0,
          //     "revenue_amount": 0,
          //     "refund_amount": 0
          //   },
          //   "2023-11-26": {
          //     "recharge_amount": 0,
          //     "revenue_amount": 0,
          //     "refund_amount": 0
          //   },
          //   "2023-11-27": {
          //     "recharge_amount": 0,
          //     "revenue_amount": 0,
          //     "refund_amount": 0
          //   },
          //   "2023-11-28": {
          //     "recharge_amount": 0,
          //     "revenue_amount": 0,
          //     "refund_amount": 0
          //   },
          //   "2023-11-29": {
          //     "recharge_amount": 0,
          //     "revenue_amount": 0,
          //     "refund_amount": 0
          //   },
          //   "2023-11-30": {
          //     "recharge_amount": 0,
          //     "revenue_amount": 0,
          //     "refund_amount": 0
          //   }
          // }
          var list = [];

          for (var k in res.data) {
            for (var j in res.data[k]) {
              if (j == "revenue_amount") {
                res.data[k][j] = _this.$utils.conversion(res.data[k][j]).toFixed(3);
              } else {
                res.data[k][j] = _this.$utils.conversion(res.data[k][j]).toFixed(2);
              }
            }

            list.push(_objectSpread({
              date: k
            }, res.data[k]));
          }

          _this.tableData = list;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        } // this.getechart()

      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("IncomeDetailEchart");
      var myChart = echarts.init(chartDom);
      var option;
      var dates = this.dates;
      var datas = this.datas;
      var series = this.series;
      option = {
        title: {
          text: "ROI"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: dates,
          // 这里显示点击搜索的日期--图表的最上面
          // selected: this.selectedArr,
          width: "80%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: datas
        },
        yAxis: {
          type: "value"
        },
        series: series
      };
      option && myChart.setOption(option, true);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.page = val;
      this.searchClick();
    },
    exportdata: function exportdata() {
      var data = this.tableData;
      var columns = [{
        label: "日期",
        prop: "date"
      }].concat(_toConsumableArray(this.cyclicData));
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};