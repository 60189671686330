import "core-js/modules/es.array.for-each";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import NProgress from 'nprogress';
import { Loading } from 'element-ui';
import dayjs from "dayjs";
import { getCode } from "@/api/user";
import { mapActions } from "vuex";
import localeMixin from "@/locales/mixin.js";
export default {
  name: 'login',
  mixins: [localeMixin],
  data: function data() {
    return {
      timeInterval: null,
      time: dayjs().format("HH:mm:ss"),
      // 快速选择用户
      dialogVisible: false,
      // 表单
      formLogin: {
        // email: "admin@163.com",
        // password: "123456",
        email: "",
        password: "",
        verification_code: "",
        key: ""
      },
      loading: null,
      url: "",
      key: "",
      rules: {
        email: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }],
        verification_code: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }]
      },
      isShow: false
    };
  },
  created: function created() {
    this.getCodeMeth();
    this.isShow = true;
    var that = this;

    document.onkeydown = function (event_e) {
      if (window.event) {
        event_e = window.event;
      }

      var int_keycode = event_e.charCode || event_e.keyCode;

      if (int_keycode == '13') {
        //回车键：13
        that.submit();
      }
    };
  },
  beforeDestroy: function beforeDestroy() {
    this.isShow = false;

    if (pJSDom && pJSDom.length > 0) {
      pJSDom.forEach(function (pJSDomItem) {
        pJSDomItem.pJS.fn.vendors.destroypJS();
      });
    }

    clearInterval(this.timeInterval);
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      NProgress.done();
    }, 500);
    this.$store.dispatch('d2admin/account/logout');
    this.timeInterval = setInterval(function () {
      _this.refreshTime();
    }, 1000);
    this.$bus.$off('upd');
    this.$bus.$off('updProductS');
  },
  methods: _objectSpread(_objectSpread({}, mapActions("d2admin/account", ["login"])), {}, {
    refreshTime: function refreshTime() {
      this.time = dayjs().format("HH:mm:ss");
    },

    /**
     * @description 接收选择一个用户快速登录的事件
     * @param {Object} user 用户信息
     */
    handleUserBtnClick: function handleUserBtnClick(user) {
      this.formLogin.email = user.email;
      this.formLogin.password = user.password;
      this.submit();
    },

    /**
     * @description 提交表单
     */
    // 获取验证码
    getCodeMeth: function getCodeMeth() {
      var _this2 = this;

      getCode().then(function (res) {
        _this2.url = res.data.url.img;
        _this2.key = res.data.url.key;
        _this2.formLogin.key = _this2.key;
        _this2.formLogin.verification_code = '';
      });
    },
    // 提交登录信息
    submit: function submit() {
      var _this3 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = Loading.service({
            lock: true,
            text: '登录中',
            background: 'rgba(0, 0, 0, 0.7)'
          }); // 登录
          // 注意 这里的演示没有传验证码
          // 具体需要传递的数据请自行修改代码

          _this3.login({
            email: _this3.formLogin.email,
            password: _this3.formLogin.password,
            verification_code: _this3.formLogin.verification_code,
            key: _this3.key
          }).then(function (res) {
            // 重定向对象不存在则返回顶层路径
            if (res.status == 200) {
              _this3.$router.replace(_this3.$route.query.redirect || "/");
            } else {
              _this3.getCodeMeth();
            }

            _this3.loading.close();
          }).catch(function () {
            // 如果登录失败重新请求验证码
            _this3.getCodeMeth();

            _this3.loading.close();
          });
        }
      });
    }
  })
};