import request from '@/utils/request'; //  新增用户通知

export function addnoticUser(data) {
  return request({
    url: '/datum/add_chat_notice',
    method: 'post',
    params: data
  });
} // 获取通知用户列表

export function getnoticUserList(data) {
  return request({
    url: '/datum/chat_notice',
    method: 'get',
    params: data
  });
} // 回显数据

export function returnnoticUserList(data) {
  return request({
    url: '/datum/up_chat_notice',
    method: 'get',
    params: data
  });
} // 修改

export function updatanoticUserList(data) {
  return request({
    url: '/datum/up_chat_notice',
    method: 'post',
    params: data
  });
} // 删除

export function delenoticUserList(data) {
  return request({
    url: '/datum/del_chat_notice',
    method: 'get',
    params: data
  });
}