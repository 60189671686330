import request from '@/utils/request'; // 获取搜索游戏选项

export function searchgame(data) {
  return request({
    url: '/data/search_game',
    method: 'get',
    params: data
  });
} // 数据中心搜索页获取平台列表

export function searchplatform(data) {
  return request({
    url: '/data/search_platform',
    method: 'get',
    params: data
  });
} // 数据中心搜索页获取渠道列表

export function searchchannel(data) {
  return request({
    url: '/data/search_channel',
    method: 'get',
    params: data
  });
} // 数据中心搜索页获取包名列表

export function searchpkg(data) {
  return request({
    url: '/data/search_pkg',
    method: 'get',
    params: data
  });
}
export function searchdataAll(data) {
  return request({
    url: '/data/load_detail_data',
    method: 'get',
    params: data
  });
} // 导出

export function expoatdataAll(data) {
  return request({
    url: '/data/export_load_detail_data',
    method: 'get',
    params: data
  });
} // 获取所有绑定包名

export function getRoleProductPkg(data) {
  return request({
    url: '/data/getRoleProductPkg',
    method: 'get',
    params: data
  });
} // 获取所有绑定广告渠道

export function search_channel_all(data) {
  return request({
    url: '/data/search_channel_all',
    method: 'get',
    params: data
  });
} // 服务器维度数据

export function getServerDataList(data) {
  return request({
    url: '/data/getServerDataList',
    method: 'get',
    params: data
  });
}
export function getServerDataListCurrency(data) {
  return request({
    url: '/data/getServerDataListCurrency',
    method: 'get',
    params: data
  });
} // 获取广告平台

export function searchProductAdvPlatform(data) {
  return request({
    url: '/data/searchProductAdvPlatform',
    method: 'get',
    params: data
  });
} // 充值总额排行榜

export function recharge_total_rank_currency(data) {
  return request({
    url: '/recharge_total_rank_currency',
    method: 'get',
    params: data
  });
}