var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "Efforts to generate PDF" }
    },
    [
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v(" " + _vm._s(_vm.article.title) + " ")
        ])
      ]),
      _vm._m(0),
      _c("div", {
        ref: "content",
        staticClass: "node-article-content",
        domProps: { innerHTML: _vm._s(_vm.article.content) }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "#ccc" } }, [
      _vm._v(" This article is from Evan You on "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href:
              "https://medium.com/the-vue-point/plans-for-the-next-iteration-of-vue-js-777ffea6fabf"
          }
        },
        [_vm._v("medium")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }