import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
//
//
//
//
//
//
import echartMixins from "@/utils/resizeMixins";
export default {
  props: {
    onlineNum: {
      type: Object,
      required: true
    },
    height: {
      type: [String, Number],
      required: 0
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  created: function created() {
    var _this = this;

    setTimeout(function () {
      _this.draw();
    }, 200);
  },
  mixins: [echartMixins],
  methods: {
    draw: function draw() {
      this.chart = this.$echarts.init(document.getElementById("bottomRightChart"));

      var objTokey = function objTokey(obj) {
        return Object.keys(obj).map(function (k) {
          return k;
        });
      };

      var countries = [];
      var seriesList = [];
      var xList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

      for (var k in this.onlineNum) {
        countries.push(k);
        seriesList.push({
          name: k,
          type: "line",
          data: this.zhuan(this.onlineNum[k]),
          smooth: true
        });
      }

      var option = {
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: countries,
          textStyle: {
            color: "#FFFFFF"
          },
          width: '80%'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: xList,
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          }
        },
        series: seriesList
      };
      this.chart.setOption(option);
    },
    zhuan: function zhuan(data) {
      var arr = [];

      for (var k in data) {
        arr.push(data[k]);
      }

      return arr;
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  }
};