var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d2-layout-header-aside-group",
      class: { grayMode: _vm.grayActive },
      style: _vm.styleLayoutMainGroup
    },
    [
      _c(
        "div",
        {
          staticClass: "d2-layout-header-aside-content",
          class: {
            "d2-theme-container-transition": _vm.asideTransition,
            mobile: _vm.device == "mobile"
          },
          attrs: { flex: "dir:top" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d2-layout-header-aside ",
              class: {
                hideSidebar: _vm.device == "mobile" && _vm.asideCollapse
              },
              style: {
                backgroundColor: _vm.device == "mobile" ? "#ebf1f6" : ""
              }
            },
            [
              _c(
                "div",
                {
                  ref: "aside",
                  staticClass: "d2-theme-container-aside",
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.detailWidth + "px",
                    opacity: this.searchActive ? 0.5 : 1,
                    "margin-left": _vm.asideRight + "px"
                  },
                  attrs: { "flex-box": "0" }
                },
                [
                  _c(
                    "router-link",
                    {
                      class: {
                        "logo-group": true,
                        "logo-transition": _vm.asideTransition
                      },
                      style: {
                        width: _vm.asideCollapse
                          ? _vm.asideWidthCollapse
                          : _vm.asideWidth
                      },
                      attrs: { to: "/index", "flex-box": "0" }
                    },
                    [
                      !_vm.asideCollapse
                        ? _c("img", {
                            staticClass: "logoImg",
                            attrs: {
                              src: require("../../assets/logo.fbefbe23.png")
                            }
                          })
                        : _vm._e()
                    ]
                  ),
                  _c("d2-menu-side")
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d2-theme-container",
              style: {
                marginLeft:
                  _vm.device == "mobile"
                    ? "0px"
                    : _vm.asideCollapse
                    ? _vm.asideWidthCollapse
                    : _vm.detailWidth + "px"
              }
            },
            [
              _c("div", { staticClass: "x-bc d2-theme-header" }, [
                _c("div", { staticClass: "toggle-aside-btn" }, [
                  _c(
                    "div",
                    {
                      staticClass: "iconDiv",
                      on: { click: _vm.handleToggleAside }
                    },
                    [_c("d2-icon", { attrs: { name: "bars" } })],
                    1
                  ),
                  _vm.isOverview
                    ? _c(
                        "div",
                        { staticClass: "oText", on: { click: _vm.openOv } },
                        [_vm._v("总览")]
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "d2-header-right" }, [
                  _c(
                    "div",
                    { staticClass: "x-c" },
                    [
                      _c("d2-header-search", {
                        on: { click: _vm.handleSearchClick }
                      }),
                      _c("d2-header-theme"),
                      _c("d2-header-user")
                    ],
                    1
                  ),
                  _vm.rawData.game_name
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$store.dispatch(
                                "d2admin/system/gogameIndex"
                              )
                            }
                          }
                        },
                        [
                          !_vm.rawData.pkg_name
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "游戏:" + _vm.rawData.game_name,
                                    placement: "left"
                                  },
                                  on: { click: function($event) {} }
                                },
                                [
                                  _c("el-button", { staticClass: "darkB" }, [
                                    _vm._v(" " + _vm._s(_vm.rawData.game_name))
                                  ])
                                ],
                                1
                              )
                            : _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "left" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "line-height": "18px" },
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        "产品 : " + _vm._s(_vm.rawData.name)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "游戏 : " +
                                          _vm._s(_vm.rawData.game_name)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "平台 : " +
                                          _vm._s(_vm.rawData.platform_name)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "渠道 : " +
                                          _vm._s(_vm.rawData.channel_name)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "包名 : " + _vm._s(_vm.rawData.pkg_name)
                                      )
                                    ]
                                  ),
                                  _c("el-button", { staticClass: "darkB" }, [
                                    _vm._v(" " + _vm._s(_vm.rawData.game_name))
                                  ])
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "d2-theme-container-main",
                  attrs: { "flex-box": "1", flex: "" }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.asideCollapse,
                          expression: "!asideCollapse"
                        }
                      ],
                      staticClass: "line-drage",
                      attrs: { id: "resize" }
                    },
                    [_vm._m(0)]
                  ),
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    _vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "" }
                          },
                          [
                            _c("d2-panel-search", {
                              ref: "panelSearch",
                              on: { close: _vm.searchPanelClose }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("transition", [
                    !_vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "dir:top" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-header",
                                attrs: { "flex-box": "0" }
                              },
                              [_c("d2-tabs")],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-body",
                                attrs: { "flex-box": "1" }
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: _vm.transitionActive
                                        ? "fade-transverse"
                                        : ""
                                    }
                                  },
                                  [
                                    _c(
                                      "keep-alive",
                                      { attrs: { include: _vm.keepAlive } },
                                      [
                                        _c("router-view", {
                                          key: _vm.routerViewKey
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "point", attrs: { id: "point" } }, [
      _c("div", [_vm._v(".")]),
      _c("div", [_vm._v(".")]),
      _c("div", [_vm._v(".")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }