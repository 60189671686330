var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: {
                    change: function($event) {
                      return _vm.getList()
                    }
                  },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "角色ID" },
                model: {
                  value: _vm.paramsData.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "user_id", $$v)
                  },
                  expression: "paramsData.user_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "设备ID" },
                model: {
                  value: _vm.paramsData.device_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "device_id", $$v)
                  },
                  expression: "paramsData.device_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "刷新时长"
                  },
                  model: {
                    value: _vm.paramsData.refresh_interval,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "refresh_interval", $$v)
                    },
                    expression: "paramsData.refresh_interval"
                  }
                },
                _vm._l(_vm.refreshDuration, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "input", label: "账号所属人员" }
          }),
          _c("el-table-column", { attrs: { prop: "textarea", label: "备注" } }),
          _c("el-table-column", {
            attrs: { prop: "user_id", label: "角色ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "device_id", label: "设备ID", "min-width": "200" }
          }),
          _c("el-table-column", { attrs: { prop: "ip", label: "IP" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "等级名称" } }),
          _c("el-table-column", {
            attrs: { prop: "amount_limit", label: "额度(分)" }
          }),
          _c("el-table-column", {
            attrs: { prop: "refresh_interval", label: "刷新周期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.refresh_interval
                              ? _vm.refreshDuration.find(function(i) {
                                  return i.value == row.refresh_interval
                                }).label
                              : ""
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "residueAmount",
              label: "周期内剩余福利额度(分)",
              "min-width": "100"
            }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      }),
      _c("addassociationManagement", {
        ref: "addassociationManagement",
        attrs: {
          prod: { is_prod: _vm.paramsData.is_prod },
          serverList: _vm.serverLists
        },
        on: {
          update: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }