// 设备留存
import request from '@/utils/request';
export function deviceRetain(data) {
  return request({
    url: '/data/deviceretain',
    method: 'get',
    params: data
  });
} // 激活设备留存

export function activateDeviceretain(data) {
  return request({
    url: '/data/activateDeviceretain',
    method: 'get',
    params: data
  });
} // 激活付费设备留存

export function activateRechargeDeviceretain(data) {
  return request({
    url: '/data/activateRechargeDeviceretain',
    method: 'get',
    params: data
  });
}