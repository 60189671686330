var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "privayyy warrp" }, [
    _c("div", { staticClass: "header" }),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "priva-cont" }, [
        _c(
          "div",
          { staticClass: "icon-img" },
          [
            _c("el-image", {
              staticStyle: { width: "100px", height: "53px" },
              attrs: { src: _vm.url }
            })
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "line" }),
        _vm._m(1),
        _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "sdk-tab" }, [
            _c(
              "div",
              { staticClass: "selete-menu" },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-menu",
                          {
                            staticClass: "el-menu-vertical-demo",
                            attrs: {
                              "default-active": "2-1",
                              "unique-opened": ""
                            },
                            on: { open: _vm.handleOpen, close: _vm.handleClose }
                          },
                          [
                            _c(
                              "el-submenu",
                              { attrs: { index: "1" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("A-B")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "1-1" } },
                                      [_vm._v("暗夜花园")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "1-2" } },
                                      [_vm._v("宝宝花园")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "2" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("C-D")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "2-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "2-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "3" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("E-F")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "3-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "3-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "4" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("G-H")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "4-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "4-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "5" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("I-J")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "5-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "5-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "6" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("K-L")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "6-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "6-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "7" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("M-N")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "7-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "7-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "8" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("W-X")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "8-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "8-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "9" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("Y-Z")])
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "9-1" } },
                                      [_vm._v("超级台球大师")]
                                    ),
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: "9-2" } },
                                      [_vm._v("刀剑2")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._m(2)
          ])
        ])
      ]),
      _vm._m(3)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h2", [_vm._v("第三方共享清单")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clause" }, [
      _c("p", [
        _vm._v(
          "为保障腾讯游戏的稳定运行，和实现产品内支付、设备厂商消息推送等相关功能，我们需要在您使用对应功能时向第三方共享您的个人信息。部分功能通过接入由第三方提供的软件工具开发包（SDK）的形式实现。如果您未使用相关功能，我们不会将您的个人信息与第三方共享。"
        )
      ]),
      _c("p", [
        _vm._v(
          "等信息展示如下。您可以根据游戏名称在目录中查找并查看对应游戏产品的《第三方信息共享清单》和《第三方SDK目录》。您还可以通过第三方网站链接查看第三方收集使用个人信息的规则，具体请以其公示的官方说明为准。"
        )
      ]),
      _c("p", [
        _vm._v(
          "我们将督促相关第三方按照我们在《腾讯游戏隐私保护指引》中与您的约定收集和使用您的个人信息，并采取适当的安全技术和管理措施保障您的个人信息安全。我们也会对接入的第三方软件工具开发包（SDK）进行严格的安全监测，以保护您的个人信息安全。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail-container" }, [
      _c("div", { staticClass: "game-sdk" }, [
        _c("div", { staticClass: "sdk-detail" }, [
          _c("span", { staticClass: "sdk-title" }, [
            _vm._v("《超级台球大师第三方SDK目录》")
          ]),
          _c("br"),
          _c("span", { staticClass: "item-block" }, [
            _vm._v("1、SDK名称：微信支付")
          ]),
          _c("br"),
          _c("span", { staticClass: "item-detail" }, [
            _vm._v(" 功能类型：微信支付 "),
            _c("br"),
            _vm._v(" 第三方主体：财付通支付科技有限公司 "),
            _c("br"),
            _vm._v(" 处理方式：采取去标识、加密 方式进行传输和处理 "),
            _c("br"),
            _vm._v(" 涉及个人信息类型：用户标识信息、网络IP地址、订单金额 "),
            _c("br"),
            _vm._v(" 功能类型：微信支付 "),
            _c("br"),
            _vm._v(
              " 第三方隐私政策或官网链接：https://www.tenpay.com/v3/helpcenter/low/privacy.shtml "
            ),
            _c("br")
          ]),
          _c("span", { staticClass: "item-block" }, [
            _vm._v("2、SDK名称：QQ钱包")
          ]),
          _c("br"),
          _c("span", { staticClass: "item-detail" }, [
            _vm._v(" 功能类型：QQ钱包 "),
            _c("br"),
            _vm._v(" 第三方主体：财付通支付科技有限公司 "),
            _c("br"),
            _vm._v(" 使用目的：帮助用户使用QQ钱包支付服务 "),
            _c("br"),
            _vm._v(" 处理方式：采取去标识、加密 方式进行传输和处理 "),
            _c("br"),
            _vm._v(" 涉及个人信息类型：用户标识信息、网络IP地址、订单金额 "),
            _c("br"),
            _vm._v(
              " 第三方隐私政策或官网链接：https://www.tenpay.com/v3/helpcenter/low/privacy.shtml "
            ),
            _c("br")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footer-item" }, [
        _c("div", { staticClass: "algin" }, [
          _c("p", [
            _vm._v(
              "互动娱乐 | 服务条款 | 隐私保护指引 | 儿童隐私保护指引 | 游戏招聘 | 游戏客服 | 游戏列表 | 成长守护平台 | 广告服务及商务合作"
            )
          ]),
          _c("p", [
            _vm._v("COPYRIGHT © 1998 – 2021 TENCENT. ALL RIGHTS RESERVED.")
          ]),
          _c("p", [_vm._v("布克公司 版权所有")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }