import request from '@/utils/request';
export function GameList(data) {
  return request({
    url: '/game/list',
    method: 'get',
    params: data
  });
}
export function disableGame(data) {
  return request({
    url: '/game/disableGame',
    method: 'get',
    params: data
  });
}
export function delGame(data) {
  return request({
    url: '/game/delGame',
    method: 'get',
    params: data
  });
}
export function queryGame(data) {
  return request({
    url: '/game/upGame',
    method: 'get',
    params: data
  });
}
export function upGame(data) {
  return request({
    url: '/game/upGame',
    method: 'post',
    params: data
  });
}
export function addGame(data) {
  return request({
    url: '/game/add',
    method: 'post',
    params: data
  });
}