// 获取新手引导对应
import request from '@/utils/request';
export function getNewGuideList(data) {
  return request({
    url: '/data/newbie_guide_transition',
    method: 'get',
    params: data
  });
} // 新增新手引导对应

export function addtNewGuideList(data) {
  return request({
    url: '/data/add_newbie_guide_transition',
    method: 'post',
    params: data
  });
} // 编辑新手引导对应

export function updatatNewGuideList(data) {
  return request({
    url: '/data/update_newbie_guide_transition',
    method: 'post',
    params: data
  });
} // 反显新手引导对应

export function returntNewGuideList(data) {
  return request({
    url: '/data/update_newbie_guide_transition',
    method: 'get',
    params: data
  });
} // 删除新手引导对应

export function deteltNewGuideList(data) {
  return request({
    url: '/data/del_newbie_guide_transition',
    method: 'get',
    params: data
  });
}