import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { accplay } from "@/api/dataAll/new";
export default {
  name: "accPlay",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        play: ""
      },
      tableData: [],
      Dynamic: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.guankaArr = [];

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      accplay(this.searchData).then(function (res) {
        if (res.status == 200) {
          var objToTitle = function objToTitle(obj) {
            return Object.keys(obj).map(function (k) {
              return obj[k];
            });
          };

          var objToKey = function objToKey(obj) {
            return Object.keys(obj).map(function (k) {
              return k;
            });
          };

          var pageData = res.data;
          var columns = [{
            label: '玩法',
            prop: 'start'
          }];
          var data = [];
          var complete = {
            start: ''
          };
          var number = {
            start: '玩法参加总人数'
          };
          var listNum = 0;
          pageData.map(function (item, index) {
            var k = objToKey(item)[0];
            columns.push.apply(columns, [{
              label: k,
              prop: index + 'date'
            }, {
              label: '',
              prop: index + 'complete'
            }]);
            number[index + 'date'] = +item[k].sum_num;
            complete[index + 'date'] = '新玩家参与率';
            complete[index + 'complete'] = '老玩家参与率';
            var IN = -1;

            for (var i in item[k].type) {
              data.push({});
              IN += 1;
              listNum = IN;
              var taskData = {
                start: i
              };
              taskData[index + 'date'] = item[k].type[i].new_ratio || '0%';
              taskData[index + 'complete'] = item[k].type[i].old_ratio || '0%';
              data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
            }
          });
          data.unshift(complete);
          data.unshift(number);
          data.splice(listNum + 3, data.length - listNum + 3);
          _this.tableData = data;
          _this.Dynamic = columns;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      this.$export.excel({
        columns: this.Dynamic,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};