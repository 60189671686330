import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { getAdvMsg } from "@/api/dataAll/advMessReturn";
export default {
  name: "advMessReturn",
  mixins: [serchSelect],
  data: function data() {
    return {
      more: true,
      total: 0,
      tableTitle: [],
      value1: "",
      value2: [],
      tableData: [],
      tableLtv: [],
      tableDate: "",
      show: false,
      searchData: {
        page: 1
      }
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      getAdvMsg(this.searchData).then(function (res) {
        if (res.status == 200) {
          var data = res.data;
          _this.total = data.total - 0 || 0;
          _this.tableData = data.data || [];
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.searchClick();
    }
  }
};