import "core-js/modules/es.array.filter";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Message } from "element-ui";
import { searchgame, searchplatform, searchchannel, searchpkg } from "@/api/dataAll/dataAll";
import { SelectType, adarelationPayType } from "@/api/aymentsdk/aymentsdk";
import { addLginConfig } from "@/api/loginConfig/addLogin";
export default {
  data: function data() {
    return {
      searchData: {
        game: "",
        platform: "",
        channel: "",
        pkg: ""
      },
      isShow: false,
      searchgames: [],
      searchplatforms: [],
      searchchannels: [],
      searchpkgs: [],
      advchannels: [],
      pay_types: [],
      type: '',
      rules: {
        game: [{
          required: true,
          message: '请选择游戏',
          trigger: 'blur'
        }],
        platform: [{
          required: true,
          message: '请选择平台',
          trigger: 'blur'
        }],
        channel: [{
          required: true,
          message: '请选择渠道',
          trigger: 'blur'
        }],
        pkg: [{
          required: true,
          message: '请选择包名',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    searchgame().then(function (res) {
      _this.searchgames = res.data;
    });
  },
  methods: {
    gamechange: function gamechange(game_mark) {
      var _this2 = this;

      this.searchData.platform = '';
      this.searchData.channel = '';
      this.searchData.pkg = '';
      this.searchplatforms = [];
      this.searchchannels = [];
      this.searchpkgs = [];

      if (game_mark != "") {
        var id = this.searchgames.filter(function (item) {
          return item.game_mark == game_mark;
        })[0].id;
        searchplatform({
          game_id: id
        }).then(function (res) {
          _this2.searchplatforms = res.data;
        });
      }
    },
    platformchange: function platformchange(platform_mark) {
      var _this3 = this;

      this.searchData.channel = '';
      this.searchData.pkg = '';
      this.searchchannels = [];
      this.searchpkgs = [];

      if (platform_mark != "") {
        var id = this.searchplatforms.filter(function (item) {
          return item.platform_mark == platform_mark;
        })[0].id;
        searchchannel({
          platform_id: id
        }).then(function (res) {
          _this3.searchchannels = res.data.channel;
        });
      }
    },
    channelchange: function channelchange(channel_mark) {
      var _this4 = this;

      this.searchData.pkg = '';
      this.searchpkgs = [];

      if (channel_mark != "") {
        var id = this.searchchannels.filter(function (item) {
          return item.channel_mark == channel_mark;
        })[0].id;
        searchpkg({
          channel_id: id
        }).then(function (res) {
          _this4.searchpkgs = res.data;
        });
      }
    },
    resetForm: function resetForm() {
      this.isShow = false;
      this.formName = "";
      this.clearData();
    },
    init: function init(data, type) {
      this.type = type;
      this.isShow = true;
    },
    // 重置
    clearData: function clearData() {
      this.searchData = {
        game: "",
        platform: "",
        channel: "",
        pkg: ""
      };
      this.searchplatforms = [];
      this.searchchannels = [];
      this.searchpkgs = [];
      this.$refs.ruleForm.resetFields();
    },
    dataFormSubmit: function dataFormSubmit(formName) {
      var _this5 = this;

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          if (_this5.type == "1") {
            addLginConfig(_this5.searchData).then(function (res) {
              if (res.status == 200) {
                Message.success(res.message);

                _this5.resetForm("ruleForm");

                _this5.searchData = {};
              }
            });
          }
        }
      });
    }
  }
};