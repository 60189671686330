import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { jlPostBackData, jlPostBackUpdate } from "@/api/financial/consumptionDetails";
import { tabulation } from "@/mixins/index";
export default {
  name: "backhaulConfigurationJL",
  mixins: [tabulation],
  data: function data() {
    return {
      activeIndex: '0',
      formData: {
        ecpm: "",
        except_ecpm: "",
        times: "",
        window: ""
      },
      PrimitiveData: {},
      reqLoading: false,
      reqLoadingC: false,
      issubstitution: false
    };
  },
  created: function created() {},
  methods: {
    getData: function getData() {
      var _this = this;

      if (!this.searchData.special_product_id.toString()) {
        this.$message.error('请选择产品');
        return;
      }

      this.reqLoading = true;
      jlPostBackData(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this.PrimitiveData = JSON.parse(JSON.stringify(res.data));
          _this.formData = _objectSpread({}, res.data);
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    submitData: function submitData() {
      var _this2 = this;

      if (!this.searchData.special_product_id.toString()) {
        this.$message.error('请选择产品');
        return;
      }

      this.reqLoadingC = true;
      this.searchData.game = '';
      this.searchData.platform = '';
      this.searchData.channel = '';
      this.searchData.pkg = '';
      jlPostBackUpdate(_objectSpread(_objectSpread({}, this.searchData), this.formData)).then(function (res) {
        if (res.status == 200) {
          _this2.PrimitiveData = JSON.parse(JSON.stringify(_this2.formData));

          _this2.$message.success('修改成功');
        }
      }).finally(function () {
        _this2.reqLoadingC = false;
      });
    },
    handleSelect: function handleSelect(e) {
      this.formData = _objectSpread({}, this.PrimitiveData);
      this.activeIndex = e;
    }
  }
};