var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        "value-key": item.id,
                        label: item.game_name,
                        value: item.id
                      }
                    },
                    [_vm._v(_vm._s(item.game_name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择渠道"
                  },
                  on: { change: _vm.channelchange },
                  model: {
                    value: _vm.searchData.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "channel", $$v)
                    },
                    expression: "searchData.channel"
                  }
                },
                _vm._l(_vm.searchchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  on: { change: _vm.pkgchange },
                  model: {
                    value: _vm.searchData.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pkg", $$v)
                    },
                    expression: "searchData.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  size: "medium",
                  placeholder: "请输入sdk用户id",
                  clearable: ""
                },
                model: {
                  value: _vm.searchData.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "user_id", $$v)
                  },
                  expression: "searchData.user_id"
                }
              })
            ],
            1
          ),
          _vm.advanced
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "请输入网关用户id",
                      clearable: ""
                    },
                    model: {
                      value: _vm.searchData.account_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "account_id", $$v)
                      },
                      expression: "searchData.account_id"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.advanced
            ? _c(
                "el-col",
                { staticClass: "operationBut", attrs: { span: 4 } },
                [
                  _c("unpack", {
                    attrs: { advanced: _vm.advanced },
                    on: {
                      advancedChange: function($event) {
                        _vm.advanced = $event
                      },
                      searchChange: function($event) {
                        return _vm.searchClick()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.advanced
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": _vm.pickerOptionsRadius,
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      align: "right",
                      "range-separator": "至",
                      "start-placeholder": "创建开始日期",
                      "end-placeholder": "创建结束日期"
                    },
                    model: {
                      value: _vm.value2,
                      callback: function($$v) {
                        _vm.value2 = $$v
                      },
                      expression: "value2"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": _vm.pickerOptionsDay,
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      type: "date",
                      align: "right",
                      placeholder: "最后登录时间"
                    },
                    model: {
                      value: _vm.value3,
                      callback: function($$v) {
                        _vm.value3 = $$v
                      },
                      expression: "value3"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "operationBut", attrs: { span: 4 } },
                [
                  _c("unpack", {
                    attrs: { advanced: _vm.advanced },
                    on: {
                      advancedChange: function($event) {
                        _vm.advanced = $event
                      },
                      searchChange: function($event) {
                        return _vm.searchClick()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        { attrs: { "max-height": "500", data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "game_reveal",
              label: "游戏",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "platform_reveal",
              label: "平台",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel_reveal",
              label: "渠道",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pkg_reveal",
              label: "包名",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_channel_reveal",
              label: "广告渠道",
              width: "150",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.ad_channel_reveal
                            ? scope.row.ad_channel_reveal
                            : "defalut"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "ad_placement_reveal", label: "广告位置" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.ad_placement_reveal
                            ? scope.row.ad_placement_reveal
                            : "defalut"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "account_id", label: "网关ID" }
          }),
          _c("el-table-column", { attrs: { prop: "user_id", label: "sdkID" } }),
          _c("el-table-column", {
            attrs: { prop: "conversion_ratio", label: "最后登录时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "exParam" }, [
                      _vm._v(
                        _vm._s(_vm.timestampToTime(scope.row.last_login_time))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "conversion_ratio", label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "ex Param" }, [
                      _vm._v(_vm._s(_vm.timestampToTime(scope.row.create_time)))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": 1,
          total: _vm.total,
          "page-size": 10
        },
        on: { "current-change": _vm.handleCurrentChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }