var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.searchData.id ? "修改" : "新增",
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                rules: _vm.rules,
                model: _vm.searchData,
                "validate-on-rule-change": "",
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "产品", prop: "special_product_id" }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择产品"
                      },
                      on: { change: _vm.productChange },
                      model: {
                        value: _vm.searchData.special_product_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "special_product_id", $$v)
                        },
                        expression: "searchData.special_product_id"
                      }
                    },
                    _vm._l(_vm.productAll, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "ad_channel", label: "广告渠道" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择广告渠道"
                      },
                      on: { change: _vm.advChannelchange },
                      model: {
                        value: _vm.searchData.ad_channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_channel", $$v)
                        },
                        expression: "searchData.ad_channel"
                      }
                    },
                    _vm._l(_vm.advchannels, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.adv_platform_name,
                          value: item.adv_platform_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "ad_placement", label: "广告位置" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择广告位置"
                      },
                      model: {
                        value: _vm.searchData.ad_placement,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "ad_placement", $$v)
                        },
                        expression: "searchData.ad_placement"
                      }
                    },
                    _vm._l(_vm.advLocation, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.adv_place_name,
                          value: item.adv_place_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "origin", label: "广告标示" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { placeholder: "请选择广告" },
                      model: {
                        value: _vm.searchData.origin,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "origin", $$v)
                        },
                        expression: "searchData.origin"
                      }
                    },
                    _vm._l(_vm.origin, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.mark }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.addDomain }
            },
            [_vm._v("添加配置")]
          ),
          _c(
            "div",
            { staticClass: "popover" },
            _vm._l(_vm.confValue, function(domain, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "lodingItem",
                  staticStyle: { display: "flex" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "lodingItem-operationFrame" },
                    [
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          circle: "",
                          icon: "el-icon-delete"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeDomain(domain)
                          }
                        }
                      }),
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { placeholder: "广告配置名" },
                        model: {
                          value: domain.name,
                          callback: function($$v) {
                            _vm.$set(domain, "name", $$v)
                          },
                          expression: "domain.name"
                        }
                      }),
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { placeholder: "广告配置值" },
                        model: {
                          value: domain.value,
                          callback: function($$v) {
                            _vm.$set(domain, "value", $$v)
                          },
                          expression: "domain.value"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }