//
//
//
//
//
//
//
//
//
import bottomRightChart from "./chart/bottom/bottomRightChart";
export default {
  props: {
    onlineNum: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      height: 0
    };
  },
  components: {
    bottomRightChart: bottomRightChart
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      setTimeout(function () {
        var rankingBoard = document.getElementById('onlineChart');
        _this.height = rankingBoard.clientHeight; // console.log(this.height,'onlineChart')
      }, 100);
    });
  },
  mounted: function mounted() {},
  methods: {}
};