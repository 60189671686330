import "core-js/modules/es.object.to-string";
import { GameList as _GameList, disableGame as _disableGame, delGame as _delGame, upGame as _upGame, addGame as _addGame, queryGame as _queryGame } from "@/api/game";
var state = {};
var mutations = {};
var actions = {
  GameList: function GameList(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _GameList(data).then(function (response) {
        var data = response.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  disableGame: function disableGame(_ref2, data) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _disableGame(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  delGame: function delGame(_ref3, data) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _delGame(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  queryGame: function queryGame(_ref4, data) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      _queryGame(data).then(function (response) {
        var data = response.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  addGame: function addGame(_ref5, data) {
    var commit = _ref5.commit;
    return new Promise(function (resolve, reject) {
      _addGame(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  upGame: function upGame(_ref6, data) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      _upGame(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};