var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.searchData.id ? "修改" : "新增",
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                model: _vm.searchData,
                rules: _vm.rules,
                "label-width": "90px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "产品", prop: "special_product_id" }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择产品"
                      },
                      on: { change: _vm.productChange },
                      model: {
                        value: _vm.searchData.special_product_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "special_product_id", $$v)
                        },
                        expression: "searchData.special_product_id"
                      }
                    },
                    _vm._l(_vm.productAll, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "投放渠道", prop: "op_ad_channel" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.searchData.op_ad_channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "op_ad_channel", $$v)
                        },
                        expression: "searchData.op_ad_channel"
                      }
                    },
                    _vm._l(_vm.opAdChannel, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.addDomain }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              ),
              _vm._l(_vm.ex_Val, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "lodingItem",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-button", {
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain(domain)
                            }
                          }
                        }),
                        _c(
                          "el-select",
                          {
                            staticStyle: { margin: "0 10px" },
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "广告渠道"
                            },
                            on: {
                              change: function($event) {
                                return _vm.advChannelchange($event, index)
                              }
                            },
                            model: {
                              value: domain.ad_channel,
                              callback: function($$v) {
                                _vm.$set(domain, "ad_channel", $$v)
                              },
                              expression: "domain.ad_channel"
                            }
                          },
                          _vm._l(_vm.ex_ValAdv[index].advchannels, function(
                            item
                          ) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.adv_platform_name,
                                value: item.adv_platform_mark
                              }
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "广告位置"
                            },
                            model: {
                              value: domain.ad_placement,
                              callback: function($$v) {
                                _vm.$set(domain, "ad_placement", $$v)
                              },
                              expression: "domain.ad_placement"
                            }
                          },
                          _vm._l(_vm.ex_ValAdv[index].advLocation, function(
                            item
                          ) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.adv_place_name,
                                value: item.adv_place_mark
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }