var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogShow,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popover" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "popover",
                  attrs: { "label-position": "left", "label-width": "80px" }
                },
                _vm._l(_vm.list, function(domain, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { width: "100%" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "lodingItem",
                              staticStyle: { "margin-right": "20px" },
                              attrs: {
                                label: "服务器(可多选)",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "operationFrame-entity",
                                  attrs: {
                                    filterable: "",
                                    disabled: "",
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: domain.server_id,
                                    callback: function($$v) {
                                      _vm.$set(domain, "server_id", $$v)
                                    },
                                    expression: "domain.server_id"
                                  }
                                },
                                _vm._l(_vm.serverList, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.serverID,
                                      attrs: {
                                        label: item.serverName,
                                        value: item.serverID
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "serverOption" },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(item.serverName))
                                          ]),
                                          _c(
                                            "div",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.is_recomm == 1
                                                    ? "推荐"
                                                    : ""
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "role_id",
                              staticClass: "lodingItem",
                              attrs: {
                                prop: "desc." + index + ".role_id",
                                label: "角色ID"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "operationFrame-entity",
                                attrs: { disabled: "", placeholder: "角色ID" },
                                model: {
                                  value: domain.role_id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "role_id", _vm._n($$v))
                                  },
                                  expression: "domain.role_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "itemBox" },
                        _vm._l(domain.item_list, function(domains, indexs) {
                          return _c(
                            "div",
                            { key: indexs, staticClass: "lodingItem" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0px" },
                                  attrs: {
                                    prop:
                                      "desc." +
                                      index +
                                      ".item_list." +
                                      indexs +
                                      ".item_id"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        filterable: "",
                                        disabled: "",
                                        placeholder: "物品"
                                      },
                                      model: {
                                        value: domains.item_id,
                                        callback: function($$v) {
                                          _vm.$set(domains, "item_id", $$v)
                                        },
                                        expression: "domains.item_id"
                                      }
                                    },
                                    _vm._l(_vm.articleList, function(
                                      haidaoitem,
                                      haidaoindex
                                    ) {
                                      return _c("el-option", {
                                        key: haidaoindex,
                                        attrs: {
                                          label: haidaoitem.kind,
                                          value: haidaoitem.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0px" },
                                  attrs: {
                                    prop:
                                      "desc." +
                                      index +
                                      ".item_list." +
                                      indexs +
                                      ".num"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      disabled: "",
                                      placeholder: "数量"
                                    },
                                    model: {
                                      value: domains.num,
                                      callback: function($$v) {
                                        _vm.$set(domains, "num", _vm._n($$v))
                                      },
                                      expression: "domains.num"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }