import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addReplenishment } from "@/api/kamitaku/kamitaku";
import { serchSelect } from "@/mixins/index";
import pickerOption from '@/mixins/module/pickerOption';
export default {
  mixins: [pickerOption, serchSelect],
  data: function data() {
    return {
      searchData: {
        special_product_id: "",
        server_id: '',
        device_id: '',
        user_id: '',
        role_id: '',
        order_id: '',
        level: '',
        amount: '',
        currency: '',
        product_id: '',
        product_name: '',
        ip: '',
        timestamp: ''
      },
      dialogShow: false,
      searchplatforms: [],
      searchchannels: [],
      searchpkgs: [],
      rules: {
        special_product_id: [{
          required: true,
          message: "请选择产品",
          trigger: "blur"
        }],
        server_id: [{
          required: true,
          message: "请输入服务器ID",
          trigger: "blur"
        }],
        device_id: [{
          required: true,
          message: "请输入设备ID",
          trigger: "blur"
        }],
        user_id: [{
          required: true,
          message: "请输入账号ID",
          trigger: "blur"
        }],
        role_id: [{
          required: true,
          message: "请输入角色ID",
          trigger: "blur"
        }],
        order_id: [{
          required: true,
          message: "请输入订单号",
          trigger: "blur"
        }],
        // level:[{ required: true, message: "请输入等级", trigger: "blur" }],
        // currency:[{ required: true, message: "请输入货币类型", trigger: "blur" }],
        amount: [{
          required: true,
          message: "请输入金额(分)",
          trigger: "blur"
        }],
        product_id: [{
          required: true,
          message: "请输入商品ID",
          trigger: "blur"
        }],
        product_name: [{
          required: true,
          message: "请输入商品名称",
          trigger: "blur"
        }],
        ip: [{
          required: true,
          message: "请输入充值用户IP",
          trigger: "blur"
        }],
        timestamp: [{
          required: true,
          message: "请选择充值时间",
          trigger: "blur"
        }]
      },
      issubmit: false
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      this.dialogShow = true;
    },
    clearData: function clearData() {
      this.searchData = {
        special_product_id: this.rawData.game_show_type == 1 ? '' : this.searchData.special_product_id,
        server_id: '',
        device_id: '',
        user_id: '',
        role_id: '',
        order_id: '',
        level: '',
        amount: '',
        currency: '',
        product_id: '',
        product_name: '',
        ip: '',
        timestamp: ''
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.issubmit = true;
          var data = JSON.parse(JSON.stringify(_this.searchData));
          data.timestamp = data.timestamp / 1000;
          addReplenishment(data).then(function (res) {
            if (res.status == 200) {
              _this.$message({
                message: '操作成功',
                type: 'success'
              });

              _this.dialogShow = false;
            }
          }).finally(function () {
            _this.issubmit = false;
          });
        }
      });
    }
  }
};