import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { paySupplenment } from "@/api/gmtool/packageCode";
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  name: "addreplenishment",
  props: {
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialog: false,
      pramsData: {
        role_id: '',
        //玩家ID
        op_type: '',
        // 补单操作类型 'supplement_reward  补发奖励'  'supplement_product  补发订单'
        pay_channel: '',
        //渠道ID
        orderId: '',
        //订单编号
        pay_id: '',
        //充值ID
        reward: [] //  [{item_id:init //道具ID,num:init //数量,expire  //过期时间}]

      },
      supplement: [{
        label: '补发奖励',
        value: 'supplement_reward'
      }, {
        label: '补发订单',
        value: 'supplement_product'
      }],
      rules: {
        role_id: [{
          required: true,
          message: "请输入玩家ID",
          trigger: "blur"
        }],
        op_type: [{
          required: true,
          message: "请选择补单操作类型",
          trigger: "blur"
        }],
        pay_channel: [{
          required: true,
          message: "请输入渠道ID",
          trigger: "blur"
        }],
        orderId: [{
          required: true,
          message: "请输入订单编号",
          trigger: "blur"
        }],
        pay_id: [{
          required: true,
          message: "请输入充值ID",
          trigger: "blur"
        }],
        expire: [{
          required: true,
          message: "请选择日期",
          trigger: "blur"
        }],
        item_id: [{
          required: true,
          message: "请选择物品",
          trigger: "blur"
        }],
        item_num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    init: function init() {
      this.dialog = true;
    },
    resetForm: function resetForm() {
      this.dialog = false;
      this.clearData();
    },
    addDomain: function addDomain() {
      this.pramsData.reward.push({
        item_id: '',
        num: '',
        expire: ''
      });
    },
    removeDomain: function removeDomain(item) {
      var index = this.pramsData.reward.indexOf(item);

      if (index !== -1) {
        this.pramsData.reward.splice(index, 1);
      }
    },
    dataFormSubmit: function dataFormSubmit(formName) {
      var _this = this;

      if (this.pramsData.op_type == 'supplement_reward' && !this.pramsData.reward.length) {
        this.$message({
          message: '请添加物品',
          type: 'info'
        });
        return;
      }

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this.pramsData));

          if (data.op_type == 'supplement_reward') {
            data.reward.forEach(function (i) {
              if (i.expire) {
                i.expire = Math.floor(i.expire / 1000);
              }
            });
          } else {
            data.reward = [];
          }

          paySupplenment(_objectSpread(_objectSpread({}, data), _this.prod)).then(function (res) {
            if (res.status == 200) {
              _this.resetForm();

              _this.$message({
                message: '操作成功',
                type: 'success'
              });

              _this.$emit('change');
            }
          });
        }
      });
    },
    clearData: function clearData() {
      this.pramsData = {
        role_id: '',
        //玩家ID
        op_type: '',
        // 补单操作类型 'supplement_reward  补发奖励'  'supplement_product  补发订单'
        pay_channel: '',
        //渠道ID
        orderId: '',
        //订单编号
        pay_id: '',
        //充值ID
        reward: [] //  [{item_id:init //道具ID,num:init //数量,expire  //过期时间}]

      };
      this.$refs.ruleForm.resetFields();
    }
  }
};