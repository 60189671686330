import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { payAccountRetain, payAccountRetain_currency } from "@/api/dataAll/payAccountRetain";
import * as echarts from "echarts";
export default {
  name: "payAccountRetain",
  mixins: [tabulation],
  data: function data() {
    return {
      value2: [],
      tableData: [],
      tableHeader: ["新增人数", "活跃人数", "总登录次数", "最高在线人数"],
      dates: [],
      datas: [],
      series: [],
      selectedArr: {},
      intReq: [],
      Dynamic: [],
      screen: [],
      PrimitiveDy: [],
      cellStylekeys: ["retain"]
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.kind = 1;
      this.reqLoading = true;
      this.timeInterval();
      var reqFn = this.currencyClose ? payAccountRetain_currency : payAccountRetain;
      reqFn(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this.tableData = [];
          _this.series = [];
          _this.dates = [];
          _this.datas = [];
          _this.Dynamic = [];
          var newData = res.data.data;

          for (var i in newData) {
            _this.dates.push(i);

            var data = {
              date: i,
              pnum: newData[i].pnum
            };
            var o = {
              name: i,
              type: "line",
              data: [],
              smooth: true
            };
            var arr = Object.keys(newData[i].retain);

            for (var k in newData[i].retain) {
              newData[i].retain[k] = _this.zhuan([newData[i].retain[k]])[0];
              data["retain" + k] = newData[i].retain[k];
              o.data.push(newData[i].retain[k]);

              if (_this.Dynamic.length < arr.length) {
                var a = k == 2 ? "次" : k;

                _this.Dynamic.push({
                  label: a + "留.RR" + k,
                  prop: "retain" + k,
                  tableText: k == 2 ? "RRA" + k : "RR" + k,
                  desc: k == 2 ? "次日账号留存率" : ""
                });
              }
            }

            if (!_this.screen.length) {
              _this.screen = _this.Dynamic.map(function (item) {
                return item.prop;
              });
              _this.PrimitiveDy = _this.Dynamic;
            }

            _this.screenControls();

            _this.series.push(o);

            _this.tableData.push(data);
          } // 总计数据处理


          var total = {
            date: "加权平均留存",
            pnum: res.data.totalSum
          };

          for (var _k in res.data.avgData) {
            total["retain" + _k] = _this.zhuan([res.data.avgData[_k]])[0];
          }

          _this.tableData.push(total);

          _this.$nextTick(function () {
            _this.getTableRight();
          });

          _this.getechart();
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    screenControls: function screenControls() {
      var _this2 = this;

      var list = [];
      this.Dynamic = [];
      this.PrimitiveDy.forEach(function (item) {
        _this2.screen.forEach(function (citem) {
          if (item.prop == citem) {
            list.push(item);
          }
        });
      });
      setTimeout(function () {
        _this2.Dynamic = list;
      }, 50);
    },
    zhuan: function zhuan(list) {
      var n = [];
      list.forEach(function (item) {
        n.push(item.length > 0 ? Number(item.substr(0, item.length - 1)) : 0);
      });
      return n;
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("payAccountRetainEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "付费账号留存率(%)"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          width: "80%" // selected: this.selectedArr,

        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.PrimitiveDy.map(function (item) {
            return item.label;
          })
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "日期 / Date",
        prop: "date"
      }, {
        label: "新增付费账号 / DNPU",
        prop: "pnum"
      }].concat(_toConsumableArray(this.Dynamic));
      var data = JSON.parse(JSON.stringify(this.tableData));
      this.Dynamic.forEach(function (item) {
        data.forEach(function (i) {
          i[item.prop] = i[item.prop] + "%";
        });
      });
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};