import request from '@/utils/request'; //GM工具
// 公告列表

export function announceList(data) {
  return request({
    url: 'hjhd-gm/announce-list',
    method: 'get',
    params: data
  });
} // 公告列表新

export function announceListNew(data) {
  return request({
    url: 'hjhd-gm/announce-list-new',
    method: 'get',
    params: data
  });
} // 新增公告

export function addAnnounce() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: 'hjhd-gm/add-announce',
    method: 'post',
    params: data
  });
} // 修改公告

export function upAnnounce(data) {
  return request({
    url: 'hjhd-gm/edit-announce',
    method: 'post',
    params: data
  });
} // 修改公告 新

export function upAnnounceNew(data) {
  return request({
    url: 'hjhd-gm/edit-announce-new',
    method: 'post',
    params: data
  });
} // 删除公告

export function delAnnounce(data) {
  return request({
    url: 'hjhd-gm/del-announce',
    method: 'post',
    params: data
  });
} // 删除公告 新

export function delAnnounceNew(data) {
  return request({
    url: 'hjhd-gm/del-announce-new',
    method: 'post',
    params: data
  });
} // 发送公告 

export function sendAnnounce() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: 'hjhd-gm/send-announce',
    method: 'post',
    params: data
  });
} // 礼包码列表

export function codeList(data) {
  return request({
    url: 'hjhd-gm/gift-code-list',
    method: 'get',
    params: data
  });
} // 物品列表

export function getitemList(data) {
  return request({
    url: 'data/item-list',
    method: 'get',
    params: data
  });
} // 生成礼包码

export function generateCode(data) {
  return request({
    url: 'hjhd-gm/generate-gift-code',
    method: 'post',
    params: data
  });
} // 删除礼包码

export function delgiftcode(data) {
  return request({
    url: 'hjhd-gm/delete-gift-code',
    method: 'post',
    params: data
  });
} // 邮件列表

export function mailList(data) {
  return request({
    url: 'hjhd-gm/mail-list',
    method: 'get',
    params: data
  });
} // 邮件列表 新

export function mailListNew(data) {
  return request({
    url: 'hjhd-gm/mail-list-new',
    method: 'get',
    params: data
  });
} // 保存邮件

export function sendMail(data) {
  return request({
    url: 'hjhd-gm/send-email',
    method: 'post',
    params: data
  });
} // 修改邮件

export function editMail(data) {
  return request({
    url: 'hjhd-gm/edit-email',
    method: 'post',
    params: data
  });
} // 发送邮件

export function sendMailnew(data) {
  return request({
    url: 'hjhd-gm/send-emails-new',
    method: 'post',
    params: data
  });
} // 删除邮件

export function delEmailsNew(data) {
  return request({
    url: 'hjhd-gm/del-emails-new',
    method: 'post',
    params: data
  });
} // 礼包批次列表

export function giftBatchList(data) {
  return request({
    url: 'hjhd-gm/gift-batch-list',
    method: 'get',
    params: data
  });
} // 删除礼包批次

export function deleteGiftCode(data) {
  return request({
    url: 'hjhd-gm/delete-gift-code',
    method: 'post',
    params: data
  });
} // 充值手动补单列表

export function paySupplementList(data) {
  return request({
    url: 'hjhd-gm/pay_supplement_list',
    method: 'get',
    params: data
  });
} // 充值手动补单

export function paySupplenment(data) {
  return request({
    url: 'hjhd-gm/pay_supplement',
    method: 'post',
    params: data
  });
} // 添加服务器 

export function addServer(data) {
  return request({
    url: 'hjhd-gm/add-server',
    method: 'post',
    params: data
  });
} // 服务器列表

export function serverList(data) {
  return request({
    url: 'hjhd-gm/server-list',
    method: 'get',
    params: data
  });
} // 修改服务器

export function updateServerData(data) {
  return request({
    url: 'hjhd-gm/updateServerData',
    method: 'post',
    params: data
  });
} // 删除服务器

export function deleteServer(data) {
  return request({
    url: 'hjhd-gm/delete-server',
    method: 'post',
    params: data
  });
} // 服务器修改日志

export function updateServerLogData(data) {
  return request({
    url: 'hjhd-gm/updateServerLogData',
    method: 'get',
    params: data
  });
} // 封号列表

export function banedUserList(data) {
  return request({
    url: 'hjhd-gm/baned-user-list',
    method: 'get',
    params: data
  });
} // 封号操作

export function banUser(data) {
  return request({
    url: 'hjhd-gm/ban-user',
    method: 'post',
    params: data
  });
} // 封禁日志

export function banLog(data) {
  return request({
    url: 'hjhd-gm/ban-log',
    method: 'get',
    params: data
  });
} // 物品回收列表

export function recycleList(data) {
  return request({
    url: 'hjhd-gm/recycle-item-list',
    method: 'get',
    params: data
  });
} // 提交物品回收草稿

export function recycleItem(data) {
  return request({
    url: 'hjhd-gm/recycle-item',
    method: 'post',
    params: data
  });
} // 回收

export function dorecycleItem(data) {
  return request({
    url: 'hjhd-gm/do-recycle-item',
    method: 'post',
    params: data
  });
} // 查询用户

export function userDetail(data) {
  return request({
    url: 'hjhd-gm/user-detail',
    method: 'get',
    params: data
  });
} // 邮箱日志

export function userEmail(data) {
  return request({
    url: 'hjhd-gm/user-email',
    method: 'get',
    params: data
  });
} // 上传邮件

export function uploadFileEmail(data) {
  return request({
    url: 'hjhd-gm/upload-file-email',
    method: 'post',
    params: data
  });
} // 用户反馈

export function userFeedbackList(data) {
  return request({
    url: 'hjhd-gm/user-feedback-list',
    method: 'get',
    params: data
  });
} // 反馈回复

export function replyFeedback(data) {
  return request({
    url: 'hjhd-gm/reply-feedback',
    method: 'post',
    params: data
  });
}
/**
 * 版本说明
 */
// 列表

export function versionList(data) {
  return request({
    url: 'hjhd-gm/list-version',
    method: 'get',
    params: data
  });
} // 新增

export function addVersion(data) {
  return request({
    url: 'hjhd-gm/add-version',
    method: 'post',
    params: data
  });
} // 修改

export function updVersion(data) {
  return request({
    url: 'hjhd-gm/edit-version',
    method: 'post',
    params: data
  });
} // 删除

export function delVersion(data) {
  return request({
    url: 'hjhd-gm/del-version',
    method: 'post',
    params: data
  });
} // 发送

export function sendVersion(data) {
  return request({
    url: 'hjhd-gm/send-version',
    method: 'post',
    params: data
  });
}
/**
 * 日志
 */
// 道具回收日志

export function userInfoOperationProp(data) {
  return request({
    url: 'datum/userInfoOperationProp',
    method: 'get',
    params: data
  });
} // 补单日志

export function userInfoOperationSupplement(data) {
  return request({
    url: 'datum/userInfoOperationSupplement',
    method: 'get',
    params: data
  });
} // 封禁记录

export function userBanLog(data) {
  return request({
    url: 'hjhd-gm/userBanLog',
    method: 'get',
    params: data
  });
}
/**
 * 背包物品
 */
// 背包列表

export function bagItemList(data) {
  return request({
    url: 'hjhd-gm/bagItemList',
    method: 'get',
    params: data
  });
} // 背包类型列表

export function bagItemTypeList(data) {
  return request({
    url: 'hjhd-gm/bagItemTypeList',
    method: 'get',
    params: data
  });
}
/**
 * 福利账号白名单
 */
// 白名单列表(分页)

export function gmWelfareWhiteList(data) {
  return request({
    url: 'hjhd-gm/gmWelfareWhiteList',
    method: 'get',
    params: data
  });
} // 白名单列表(全部)

export function gmWelfareWhiteLists(data) {
  return request({
    url: 'hjhd-gm/gmWelfareWhiteLists',
    method: 'get',
    params: data
  });
} // 白名单新增 

export function gmWelfareWhiteAdd(data) {
  return request({
    url: 'hjhd-gm/gmWelfareWhiteAdd',
    method: 'post',
    params: data
  });
} // 白名单修改 

export function gmWelfareWhiteUpd(data) {
  return request({
    url: 'hjhd-gm/gmWelfareWhiteUpd',
    method: 'post',
    params: data
  });
} // 白名单删除  

export function gmWelfareWhiteDel(data) {
  return request({
    url: 'hjhd-gm/gmWelfareWhiteDel',
    method: 'post',
    params: data
  });
}
/**
 * 福利等级列表
 */
// 福利等级列表(分页)

export function gmWelfareLevelList(data) {
  return request({
    url: 'hjhd-gm/gmWelfareLevelList',
    method: 'get',
    params: data
  });
} // 福利等级列表(全部)

export function gmWelfareLevelLists(data) {
  return request({
    url: 'hjhd-gm/gmWelfareLevelLists',
    method: 'get',
    params: data
  });
} // 福利等级新增 

export function gmWelfareLevelAdd(data) {
  return request({
    url: 'hjhd-gm/gmWelfareLevelAdd',
    method: 'post',
    params: data
  });
} // 福利等级修改 

export function gmWelfareLevelUpd(data) {
  return request({
    url: 'hjhd-gm/gmWelfareLevelUpd',
    method: 'post',
    params: data
  });
} // 福利等级删除  

export function gmWelfareLevelDel(data) {
  return request({
    url: 'hjhd-gm/gmWelfareLevelDel',
    method: 'post',
    params: data
  });
}
/**
 * 关联列表
 */
// 福利等级列表

export function receiveList(data) {
  return request({
    url: 'hjhd-gm/associationList',
    method: 'get',
    params: data
  });
} // 添加关联

export function addReceive(data) {
  return request({
    url: 'hjhd-gm/addAssociation',
    method: 'post',
    params: data
  });
} // 解除关联 

export function delReceive(data) {
  return request({
    url: 'hjhd-gm/delAssociation',
    method: 'post',
    params: data
  });
}
/**
 * 福利领取操作记录
 */
// 操作记录列表 

export function welfareReceiveList(data) {
  return request({
    url: 'hjhd-gm/welfareReceiveList',
    method: 'get',
    params: data
  });
} // 发放福利 

export function welfareCollection(data) {
  return request({
    url: 'hjhd-gm/interiorWelfareCollection',
    method: 'post',
    params: data
  });
}
/**
 * 审核服管理
 */
// 列表

export function examineServerList(data) {
  return request({
    url: 'hjhd-gm/examineServerList',
    method: 'get',
    params: data
  });
} // 新增

export function examineServerAdd(data) {
  return request({
    url: 'hjhd-gm/examineServerAdd',
    method: 'post',
    params: data
  });
} // 修改

export function examineServerUpd(data) {
  return request({
    url: 'hjhd-gm/examineServerUpd',
    method: 'post',
    params: data
  });
} // 删除 

export function examineServerDel(data) {
  return request({
    url: 'hjhd-gm/examineServerDel',
    method: 'post',
    params: data
  });
} // 查找 

export function examineServerSelect(data) {
  return request({
    url: 'hjhd-gm/examineServerSelect',
    method: 'post',
    params: data
  });
}
/**
 * 审核服包管理
 */
// 列表

export function examinePkgList(data) {
  return request({
    url: 'hjhd-gm/examinePkgList',
    method: 'get',
    params: data
  });
} // 新增

export function examinePkgAdd(data) {
  return request({
    url: 'hjhd-gm/examinePkgAdd',
    method: 'post',
    params: data
  });
} // 修改

export function examinePkgUpd(data) {
  return request({
    url: 'hjhd-gm/examinePkgUpd',
    method: 'post',
    params: data
  });
} // 删除 

export function examinePkgDel(data) {
  return request({
    url: 'hjhd-gm/examinePkgDel',
    method: 'post',
    params: data
  });
} // 所有包名 

export function examinePkgListS(data) {
  return request({
    url: 'hjhd-gm/examinePkgListS',
    method: 'get',
    params: data
  });
} // 审核服开放的渠道和版本 

export function getServerListData(data) {
  return request({
    url: 'hjhd-gm/getServerListData',
    method: 'get',
    params: data
  });
} // 玩家详情用户详情背包

export function gmDagDesc(data) {
  return request({
    url: 'hjhd-gm/bag_desc',
    method: 'get',
    params: data
  });
} // 玩家详情用户详情订单

export function gmRechargeGame(data) {
  return request({
    url: 'hjhd-gm/recharge_game',
    method: 'get',
    params: data
  });
}
export function gmRechargeGame_currency(data) {
  return request({
    url: 'hjhd-gm/recharge_game_currency',
    method: 'get',
    params: data
  });
} // 玩家详情用户详情全部订单

export function gmRechargeGameAll(data) {
  return request({
    url: 'hjhd-gm/recharge_game_all',
    method: 'get',
    params: data
  });
}
export function recharge_game_all_currency(data) {
  return request({
    url: 'hjhd-gm/recharge_game_all_currency',
    method: 'get',
    params: data
  });
} // 审核服开放的渠道和版本 

export function gmResourceDescList(data) {
  return request({
    url: 'hjhd-gm/resource_desc_list',
    method: 'get',
    params: data
  });
} // 获取所有环境权限

export function getProd(data) {
  return request({
    url: 'hjhd-gm/getProd',
    method: 'get',
    params: data
  });
} // 获取角色下的环境权限 

export function getRoleProd(data) {
  return request({
    url: 'prod/getRoleProd',
    method: 'get',
    params: data
  });
} // 修改权限 

export function roleProdUpd(data) {
  return request({
    url: 'prod/roleProdUpd',
    method: 'post',
    params: data
  });
} // 获取用户权限 

export function getUserProd(data) {
  return request({
    url: 'prod/getUserProd',
    method: 'get',
    params: data
  });
} // 每日创角
// 获取所有国家

export function gmCountry(data) {
  return request({
    url: 'hjhd-gm/gmCountry',
    method: 'get',
    params: data
  });
} // 每日创角列表

export function getServerRoleNum(data) {
  return request({
    url: 'hjhd-gm/getServerRoleNum',
    method: 'get',
    params: data
  });
} // 账号第三方账号绑定列表

export function accountBindList(data) {
  return request({
    url: 'hjhd-gm/accountBindList',
    method: 'get',
    params: data
  });
} // 转移账号的第三方绑定

export function replaceAccountBind(data) {
  return request({
    url: 'hjhd-gm/replaceAccountBind',
    method: 'post',
    params: data
  });
} // 获取所有玩家详情

export function userInfoAll(data) {
  return request({
    url: 'hjhd-gm/user-detailAll',
    method: 'get',
    params: data
  });
} // 获取活动类型列表

export function activityTypeList(data) {
  return request({
    url: 'hjhd-gm/activityTypeList',
    method: 'get',
    params: data
  });
} // 新增活动类型列表

export function activityTypeAdd(data) {
  return request({
    url: 'hjhd-gm/activityTypeAdd',
    method: 'post',
    params: data
  });
} // 修改活动类型列表

export function activityTypeUpd(data) {
  return request({
    url: 'hjhd-gm/activityTypeUpd',
    method: 'post',
    params: data
  });
} // 删除活动类型列表

export function activityTypeDel(data) {
  return request({
    url: 'hjhd-gm/activityTypeDel',
    method: 'post',
    params: data
  });
} // 获取配置列表

export function getActivityConfigList(data) {
  return request({
    url: 'hjhd-gm/getActivityConfigList',
    method: 'get',
    params: data
  });
} // 获取配置详情

export function getActivityConfigDetailToId(data) {
  return request({
    url: 'hjhd-gm/getActivityConfigDetailToId',
    method: 'get',
    params: data
  });
} // 获取配置文件名

export function getActivityConfigNameList(data) {
  return request({
    url: 'hjhd-gm/getActivityConfigNameList',
    method: 'get',
    params: data
  });
} // 获取活动ID

export function getActivityConfigIdToName(data) {
  return request({
    url: 'hjhd-gm/getActivityConfigIdToName',
    method: 'get',
    params: data
  });
} // 获取活动列表

export function getActivityList(data) {
  return request({
    url: 'hjhd-gm/getActivityList',
    method: 'get',
    params: data
  });
} // 新增活动列表

export function addActivityList(data) {
  return request({
    url: 'hjhd-gm/addActivityList',
    method: 'post',
    params: data
  });
} // 修改活动列表

export function updActivityList(data) {
  return request({
    url: 'hjhd-gm/updActivityList',
    method: 'post',
    params: data
  });
} // 删除活动列表

export function delActivityList(data) {
  return request({
    url: 'hjhd-gm/delActivityList',
    method: 'post',
    params: data
  });
} // 发布活动

export function activitySend(data) {
  return request({
    url: 'hjhd-gm/activitySend',
    method: 'post',
    params: data
  });
} // 获取配置记录列表

export function getActivityListConfig(data) {
  return request({
    url: 'hjhd-gm/getActivityListConfig',
    method: 'get',
    params: data
  });
} // 新增活动配置列表

export function addActivityListConfig(data) {
  return request({
    url: 'hjhd-gm/addActivityListConfig',
    method: 'post',
    params: data
  });
} // 修改活动配置列表

export function updActivityListConfig(data) {
  return request({
    url: 'hjhd-gm/updActivityListConfig',
    method: 'post',
    params: data
  });
} // 判断配置ID是否使用

export function configIdIsExist(data) {
  return request({
    url: 'hjhd-gm/configIdIsExist',
    method: 'get',
    params: data
  });
} // 活动操作上报

export function clickOperationLog(data) {
  return request({
    url: 'hjhd-gm/clickOperationLog',
    method: 'post',
    params: data
  });
} // 活动日志

export function getActivityLogList(data) {
  return request({
    url: 'hjhd-gm/getActivityLogList',
    method: 'get',
    params: data
  });
}
export function getActivitySendData(data) {
  return request({
    url: 'getActivitySendData',
    method: 'get',
    params: data
  });
}