// 穿山甲数据
import request from '@/utils/request';
export function getPanglinData(data) {
  return request({
    url: '/datum/pangolin',
    method: 'get',
    params: data
  });
}
export function exportPanglinData(data) {
  return request({
    url: '/datum/export_pangolin',
    method: 'get',
    params: data
  });
}