// 广告主
import request from '@/utils/request';
export function advertiserList(data) {
  return request({
    url: '/advertiser/list',
    method: 'get',
    params: data
  });
}
export function addAdvertiser(data) {
  return request({
    url: '/advertiser/add',
    method: 'post',
    params: data
  });
}
export function updAdvertiser(data) {
  return request({
    url: '/advertiser/update',
    method: 'post',
    params: data
  });
}
export function delAdvertiser(data) {
  return request({
    url: '/advertiser/del',
    method: 'get',
    params: data
  });
}