var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderNum" }, [
    _c("span", { staticClass: "text" }, [_vm._v("每日订单数")]),
    _c(
      "div",
      { attrs: { id: "orderNumC" } },
      [
        _vm.height
          ? _c("centreRight2Chart2", {
              attrs: { orderNumList: _vm.orderNumList, height: _vm.height }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }