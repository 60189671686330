var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "mini" },
              model: {
                value: _vm.showIndex,
                callback: function($$v) {
                  _vm.showIndex = $$v
                },
                expression: "showIndex"
              }
            },
            _vm._l(_vm.radioOptions, function(item, index) {
              return _c(
                "el-radio-button",
                { key: index, attrs: { label: item.value } },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { margin: "-10px" } },
        [
          _c("el-alert", {
            staticClass: "d2-m-10",
            staticStyle: { width: "auto", "margin-bottom": "10px" },
            attrs: { title: "点击图标复制代码", type: "info" }
          }),
          _vm._l(_vm.iconShow.icon, function(iconItem, iconIndex) {
            return _c(
              "el-col",
              { key: iconIndex, staticClass: "d2-p-10", attrs: { span: 1 } },
              [_c("d2-icon-cell", { attrs: { icon: iconItem } })],
              1
            )
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }