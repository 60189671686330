import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { getAdvistOrigin } from "@/api/dataAll/advtistShow";
export default {
  name: "advoriginData",
  mixins: [serchSelect],
  data: function data() {
    return {
      value2: [],
      value1: [],
      searchData: {
        activate_starttime: "",
        activate_endtime: "",
        ad_action_channel: "",
        ad_action_placement: "",
        ad_action: "",
        ext: ""
      },
      tableData: [],
      ad: [],
      guankaArr: [],
      gkTd: "",
      tableTime: [],
      show: false,
      more: true
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    getactionsearchDate: function getactionsearchDate() {
      var now = new Date();
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value1[0] = date;
      this.value1[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      this.guankaArr = [];
      this.tableData = [];

      if (this.value2 && Array.isArray(this.value2) && this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      if (this.value1 && Array.isArray(this.value1) && this.value1.length > 0) {
        this.searchData.activate_starttime = this.value1[0];
        this.searchData.activate_endtime = this.value1[1];
      } else {
        this.searchData.activate_starttime = "";
        this.searchData.activate_endtime = "";
      }

      getAdvistOrigin(this.searchData).then(function (res) {
        if (res.data.length > 0) {
          _this.tableData = res.data;
          _this.show = true;
        }
      });
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "分类",
        prop: "title"
      }, {
        label: "占比",
        prop: "proportion"
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};