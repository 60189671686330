import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { serchSelect, tabulation, gmToolNewPage } from "@/mixins/index";
import { szGetFightingRank } from "@/api/dataAll/effectiveness";
import { serverList as hdServerList } from "@/api/gmtool/packageCode";
import { serverList as yjServerList } from "@/api/gmToolNew/gmToolNew";
export default {
  name: "effectiveness",
  mixins: [serchSelect, tabulation, gmToolNewPage],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        page: 1,
        page_size: 10,
        server_id: []
      },
      serverLists: [],
      tableData: [],
      Dynamic: []
    };
  },
  created: function created() {},
  computed: _objectSpread({}, mapState("d2admin", {
    severlist: function severlist(state) {
      return state.user.GMauthority;
    }
  })),
  methods: {
    searchClick: function searchClick(e) {
      var _this = this;

      // if (this.rawData.game_mark.indexOf('royalpirates') > -1 && !this.searchData.is_prod) {
      //   this.$message.error('请选择环境')
      //   return
      // }
      // if (this.searchData.server_id.length == 0) {
      //   this.$message.error('请选择服务器')
      //   return
      // }
      this.searchData.page = e;
      this.reqLoading = true;
      szGetFightingRank(this.searchData).then(function (res) {
        if (res.status == 200) {
          // res.data.map(i=>{
          //   i.last_login_time = i.last_login_time ? this.$times.timestampToTime(i.last_login_time) : '/'
          // })
          _this.tableData = JSON.stringify(res.data) != '[]' ? res.data.data : [];
          _this.total = res.data.total;

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      var _this2 = this;

      szGetFightingRank(_objectSpread(_objectSpread({}, this.searchData), {}, {
        page_size: -1
      })).then(function (res) {
        if (res.status == 200) {
          var columns = [{
            label: '角色ID',
            prop: 'role_id'
          }, {
            label: '战斗力',
            prop: 'fighting'
          }, {
            label: '等级',
            prop: 'level'
          }, {
            label: '最后登录时间',
            prop: 'last_login_time'
          }];

          _this2.$export.excel({
            columns: columns,
            data: res.data,
            title: _this2.$route.meta.title
          });
        }
      });
    }
  }
};