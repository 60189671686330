var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page sewall section dataAll-content" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  model: {
                    value: _vm.searchData.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pkg", $$v)
                    },
                    expression: "searchData.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.pkg_mark }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告渠道"
                  },
                  on: { change: _vm.advChannelchange },
                  model: {
                    value: _vm.searchData.ad_channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_channel", $$v)
                    },
                    expression: "searchData.ad_channel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_platform_name,
                      value: item.adv_platform_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告位置"
                  },
                  model: {
                    value: _vm.searchData.ad_placement,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "ad_placement", $$v)
                    },
                    expression: "searchData.ad_placement"
                  }
                },
                _vm._l(_vm.advLocation, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.adv_place_name,
                      value: item.adv_place_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "开始行为"
                  },
                  on: { change: _vm.beginactionChange },
                  model: {
                    value: _vm.searchData.beginaction,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "beginaction", $$v)
                    },
                    expression: "searchData.beginaction"
                  }
                },
                _vm._l(_vm.behaviorList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "留存行为"
                  },
                  on: { change: _vm.beginactionChange },
                  model: {
                    value: _vm.searchData.retain_action,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "retain_action", $$v)
                    },
                    expression: "searchData.retain_action"
                  }
                },
                _vm._l(_vm.behaviorList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "分析类型"
                  },
                  model: {
                    value: _vm.searchData.analyzetype,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "analyzetype", $$v)
                    },
                    expression: "searchData.analyzetype"
                  }
                },
                _vm._l(_vm.analyzetypeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      disabled: item.disabled,
                      label: item.label,
                      value: item.value
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: false,
                  "picker-options": _vm.pickerOptionsRadius,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  placeholder: "请选择操作时间"
                },
                model: {
                  value: _vm.value2,
                  callback: function($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择国家"
                  },
                  model: {
                    value: _vm.searchData.country,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "country", $$v)
                    },
                    expression: "searchData.country"
                  }
                },
                _vm._l(_vm.searchCountry, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.country, value: item.country }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    clearable: "",
                    filterable: "",
                    placeholder: "留存"
                  },
                  on: { change: _vm.retentionSelectChange },
                  model: {
                    value: _vm.retentionSelect,
                    callback: function($$v) {
                      _vm.retentionSelect = $$v
                    },
                    expression: "retentionSelect"
                  }
                },
                _vm._l(_vm.retentionPrimitive, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.prop }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut  ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchClick } },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-row",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "date",
              "class-name": "font-bold",
              label: "日期",
              "render-header": _vm.renderheader
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "num",
              label: "总人数",
              "render-header": _vm.renderheader
            }
          }),
          _vm._l(_vm.retention, function(i, ind) {
            return _c("el-table-column", {
              key: ind,
              attrs: {
                sortable: "",
                prop: i.prop,
                label: i.label,
                "render-header": _vm.renderheader
              }
            })
          })
        ],
        2
      ),
      _c("div", {
        staticClass: "margin-top",
        staticStyle: { height: "500px" },
        attrs: { id: "retentionAnalysisEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }