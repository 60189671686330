//
//
//
//
//
//
//
//
//
//
import bottomLeftBar from "./chart/bottom/bottomLeftBar";
export default {
  props: {
    activeRegistNum: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      height: 0
    };
  },
  components: {
    bottomLeftBar: bottomLeftBar
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      var rankingBoard = document.getElementById('statiStical');
      _this.height = rankingBoard.clientHeight; // console.log(this.height,'statiStical')
    });
  },
  mounted: function mounted() {},
  methods: {}
};