// 自定义事件留存
import request from "@/utils/request"; //事件列表

export function customEventList(data) {
  return request({
    url: "/data/customEventList",
    method: "get",
    params: data
  });
} // 新增

export function customEventAdd(data) {
  return request({
    url: '/data/customEventAdd',
    method: 'post',
    params: data
  });
} // 修改

export function customEventUpd(data) {
  return request({
    url: '/data/customEventUpd',
    method: 'post',
    params: data
  });
} // 删除

export function customEventDel(data) {
  return request({
    url: '/data/customEventDel',
    method: 'post',
    params: data
  });
}