import "core-js/modules/es.array.from";
import "core-js/modules/es.string.iterator";
var watermark = {};

var setWatermark = function setWatermark(text, sourceBody) {
  var id = Math.random() * 10000 + "-" + Math.random() * 10000 + "/" + Math.random() * 10000;

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }

  var can = document.createElement("canvas");
  can.width = 500; //设置水印之间的左右间距

  can.height = 400; //设置水印之间的上下间距

  var cans = can.getContext("2d");
  cans.rotate(-20 * Math.PI / 180);
  cans.font = "30px Vedana";
  cans.fillStyle = "rgba(0, 0, 0, .03)";
  cans.textAlign = "left";
  cans.textBaseline = "Middle";
  cans.fillText(text, can.width / 20, can.height);
  var water_div = document.createElement("div");
  water_div.id = id;
  water_div.className = "watermarkClass";
  water_div.style.pointerEvents = "none";
  water_div.style.background = "url(" + can.toDataURL("image/png") + ") left top repeat";

  if (sourceBody) {
    water_div.style.width = "100%";
    water_div.style.height = "100%";
    sourceBody.appendChild(water_div);
  } else {
    water_div.style.top = "3px";
    water_div.style.left = "0px";
    water_div.style.position = "fixed";
    water_div.style.zIndex = "100000";
    water_div.style.width = document.documentElement.clientWidth + "px";
    water_div.style.height = document.documentElement.clientHeight + "px";
    document.body.appendChild(water_div);
  }

  return id;
};
/**
 *  该方法只允许调用一次
 *  @param:
 *  @text == 水印内容
 *  @sourceBody == 水印添加的位置，不传就是body
 * */


watermark.set = function (text, sourceBody) {
  var domArr = Array.from(document.getElementsByClassName("watermarkClass"));

  for (var i = 0; i < domArr.length; i++) {
    var element = domArr[i]; // element.parentNode.removeChild(element);

    element.remove();
  }

  if (text == "") {
    return;
  }

  setWatermark(text, sourceBody); // let id = setWatermark(text, sourceBody);
  // setInterval(() => {
  //   if (document.getElementById(id) === null) {
  //     id = setWatermark(text, sourceBody);
  //   }
  // }, 2000);
  // window.onresize = () => {
  //   setWatermark(text, sourceBody);
  // };
};

export default watermark;