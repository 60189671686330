//roi
import request from '@/utils/request';
export function getRoi(data) {
  return request({
    url: '/data/roi',
    method: 'get',
    params: data
  });
}
export function getRoi_currency(data) {
  return request({
    url: '/data/roi_currency',
    method: 'get',
    params: data
  });
}
export function activityDevireRoiCurrencyOther(data) {
  return request({
    url: '/data/activityDevireRoiCurrencyOther',
    method: 'get',
    params: data
  });
}