var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramesData.id ? "修改" : "新增",
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm()
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramesData
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "ad_name", label: "广告主名称" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入广告主名称" },
                    model: {
                      value: _vm.paramesData.ad_name,
                      callback: function($$v) {
                        _vm.$set(_vm.paramesData, "ad_name", $$v)
                      },
                      expression: "paramesData.ad_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "ad_id", label: "广告主ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入广告主ID", type: "number" },
                    model: {
                      value: _vm.paramesData.ad_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramesData, "ad_id", $$v)
                      },
                      expression: "paramesData.ad_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }