import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import icon from './data/index';
export default {
  name: 'resources',
  components: {
    'd2-icon-cell': function d2IconCell() {
      return import('./components/d2-icon-cell.vue');
    }
  },
  data: function data() {
    return {
      icon: icon,
      showIndex: 0
    };
  },
  computed: {
    iconShow: function iconShow() {
      return this.icon[this.showIndex];
    },
    radioOptions: function radioOptions() {
      return this.icon.map(function (e, index) {
        return {
          label: e.title,
          value: index
        };
      });
    }
  }
};