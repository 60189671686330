//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect, tabulation } from "@/mixins/index";
import Events from "./mixins/Events";
export default {
  name: "systemEvents",
  mixins: [serchSelect, tabulation, Events],
  data: function data() {
    return {
      // more:true,
      value2: [],
      searchData: {
        begin_date: '',
        end_date: '',
        country: '',
        begin_action: 'activity',
        retain_action: 'create_role',
        retain_days: 5,
        analyze_type: 'device',
        ad_id: ''
      },
      behaviorList: [{
        label: '激活',
        value: 'activity',
        disabled: false
      }, {
        label: '创角',
        value: 'create_role',
        disabled: false
      }, {
        label: '注册',
        value: 'register',
        disabled: false
      }, {
        label: '登录',
        value: 'login',
        disabled: false
      }, {
        label: '充值',
        value: 'recharge',
        disabled: false
      }],
      rehaviorList: [{
        label: '激活',
        value: 'activity',
        disabled: true
      }, {
        label: '创角',
        value: 'create_role',
        disabled: false
      }, {
        label: '注册',
        value: 'register',
        disabled: false
      }, {
        label: '登录',
        value: 'login',
        disabled: false
      }, {
        label: '充值',
        value: 'recharge',
        disabled: false
      }]
    };
  },
  created: function created() {},
  activated: function activated() {},
  methods: {}
};