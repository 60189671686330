var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        "value-key": item.id,
                        label: item.game_name,
                        value: item.id
                      }
                    },
                    [_vm._v(_vm._s(item.game_name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择渠道"
                  },
                  on: { change: _vm.channelchange },
                  model: {
                    value: _vm.searchData.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "channel", $$v)
                    },
                    expression: "searchData.channel"
                  }
                },
                _vm._l(_vm.searchchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  on: { change: _vm.pkgchange },
                  model: {
                    value: _vm.searchData.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pkg", $$v)
                    },
                    expression: "searchData.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择广告渠道"
                  },
                  on: { change: _vm.advChannelchange },
                  model: {
                    value: _vm.searchData.advChannel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "advChannel", $$v)
                    },
                    expression: "searchData.advChannel"
                  }
                },
                _vm._l(_vm.advchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.adv_platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.advanced
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择广告位置"
                      },
                      on: { change: _vm.advLocationchange },
                      model: {
                        value: _vm.searchData.advLocation,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "advLocation", $$v)
                        },
                        expression: "searchData.advLocation"
                      }
                    },
                    _vm._l(_vm.advLocation, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.adv_place_name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.advanced
            ? _c(
                "el-col",
                { staticClass: "operationBut", attrs: { span: 4 } },
                [
                  _c("unpack", {
                    attrs: { advanced: _vm.advanced },
                    on: {
                      advancedChange: function($event) {
                        _vm.advanced = $event
                      },
                      searchChange: function($event) {
                        return _vm.searchClick()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.advanced
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    staticStyle: { "margin-right": "-24px" },
                    attrs: { size: "medium", placeholder: "请输入用户名" },
                    on: { change: _vm.userChange },
                    model: {
                      value: _vm.searchData.user_name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "user_name", $$v)
                      },
                      expression: "searchData.user_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入手机号" },
                    on: { change: _vm.phoneChange },
                    model: {
                      value: _vm.searchData.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "phone", $$v)
                      },
                      expression: "searchData.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入邮箱号" },
                    on: { change: _vm.emailChange },
                    model: {
                      value: _vm.searchData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "email", $$v)
                      },
                      expression: "searchData.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入设备id" },
                    on: { change: _vm.deviceChange },
                    model: {
                      value: _vm.searchData.device_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "device_id", $$v)
                      },
                      expression: "searchData.device_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "operationBut", attrs: { span: 4 } },
                [
                  _c("unpack", {
                    attrs: { advanced: _vm.advanced },
                    on: {
                      advancedChange: function($event) {
                        _vm.advanced = $event
                      },
                      searchChange: function($event) {
                        return _vm.searchClick()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "60" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "game_reveal",
              label: "游戏",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "platform_reveal",
              label: "平台",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel_reveal",
              label: "渠道",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pkg_reveal",
              label: "包名",
              "show-overflow-tooltip": "",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_channel_reveal",
              label: "广告渠道",
              "show-overflow-tooltip": "",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.ad_channel_reveal || "未配置") +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_placement_reveal",
              label: "广告平台",
              "show-overflow-tooltip": "",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.ad_placement_reveal || "未配置") +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "user_id", label: "用户ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "clickUserID",
                        on: {
                          click: function($event) {
                            return _vm.handleGetInfo(
                              scope.row.user_id,
                              scope.row,
                              4
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.user_id) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "注册时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "is_visitor", label: "是否是游客" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.is_visitor == 1 ? "是" : "不是") +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.banHandle(
                                scope.row.id,
                                scope.row.user_id,
                                scope.row,
                                1
                              )
                            }
                          }
                        },
                        [_vm._v("封禁")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.banHandle(
                                scope.row.id,
                                scope.row.user_id,
                                scope.row,
                                2
                              )
                            }
                          }
                        },
                        [_vm._v("正常")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.up_register == true,
                              expression: "scope.row.up_register == true"
                            }
                          ],
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(
                                scope.row.user_id,
                                scope.row,
                                1
                              )
                            }
                          }
                        },
                        [_vm._v("更改密码")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.up_phone == true,
                              expression: "scope.row.up_phone == true"
                            }
                          ],
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource2(
                                scope.row.user_id,
                                scope.row,
                                2
                              )
                            }
                          }
                        },
                        [_vm._v("换绑手机")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.up_email == true,
                              expression: "scope.row.up_email == true"
                            }
                          ],
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource1(
                                scope.row.user_id,
                                scope.row,
                                3
                              )
                            }
                          }
                        },
                        [_vm._v("换绑邮箱")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.page,
          total: _vm.total,
          "page-size": _vm.pagrSize
        },
        on: { "current-change": _vm.handleCurrentChange }
      }),
      _c(
        "div",
        { staticStyle: { "pointer-events": "none" } },
        [
          _vm.addWayConfig
            ? _c("adduserPage", {
                ref: "addWayConfig",
                attrs: { showing: _vm.showing },
                on: {
                  changeShow: _vm.changeShow,
                  refreshDataList: _vm.searchClick
                }
              })
            : _vm._e(),
          _vm.addWayConfigPhone
            ? _c("bindPhone", {
                ref: "addWayConfig",
                attrs: { showing: _vm.showing },
                on: {
                  changeShow: _vm.changeShow,
                  refreshDataList: _vm.searchClick
                }
              })
            : _vm._e(),
          _vm.addWayConfigEmail
            ? _c("bindEmail", {
                ref: "addWayConfig",
                attrs: { showing: _vm.showing },
                on: {
                  changeShow: _vm.changeShow,
                  refreshDataList: _vm.searchClick
                }
              })
            : _vm._e(),
          _vm.addWayConfigInfor
            ? _c("accassDetail", {
                ref: "addWayConfig",
                attrs: { showing: _vm.showing },
                on: {
                  changeShow: _vm.changeShow,
                  refreshDataList: _vm.searchClick
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }