var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                staticClass: "flex-1 ",
                attrs: {
                  "popper-class": "no-atTheMoment",
                  type: "datetime",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "请选择",
                  align: "right"
                },
                model: {
                  value: _vm.searchData.date,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "date", $$v)
                  },
                  expression: "searchData.date"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [_c("el-button", { on: { click: _vm.getData } }, [_vm._v("搜索")])],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "x-start largeScreenDataBox" }, [
        _c("div", { staticClass: "dataLeft" }, [
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "320px" } },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("实时在线人数")]),
              _c("div", {
                ref: "onlineEchart",
                staticStyle: { width: "100%", height: "276px" },
                attrs: { id: "onlineEchart" }
              }),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "300px" } },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("订单列表")]),
              _c(
                "div",
                {},
                [
                  _c("div", { staticClass: "wraptit" }, [
                    _c("div", [_vm._v("角色名称")]),
                    _c("div", [_vm._v("商品名称")]),
                    _c("div", [_vm._v("订单金额(元)")]),
                    _c("div", [_vm._v("下单时间")])
                  ]),
                  _c(
                    "vueSeamless",
                    {
                      staticClass: "seamless-warp",
                      attrs: {
                        data: _vm.orderList,
                        "class-option": _vm.defaultOption
                      }
                    },
                    _vm._l(_vm.orderList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "wraptit wraptitItem" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.role_name,
                                placement: "top-start"
                              }
                            },
                            [
                              _c("div", { staticClass: "text-ellipsis-1" }, [
                                _vm._v(_vm._s(item.role_name))
                              ])
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.product_name,
                                placement: "top-start"
                              }
                            },
                            [
                              _c("div", { staticClass: "text-ellipsis-1" }, [
                                _vm._v(_vm._s(item.product_name))
                              ])
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$utils
                                  .conversion(item.amount)
                                  .toFixed(2),
                                placement: "top-start"
                              }
                            },
                            [
                              _c("div", { staticClass: "text-ellipsis-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$utils
                                      .conversion(item.amount)
                                      .toFixed(2)
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$times.timestampToTime(
                                  item.time,
                                  "HH:MM:SS"
                                ),
                                placement: "top-start"
                              }
                            },
                            [
                              _c("div", { staticClass: "text-ellipsis-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$times.timestampToTime(
                                      item.time,
                                      "HH:MM:SS"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "300px" } },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("购买物品占比")]),
              _c("div", {
                staticStyle: { width: "100%", height: "200px" },
                attrs: { id: "ItemRatio" }
              }),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "dataCenter" }, [
          _c(
            "div",
            { staticClass: "boxall x-c", staticStyle: { height: "230px" } },
            [
              _c("div", { staticClass: "rateItme" }, [
                _c("div", { staticClass: "rateTitle" }, [
                  _vm._v(" 安装注册转化率 ")
                ]),
                _c("div", {
                  staticStyle: { height: "170px", width: "170px" },
                  attrs: { id: "Insregrate" }
                })
              ]),
              _c("div", { staticClass: "rateItme" }, [
                _c("div", { staticClass: "rateTitle" }, [
                  _vm._v(" 新增付费率 ")
                ]),
                _c("div", {
                  staticStyle: { height: "170px", width: "170px" },
                  attrs: { id: "IncrementalFeeRate" }
                })
              ]),
              _c("div", { staticClass: "rateItme" }, [
                _c("div", { staticClass: "rateTitle" }, [
                  _vm._v(" 活跃付费率 ")
                ]),
                _c("div", {
                  staticStyle: { height: "170px", width: "170px" },
                  attrs: { id: "activePayRate" }
                })
              ]),
              _c("div", { staticClass: "rateItme" }, [
                _c("div", { staticClass: "rateTitle" }, [_vm._v(" 次留 ")]),
                _c("div", {
                  staticStyle: { height: "170px", width: "170px" },
                  attrs: { id: "timeremaining" }
                })
              ]),
              _c("div", { staticClass: "rateItme" }, [
                _c("div", { staticClass: "rateTitle" }, [
                  _vm._v(" 自然量K因子 ")
                ]),
                _c("div", {
                  staticStyle: { height: "170px", width: "170px" },
                  attrs: { id: "ltvOne" }
                })
              ]),
              _c("div", { staticClass: "rateItme" }, [
                _c("div", { staticClass: "rateTitle" }, [_vm._v(" 买量安装 ")]),
                _c("div", {
                  staticStyle: { height: "170px", width: "170px" },
                  attrs: { id: "installationCost" }
                })
              ]),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "350px" } },
            [
              _c("div", { staticClass: "alltitle" }, [
                _vm._v("实时付费人数、充值收入(元)")
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "320px" },
                attrs: { id: "PayersRechargeIncome" }
              }),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "340px" } },
            [
              _c("div", { staticClass: "alltitle" }, [
                _vm._v("实时新增账号数、实时登录数据")
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "310px" },
                attrs: { id: "RealTimeaccountNumLogin" }
              }),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          ),
          _c("div", { staticClass: "map" }, [
            _c("div", { staticClass: "map1" }),
            _c("div", { staticClass: "map2" }),
            _c("div", { staticClass: "map3" })
          ])
        ]),
        _c("div", { staticClass: "dataRight" }, [
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "300px" } },
            [
              _c("div", { staticClass: "alltitle" }, [
                _vm._v("每日充值人员排行榜")
              ]),
              _c(
                "div",
                { staticClass: "table1" },
                [
                  _c("div", { staticClass: "table1Item" }, [
                    _c("div", { staticClass: "col toptop " }, [_vm._v("排名")]),
                    _c("div", { staticClass: "col" }, [_vm._v("用户ID")]),
                    _c("div", { staticClass: "col" }, [_vm._v("金额(元)")])
                  ]),
                  _c(
                    "vueSeamless",
                    {
                      staticClass: "rankingClass",
                      attrs: {
                        data: _vm.rechargeRankList,
                        "class-option": _vm.defaultOption
                      }
                    },
                    _vm._l(_vm.rechargeRankList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "table1Item" },
                        [
                          _c("div", { staticClass: "col toptop tr" }, [
                            _c("span", { class: "spans" + index }, [
                              _vm._v(_vm._s(index + 1))
                            ])
                          ]),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.user_id,
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col tr text-ellipsis-1" },
                                [_vm._v(_vm._s(item.user_id))]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "col tr" }, [
                            _vm._v(_vm._s(item.amount)),
                            _c("br")
                          ])
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "300px" } },
            [
              _c("div", { staticClass: "alltitle" }, [
                _vm._v("每日在线时长排行榜")
              ]),
              _c(
                "div",
                { staticClass: "table1" },
                [
                  _c("div", { staticClass: "table1Item" }, [
                    _c("div", { staticClass: "col toptop " }, [_vm._v("排名")]),
                    _c("div", { staticClass: "col" }, [_vm._v("用户ID")]),
                    _c("div", { staticClass: "col" }, [_vm._v("在线时长(分)")])
                  ]),
                  _c(
                    "vueSeamless",
                    {
                      staticClass: "rankingClass",
                      attrs: {
                        data: _vm.onlineList,
                        "class-option": _vm.defaultOption
                      }
                    },
                    _vm._l(_vm.onlineList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "table1Item" },
                        [
                          _c("div", { staticClass: "col toptop tr" }, [
                            _c("span", { class: "spans" + index }, [
                              _vm._v(_vm._s(index + 1))
                            ])
                          ]),
                          _c("div", { staticClass: "col tr" }, [
                            _vm._v(_vm._s(item.user_id))
                          ]),
                          _c("div", { staticClass: "col tr" }, [
                            _vm._v(_vm._s(item.hour)),
                            _c("br")
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "boxall", staticStyle: { height: "320px" } },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("新增占比")]),
              _c("div", {
                staticStyle: { width: "97%", height: "260px" },
                attrs: { id: "newProportion" }
              }),
              _c("div", { staticClass: "corner" }, [
                _c("div", { staticClass: "corner-tl" }),
                _c("div", { staticClass: "corner-tr" }),
                _c("div", { staticClass: "corner-bl" }),
                _c("div", { staticClass: "corner-br" })
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }