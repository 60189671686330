var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.searchData.id ? "修改" : "新增",
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                model: _vm.searchData,
                rules: _vm.rules,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "游戏", prop: "game" }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择游戏"
                      },
                      on: { change: _vm.gamechange },
                      model: {
                        value: _vm.searchData.game,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "game", $$v)
                        },
                        expression: "searchData.game"
                      }
                    },
                    _vm._l(_vm.searchgames, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.game_name, value: item.game_mark }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "平台", prop: "platform" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择平台"
                      },
                      on: { change: _vm.platformchange },
                      model: {
                        value: _vm.searchData.platform,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "platform", $$v)
                        },
                        expression: "searchData.platform"
                      }
                    },
                    _vm._l(_vm.searchplatforms, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.platform_name,
                          value: item.platform_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "渠道", prop: "channel" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择渠道"
                      },
                      on: { change: _vm.channelchange },
                      model: {
                        value: _vm.searchData.channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "channel", $$v)
                        },
                        expression: "searchData.channel"
                      }
                    },
                    _vm._l(_vm.searchchannels, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.channel_name,
                          value: item.channel_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "包名", prop: "pkg" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择投放包"
                      },
                      model: {
                        value: _vm.searchData.pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "pkg", $$v)
                        },
                        expression: "searchData.pkg"
                      }
                    },
                    _vm._l(_vm.searchpkgs, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg_name, value: item.pkg_mark }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "回调地址", prop: "callback_url" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入回调地址" },
                    model: {
                      value: _vm.searchData.callback_url,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "callback_url", $$v)
                      },
                      expression: "searchData.callback_url"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "回调密钥ID", prop: "callback_id" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入回调密钥ID" },
                    model: {
                      value: _vm.searchData.callback_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "callback_id", $$v)
                      },
                      expression: "searchData.callback_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { label: "回调密钥key", prop: "callback_key" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入回调密钥key" },
                    model: {
                      value: _vm.searchData.callback_key,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "callback_key", $$v)
                      },
                      expression: "searchData.callback_key"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.addDomain }
            },
            [_vm._v("添加参数")]
          ),
          _c(
            "div",
            { staticClass: "popover" },
            _vm._l(_vm.ex_Val, function(domain, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "lodingItem",
                  staticStyle: { display: "flex" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "lodingItem-operationFrame" },
                    [
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          circle: "",
                          icon: "el-icon-delete"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeDomain(domain)
                          }
                        }
                      }),
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { placeholder: "请输入参数名" },
                        model: {
                          value: domain.name,
                          callback: function($$v) {
                            _vm.$set(domain, "name", $$v)
                          },
                          expression: "domain.name"
                        }
                      }),
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { placeholder: "请输入参数值" },
                        model: {
                          value: domain.value,
                          callback: function($$v) {
                            _vm.$set(domain, "value", $$v)
                          },
                          expression: "domain.value"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }