var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addClick } },
                [_vm._v("新增")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.getList }
                },
                [_vm._v("刷新")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "类型名称" } }),
          _c("el-table-column", {
            attrs: { prop: "development_config_id", label: "配置ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "file", label: "配置文件名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1" }, [
                      _vm._v(_vm._s(row.file || "/"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "controlsBtn",
                        on: {
                          click: function($event) {
                            return _vm.uptype(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c("div", { staticClass: "controlsBtn-vertical" }),
                    _c(
                      "div",
                      {
                        staticClass: "controlsBtn",
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("addtypeActivities", {
        ref: "addtypeActivities",
        on: { update: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }