//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import * as clipboard from 'clipboard-polyfill';
export default {
  props: {
    icon: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    iconClass: function iconClass() {
      return "this.icon";
    },
    iconHtml: function iconHtml() {
      return "<i class=\"fa fa-".concat(this.icon, "\" aria-hidden=\"true\"></i>");
    },
    iconComponent: function iconComponent() {
      return "<d2-icon name=\"".concat(this.icon, "\"/>");
    }
  },
  methods: {
    copy: function copy(text) {
      clipboard.writeText(text);
      this.$message({
        message: "".concat(text, " \u590D\u5236\u5230\u526A\u8D34\u677F"),
        type: 'success'
      });
    }
  }
};