var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id !== "" ? "修改" : "新增",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData(true)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "white_id", label: "白名单账号" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        prop: "label",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.white_id,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "white_id", $$v)
                        },
                        expression: "paramsData.white_id"
                      }
                    },
                    _vm._l(_vm.gmWelfareWhiteListsList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            "用户ID:" +
                            item.user_id +
                            " 设备ID: " +
                            item.device_id,
                          value: item.id
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "welfare_level_id", label: "福利等级" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        prop: "label",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.welfare_level_id,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "welfare_level_id", $$v)
                        },
                        expression: "paramsData.welfare_level_id"
                      }
                    },
                    _vm._l(_vm.gmWelfareLevelListsList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }