import request from '@/utils/request'; //获取三方登录

export function getThreeData(data) {
  return request({
    url: '/pay/login_type',
    method: 'get',
    params: data
  });
} //新增三方登录

export function addThreeData(data) {
  return request({
    url: '/pay/add_login_type',
    method: 'post',
    params: data
  });
} //修改三方登录

export function updThreeData(data) {
  return request({
    url: '/pay/update_login_type',
    method: 'post',
    params: data
  });
}