import "core-js/modules/es.array.find";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
// 表格工具
import Sortable from "sortablejs";
import { screenDataAdd } from "@/api/user";
import pickerOption from "@/mixins/module/pickerOption";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [pickerOption],
  data: function data() {
    return {
      tableScrollRight: 0,
      // 横向滚动条位置
      pageFnName: "searchClick",
      myChart: null,
      reqLoading: false,
      currencyClose: false,
      tableData: [],
      searchCurrency: [],
      searchData: {
        game_id: "",
        ad_channel: "",
        // 广告渠道
        ad_placement: "" //广告位置

      },
      markingList: [],
      cellStylekeys: [],
      userTypes: [{
        label: "新增",
        value: 1
      }, {
        label: "活跃",
        value: 2
      }]
    };
  },
  activated: function activated() {
    var _this = this;

    this.$nextTick(function () {
      _this.setTableRight(_this.$route.meta.scrollLeft);
    });
  },
  created: function created() {
    this.pageFn = this[this.pageFnName];
  },
  computed: _objectSpread({}, mapState("d2admin", {
    rawData: function rawData(state) {
      return state.serchSelect.rawData;
    }
  })),
  methods: {
    //请求默认数据处理
    defaultData: function defaultData() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "searchData";
      return {
        currency_info: (this.currencyClose || this.searchCurrency.length) && !this[type].currency_info ? process.env.VUE_APP_HB : this[type].currency_info
      };
    },
    // 横向滚动条监听 数据加载完成后调用
    getTableRight: function getTableRight() {
      var _this2 = this;

      if (this.$refs.tableRef) {
        this.$refs.tableRef.bodyWrapper.addEventListener("scroll", function (res) {
          _this2.$route.meta.scrollLeft = res.target.scrollLeft;
        });
      }
    },
    // 设置竖向滚动条
    setStop: function setStop() {
      var scrollTop = this.$route.meta.scrollTop;
      var content = document.querySelector(".d2-container-full__body");

      if (scrollTop && content) {
        content.scrollTo({
          top: scrollTop,
          behavior: "smooth"
        });
      }
    },
    // 设置横向滚动条
    setTableRight: function setTableRight(tableScrollRight) {
      var _this3 = this;

      if (this.tableData && this.tableData.length) {
        this.$nextTick(function () {
          setTimeout(function () {
            var tableScrollRef = _this3.$el.querySelector(".el-table__body-wrapper");

            tableScrollRef.scrollLeft = tableScrollRight;
            setTimeout(function () {
              _this3.setStop();
            }, 600);
          }, 200);
        });
      }
    },
    // 货币变化监听
    currencyChange: function currencyChange(e) {
      this.currencyClose = e;
    },
    //列拖拽
    columnDropInit: function columnDropInit() {
      var _this4 = this;

      var fiNum = this.searchData.channel == "wxminigame" ? 2 : 0;
      var wrapperColumn = document.querySelector(".el-table__header-wrapper tr");
      this.sortable = Sortable.create(wrapperColumn, {
        animation: 500,
        delay: 0,
        onEnd: function onEnd(event) {
          var tempHableHeader = _toConsumableArray(_this4.sericeArr);

          var temp;
          temp = tempHableHeader[event.oldIndex - fiNum];
          tempHableHeader.splice(event.oldIndex - fiNum, 1);
          tempHableHeader.splice(event.newIndex - fiNum, 0, temp);
          _this4.sericeArr = [];

          _this4.$nextTick(function () {
            _this4.sericeArr = tempHableHeader;
            screenDataAdd({
              data: JSON.stringify(tempHableHeader)
            }); // this.$store.commit("d2admin/serchSelect/setsericeArr", tempHableHeader);
          });
        }
      });
    },
    // 列表
    cellStyle: function cellStyle(_ref) {
      var _this5 = this;

      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;
      var isClass = false;
      var strIndex = column.property.indexOf(',');
      var data = this.markingList.findIndex(function (i) {
        return i.date == row.date;
      }) > -1 ? _objectSpread({}, this.markingList.find(function (i) {
        return i.date == row.date;
      })) : {
        day: -1
      };
      data.day = strIndex > -1 ? Number(data.day) - 1 : data.day;
      Object.keys(row).forEach(function (key) {
        _this5.cellStylekeys.forEach(function (i) {
          if (data.day == key.replace(i, "") && key == (strIndex > -1 ? column.property.split(',')[0] : column.property)) {
            if (rowIndex === rowIndex && columnIndex === columnIndex) {
              isClass = true;
            } else {
              isClass = false;
            }
          }
        });
      });

      if (isClass) {
        return "ondayClass";
      }

      return "";
    },
    // 获取时间间隔
    timeInterval: function timeInterval() {
      var _this6 = this;

      return new Promise(function (resolve, reject) {
        // let list = [];
        // this.tableData.forEach((i) => {
        //   list.push({
        //     date: i.date,
        //     day: this.$times.differenceTime(i.date, new Date()) + 1,
        //   });
        // });
        // this.markingList = list;
        var dayList = _this6.$times.getDayAll(_this6.value2[0], _this6.value2[1]);

        var list = [];
        dayList.forEach(function (i) {
          list.push({
            date: i,
            day: _this6.$times.differenceTime(i, new Date()) + 1
          });
        });
        _this6.markingList = list;
        resolve();
      });
    },
    // let dayList = this.$times.getDayAll(this.value2[0],this.value2[1])
    //   let list = []
    //   dayList.forEach(i=>{
    //       list.push({
    //         date:i,
    //         day:this.$times.differenceTime(i,new Date()) + 1
    //       })
    //   })
    //   this.markingList = list
    // 公用分页
    sizeChange: function sizeChange(e) {
      this.searchData.page_size = e;
      this.pageFn && this.pageFn(1);
    },
    // 组件传值数据处理
    searchChange: function searchChange(e) {
      this[e.type] = e.data instanceof Array ? e.data : _objectSpread(_objectSpread({}, this.searchData), e.data);
    },
    // 表格提示转化
    renderheader: function renderheader(h, _ref2, desc) {
      var column = _ref2.column,
          $index = _ref2.$index;
      return h("div", {
        style: {
          display: "flex",
          "align-items": "center"
        }
      }, [h("div", {
        style: {
          display: "flex",
          "flex-direction": "column",
          "align-items": "center"
        }
      }, [h("div", {}, column.label.split(".")[0]), h("div", {}, column.label.split(".")[1])]), desc ? h("el-tooltip", {
        props: {
          content: desc,
          placement: "top"
        }
      }, [h("span", {
        // style: {
        //   'margin-left': '4px',
        // },
        class: {
          "el-icon-question": true
        }
      })]) : ""]);
    }
  }
};