// 产品映射管理 productMapping
import request from '@/utils/request'; // 列表

export function productList(data) {
  return request({
    url: '/game-product/list',
    method: 'get',
    params: data
  });
} // 详情

export function productDetails(data) {
  return request({
    url: '/game-product/upGame',
    method: 'get',
    params: data
  });
} //增

export function addProduct(data) {
  return request({
    url: '/game-product/add',
    method: 'post',
    params: data
  });
} //改

export function updProduct(data) {
  return request({
    url: '/game-product/upGame',
    method: 'post',
    params: data
  });
} // 删

export function delProduct(data) {
  return request({
    url: '/game-product/delGame',
    method: 'get',
    params: data
  });
} // 关联列表

export function allProduct(data) {
  return request({
    url: '/game-product/all-product',
    method: 'get',
    params: data
  });
}
export function allProductList(data) {
  return request({
    url: '/game-product/all-product-list',
    method: 'get',
    params: data
  });
}
export function searchProduct(data) {
  return request({
    url: '/data/search_product',
    method: 'get',
    params: data
  });
} // 获取产品列表

export function searchProductPro(data) {
  return request({
    url: '/data/search_product_pro',
    method: 'get',
    params: data
  });
} // 获取产品列表 -- 混合

export function search_product_blend(data) {
  return request({
    url: '/data/search_product_blend',
    method: 'get',
    params: data
  });
}