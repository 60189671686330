var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "路由", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("父级路由")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-cascader", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      options: _vm.options,
                      props: { checkStrictly: true },
                      "collapse-tags": "",
                      clearable: ""
                    },
                    on: { change: _vm.getValue },
                    model: {
                      value: _vm.valuearray,
                      callback: function($$v) {
                        _vm.valuearray = $$v
                      },
                      expression: "valuearray"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("排序")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.listorder,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "listorder", $$v)
                      },
                      expression: "form.listorder"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("路由名称")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      placeholder: "请输入路由名称",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("请求方式")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { placeholder: "请选择活动区域" },
                      model: {
                        value: _vm.form.method,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "method", $$v)
                        },
                        expression: "form.method"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "POST", value: "post" }
                      }),
                      _c("el-option", { attrs: { label: "GET", value: "get" } })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("请求路径")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请求路径", autocomplete: "off" },
                    model: {
                      value: _vm.form.url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("图标")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "icon", $$v)
                      },
                      expression: "form.icon"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { round: "" }, on: { click: _vm.resetForm } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }