import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { serverList, getitemList } from "@/api/gmtool/packageCode";
import store from '@/store/index';
export default {
  namespaced: true,
  state: {
    itemList: [],
    serverList: [],
    isreqItemList: false
  },
  actions: {
    getItemList: function getItemList(_ref, data) {
      var commit = _ref.commit,
          state = _ref.state;
      return new Promise(function (resolve, reject) {
        if (store.state.d2admin.serchSelect.rawData.game_id && !state.isreqItemList) {
          commit('set_isreqItemList', true);
          getitemList(_objectSpread(_objectSpread({}, data), {}, {
            game_id: store.state.d2admin.serchSelect.rawData.game_id
          })).then(function (res) {
            if (res.status == 200) {
              commit('set_itemList', res.data);
              resolve(res);
            }
          });
        }
      });
    },
    getserverList: function getserverList(_ref2, data) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        serverList().then(function (res) {
          if (res.status == 200) {
            commit('set_serverList', res.data);
            resolve();
          }
        });
      });
    },
    clearData: function clearData(_ref3) {
      var state = _ref3.state;
      state.itemList = [];
    }
  },
  mutations: {
    set_itemList: function set_itemList(state, data) {
      state.itemList = data;
    },
    set_serverList: function set_serverList(state, data) {
      state.serverList = data;
    },
    set_isreqItemList: function set_isreqItemList(state, data) {
      state.isreqItemList = data;
    }
  }
};