import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { rawDataSummary } from "@/api/dataAll/rawData";
import * as echarts from "echarts";
export default {
  name: "rawData",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        start_time: "",
        end_time: ""
      },
      tableData: [],
      cyclicData: [],
      dates: [],
      datas: [],
      series: [],
      DynamicList: [{
        label: '日期',
        prop: 'date'
      }, {
        label: '注册人数',
        prop: 'register_count'
      }, {
        label: '登录人数',
        prop: 'login_count'
      }, {
        label: '充值人数',
        prop: 'recharge_count'
      }, {
        label: '充值金额',
        prop: 'recharge_amount'
      }]
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      // if(this.currencyClose && !this.searchData.currency_info){
      //   this.$message.error('请选择货币类型')
      //   return
      // }
      this.searchData.starttime = this.value2[0] || '';
      this.searchData.endtime = this.value2[1] || '';
      this.reqLoading = true;
      rawDataSummary(_objectSpread(_objectSpread({}, this.searchData), this.defaultData())).then(function (res) {
        if (res.status == 200) {
          _this.tableData = [];

          for (var k in res.data) {
            _this.tableData.push(_objectSpread(_objectSpread({
              date: k
            }, res.data[k]), {}, {
              recharge_amount: _this.$utils.conversion(res.data[k]['recharge_amount']).toFixed(2)
            }));
          }

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.page = val;
      this.searchClick();
    },
    exportdata: function exportdata() {
      var columns = _toConsumableArray(this.DynamicList);

      this.$export.excel({
        columns: columns,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};