//
//
//
//
import { getRowDatajl } from "@/api/dataAll/rowData";
import montorowData from './montorowData';
export default {
  name: "montorowDatajl",
  components: {
    montorowData: montorowData
  },
  data: function data() {
    return {
      reqFns: null
    };
  },
  created: function created() {
    this.reqFns = getRowDatajl;
  },
  methods: {}
};