export default {
  props: {},
  data: function data() {
    return {
      appType: [{
        label: '安卓',
        value: 'android'
      }, {
        label: 'IOS',
        value: 'ios'
      }]
    };
  },
  activated: function activated() {},
  created: function created() {},
  watch: {},
  computed: {},
  methods: {}
};