var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uploadImage" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: { hide: _vm.showUpload },
          attrs: {
            limit: _vm.limit,
            multiple: _vm.limit > 1 ? true : false,
            "on-error": _vm.upErr,
            headers: _vm.headers,
            action: _vm.action,
            name: _vm.filesname,
            accept: _vm.fileType,
            "list-type": "picture-card",
            "on-exceed": _vm.handleExceed,
            "before-upload": _vm.beforeUpload,
            data: _vm.paramsData,
            "on-success": _vm.upSuccess,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove,
            disabled: _vm.disabled
          }
        },
        [
          _c("el-button", { attrs: { size: "warning", type: "primary" } }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }