var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          staticStyle: { "pointer-events": "auto" },
          attrs: {
            title: _vm.isnum == "1" ? "新增登录渠道" : "修改登录渠道",
            visible: _vm.isShow,
            width: "30%",
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false,
            top: "13vh"
          },
          on: {
            "update:visible": function($event) {
              _vm.isShow = $event
            }
          }
        },
        [
          _c("div", { staticClass: "addchannelss add-header" }, [
            _c(
              "div",
              { staticClass: "child_box" },
              [
                _c("div", { staticClass: "first_box" }, [
                  _vm._v("登陆渠道名称:")
                ]),
                _c("el-input", {
                  attrs: { placeholder: "请输入名称" },
                  model: {
                    value: _vm.searchData.login_name,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "login_name", $$v)
                    },
                    expression: "searchData.login_name"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "child_box" },
              [
                _c("div", { staticClass: "first_box" }, [
                  _vm._v("登陆渠道标志:")
                ]),
                _c("el-input", {
                  attrs: { placeholder: "请输入标志" },
                  model: {
                    value: _vm.searchData.login_mark,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "login_mark", $$v)
                    },
                    expression: "searchData.login_mark"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }