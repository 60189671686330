var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCode" },
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id ? "修改版本说明" : "新增版本说明",
            visible: _vm.dialogShow,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData(true)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "version", label: "版本号" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity no-number",
                    attrs: { placeholder: "请输入版本号(格式:x.x.x)" },
                    model: {
                      value: _vm.paramsData.version,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "version", $$v)
                      },
                      expression: "paramsData.version"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "渠道" } },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity no-number",
                    attrs: {
                      type: "number",
                      placeholder: "请输入渠道(不填默认为所有)"
                    },
                    model: {
                      value: _vm.paramsData.channel_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "channel_id", $$v)
                      },
                      expression: "paramsData.channel_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { prop: "introduction" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addContent }
                        },
                        [_vm._v("添加内容 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.introduction, function(domain, index) {
                return _c(
                  "div",
                  { key: index + "k", staticClass: "introductionClass" },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass:
                              "lodingItem introductionValitem lodingItemt",
                            attrs: {
                              prop: "introduction." + index + ".lang",
                              rules: _vm.rules.language,
                              label: "语言",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "flex-1",
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: domain.lang,
                                  callback: function($$v) {
                                    _vm.$set(domain, "lang", $$v)
                                  },
                                  expression: "domain.lang "
                                }
                              },
                              _vm._l(_vm.langList, function(item) {
                                return _c("el-option", {
                                  key: item.label,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.paramsData.introduction.length > 1
                          ? _c("el-button", {
                              staticStyle: { margin: "0 0 20px 10px" },
                              attrs: {
                                size: "mini",
                                type: "danger",
                                circle: "",
                                icon: "el-icon-delete"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeContent(domain)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex lodingItem-title first_box",
                        staticStyle: {
                          "margin-bottom": "20px",
                          width: "100%",
                          "align-items": "center"
                        }
                      },
                      [
                        _vm._v("内容: "),
                        !_vm.paramsData.is_pause
                          ? _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { "padding-left": "20px" }
                              },
                              _vm._l(_vm.tagList, function(items, indexs) {
                                return _c(
                                  "div",
                                  {
                                    key: indexs,
                                    staticClass: "addtag",
                                    on: {
                                      click: function($event) {
                                        return _vm.addtag(items.content, index)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          prop: "introduction." + index + ".content",
                          rules: _vm.rules.introduction
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 2, maxRows: 12 },
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: domain.content,
                            callback: function($$v) {
                              _vm.$set(domain, "content", $$v)
                            },
                            expression: "domain.content "
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }