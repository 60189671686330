import { render, staticRenderFns } from "./kamitakauUpload.vue?vue&type=template&id=938b66d8&scoped=true&"
import script from "./kamitakauUpload.vue?vue&type=script&lang=js&"
export * from "./kamitakauUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "938b66d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\admin\\datacenter_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('938b66d8')) {
      api.createRecord('938b66d8', component.options)
    } else {
      api.reload('938b66d8', component.options)
    }
    module.hot.accept("./kamitakauUpload.vue?vue&type=template&id=938b66d8&scoped=true&", function () {
      api.rerender('938b66d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/datalist/kamitakauUpload.vue"
export default component.exports