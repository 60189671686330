var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.form.id ? "修改" : "新增",
            visible: _vm.show,
            "destroy-on-close": "",
            fullscreen: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            modal: false,
            "append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.show = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "n_game", label: "新游戏" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择游戏"
                      },
                      on: {
                        change: function($event) {
                          return _vm.gamechange($event, "n_")
                        }
                      },
                      model: {
                        value: _vm.form.n_game,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "n_game", $$v)
                        },
                        expression: "form.n_game"
                      }
                    },
                    _vm._l(_vm.searchgames, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          "value-key": item.id,
                          label: item.game_name,
                          value: item.game_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "n_platform", label: "新平台" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择平台"
                      },
                      on: {
                        change: function($event) {
                          return _vm.platformchange($event, "n_")
                        }
                      },
                      model: {
                        value: _vm.form.n_platform,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "n_platform", $$v)
                        },
                        expression: "form.n_platform"
                      }
                    },
                    _vm._l(_vm.n_searchplatform, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.platform_name,
                          value: item.platform_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "n_channel", label: "新渠道" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择渠道"
                      },
                      on: {
                        change: function($event) {
                          return _vm.channelchange($event, "n_")
                        }
                      },
                      model: {
                        value: _vm.form.n_channel,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "n_channel", $$v)
                        },
                        expression: "form.n_channel"
                      }
                    },
                    _vm._l(_vm.n_searchchannel, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.channel_name,
                          value: item.channel_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "n_pkg", label: "新包名" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择包名"
                      },
                      model: {
                        value: _vm.form.n_pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "n_pkg", $$v)
                        },
                        expression: "form.n_pkg"
                      }
                    },
                    _vm._l(_vm.n_searchpkg, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg_name, value: item.pkg_mark }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "o_game", label: "替换游戏" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择游戏"
                      },
                      on: {
                        change: function($event) {
                          return _vm.gamechange($event, "o_")
                        }
                      },
                      model: {
                        value: _vm.form.o_game,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "o_game", $$v)
                        },
                        expression: "form.o_game"
                      }
                    },
                    _vm._l(_vm.searchgames, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          "value-key": item.id,
                          label: item.game_name,
                          value: item.game_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "o_platform", label: "替换平台" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择平台"
                      },
                      on: {
                        change: function($event) {
                          return _vm.platformchange($event, "o_")
                        }
                      },
                      model: {
                        value: _vm.form.o_platform,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "o_platform", $$v)
                        },
                        expression: "form.o_platform"
                      }
                    },
                    _vm._l(_vm.o_searchplatform, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.platform_name,
                          value: item.platform_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "o_channel", label: "替换渠道" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择渠道"
                      },
                      on: {
                        change: function($event) {
                          return _vm.channelchange($event, "o_")
                        }
                      },
                      model: {
                        value: _vm.form.o_channel,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "o_channel", $$v)
                        },
                        expression: "form.o_channel"
                      }
                    },
                    _vm._l(_vm.o_searchchannel, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.channel_name,
                          value: item.channel_mark
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "o_pkg", label: "替换包名" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择包名"
                      },
                      model: {
                        value: _vm.form.o_pkg,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "o_pkg", $$v)
                        },
                        expression: "form.o_pkg"
                      }
                    },
                    _vm._l(_vm.o_searchpkg, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.pkg_name, value: item.pkg_mark }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }