var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "other" }, [
    _c("div", { staticClass: "therList", attrs: { id: "therList" } }, [
      _vm.otherhWidth
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  style: { width: _vm.otherhWidth + "px" },
                  attrs: {
                    data: _vm.orderList,
                    "max-height": _vm.otherhHeight + "px",
                    border: "",
                    "cell-style": {
                      textAlign: "center",
                      background: "none",
                      color: "#FFFFFF"
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "order_id", label: "订单" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { slot: "content" },
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.order_id)
                                    },
                                    slot: "content"
                                  }),
                                  _c("div", {
                                    staticClass: "text-ellipsis-1",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.order_id || "未设置"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2321392574
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "game", label: "游戏" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { slot: "content" },
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.game)
                                    },
                                    slot: "content"
                                  }),
                                  _c("div", {
                                    staticClass: "text-ellipsis-1",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.game || "未设置"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3238378494
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "channel", label: "渠道" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { slot: "content" },
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.channel)
                                    },
                                    slot: "content"
                                  }),
                                  _c("div", {
                                    staticClass: "text-ellipsis-1",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.channel || "未设置"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2388021950
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "platform", label: "平台" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { slot: "content" },
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.platform)
                                    },
                                    slot: "content"
                                  }),
                                  _c("div", {
                                    staticClass: "text-ellipsis-1",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.platform || "未设置"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3131865502
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pkg", label: "包名" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { slot: "content" },
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.pkg)
                                    },
                                    slot: "content"
                                  }),
                                  _c("div", {
                                    staticClass: "text-ellipsis-1",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.pkg || "未设置"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4274995134
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "价格" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { slot: "content" },
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.price)
                                    },
                                    slot: "content"
                                  }),
                                  _c("div", {
                                    staticClass: "text-ellipsis-1",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.price || "未设置"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4803262
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "product_id", label: "产品ID" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { slot: "content" },
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.product_id)
                                    },
                                    slot: "content"
                                  }),
                                  _c("div", {
                                    staticClass: "text-ellipsis-1",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.product_id || "未设置"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1144355166
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }