import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
import echartMixins from "@/utils/resizeMixins";
export default {
  data: function data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  mounted: function mounted() {
    this.draw();
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "chartRate"
    },
    tips: {
      type: Number,
      required: true,
      default: 50
    },
    colorObj: {
      type: Object,
      default: function _default() {
        return {
          textStyle: "#3fc0fb",
          series: {
            dataColor: {
              normal: "#03a9f4",
              shadowColor: "#97e2f5"
            }
          }
        };
      }
    }
  },
  methods: {
    draw: function draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById(this.id)); //  ----------------------------------------------------------------

      var tips = this.tips;
      var option = {
        title: [{
          text: tips * 1 + "%",
          x: "center",
          y: "center",
          textStyle: {
            color: this.colorObj.textStyle,
            fontSize: 16
          }
        }],
        series: [{
          type: "pie",
          radius: ["75%", "80%"],
          center: ["50%", "50%"],
          hoverAnimation: false,
          color: this.colorObj.series.color,
          label: {
            normal: {
              show: false
            }
          },
          data: [{
            value: tips,
            itemStyle: {
              normal: {
                color: this.colorObj.series.dataColor.normal,
                shadowBlur: 10,
                shadowColor: this.colorObj.series.dataColor.shadowColor
              }
            }
          }, {
            value: 100 - tips
          }]
        }]
      };
      this.chart.setOption(option);
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  }
};