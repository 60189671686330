//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addhost } from "@/api/liveData/liveData";
export default {
  data: function data() {
    return {
      showDialog: false,
      paramsData: {
        host_name: "",
        host_flag: ""
      }
    };
  },
  created: function created() {},
  computed: {},
  methods: {
    open: function open(data) {
      this.showDialog = true;
      this.paramsData = {
        host_name: "",
        host_flag: ""
      };
    },
    hide: function hide() {
      this.showDialog = false;
    },
    clearData: function clearData() {
      this.paramsData = {
        host_name: "",
        host_flag: ""
      };
    },
    submitData: function submitData() {
      var _this = this;

      if (!this.paramsData.host_name || !this.paramsData.host_flag) {
        return;
      }

      addhost(this.paramsData).then(function (res) {
        if (res.status == 200) {
          _this.$emit('change');

          _this.hide();
        }
      });
    }
  }
};