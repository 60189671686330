import request from '@/utils/request'; // 获取平台列表

export function platformList(data) {
  return request({
    url: '/platform/list',
    method: 'get',
    params: data
  });
} // 增加平台

export function addPlatform(data) {
  return request({
    url: '/platform/add',
    method: 'post',
    params: data
  });
} // 修改获取平台

export function upPlatform(data) {
  return request({
    url: '/platform/upPlatform',
    method: 'get',
    params: data
  });
} // 修改平台列表

export function update(data) {
  return request({
    url: '/platform/upPlatform',
    method: 'post',
    params: data
  });
} // 禁用平台列表

export function disablePlatform(data) {
  return request({
    url: '/platform/disablePlatform',
    method: 'get',
    params: data
  });
} // 删除平台

export function delPlatform(data) {
  return request({
    url: '/platform/delPlatform',
    method: 'get',
    params: data
  });
}