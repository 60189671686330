import request from '@/utils/request'; //获取用户中心换包配置列表

export function userConfigList(data) {
  return request({
    url: '/apply/user_replace',
    method: 'get',
    params: data
  });
} //新增用户中心换包配置

export function adduserConfig(data) {
  return request({
    url: '/apply/add_user_replace',
    method: 'post',
    params: data
  });
} //修改用户中心换包配置

export function updatauserConfig(data) {
  return request({
    url: '/apply/up_user_replace',
    method: 'post',
    params: data
  });
} // 反显数据

export function returnuserConfig(data) {
  return request({
    url: '/apply/up_user_replace',
    method: 'get',
    params: data
  });
} // 删除用户中心配置

export function deleuserConfig(data) {
  return request({
    url: '/apply/del_user_replace',
    method: 'post',
    params: data
  });
}