var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleSource(undefined, 1)
                }
              }
            },
            [_vm._v("添加广告位置")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "margin-top",
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "ID", label: "ID", "min-width": "60" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "game_reveal",
              label: "游戏名称",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "platform_reveal",
              label: "平台名称",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel_reveal",
              label: "渠道名称",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pkg_reveal",
              label: "包名名称",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "origin",
              label: "广告标识",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_channel_reveal",
              label: "广告渠道",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ad_placement_reveal",
              label: "广告位置",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", { attrs: { prop: "conf", label: "配置" } }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row.ID)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("adConfigRt", {
        ref: "adConfigRt",
        on: { refreshDataList: _vm.getData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }