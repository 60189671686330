var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "产品管理", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.dataReset()
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c(
              "div",
              { staticClass: "popover-item" },
              [
                _c(
                  "el-table",
                  {
                    ref: "gamemultipleTable",
                    attrs: {
                      height: "580",
                      "tooltip-effect": "dark",
                      data: _vm.gametableData,
                      border: "",
                      "show-overflow-tooltip": true
                    },
                    on: {
                      "selection-change": function($event) {
                        return _vm.handleSelectionChange(
                          $event,
                          "gameSelection"
                        )
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", prop: "game_id", width: "50" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "game_name", label: "游戏" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popover-item" },
              [
                _c(
                  "el-table",
                  {
                    ref: "platformmultipleTable",
                    attrs: {
                      height: "580",
                      "tooltip-effect": "dark",
                      data: _vm.platformtableData,
                      border: "",
                      "show-overflow-tooltip": true
                    },
                    on: {
                      "selection-change": function($event) {
                        return _vm.handleSelectionChange(
                          $event,
                          "platformSelection"
                        )
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", prop: "id", width: "50" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "platform_name", label: "平台" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popover-item" },
              [
                _c(
                  "el-table",
                  {
                    ref: "channelmultipleTable",
                    attrs: {
                      height: "580",
                      "tooltip-effect": "dark",
                      data: _vm.channeltableData,
                      border: "",
                      "show-overflow-tooltip": true
                    },
                    on: {
                      "selection-change": function($event) {
                        return _vm.handleSelectionChange(
                          $event,
                          "channelSelection"
                        )
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", prop: "id", width: "50" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "channel_name", label: "渠道" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popover-item" },
              [
                _c(
                  "el-table",
                  {
                    ref: "pkgmultipleTable",
                    attrs: {
                      height: "580",
                      "tooltip-effect": "dark",
                      data: _vm.pkgtableData,
                      border: "",
                      "show-overflow-tooltip": true
                    },
                    on: {
                      "selection-change": function($event) {
                        return _vm.handleSelectionChange($event, "pkgSelection")
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", prop: "id", width: "50" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "pkg_name", label: "包名" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { round: "" }, on: { click: _vm.dataReset } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.dataFormSubmit }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }