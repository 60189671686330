//
//
//
//
import { getRowDatagdt } from "@/api/dataAll/rowData";
import montorowData from './montorowData';
export default {
  name: "montorowDatagdt",
  components: {
    montorowData: montorowData
  },
  data: function data() {
    return {
      reqFns: null
    };
  },
  created: function created() {
    this.reqFns = getRowDatagdt;
  },
  methods: {}
};