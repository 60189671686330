import request from '@/utils/request'; // 渠道列表接口

export function channelList(data) {
  return request({
    url: '/channel/list',
    method: 'get',
    params: data
  });
} // 增加渠道

export function addChannel(data) {
  return request({
    url: '/channel/add',
    method: 'post',
    params: data
  });
} // 获取修改查询渠道

export function upChannel(data) {
  return request({
    url: '/channel/upChannel',
    method: 'get',
    params: data
  });
} // 修改

export function uploadChannel(data) {
  return request({
    url: '/channel/upChannel',
    method: 'post',
    params: data
  });
} //  禁用

export function disableChannel(data) {
  return request({
    url: '/channel/disableChannel',
    method: 'get',
    params: data
  });
} // 删除

export function delChannel(data) {
  return request({
    url: '/channel/delChannel',
    method: 'get',
    params: data
  });
}