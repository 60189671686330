import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
import echartMixins from "@/utils/resizeMixins";
export default {
  props: {
    dataCompare: {
      type: Object,
      required: true
    },
    height: {
      type: [String, Number],
      required: 0
    }
  },
  data: function data() {
    return {
      chart: null,
      comperDataSum: {},
      category: [],
      //横坐标
      charAmunt: [],
      //充值金额
      conversion: [] //日转换率

    };
  },
  created: function created() {
    this.comperDataSum = this.dataCompare;

    for (var k in this.comperDataSum) {
      this.category.push(k);
      this.conversion.push(this.comperDataSum[k].conversion_rate);
      this.charAmunt.push(this.comperDataSum[k].recharge_amount / 100);
    }
  },
  mounted: function mounted() {
    this.draw();
  },
  methods: {
    draw: function draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bottomLeftChart")); //  ----------------------------------------------------------------

      var category = this.category;
      var lineData = this.conversion;
      var barData = this.charAmunt;
      var option = {
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#F56A6A"
            }
          }
        },
        legend: {
          data: ["充值金额", "日转化率"],
          textStyle: {
            color: "#B4B4B4"
          },
          top: 0
        },
        xAxis: [{
          type: "category",
          data: category,
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          }
        }],
        yAxis: [{
          type: "value",
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          },
          axisLabel: {
            formatter: "{value}元"
          }
        }, {
          type: "value",
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisLabel: {
            formatter: "{value} %"
          }
        }],
        series: [{
          name: "充值金额",
          type: "bar",
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + "元";
            }
          },
          itemStyle: {
            color: "#FAC858"
          },
          data: barData
        }, {
          name: "日转化率",
          type: "line",
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + "%";
            }
          },
          itemStyle: {
            color: "#3FC0FB"
          },
          data: lineData
        }],
        grid: {
          width: 'auto',
          height: 'auto',
          top: "20%",
          left: '0%',
          //默认10%
          right: '3%',
          //默认10%
          bottom: '0',
          //默认60
          containLabel: true
        }
      };
      this.chart.setOption(option);
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  }
};