//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogShow: false,
      data: {}
    };
  },
  created: function created() {},
  methods: {
    init: function init(data) {
      this.data = data;
      this.dialogShow = true;
    }
  }
};