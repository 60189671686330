import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _createForOfIteratorHelper from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { serchSelect } from "@/mixins/index";
import { swallowClickNumber } from "@/api/dataAll/new";
import * as echarts from "echarts";
export default {
  name: "swallowClickNumber",
  mixins: [serchSelect],
  data: function data() {
    return {
      value2: [],
      searchData: {
        advert: ""
      },
      tableData: [],
      // 表格数据
      tableLtv: [],
      tableDate: "",
      // 表格时间
      show: false,
      echarts: false,
      // 图标显示
      dates: [],
      // 报表日期数组
      datas: [],
      // 报表时间数据
      series: [],
      selectedArr: {},
      more: true
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.value2[0] = date;
      this.value2[1] = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.searchData.time = this.searchData.starttime;
      swallowClickNumber(this.searchData).then(function (res) {
        var data_array = res.data;
        var table_top = "<th style='width:100px;'>广告点位</th>";
        var table_bodoy = "";
        var tableLtv = [];

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objTokey = function objTokey(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        tableLtv = objTokey(data_array);
        var table = [];
        _this.dates = [];
        _this.datas = [];

        var _iterator = _createForOfIteratorHelper(tableLtv),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var date = _step.value;

            _this.dates.push(date);

            table_top += '<th style="width:100px;line-height:40px;">' + date + "</th>";
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        for (var i in data_array) {
          for (var _a in data_array[i]) {
            if (table.indexOf(_a) > -1) {} else {
              table.push(_a);
            }
          }
        }

        _this.series = [];
        var data = objToTitle(data_array);
        data.forEach(function (item, i) {
          var ds = objToTitle(item);
          var obj = {
            name: _this.dates[i],
            type: "line",
            data: ds
          };

          _this.series.push(obj);
        });
        var a = null;

        _this.dates.forEach(function (val, i) {
          if (a != val) {
            a = val;

            if (i == 0) {
              _this.selectedArr[a] = true;
            } else {
              _this.selectedArr[a] = false;
            }
          }
        });

        table.sort();
        table.forEach(function (item, i) {
          _this.datas.push(item);

          table_bodoy += "<tr>";
          table_bodoy += "<td style='min-width:150px;WORD-WRAP:break-word'>".concat(item, "</td>");
          data.forEach(function (val, a) {
            table_bodoy += "<td style='WORD-WRAP:break-word'>" + val[item] + "</td>";
          });
          table_bodoy += "</tr>";
        });
        var header = document.getElementById("header");
        var bodoy = document.getElementById("bodoy");
        header.innerHTML = table_top;
        bodoy.innerHTML = table_bodoy;
        _this.show = true;
        _this.echarts = true; // this.getechart()
      });
    }
  }
};