var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: {
                    change: function($event) {
                      return _vm.getList(1)
                    }
                  },
                  model: {
                    value: _vm.paramsData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "is_prod", $$v)
                    },
                    expression: "paramsData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "ios_server_id", label: "IOS审核服ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "server_id", label: "服务器ID" }
          }),
          _c("el-table-column", { attrs: { prop: "pkg_name", label: "包名" } }),
          _c("el-table-column", {
            attrs: { prop: "version_list", label: "配置" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#409EFF" },
                        on: {
                          click: function($event) {
                            return _vm.openView(row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.currentPage,
          total: _vm.tableCopyTableList.length,
          "page-size": _vm.pageSize
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      }),
      _c(
        "el-drawer",
        {
          attrs: { title: "版本详情", visible: _vm.drawer },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-table",
                {
                  attrs: { data: _vm.versionList, border: "" },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "pkgName", label: "包名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "value", label: "版本号" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }