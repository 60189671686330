var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "角色", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.resetForm("ruleForm")
            }
          }
        },
        [
          _c("div", { staticClass: "popover" }, [
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("角色名称")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      placeholder: "请输入角色名称",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("角色描述")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      placeholder: "请输入角色描述",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "lodingItem" },
              [
                _c("div", { staticClass: "lodingItem-mintitle" }, [
                  _vm._v("展示主页")
                ]),
                _c(
                  "el-select",
                  {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.form.is_show_home,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "is_show_home", $$v)
                      },
                      expression: "form.is_show_home"
                    }
                  },
                  _vm._l(_vm.presentation, function(item) {
                    return _c("el-option", {
                      key: item.label,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("路由权限")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c("el-cascader", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      options: _vm.options,
                      props: _vm.props,
                      "collapse-tags": "",
                      clearable: "",
                      filterable: ""
                    },
                    model: {
                      value: _vm.valuearray,
                      callback: function($$v) {
                        _vm.valuearray = $$v
                      },
                      expression: "valuearray"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lodingItem" }, [
              _c("div", { staticClass: "lodingItem-mintitle" }, [
                _vm._v("状态")
              ]),
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1, border: "" } }, [
                        _vm._v("启用")
                      ]),
                      _c("el-radio", { attrs: { label: 0, border: "" } }, [
                        _vm._v("禁用")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }