// 广告配置
import request from '@/utils/request'; // 广告配置列表

export function configList(data) {
  return request({
    url: '/poster/data_config',
    method: 'get',
    params: data
  });
} // 添加广告配置

export function addConfig(data) {
  return request({
    url: 'poster/add_data_config',
    method: 'post',
    params: data
  });
} //   返回所有广告标识

export function posterAll(data) {
  return request({
    url: '/poster/list_all',
    method: 'get',
    params: data
  });
} //   游戏平台渠道包名

export function getAll(data) {
  return request({
    url: '/data/searchData',
    method: 'get',
    params: data
  });
} //   修改广告配置'

export function disablePoster(data) {
  return request({
    url: '/poster/update_data_config',
    method: 'post',
    params: data
  });
} //   删除广告配置

export function delPoster(data) {
  return request({
    url: '/poster/del_data_config',
    method: 'post',
    params: data
  });
}