//
//
//
//
//
//
//
//
//
import centerChart from "./chart/center/centerChartRate";
import centreRight2Chart2 from "./chart/centreRight2/centreRight2Chart2";
export default {
  props: {
    orderNumList: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      height: 0,
      titleItem: [{
        title: "今年累计任务建次数",
        number: {
          number: [120],
          toFixed: 1,
          content: "{nt}"
        }
      }, {
        title: "本月累计任务次数",
        number: {
          number: [18],
          toFixed: 1,
          content: "{nt}"
        }
      }, {
        title: "今日累计任务次数",
        number: {
          number: [2],
          toFixed: 1,
          content: "{nt}"
        }
      }, {
        title: "今年失败任务次数",
        number: {
          number: [14],
          toFixed: 1,
          content: "{nt}"
        }
      }, {
        title: "今年成功任务次数",
        number: {
          number: [106],
          toFixed: 1,
          content: "{nt}"
        }
      }, {
        title: "今年达标任务个数",
        number: {
          number: [100],
          toFixed: 1,
          content: "{nt}"
        }
      }],
      ranking: {
        data: [{
          name: "周口",
          value: 55
        }, {
          name: "南阳",
          value: 120
        }, {
          name: "西峡",
          value: 78
        }, {
          name: "驻马店",
          value: 66
        }, {
          name: "新乡",
          value: 80
        }, {
          name: "新乡",
          value: 80
        }, {
          name: "新乡",
          value: 80
        }, {
          name: "新乡",
          value: 80
        }],
        waitTime: 4000,
        carousel: "page",
        unit: "份"
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [{
        id: "centerRate1",
        tips: 60,
        colorData: {
          textStyle: "#3fc0fb",
          series: {
            dataColor: {
              normal: "#03a9f4",
              shadowColor: "#97e2f5"
            }
          }
        }
      }, {
        id: "centerRate2",
        tips: 40,
        colorData: {
          textStyle: "#67e0e3",
          series: {
            dataColor: {
              normal: "#ff9800",
              shadowColor: "#fcebad"
            }
          }
        }
      }]
    };
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      var orderNumC = document.getElementById('orderNumC');
      _this.height = orderNumC.clientHeight;
      _this.rate = _this.rateData; // console.log(this.height, 'orderNum')
    });
  },
  components: {
    centerChart: centerChart,
    centreRight2Chart2: centreRight2Chart2
  }
};