var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "发送礼包", visible: _vm.dialogShow },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: _vm.clearData
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  key: "gift_id",
                  staticClass: "lodingItem",
                  attrs: { prop: "gift_id", label: "礼包ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { type: "number", placeholder: "请输入ID" },
                    model: {
                      value: _vm.paramsData.gift_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "gift_id", _vm._n($$v))
                      },
                      expression: "paramsData.gift_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "name",
                  staticClass: "lodingItem",
                  attrs: { prop: "name", label: "礼包名字" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { type: "number", placeholder: "请输入名字" },
                    model: {
                      value: _vm.paramsData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "name", _vm._n($$v))
                      },
                      expression: "paramsData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "desc",
                  staticClass: "lodingItem",
                  attrs: { prop: "desc", label: "礼包描述" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入礼包描述" },
                    model: {
                      value: _vm.paramsData.desc,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "desc", $$v)
                      },
                      expression: "paramsData.desc"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "icon",
                  staticClass: "lodingItem",
                  attrs: { prop: "icon", label: "图标ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { type: "number", placeholder: "请输入图标id" },
                    model: {
                      value: _vm.paramsData.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "icon", _vm._n($$v))
                      },
                      expression: "paramsData.icon"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "refresh",
                  staticClass: "lodingItem",
                  attrs: { prop: "refresh", label: "刷新类型" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { clearable: "", placeholder: "请选择刷新类型" },
                      model: {
                        value: _vm.paramsData.refresh,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "refresh", $$v)
                        },
                        expression: "paramsData.refresh"
                      }
                    },
                    _vm._l(_vm.refreshType, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "expire",
                  staticClass: "lodingItem",
                  attrs: { prop: "expire", label: "失效时间" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "日期"
                    },
                    on: { change: _vm.expireChange },
                    model: {
                      value: _vm.paramsData.expire,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "expire", $$v)
                      },
                      expression: "paramsData.expire"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "is_delay",
                  staticClass: "lodingItem",
                  attrs: { prop: "is_delay", label: "定时发送" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        disabled: _vm.paramsData.id ? true : false,
                        placeholder: "请选择是否定时发送"
                      },
                      model: {
                        value: _vm.paramsData.is_delay,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "is_delay", $$v)
                        },
                        expression: "paramsData.is_delay"
                      }
                    },
                    _vm._l(_vm.timing, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.paramsData.is_delay == 1
                ? _c(
                    "el-form-item",
                    {
                      key: "delay_time",
                      staticClass: "lodingItem",
                      attrs: { prop: "delay_time", label: "发送时间" }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "operationFrame-entity",
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          "value-format": "timestamp",
                          type: "datetime",
                          placeholder: "日期"
                        },
                        model: {
                          value: _vm.paramsData.delay_time,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "delay_time", $$v)
                          },
                          expression: "paramsData.delay_time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  key: "num",
                  staticClass: "lodingItem",
                  attrs: { prop: "num", label: "礼包数量" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { type: "number", placeholder: "请输入礼包数量" },
                    model: {
                      value: _vm.paramsData.num,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "num", _vm._n($$v))
                      },
                      expression: "paramsData.num"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "has_discount",
                  staticClass: "lodingItem",
                  attrs: { prop: "has_discount", label: "是否打折" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { clearable: "", placeholder: "请选择" },
                      on: {
                        change: function($event) {
                          _vm.paramsData.original_cost = []
                        }
                      },
                      model: {
                        value: _vm.paramsData.has_discount,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "has_discount", $$v)
                        },
                        expression: "paramsData.has_discount"
                      }
                    },
                    _vm._l(_vm.discount, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.paramsData.has_discount == 1
                ? _c(
                    "el-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "lodingItem ",
                          staticStyle: { "margin-bottom": "0" },
                          attrs: {
                            prop: "original_cost",
                            rules:
                              _vm.paramsData.has_discount == 1
                                ? _vm.rules.original_cost
                                : []
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.addDomain("original_cost")
                                }
                              }
                            },
                            [_vm._v("添加原价 ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.paramsData.original_cost, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index + "original_cost",
                    staticClass: "lodingItem",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-button", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain("original_cost", index)
                            }
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "original_cost." + index + ".type",
                              rules: _vm.rules.type
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { placeholder: "类型" },
                                on: {
                                  change: function($event) {
                                    return _vm.domainType(
                                      "original_cost",
                                      index
                                    )
                                  }
                                },
                                model: {
                                  value: domain.type,
                                  callback: function($$v) {
                                    _vm.$set(domain, "type", $$v)
                                  },
                                  expression: "domain.type"
                                }
                              },
                              _vm._l(_vm.itemType, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: {
                                    label: items.label,
                                    value: items.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              margin: "0px 10px 0 0",
                              "min-width": "150px"
                            },
                            attrs: {
                              prop: "original_cost." + index + ".id",
                              rules: _vm.rules.item_id
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "150px"
                                },
                                attrs: {
                                  filterable: "",
                                  disabled: !domain.type,
                                  placeholder: "物品"
                                },
                                model: {
                                  value: domain.id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "id", $$v)
                                  },
                                  expression: "domain.id"
                                }
                              },
                              [
                                _vm._l(_vm.itemType1, function(items, indexs) {
                                  return domain.type == 1
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType2, function(items, indexs) {
                                  return domain.type == 2
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType3, function(items, indexs) {
                                  return domain.type == 3
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType4, function(items, indexs) {
                                  return domain.type == 4
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType5, function(items, indexs) {
                                  return domain.type == 5
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType6, function(items, indexs) {
                                  return domain.type == 6
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType7, function(items, indexs) {
                                  return domain.type == 7
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType8, function(items, indexs) {
                                  return domain.type == 8
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "original_cost." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", _vm._n($$v))
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        ),
                        domain.type == 2
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { margin: "0px 10px 0 0" },
                                attrs: {
                                  prop: "original_cost." + index + ".quality",
                                  rules: _vm.rules.item_quality
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "品质" },
                                    model: {
                                      value: domain.quality,
                                      callback: function($$v) {
                                        _vm.$set(domain, "quality", $$v)
                                      },
                                      expression: "domain.quality"
                                    }
                                  },
                                  _vm._l(_vm.quality, function(items, indexs) {
                                    return _c("el-option", {
                                      key: indexs,
                                      attrs: { label: items, value: items }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              }),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { prop: "cost" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addDomain("cost")
                            }
                          }
                        },
                        [_vm._v("添加售价 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.cost, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index + "cost",
                    staticClass: "lodingItem",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-button", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain("cost", index)
                            }
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "cost." + index + ".type",
                              rules: _vm.rules.type
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "类型" },
                                on: {
                                  change: function($event) {
                                    return _vm.domainType("cost", index)
                                  }
                                },
                                model: {
                                  value: domain.type,
                                  callback: function($$v) {
                                    _vm.$set(domain, "type", $$v)
                                  },
                                  expression: "domain.type"
                                }
                              },
                              _vm._l(_vm.itemType, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: {
                                    label: items.label,
                                    value: items.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              margin: "0px 10px 0 0",
                              "min-width": "150px"
                            },
                            attrs: {
                              prop: "cost." + index + ".id",
                              rules: _vm.rules.item_id
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "150px"
                                },
                                attrs: {
                                  filterable: "",
                                  disabled: !domain.type,
                                  placeholder: "物品"
                                },
                                model: {
                                  value: domain.id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "id", $$v)
                                  },
                                  expression: "domain.id"
                                }
                              },
                              [
                                _vm._l(_vm.itemType1, function(items, indexs) {
                                  return domain.type == 1
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType2, function(items, indexs) {
                                  return domain.type == 2
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType3, function(items, indexs) {
                                  return domain.type == 3
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType4, function(items, indexs) {
                                  return domain.type == 4
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType5, function(items, indexs) {
                                  return domain.type == 5
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType6, function(items, indexs) {
                                  return domain.type == 6
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType7, function(items, indexs) {
                                  return domain.type == 7
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType8, function(items, indexs) {
                                  return domain.type == 8
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "cost." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", _vm._n($$v))
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        ),
                        domain.type == 2
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { margin: "0px 10px 0 0" },
                                attrs: {
                                  prop: "cost." + index + ".quality",
                                  rules: _vm.rules.item_quality
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "品质" },
                                    model: {
                                      value: domain.quality,
                                      callback: function($$v) {
                                        _vm.$set(domain, "quality", $$v)
                                      },
                                      expression: "domain.quality"
                                    }
                                  },
                                  _vm._l(_vm.quality, function(items, indexs) {
                                    return _c("el-option", {
                                      key: indexs,
                                      attrs: { label: items, value: items }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              }),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { prop: "reward" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addDomain("reward")
                            }
                          }
                        },
                        [_vm._v("添加物品 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.reward, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index + "reward",
                    staticClass: "lodingItem",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-button", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain("reward", index)
                            }
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "reward." + index + ".type",
                              rules: _vm.rules.type
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "类型" },
                                on: {
                                  change: function($event) {
                                    return _vm.domainType("reward", index)
                                  }
                                },
                                model: {
                                  value: domain.type,
                                  callback: function($$v) {
                                    _vm.$set(domain, "type", $$v)
                                  },
                                  expression: "domain.type"
                                }
                              },
                              _vm._l(_vm.itemType, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: {
                                    label: items.label,
                                    value: items.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              margin: "0px 10px 0 0",
                              "min-width": "150px"
                            },
                            attrs: {
                              prop: "reward." + index + ".id",
                              rules: _vm.rules.item_id
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "150px"
                                },
                                attrs: {
                                  filterable: "",
                                  disabled: !domain.type,
                                  placeholder: "物品"
                                },
                                model: {
                                  value: domain.id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "id", $$v)
                                  },
                                  expression: "domain.id"
                                }
                              },
                              [
                                _vm._l(_vm.itemType1, function(items, indexs) {
                                  return domain.type == 1
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType2, function(items, indexs) {
                                  return domain.type == 2
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType3, function(items, indexs) {
                                  return domain.type == 3
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType4, function(items, indexs) {
                                  return domain.type == 4
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType5, function(items, indexs) {
                                  return domain.type == 5
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType6, function(items, indexs) {
                                  return domain.type == 6
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType7, function(items, indexs) {
                                  return domain.type == 7
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType8, function(items, indexs) {
                                  return domain.type == 8
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "reward." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", _vm._n($$v))
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        ),
                        domain.type == 2
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { margin: "0px 10px 0 0" },
                                attrs: {
                                  prop: "reward." + index + ".quality",
                                  rules: _vm.rules.item_quality
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "品质" },
                                    model: {
                                      value: domain.quality,
                                      callback: function($$v) {
                                        _vm.$set(domain, "quality", $$v)
                                      },
                                      expression: "domain.quality"
                                    }
                                  },
                                  _vm._l(_vm.quality, function(items, indexs) {
                                    return _c("el-option", {
                                      key: indexs,
                                      attrs: { label: items, value: items }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }