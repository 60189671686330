var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
          on: {
            select: function($event) {
              return _vm.handover($event)
            }
          }
        },
        [
          _c("el-menu-item", { attrs: { index: "systemEvents" } }, [
            _vm._v("系统事件")
          ]),
          _c("el-menu-item", { attrs: { index: "customizationEvents" } }, [
            _vm._v("自定义事件")
          ])
        ],
        1
      ),
      _c("systemEvents", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "systemEvents",
            expression: "activeIndex == 'systemEvents'"
          }
        ],
        ref: "systemEvents"
      }),
      _c("customizationEvents", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "customizationEvents",
            expression: "activeIndex == 'customizationEvents'"
          }
        ],
        ref: "customizationEvents"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }