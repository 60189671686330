var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  ref: "",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择游戏"
                  },
                  on: { change: _vm.gamechange },
                  model: {
                    value: _vm.searchData.game,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "game", $$v)
                    },
                    expression: "searchData.game"
                  }
                },
                _vm._l(_vm.searchgames, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        "value-key": item.id,
                        label: item.game_name,
                        value: item.id
                      }
                    },
                    [_vm._v(_vm._s(item.game_name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择平台"
                  },
                  on: { change: _vm.platformchange },
                  model: {
                    value: _vm.searchData.platform,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "platform", $$v)
                    },
                    expression: "searchData.platform"
                  }
                },
                _vm._l(_vm.searchplatforms, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.platform_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择渠道"
                  },
                  on: { change: _vm.channelchange },
                  model: {
                    value: _vm.searchData.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "channel", $$v)
                    },
                    expression: "searchData.channel"
                  }
                },
                _vm._l(_vm.searchchannels, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择投放包"
                  },
                  on: { change: _vm.pkgchange },
                  model: {
                    value: _vm.searchData.pkg,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "pkg", $$v)
                    },
                    expression: "searchData.pkg"
                  }
                },
                _vm._l(_vm.searchpkgs, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.pkg_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "13px" },
                  attrs: { type: "success" },
                  on: { click: _vm.searchClick }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 4 } })
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", round: "" },
              on: {
                click: function($event) {
                  return _vm.handleSource("t", 1)
                }
              }
            },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "80", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "timestamp",
              label: "日期",
              align: "center",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "game", label: "游戏", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "platform", label: "平台", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "channel", label: "渠道", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pkg",
              label: "包名",
              align: "center",
              "min-width": "140"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "monitor_url",
              label: "展示监控链接",
              align: "center",
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                          cursor: "pointer"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.monitor_url))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleSource(scope.row.id, 2)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleClipboard(
                                scope.row.monitor_url,
                                $event
                              )
                            }
                          }
                        },
                        [_vm._v("复制")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total,
          "page-size": _vm.pagrSize
        },
        on: { "current-change": _vm.handleCurrentChange }
      }),
      _vm.addWayConfig
        ? _c("addafLinks", {
            ref: "addWayConfig",
            attrs: { showing: _vm.showing },
            on: { changeShow: _vm.changeShow, refreshDataList: _vm.searchClick }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }