import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addcurrencyMapping from "./components/addcurrencyMapping.vue";
import { tabulation } from "@/mixins/index";
import { getCurrencyManagement, delCurrencyManagement } from "@/api/dataAll/currencyMapping";
import * as echarts from "echarts";
export default {
  name: "currencyMapping",
  mixins: [tabulation],
  components: {
    addcurrencyMapping: addcurrencyMapping
  },
  data: function data() {
    return {
      tableData: [],
      sels: [],
      selsString: ''
    };
  },
  created: function created() {
    this.searchClick();
  },
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      this.reqLoading = true;
      getCurrencyManagement({
        game_id: this.rawData.game_id
      }).then(function (res) {
        if (res.status == 200) {
          _this.tableData = res.data;
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this2 = this;

      this.sels = [];
      val.forEach(function (e) {
        if (_this2.tableData.findIndex(function (i) {
          return i.id == e.id;
        }) > -1) {
          _this2.sels.push(e.id);
        }
      });
      this.selsString = this.sels.toString();
    },
    // 删除配置
    handleDelete: function handleDelete(id) {
      var _this3 = this;

      this.$confirm("此操作将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        delCurrencyManagement({
          id: id.toString()
        }).then(function (res) {
          if (res.status == 200) {
            _this3.searchClick();

            _this3.$message({
              type: "success",
              message: '删除成功'
            });
          }
        });
      });
    }
  }
};