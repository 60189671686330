import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var jsDiff = require("diff");

import uploadImagetow from "@/components/uploadImagetow";
import { clickOperationLog } from "@/api/gmtool/packageCode";
export default {
  components: {
    uploadImagetow: uploadImagetow
  },
  data: function data() {
    return {
      dialogShow: false,
      paramsData: {
        text1: '',
        text2: ''
      },
      iscontentDom: false
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      this.paramsData.text1 = data;
      this.dialogShow = true;
    },
    clearData: function clearData() {
      this.paramsData = {
        text1: '',
        text2: ''
      };
      var contentDom = document.getElementById("content");
      contentDom.innerHTML = "";
      this.iscontentDom = false;
    },
    dataFormSubmit: function dataFormSubmit() {
      this.iscontentDom = true;
      var list = jsDiff.diffWordsWithSpace(this.paramsData.text1, this.paramsData.text2);
      var virtualDOM = document.createDocumentFragment();
      list.forEach(function (part) {
        var span = document.createElement("span");
        span.style.color = part.added ? "red" : "#333333";
        span.style["font-weight"] = part.added ? "600" : "400";

        if (part.removed) {
          span.style["text-decoration"] = "line-through";
        }

        span.appendChild(document.createTextNode(part.value));
        virtualDOM.appendChild(span);
      });
      var contentDom = document.getElementById("content");
      contentDom.innerHTML = "";
      contentDom.appendChild(virtualDOM);
      this.lodclickOperationLog('compare-config', '点击对比按钮');
    },
    lodclickOperationLog: function lodclickOperationLog(type, name) {
      var other = {
        'origin-data': this.paramsData.text1,
        'desc-config': this.paramsData.text2
      };
      clickOperationLog({
        click: type,
        name: name,
        other: JSON.stringify(other)
      });
    }
  }
};