//监测统计数据
import request from '@/utils/request';
export function getStaticData(data) {
  return request({
    url: '/datum/monitoring_statistics_data',
    method: 'get',
    params: data
  });
}
export function getStaticDatajl(data) {
  return request({
    url: '/datum/monitoring_jl_statistics_data',
    method: 'get',
    params: data
  });
}
export function getStaticDatagdt(data) {
  return request({
    url: '/datum/monitoring_gdt_statistics_data',
    method: 'get',
    params: data
  });
}