import request from '@/utils/request'; //新增投放渠道收益

export function addIncomOfChannle(data) {
  return request({
    url: '/advertising/add_channel_earnings',
    method: 'post',
    params: data
  });
} //展示投放渠道收益数据

export function getIncomOfChannleList(data) {
  return request({
    url: '/advertising/channel_earnings',
    method: 'get',
    params: data
  });
} //获取反显投放渠道收益数据

export function returnIncomOfChannle(data) {
  return request({
    url: '/advertising/update_channel_earnings',
    method: 'get',
    params: data
  });
} //修改投放渠道收益数据

export function updateIncomOfChannle(data) {
  return request({
    url: '/advertising/update_channel_earnings',
    method: 'post',
    params: data
  });
} //删除投放渠道收益数据

export function deleIncomOfChannle(data) {
  return request({
    url: '/advertising/del_channel_earnings',
    method: 'post',
    params: data
  });
}