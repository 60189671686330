import request from '@/utils/request'; // 游戏-平台-渠道-包名

export function getAll(data) {
  return request({
    url: '/data/searchData',
    method: 'get',
    params: data
  });
} // 游戏关联平台-获取已关联ID

export function getGameId(data) {
  return request({
    url: '/data/game_platform',
    method: 'get',
    params: data
  });
} // 游戏关联平台展示

export function getGamePlatform(data) {
  return request({
    url: '/data/game_platform',
    method: 'post',
    params: data
  });
} // 平台关联渠道-获取关联渠道id

export function getPlatformId(data) {
  return request({
    url: '/data/platform_channel',
    method: 'get',
    params: data
  });
} // 平台关联渠道展示

export function getPlatformChannel(data) {
  return request({
    url: '/data/platform_channel',
    method: 'post',
    params: data
  });
} //渠道关联包名 -获取关联包名ID

export function getChannelPkgId(data) {
  return request({
    url: '/data/channel_pkg',
    method: 'get',
    params: data
  });
} //渠道关联包名 -获取包名展示

export function getChannelPkgList(data) {
  return request({
    url: '/data/channel_pkg',
    method: 'post',
    params: data
  });
} // 设置关联（更新接口）

export function relationSetting(data) {
  return request({
    url: '/adv/relevance_center',
    method: 'post',
    params: data
  });
}