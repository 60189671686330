import request from '@/utils/request'; //获取网关换包配置列表

export function getwayConfigList(data) {
  return request({
    url: '/apply/gateway_replace',
    method: 'get',
    params: data
  });
} //新增网关换包配置

export function addgetwayConfig(data) {
  return request({
    url: '/apply/add_gateway_replace',
    method: 'post',
    params: data
  });
} //修改网关换包配置

export function updatagetwayConfig(data) {
  return request({
    url: '/apply/up_gateway_replace',
    method: 'post',
    params: data
  });
} // 反显数据

export function returngetwayConfig(data) {
  return request({
    url: '/apply/up_gateway_replace',
    method: 'get',
    params: data
  });
} // 删除配置

export function delegetwayConfig(data) {
  return request({
    url: '/apply/del_gateway_replace',
    method: 'post',
    params: data
  });
}