var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id ? "修改" : "提交",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  key: "op_type",
                  staticClass: "lodingItem",
                  attrs: { prop: "op_type", label: "操作" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { placeholder: "请选择封禁状态" },
                      model: {
                        value: _vm.paramsData.op_type,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "op_type", $$v)
                        },
                        expression: "paramsData.op_type"
                      }
                    },
                    _vm._l(_vm.whetherNot, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addDomain }
                    },
                    [_vm._v("添加角色")]
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.uids, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "flex",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "uids",
                        staticClass: "lodingItem",
                        attrs: {
                          prop: "uids." + index + ".uids",
                          rules: _vm.rules.uids,
                          label: "角色ID"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "operationFrame-entity",
                          attrs: { placeholder: "角色ID" },
                          model: {
                            value: domain.uids,
                            callback: function($$v) {
                              _vm.$set(domain, "uids", _vm._n($$v))
                            },
                            expression: "domain.uids"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.paramsData.op_type == 1
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "lodingItem",
                            staticStyle: {
                              margin: "0 0 20px 20px",
                              width: "360px"
                            },
                            attrs: { label: "是否永禁" }
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-text": "开启",
                                "inactive-text": "关闭",
                                "active-color": "#13ce66"
                              },
                              model: {
                                value: domain.isForever,
                                callback: function($$v) {
                                  _vm.$set(domain, "isForever", $$v)
                                },
                                expression: "domain.isForever"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.paramsData.op_type == 1
                      ? _c(
                          "el-form-item",
                          {
                            key: "expire_time",
                            staticClass: "lodingItem",
                            attrs: {
                              prop: "uids." + index + ".expire_time",
                              rules: !domain.isForever
                                ? _vm.rules.expire_time
                                : [],
                              label: "封禁时间"
                            }
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                "popper-class": "no-atTheMoment",
                                disabled: domain.isForever,
                                "value-format": "timestamp",
                                type: "datetime",
                                placeholder: "日期"
                              },
                              model: {
                                value: domain.expire_time,
                                callback: function($$v) {
                                  _vm.$set(domain, "expire_time", $$v)
                                },
                                expression: "domain.expire_time"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.paramsData.uids.length > 1
                      ? _c("el-button", {
                          staticStyle: { margin: "0 10px 20px 20px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain(domain)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "flex lodingItem-title first_box",
                  staticStyle: {
                    "margin-bottom": "20px",
                    width: "100%",
                    "align-items": "center"
                  }
                },
                [_vm._v(" 原因: ")]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: _vm.paramsData.op_type == 1 ? "reason" : "" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 12 },
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.paramsData.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "reason", $$v)
                      },
                      expression: "paramsData.reason"
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "", disabled: _vm.issubmit },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }